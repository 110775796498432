import {
  convertDateToString,
  getScoreText,
  getScoreColor,
} from "utils/globalFunctions";
import { months } from "utils/globalData";
import {
  Block,
  BlockBody,
  BlockHeader,
  Card,
  CardBody,
  CardHeader,
} from "styles";
import { Body, Header, TableContent } from "components/Table/style";
function HistoricTable({ perPeriod, type, broiler, broilerList }) {
  const getCards = () => {
    const colors = ["primary", "info", "danger", "dark", "warning"];
    let i = -1;
    return broilerList.map((item, index) => {
      if (i > 3) {
        i = 0;
      } else {
        i++;
      }
      return (
        <Card
          id={`historicPerPeriod-${index}`}
          key={`historicPerPeriod-${index}`}
        >
          <CardHeader>
            <strong>
              {broiler.filter(({ value }) => value === item)[0].label}
            </strong>
          </CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th className=" text-right" style={{ width: "130px" }}>
                    Fase
                  </th>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map((item, index) => (
                      <th className={` text-${colors[i]}`} key={index}>
                        {(type === "Semanal"
                          ? `Semana ${item.week}`
                          : months[item.month]) +
                          "/" +
                          item.year +
                          ` - De ${convertDateToString(
                            type === "Semanal"
                              ? item.startOfWeek
                              : item.startOfMonth
                          )} até ${convertDateToString(
                            type === "Semanal"
                              ? item.endOfWeek
                              : item.endOfMonth
                          )}`}
                      </th>
                    ))}
                </tr>
              </Header>
              <Body>
                <tr>
                  <td className=" text-right">Pré</td>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map(({ historic }, index) => (
                      <td className={` text-${colors[i]}`} key={index}>
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["moleculePre"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.pre?.product}
                        /
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["dosePre"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.pre?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td className=" text-right">Inicial</td>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map(({ historic }, index) => (
                      <td className={` text-${colors[i]}`} key={index}>
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["moleculeInitial"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.initial?.product}
                        /
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["doseInitial"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.initial?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td className=" text-right">Crescimento 1</td>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map(({ historic }, index) => (
                      <td className={` text-${colors[i]}`} key={index}>
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["moleculeGrowth"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.growth?.product}
                        /
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["doseGrowth"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.growth?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td className=" text-right">Crescimento 2</td>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map(({ historic }, index) => (
                      <td className={` text-${colors[i]}`} key={index}>
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["moleculeGrowth2"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.growth2?.product}
                        /
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["doseGrowth2"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.growth2?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td className=" text-right">Final</td>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map(({ historic }, index) => (
                      <td className={` text-${colors[i]}`} key={index}>
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["moleculefinal"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.final?.product}
                        /
                        {item.split(".")[1] === "anticoccidian"
                          ? historic?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.["doseFinal"]
                          : historic?.[item.split(".")[0]]?.[item.split(".")[1]]
                              ?.final?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td className=" text-right">
                    <strong>Score</strong>
                  </td>
                  {perPeriod
                    .sort(({ historic }) => !!historic)
                    .map(({ totalScore }, index) => (
                      <td
                        className={` `}
                        key={index}
                        style={{ color: getScoreColor(+totalScore) }}
                      >
                        <strong>
                          {totalScore.toFixed(2).replace(".", ",")}
                        </strong>
                        <small className="ml-1">
                          ({getScoreText(+totalScore)})
                        </small>
                      </td>
                    ))}
                </tr>
              </Body>
            </TableContent>
          </CardBody>
        </Card>
      );
    });
  };
  return (
    <Block>
      <BlockHeader> Programa por Período</BlockHeader>
      <BlockBody>
        <div style={{ marginBottom: "15px" }}>{getCards()}</div>
      </BlockBody>
    </Block>
  );
}

export default HistoricTable;
