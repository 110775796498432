import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";

import { Separator } from "styles";

import { H2 } from "../style";
import { Table } from "../Table";

import ReactLoading from "react-loading";

export function Module2(props) {
  const [data, setData] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    description: "",
  });

  const [loading, setLoading] = useState(true);

  const [getData, setGetData] = useState(true);

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };

    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar criação da Termo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar Avaliação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`inspectiontermbpf/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Criar Termo",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }

    /* if (!props.id) {
      const response = await Swal.fire({
        title: "Criar Termo",
        text: "Deseja confirmar criação da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`inspectiontermbpf/${props.match.params.id}`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setData((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState._id = response.value.data.id;
          return newState;
        });
        props.setUpdateList(true);
      }
    } else {
      query._id = props.id;
      const response = await Swal.fire({
        title: "Editar Termo",
        text: "Deseja confirmar edição da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Termo!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(
              `inspectiontermbpf/${props.match.params.id}/${props.id}`,
              query
            )
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        props.setUpdateList(true);
      }
    } */
  }, [data, props]);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `inspectiontermbpf/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire("Editar Termo", "Não foi possível buscar a Termo", "error");
        }
        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>ANEXO IV B – Modelo de Termo de Fiscalização - BPF</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <H2>Módulo II</H2>

          <Separator />
          <Table
            module={data}
            setModule={setData}
            title="USO DE MEDICAMENTOS/PRODUTOS MEDICADOS"
            body={[
              [
                "",
                "L",
                "1.",
                "As medidas preventivas relativas à contaminação cruzada devido ao armazenamento e uso de medicamentos/produtos medicados estão contempladas em procedimento escrito e são atendidas.",
                "1",
              ],
              [
                "",
                "DL",
                "2.",
                "Os medicamentos são licenciados no MAPA, utilizados nas categorias de produtos às quais são indicados, nas quantidades prescritas e a prescrição veterinária atende à legislação específica. (RR)",
                "2",
              ],
              [
                "",
                "DL",
                "3.",
                "Para estabelecimento que manipula somente os produtos com medicamento, estes provêm de estabelecimento autorizado, são utilizados nas categorias de produtos e espécies as quais são indicadas, nas quantidades prescritas e a prescrição veterinária atende à legislação específica. (RR)",
                "3",
              ],
              [
                "",
                "DL",
                "4.",
                "Produtos acabados atendem aos requisitos específicos de rotulagem de produtos medicados.",
                "4",
              ],
              [
                "",
                "D",
                "5.",
                "Há evidências de que premixes, núcleos e concentrados com medicamentos sejam comercializados apenas para estabelecimentos autorizados a fabricar rações, suplementos e concentrados com medicamentos ou produtos medicados.",
                "5",
              ],
              [
                "",
                "D",
                "6.",
                "Realiza monitoramento laboratorial que evidencia o controle da contaminação cruzada e eficiência de limpeza para os diferentes princípios ativos utilizados. (RR)",
                "6",
              ],
            ]}
          />

          <Input
            type="textarea"
            item={data}
            setItem={setData}
            params="description"
            label="DESCREVER AS NÃO CONFORMIDADES:"
            rows={20}
          />
        </>
      )}
    </Modal>
  );
}
