import { useCallback, useEffect, useState } from "react";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";

import { Separator } from "styles";

import { H2 } from "../style";
import { Table } from "../Table";

import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { api } from "services/api";

export function Module3(props) {
  const [data, setData] = useState({
    56: "",
    57: "",
    58: "",
    58_1: "",
    59: "",
    60: "",
    61: "",
    62: "",
    63: "",
    64: "",
    65: "",
    66: "",
    67: "",
    68: "",
    69: "",

    description: "",
  });

  const [loading, setLoading] = useState(true);

  const [getData, setGetData] = useState(true);

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };
    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar criação da Termo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar Avaliação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`inspectiontermbpf/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Criar Termo",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }

    /*  if (!props.id) {
      const response = await Swal.fire({
        title: "Criar Termo",
        text: "Deseja confirmar criação da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`inspectiontermbpf/${props.match.params.id}`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setData((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState._id = response.value.data.id;
          return newState;
        });
        props.setUpdateList(true);
      }
    } else {
      query._id = props.id;
      const response = await Swal.fire({
        title: "Editar Termo",
        text: "Deseja confirmar edição da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Termo!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(
              `inspectiontermbpf/${props.match.params.id}/${props.id}`,
              query
            )
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        props.setUpdateList(true);
      }
    } */
  }, [data, props]);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `inspectiontermbpf/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire("Editar Termo", "Não foi possível buscar a Termo", "error");
        }
        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>ANEXO IV B – Modelo de Termo de Fiscalização - BPF</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <H2>Módulo III</H2>
          <Separator />
          <Table
            module={data}
            setModule={setData}
            title="RESPALDO PARA CERTIFICAÇÃO OFICIAL
        (cite os países e produtos)"
            body={[
              [
                "",
                "D",
                "56.",
                "Os produtos acabados, o processo de fabricação e os produtos utilizados, inclusive os de origem animal, atendem às exigências dos países importadores permitindo a certificação para a exportação (ou sua manutenção). (RR)",
                "56",
              ],
              [
                "",
                "D",
                "57.",
                "Especificamente sobre as análises laboratoriais que embasam a certificação: estas são condizentes com os certificados a que estão submetidos os produtos. (RR)",
                "57",
              ],
              [
                "",
                "DL",
                "58.",
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "800px" }}>
                    SE A CERTIFICAÇÃO OFICIAL DEPENDE DE APPCC,avalie-o quanto:
                  </div>
                  <Input
                    type="inputOnly"
                    item={data}
                    setItem={setData}
                    params="58_1"
                  />
                </div>,
                "58",
              ],
              [
                "",
                "D",
                "59.",
                "Programa escrito: equipe, descrição de todos os perigos (biológicos, físicos e químicos).",
                "59",
              ],
              [
                "",
                "D",
                "60.",
                "Registros de monitoramento e ações corretivas dos PCCs.",
                "60",
              ],
              [
                "",
                "D",
                "61.",
                "Registros de verificação e ações corretivas dos PCCs.",
                "61",
              ],
              [
                "",
                "D",
                "62.",
                "Registros de validação do programa escrito.",
                "62",
              ],
            ]}
          />
          <H2>Módulo IV</H2>
          <Separator />
          <Table
            module={data}
            setModule={setData}
            title="FABRICAÇÃO COMPARTILHADA PARA RUMINANTES E NÃO RUMINANTES COM USO DE PRODUTOS DE ORIGEM ANIMAL"
            body={[
              [
                "",
                "D",
                "63.",
                "POP descreve as medidas corretivas no caso de ocorrência de contaminação por ingrediente de origem animal detectada no monitoramento por meio de análise de laboratório e estas são adotadas.",
                "63",
              ],
              [
                "",
                "D",
                "64.",
                "Possui linhas completamente separadas até a entrada do misturador.",
                "64",
              ],

              [
                "",
                "D",
                "65.",
                "Monitora 10% dos lotes produzidos para alimentação de ruminantes.",
                "65",
              ],
            ]}
          />
          <H2>Módulo V</H2>
          <Separator />
          <Table
            module={data}
            setModule={setData}
            title="ADESÃO AO SISTEMA DE PRODUÇÃO LIVRE DE RACTOPAMINA"
            body={[
              [
                "",
                "D",
                "66.",
                "Para estabelecimentos de dedicação total: premixes, núcleos e concentrados são provenientes de estabelecimentos constantes da lista de fornecedores do MAPA.",
                "66",
              ],
              [
                "",
                "D",
                "67.",
                "Para estabelecimentos de dedicação total: demais produtos (aditivos, coprodutos e ingredientes) são monitorados pela empresa quanto à presença de ractopamina.",
                "67",
              ],

              [
                "",
                "DL",
                "68.",
                "Para estabelecimentos de dedicação parcial: Plantas baixas e linhas de equipamentos, utensílios, silos e veículos dedicados à fabricação livre de ractopamina estão protegidas da contaminação por este aditivo.",
                "68",
              ],
              [
                "",
                "D",
                "69.",
                "Monitora premixes, núcleos e rações com relação à presença de ractopamina, quando necessário.",
                "69",
              ],
            ]}
          />
          <Input
            type="textarea"
            item={data}
            setItem={setData}
            params="description"
            label="DESCREVER AS NÃO CONFORMIDADES:"
            rows={20}
          />
        </>
      )}
    </Modal>
  );
}
