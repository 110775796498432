import { useState, Fragment } from "react";
import { Icons } from "components/Icons";
import { Col } from "styles";
import { necropsyConfig } from "utils/globalData";
import {
  ItemScore,
  ItemScoreTitle,
  ItemScoreProgress,
  ItemScoreQtd,
  ItemScoreNumber,
  BoxIconContent,
} from "../../style";

export function BoxInjury({ params, response, index, injuryTypeLabel }) {
  const splitParams = params.split(".");
  const config = necropsyConfig[splitParams[0]][splitParams[1]];
  const { totalPerInjury, scores, totalBirds } = response;
  const [showScores, setShowScores] = useState(true);
  return (
    <Col>
      <ItemScore onClick={() => setShowScores(!showScores)}>
        <BoxIconContent>
          <Icons type="necropsy" size={24} color={config.color} />
          <ItemScoreTitle>{injuryTypeLabel}</ItemScoreTitle>
        </BoxIconContent>

        <ItemScoreTitle>{config.label}</ItemScoreTitle>
        <ItemScoreProgress>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuenow="100"
            aria-valuemax="100"
            style={{
              width: `${
                totalBirds
                  ? (
                      (totalPerInjury[index][splitParams[1]] / totalBirds) *
                      100
                    ).toFixed(0)
                  : 0
              }%`,
              backgroundColor: config.color,
            }}
          >
            <span>
              {totalBirds
                ? (
                    (totalPerInjury[index][splitParams[1]] / totalBirds) *
                    100
                  ).toFixed(0)
                : 0}
              %
            </span>
          </div>
        </ItemScoreProgress>

        <ItemScoreQtd>
          <b>Qtd: </b>
          {totalPerInjury[index][splitParams[1]]}
        </ItemScoreQtd>

        {showScores &&
          scores[index][splitParams[1]] &&
          scores[index][splitParams[1]]
            .sort(function (a, b) {
              return a.key < b.key ? -1 : a.key > b.key ? 1 : 0;
            })
            .map((item) => (
              <Fragment key={item.key}>
                <ItemScoreNumber>
                  <div style={{ width: "100%" }}>
                    <b>Score {item.key}:</b> {item.total}
                  </div>
                  <div>
                    {totalBirds
                      ? (
                          (item.total / totalPerInjury[index][splitParams[1]]) *
                          100
                        ).toFixed()
                      : 0}
                    %
                  </div>
                </ItemScoreNumber>
                <ItemScoreProgress>
                  <div
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuenow="100"
                    aria-valuemax="100"
                    style={{
                      width: `${
                        totalBirds
                          ? (
                              (item.total /
                                totalPerInjury[index][splitParams[1]]) *
                              100
                            ).toFixed()
                          : 0
                      }%`,
                      backgroundColor: config.color,
                    }}
                  ></div>
                </ItemScoreProgress>
              </Fragment>
            ))}
      </ItemScore>
    </Col>
  );
}
