import {
  Container,
  Header,
  TableContent,
  CheckboxCell,
  Body,
  Index,
  EditAccess,
  Title,
  EmptyTable,
} from "./style";

import { ViewLoader } from "components/ViewLoader";
import { Input } from "components/Input";
import { Icons } from "components/Icons";
import { api } from "services/api";
import Swal from "sweetalert2";
import { useState, createRef } from "react";

export function IngredientsList({
  items,
  setItems,
  loading,
  setLoading,
  getIngredients,
}) {
  const ref = createRef();

  const [selectAll, setSelectAll] = useState(false);

  const columns = [
    {
      key: "ingredient",
      label: "Ingredientes",
      placeholder: "Digite o ingrediente",
      left: true,
      mobile: {
        type: "title",
      },
    },
  ];
  const emptyListText = "Não há ingredientes adicionados";

  const selectAllHandler = (checked) => {
    const copyItems = items.map((item) => {
      item.checked = checked;
      return item;
    });
    setItems(copyItems);
    setSelectAll(checked);
  };

  const selectItemsHandler = (checked, index) => {
    const copyItems = [...items];
    copyItems[index].checked = checked;
    setItems(copyItems);
    const hasNotChecked = copyItems.filter((item) => {
      return !item.checked;
    });
    setSelectAll(hasNotChecked.length === 0);
  };

  const removeItemsHandler = async () => {
    const selectItemsIds = items
      .filter((item) => item.checked)
      .map((item) => item._id);
    if (!selectItemsIds[0]) {
      return;
    }

    try {
      const result = await Swal.fire({
        text: `Deseja arquivar o${selectItemsIds[1] ? "s" : ""} ite${
          selectItemsIds[1] ? "ns" : "m"
        } selecionado${selectItemsIds[1] ? "s" : ""}?`,
        title: "Arquivar Itens!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: `Sim, arquivar ite${
          selectItemsIds[1] ? "ns" : "m"
        }!`,
      });
      if (result.value) {
        setLoading(true);
        const response = await api.delete(`ingredients`, {
          data: selectItemsIds,
        });
        if (response.data) {
          Swal.fire(
            `Arquivar Ite${selectItemsIds[1] ? "ns" : "m"}!`,
            `Ite${selectItemsIds[1] ? "ns" : "m"} arquivado${
              selectItemsIds[1] ? "s" : ""
            } com sucesso.`,
            "success"
          );
          await getIngredients();
          setSelectAll(false);
        }
      }
    } catch (error) {
      console.log("Erro ao arquivar ingredientes");
    } finally {
      setLoading(false);
    }
  };

  /* const pageClickHandler = useCallback(
    (pageNumber) => {
      if (+pageNumber !== +match.params.page) {
        history.push(
          match.path
            .replace(":page", pageNumber)
            .replace(":limit", match.params.limit)
            .replace(":sort", match.params.sort)
            .replace(":query", match.params.query)
            .replace(":id", match.params.id)
            .replace(":tab", match.params.tab)
        );
        setRunLoadItems(true);
        setLoading(true);
      }
    },
    [history, match]
  ); */

  return (
    <>
      {loading ? <ViewLoader isLoading={loading} /> : null}
      <Container className="animate__animated animate__fadeInUp">
        <TableContent>
          {items.length > 0 && (
            <Header ref={ref}>
              <tr>
                <CheckboxCell onClick={() => null}>
                  <Input
                    type="checkbox"
                    value={selectAll}
                    onChange={(e) => selectAllHandler(e.target.checked)}
                  />
                </CheckboxCell>
                <Index onClick={() => null}>
                  <span>Índice</span>
                </Index>
                {columns.map(({ label, key }, index) => (
                  <th key={key}>
                    <Title>{label}</Title>
                  </th>
                ))}
                <EditAccess>
                  <span>
                    Deletar
                    <Icons
                      type="trash"
                      size="24"
                      color="#7E8299"
                      onClick={removeItemsHandler}
                    />
                  </span>
                </EditAccess>
              </tr>
            </Header>
          )}
          <Body>
            {items.map((row, index) => (
              <tr key={row._id}>
                <CheckboxCell onClick={() => null}>
                  <Input
                    type="checkbox"
                    value={items[index].checked}
                    onChange={(e) =>
                      selectItemsHandler(e.target.checked, index)
                    }
                  />
                </CheckboxCell>
                <td>
                  <span>{index + 1}</span>
                </td>
                <td>
                  <span>{row.name}</span>
                </td>
              </tr>
            ))}

            {!items[0] && (
              <tr>
                <EmptyTable colSpan={columns.length + 3}>
                  <span>{emptyListText}</span>
                </EmptyTable>
              </tr>
            )}
          </Body>
        </TableContent>
      </Container>
    </>
  );
}
