import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Card, CardBody, CardHeader } from "styles";
import { Table as TableContent, H3 } from "../style";
export function Table({ module, setModule, title, body }) {
  return (
    <Card>
      <CardHeader>
        <H3>{title}</H3>
      </CardHeader>
      <CardBody>
        <TableContent>
          <Header>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>C</th>
              <th>NC</th>
              <th>NA</th>
            </tr>
          </Header>
          <Body>
            {body.map((item) => (
              <tr key={item[4]}>
                <td>{item[0]}</td>
                <td>{item[1]}</td>
                <td>{item[2]}</td>
                <td>{item[3]}</td>
                <td>
                  <Input
                    type="radiobox"
                    value="C"
                    item={module}
                    setItem={setModule}
                    params={item[4]}
                  />
                </td>
                <td>
                  <Input
                    type="radiobox"
                    value="NC"
                    item={module}
                    setItem={setModule}
                    params={item[4]}
                  />
                </td>
                <td>
                  <Input
                    type="radiobox"
                    value="NA"
                    item={module}
                    setItem={setModule}
                    params={item[4]}
                  />
                </td>
              </tr>
            ))}
          </Body>
        </TableContent>
      </CardBody>
    </Card>
  );
}
