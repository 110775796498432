import {
  Container,
  Content,
  Data,
  Title,
  Text,
  Description,
  ScoreAndButton,
} from "./style";

export function ScoreItem({
  title,
  text,
  description,
  scoreColor,
  score,
  button,
}) {
  return (
    <Container>
      <Content scoreColor={scoreColor}>
        <Data>
          <Title>{title}</Title>
          <Text>
            {text.filter((item) => !!item)[0]
              ? text.filter((item) => !!item).reduce((a, b) => a + ", " + b)
              : null}
          </Text>
          <Description>
            {description.filter((item) => !!item)[0]
              ? description
                  .filter((item) => !!item)
                  .reduce((a, b) => a + ", " + b)
              : null}
          </Description>
        </Data>
        <ScoreAndButton>
          {score}
          {button}
        </ScoreAndButton>
      </Content>
    </Container>
  );
}
