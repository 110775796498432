import styled, { css } from "styled-components";

export const showContainer = css`
  margin-top: 0;
  transition: all 0.3s ease;
  box-shadow: 0 1px 9px -3px rgb(0 0 0 / 20%);
  border-top: 1px solid #ebedf2;
  top: 60px !important;
`;

export const Container = styled.div`
  ${({ show }) => (show === "true" ? showContainer : "")}
  display: flex;
  align-items: stretch;
  align-content: flex-end;
  @media (max-width: 1024px) {
    padding: 0 15px;
    background-color: #fff;
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-end;
    width: 100%;
    top: 0;
  }
`;

export const DropdownConnect = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0 0.25rem;
  position: relative;
  @media (max-width: 1024px) {
    margin: 0 0.25rem;
  }

  @media (max-width: 1024px) {
    position: unset;
  }
`;

export const DropdownConnectContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: stretch;
`;

export const DropdownConnectToggle = styled.div`
  min-width: 42px;
  width: auto !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  cursor: pointer;
  background: transparent;
  transition: all 0.3s;
  border-radius: 4px;
  color: #74788d;
  font-weight: 500;
  font-size: 1rem;
  text-transform: none;

  span {
    margin-top: 5px;
    margin-right: 15px;
  }
  svg {
    path {
      fill: #74788d;
    }
  }

  @media (max-width: 1024px) {
    border: 0;
    span {
      color: #747890;
    }
    svg {
      path {
        fill: #747890;
      }
      margin-right: 5px;
    }
  }
`;

export const UserData = styled.div`
  margin-right: 0;
  display: flex;
  align-items: stretch;
  @media (max-width: 1024px) {
    padding-right: 0 !important;
    margin-right: 0 !important;

    padding: 0 0 0 0.25rem;
    margin: 0;
  }
`;

export const UserDataContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: stretch;
  @media (max-width: 1024px) {
    display: flex;
    align-items: stretch;
  }
`;

export const UserDataDropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: stretch;
  position: relative;
  @media (max-width: 1024px) {
    display: flex;
    align-items: stretch;
    position: unset;
  }
`;

export const UserDataDropdownToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: stretch;
  @media (max-width: 1024px) {
    display: flex;
    align-items: stretch;
  }
`;
export const UserDataWelcome = styled.span`
  display: flex;
  align-self: center;
  padding: 0 0.55rem 0 0;
  font-weight: 500;
  font-size: 0.9rem;
  color: #74788d;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  @media (max-width: 1024px) {
    color: #74788d;
    padding: 0 0.35rem 0 0;
    font-size: 0.9rem;
  }
`;

export const UserDataUsername = styled.span`
  display: flex;
  align-self: center;
  padding: 0 0.55rem 0 0;
  font-weight: 500;
  font-size: 1rem;
  color: #74788d;
  @media (max-width: 1024px) {
    color: #595d6e;
    padding: 0 0.55rem 0 0;
    font-size: 0.9rem;
  }
`;

export const UserDataIcon = styled.span`
  background-color: var(--default);
  min-width: 42px;
  width: auto !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 50%;
  @media (max-width: 1024px) {
    background-color: rgba(116, 120, 141, 0.1);
    max-height: 34px;
    max-width: 34px;
    border: 0;
  }
  b {
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    @media (max-width: 1024px) {
      font-size: 1.4rem;
      font-weight: 500;
      color: #74788d;
    }
  }
`;
