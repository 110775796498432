import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import updateList from "contexts/updateList";
import { useCallback, useContext, useState } from "react";
import { Card, CardBody, CardHeader } from "styles";
import { FactoryClassification } from "./FactoryClassification";
import { MixingHomogeneity } from "./MixingHomogeneity";
import { CleaningValidation } from "./CleaningValidation";

export function Gate798(props) {
  const moduleLabel = {
    FactoryClassification: "Classificação de fábrica",
    MixingHomogeneity: "Homogeneidade de mistura",
    CleaningValidation: "Validação de limpeza",
  };

  const tableParams = [
    {
      key: "user",
      params: "name",
      label: "Cadastrado por",
      filter: "text",
      placeholder: "Digite a nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "date",
      label: "Data da Visita",
      filter: "date",
      type: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "module",
      label: "Modelo de Termo",
      type: "custom",
      filter: "select",
      options: [
        { value: "FactoryClassification", label: "Classificação de fábrica" },
        { value: "MixingHomogeneity", label: "Homogeneidade de mistura" },
        { value: "CleaningValidation", label: "Validação de limpeza" },
      ],
      mobile: {
        type: "item",
      },
      response: ({ termType }) => moduleLabel[termType],
    },
    {
      key: "score",
      label: "Resultado",
      type: "text",
      mobile: {
        type: "small",
      },
    },
  ];

  const { setUpdateList } = useContext(updateList);

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const editTermHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(row.termType);
  }, []);

  const createTermHandler = useCallback((termType) => {
    setId("");
    setModalShow(termType);
  }, []);

  return (
    <>
      {modalShow === "FactoryClassification" && (
        <FactoryClassification
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
          isSwine={props.isSwine}
        />
      )}
      {modalShow === "MixingHomogeneity" && (
        <MixingHomogeneity
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}
      {modalShow === "CleaningValidation" && (
        <CleaningValidation
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}

      <Card>
        <CardHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Portaria 798</div>
            <div style={{ display: "flex" }}>
              <Button
                type="button"
                target="_blank"
                href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Portaria%20798_10_05_2023.pdf"
                color="default"
                bg="light"
                border="default"
                size="sm"
              >
                <Icons type="download" color="#0f70b9" size={18} />
                Portaria 798 PDF
              </Button>
              <Button
                color="dark"
                bg="light"
                border="dark"
                size="sm"
                onClick={() => createTermHandler("FactoryClassification")}
                style={{ marginLeft: "10px" }}
                type="button"
              >
                Classificação de fábrica
              </Button>

              <Button
                color="white"
                bg="default"
                border="default"
                size="sm"
                onClick={() => createTermHandler("MixingHomogeneity")}
                style={{ marginLeft: "10px" }}
                type="button"
              >
                Homogeneidade de mistura
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                color="white"
                bg="basf"
                border="basf"
                size="sm"
                onClick={() => createTermHandler("CleaningValidation")}
                type="button"
              >
                Validação de limpeza
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            noLoad={true}
            cols={tableParams}
            editHandler={editTermHandler}
            emptyText={"Nenhum termo localizado!"}
            route={`/gate798/${props.match.params.id}`}
            {...props}
          />
        </CardBody>
      </Card>
    </>
  );
}

//MATEUS CASEIRO
