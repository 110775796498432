import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function CopperSulfate(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "50px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual PPM?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Cria</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.create.ppm"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Recria</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.recreate.ppm"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Produção</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.production.ppm"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
