import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Col, Row } from "styles";

export function Products({ evaluation, setEvaluation }) {
  const addProduct = () => {
    setEvaluation((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.products.push({
        code: "",
        productName: "",
        manufacturingDate: "",
        collectionDate: "",
        lot: "",
        bagNumber: "",
      });
      return newState;
    });
  };
  const removeProduct = (index) => {
    setEvaluation((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.products.splice(index, 1);
      return newState;
    });
  };

  return (
    <>
      {evaluation.products.map((item, index) => (
        <Row key={index}>
          <Col size={3}>
            <Input
              item={evaluation}
              setItem={setEvaluation}
              params={`products.${index}.code`}
              type="input"
              label="Código"
              placeholder="Código produto"
            />
          </Col>
          <Col size={5}>
            <Input
              item={evaluation}
              setItem={setEvaluation}
              params={`products.${index}.productName`}
              type="input"
              label="Nome do Produto"
            />
          </Col>
          <Col size={3}>
            <Input
              item={evaluation}
              setItem={setEvaluation}
              params={`products.${index}.manufacturingDate`}
              type="inputMask"
              label="Data de Fabricação"
              mask="99/99/9999"
            />
          </Col>
          <Col size={3}>
            <Input
              item={evaluation}
              setItem={setEvaluation}
              params={`products.${index}.collectionDate`}
              type="inputMask"
              label="Data de Fabricação"
              mask="99/99/9999"
            />
          </Col>
          <Col size={3}>
            <Input
              item={evaluation}
              setItem={setEvaluation}
              params={`products.${index}.lote`}
              type="input"
              label="Lote"
            />
          </Col>
          <Col size={5}>
            <Input
              item={evaluation}
              setItem={setEvaluation}
              params={`products.${index}.bagNumber`}
              type="input"
              label="Número do Saco"
              placeholder="(constante na lateral do rótulo)"
            />
          </Col>

          <Col size={1} style={{ paddingTop: "33px" }}>
            <Icons
              type="trash"
              color="#F64E60"
              size={24}
              onClick={() => removeProduct(index)}
            />
          </Col>
        </Row>
      ))}

      <Row>
        <Col>
          <Button
            type="button"
            size="sm"
            onClick={addProduct}
            color="white"
            bg="default"
            border="default"
          >
            <Icons type="plus" color="#fff" size={15} /> Add Produto
          </Button>
        </Col>
      </Row>
    </>
  );
}
