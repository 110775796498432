import { useState, useCallback, useEffect } from "react";
import { CreateEdit } from "../CreateEdit";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import { api } from "services/api";

export function List(props) {
  const tableParams = [
    {
      key: "customer",
      params: "name",
      label: "Cliente",
      mobile: {
        type: "title",
      },
    },
    {
      key: "technicalManager",
      label: "Responsável Técnico",
      filter: "text",
      placeholder: "Digite o Nome",
      mobile: {
        type: "small",
      },
    },
    {
      key: "visitDate",
      label: "Data da visita",
      type: "date",
      filter: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "integrated",
      label: "Integrado",
      filter: "text",
      mobile: {
        type: "item",
      },
    },
    { key: "lotNumber", label: "Nº Lote", filter: "text" },
    { key: "bedNumber", label: "Nº Cama", filter: "text" },
    {
      key: "lineage",
      label: "Linhagem",
      filter: "select",
      options: [
        { value: "Cobb" },
        { value: "Ross" },
        { value: "Hubbard" },
        { value: "Misto" },
      ],
      mobile: {
        type: "small",
      },
    },
  ];

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const [customerName, setCustomerName] = useState("");

  const editHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(true);
  }, []);

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
    };
    if (props.match.params.id) {
      getCustomerName();
    }
  }, [props.match.params.id]);

  return (
    <>
      {modalShow && (
        <CreateEdit
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
        />
      )}
      <SubHeader
        {...props}
        title={customerName || "Checklists - Abates"}
        route="broilerchecklistslaughter"
        breadcrumbs={[
          {
            label: customerName,
            to: "/connect/customer/" + props.match.params.id,
          },
          {
            label: "Frango de Corte",
          },
          {
            label: "Checklist Abate",
          },
        ]}
        icon={<Icons type="checklist" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editHandler={editHandler}
        emptyText={"Nenhuma checklist localizado!"}
        route={`/broilerchecklistslaughter/list`}
        {...props}
      />
    </>
  );
}
