import { ScoreItem } from "components/ScoreItem";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Block, BlockBody, BlockHeader } from "styles";
import {
  getColor,
  getScoreColor,
  getScoreText,
  getTotalScore,
} from "utils/globalFunctions";
import Score from "react-apexcharts";
import { Button } from "components/Button";
import { CreateEdit } from "../../../Necropsy/CreateEdit";
import { useState } from "react";
import { Icons } from "components/Icons";

export function Necropsies({ responseReport, location, customer, match }) {
  const [showNecropsy, setShowNecropsy] = useState(false);

  const [necropsyId, setNecropsyId] = useState("");

  return (
    <>
      {showNecropsy ? (
        <CreateEdit
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={location}
          customer={customer}
          id={necropsyId}
          match={match}
        />
      ) : null}
      <Block>
        <BlockHeader>Necropsias do período</BlockHeader>
        <BlockBody>
          {responseReport.necropsies.map((item) => (
            <ScoreItem
              key={item._id}
              title={item.customer?.name}
              scoreColor={getScoreColor(getTotalScore(item.birds))}
              text={[item.integrated, item.incubatory]}
              description={[
                item.visitDate
                  ? `Visita ${format(new Date(item.visitDate), "dd MMM y", {
                      locale: ptBR,
                    })}`
                  : null,
                `${item.birds.length} aves`,
                item.lineage,
                item.age
                  ? `idade:
            ${item.age}`
                  : null,
              ]}
              score={
                <Score
                  options={{
                    plotOptions: {
                      radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                          margin: 0,
                          size: "70%",
                          background: "#f9f9fc ",
                          image: undefined,
                          imageOffsetX: 0,
                          imageOffsetY: 0,
                          position: "front",
                        },
                        track: {
                          background: getColor(
                            getScoreColor(getTotalScore(item.birds)),
                            0.2
                          ),
                          strokeWidth: "67%",
                          margin: 0, // margin is in pixels
                        },

                        dataLabels: {
                          showOn: "always",
                          name: {
                            offsetY: -5,
                            show: true,
                            color: getScoreColor(getTotalScore(item.birds)),
                            fontSize: "7px",
                          },
                          value: {
                            formatter: function (val) {
                              return (val / 10).toLocaleString();
                            },
                            color: getScoreColor(getTotalScore(item.birds)),
                            fontSize: "10px",
                            show: true,
                            offsetY: -5,
                          },
                        },
                      },
                    },
                    fill: {
                      colors: [getScoreColor(getTotalScore(item.birds))],
                      type: "gradient",
                      gradient: {
                        shade: "dark",
                        type: "horizontal",

                        gradientToColors: [
                          getScoreColor(getTotalScore(item.birds)),
                        ],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 0.8,
                        stops: [0, 100],
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: [getScoreText(getTotalScore(item.birds))],
                  }}
                  series={[getTotalScore(item.birds) * 10]}
                  type="radialBar"
                  width={"110"}
                />
              }
              button={
                <Button
                  bg={getScoreColor(getTotalScore(item.birds))}
                  border={getScoreColor(getTotalScore(item.birds))}
                  onClick={() => {
                    setNecropsyId(item._id);
                    setShowNecropsy(true);
                  }}
                  color="white"
                  size="sm"
                >
                  <Icons type="etc" color="#fff" size="15" />
                </Button>
              }
            />
          ))}
        </BlockBody>
      </Block>
    </>
  );
}
