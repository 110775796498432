import React from "react";
import isMobile from "is-mobile";
import { Input } from "components/Input";
import { Table } from "../../style";
import { Body, Header } from "components/Table/style";

const Steps = (props) => {
  return (
    <Table>
      <Header>
        <tr>
          <th>
            <span></span>
          </th>
          {isMobile() ? (
            <th>
              <span>Fase</span>
            </th>
          ) : (
            <>
              <th>
                <span>Pré Postura</span>
              </th>
              <th>
                <span>Pré Pico</span>
              </th>
              <th>
                <span>Pico de Postura</span>
              </th>
              <th>
                <span>Postura 01</span>
              </th>
              <th>
                <span>Postura 02</span>
              </th>
              <th>
                <span>Postura 03</span>
              </th>
              <th>
                <span>Postura 04</span>
              </th>
              <th>
                <span>Postura 05</span>
              </th>
              <th>
                <span>Postura 06</span>
              </th>
            </>
          )}
        </tr>
      </Header>
      <Body>
        <tr>
          <td>
            <span>
              <b>Produção:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.preLay.feedProductionYear
                }
                label="Pré Postura"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.prePeak.feedProductionYear
                }
                label="Pré Pico"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layPeak.feedProductionYear
                }
                label="Pico de Postura"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layFinal01.feedProductionYear
                }
                label="Postura 01"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layFinal02.feedProductionYear
                }
                label="Postura 02"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layFinal03.feedProductionYear
                }
                label="Postura 03"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layFinal04.feedProductionYear
                }
                label="Postura 04"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layFinal05.feedProductionYear
                }
                label="Postura 05"
              />
              <Input
                type="input"
                inputType="number"
                value={
                  props.meggProduction.premixSteps.layFinal01.feedProductionYear
                }
                label="Postura 05"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.preLay.feedProductionYear
                  }
                  placeholder="Pré Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.prePeak.feedProductionYear
                  }
                  placeholder="Pré Pico"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layPeak.feedProductionYear
                  }
                  placeholder="Pico de Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layFinal01
                      .feedProductionYear
                  }
                  placeholder="Postura 01"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layFinal02
                      .feedProductionYear
                  }
                  placeholder="Postura 02"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layFinal03
                      .feedProductionYear
                  }
                  placeholder="Postura 03"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layFinal04
                      .feedProductionYear
                  }
                  placeholder="Postura 04"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layFinal05
                      .feedProductionYear
                  }
                  placeholder="Postura 05"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={
                    props.meggProduction.premixSteps.layFinal06
                      .feedProductionYear
                  }
                  placeholder="Postura 06"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Forma Física:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.preLay.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Pré Postura"
                label="Pré Postura"
              />

              <Input
                type="input"
                params="premixSteps.prePeak.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Pré Pico"
                label="Pré Pico"
              />

              <Input
                type="input"
                params="premixSteps.layPeak.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Pico de Postura"
                label="Pico de Postura"
              />

              <Input
                type="input"
                params="premixSteps.layFinal01.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 01"
                label="Postura 01"
              />
              <Input
                type="input"
                params="premixSteps.layFinal02.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 02"
                label="Postura 02"
              />
              <Input
                type="input"
                params="premixSteps.layFinal03.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 03"
                label="Postura 03"
              />
              <Input
                type="input"
                params="premixSteps.layFinal04.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 04"
                label="Postura 04"
              />
              <Input
                type="input"
                params="premixSteps.layFinal05.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 05"
                label="Postura 05"
              />
              <Input
                type="input"
                params="premixSteps.layFinal06.physicalForm"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 06"
                label="Postura 06"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.preLay.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.prePeak.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Pico"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layPeak.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pico de Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal01.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 01"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal02.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 02"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal03.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 03"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal04.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 04"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal05.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 05"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal06.physicalForm"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 06"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Fornecedor:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.preLay.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Pré Postura"
              />

              <Input
                type="input"
                params="premixSteps.prePeak.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Pré Pico"
              />

              <Input
                type="input"
                params="premixSteps.layPeak.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Pico de Postura"
              />

              <Input
                type="input"
                params="premixSteps.layFinal01.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 01"
              />
              <Input
                type="input"
                params="premixSteps.layFinal02.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 02"
              />
              <Input
                type="input"
                params="premixSteps.layFinal02.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 02"
              />
              <Input
                type="input"
                params="premixSteps.layFinal03.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 03"
              />
              <Input
                type="input"
                params="premixSteps.layFinal04.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 04"
              />
              <Input
                type="input"
                params="premixSteps.layFinal05.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 05"
              />
              <Input
                type="input"
                params="premixSteps.layFinal06.provider"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 06"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.preLay.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.prePeak.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Pico"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layPeak.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pico de Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal01.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 01"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal02.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 02"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal03.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 03"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal04.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 04"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal05.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 05"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal06.provider"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 06"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Descrição Comum:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.preLay.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Pré Postura"
                label="Pré Postura"
              />

              <Input
                type="input"
                params="premixSteps.prePeak.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Pré Pico"
                label="Pré Pico"
              />

              <Input
                type="input"
                params="premixSteps.layPeak.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Pico de Postura"
                label="Pico de Postura"
              />

              <Input
                type="input"
                params="premixSteps.layFinal01.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 01"
                label="Postura 01"
              />
              <Input
                type="input"
                params="premixSteps.layFinal02.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 02"
                label="Postura 02"
              />
              <Input
                type="input"
                params="premixSteps.layFinal03.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 03"
                label="Postura 03"
              />
              <Input
                type="input"
                params="premixSteps.layFinal04.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 04"
                label="Postura 04"
              />
              <Input
                type="input"
                params="premixSteps.layFinal01.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 05"
                label="Postura 05"
              />
              <Input
                type="input"
                params="premixSteps.layFinal06.commonDescription"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                placeholder="Postura 06"
                label="Postura 06"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.preLay.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.prePeak.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Pico"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layPeak.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pico de Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal01.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 01"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal02.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 02"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal03.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 03"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal04.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 04"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal05.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 05"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal06.commonDescription"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 06"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Classe PX:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.preLay.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Pré Postura"
              />

              <Input
                type="input"
                params="premixSteps.prePeak.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Pré Pico"
              />

              <Input
                type="input"
                params="premixSteps.layPeak.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Pico de Postura"
              />

              <Input
                type="input"
                params="premixSteps.layFinal01.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 01"
              />
              <Input
                type="input"
                params="premixSteps.layFinal02.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 02"
              />
              <Input
                type="input"
                params="premixSteps.layFinal03.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 03"
              />
              <Input
                type="input"
                params="premixSteps.layFinal04.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 04"
              />
              <Input
                type="input"
                params="premixSteps.layFinal05.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 05"
              />
              <Input
                type="input"
                params="premixSteps.layFinal05.pxClass"
                item={props.meggProduction}
                setItem={props.setMeggProduction}
                label="Postura 05"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.preLay.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.prePeak.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pré Pico"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layPeak.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Pico de Postura"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal01.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 01"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal02.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 02"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal03.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 03"
                />
              </td>

              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal04.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 04"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal05.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 05"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.layFinal06.pxClass"
                  item={props.meggProduction}
                  setItem={props.setMeggProduction}
                  placeholder="Postura 06"
                />
              </td>
            </>
          )}
        </tr>
      </Body>
    </Table>
  );
};

export default Steps;
