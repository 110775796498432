import styled from "styled-components";
import { TableContent } from "components/Table/style";

export const H2 = styled.h2`
  color: var(--default);
  margin-bottom: 10px;
`;

export const H3 = styled.h3`
  color: var(--gray-dark);
`;

export const Table = styled(TableContent)`
  width: 100%;
  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3) {
    width: 30px;
  }
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7) {
    width: 50px;
  }
`;
