import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Col, Row } from "styles";

import { RepetitionBox } from "./RepetitionBox";
import ReactLoading from "react-loading";

export function CleaningValidation(props) {
  const [data, setData] = useState({
    evaluationMolecule: "",
    quantityPerTon: "",
    feedPhase: "",
    repetitions: {
      repetition1: {
        beat1: "0%",
        beat2: "Limpeza",
        beat3: "0%",
      },
      repetition2: {
        beat1: "0%",
        beat2: "Limpeza",
        beat3: "0%",
      },
      repetition3: {
        beat1: "0%",
        beat2: "Limpeza",
        beat3: "0%",
      },
    },
  });

  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState(true);

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };

    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar validação de limpeza",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar validação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`gate798/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Criar validação de limpeza",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;

        return newState;
      });
      props.setUpdateList(true);
    }
  }, [data, props]);

  const updateBeat = (beat, value, index) => {
    setData((prevState) => {
      const { repetitions } = prevState;

      const newRepetitions = {
        ...repetitions,
        [`repetition${index}`]: {
          ...repetitions[`repetition${index}`],
          [beat]: value,
        },
      };
      return {
        ...prevState,
        repetitions: newRepetitions,
      };
    });
  };

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `gate798/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire(
            "Editar Validação",
            "Não foi possível buscar a Classificação",
            "error"
          );
        }

        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Validação de limpeza</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <Col>
          <Row>
            <Col>
              <Input
                type="input"
                item={data}
                setItem={setData}
                params={`evaluationMolecule`}
                label={`Molécula para avaliação`}
                inputType="text"
              />
            </Col>
            <Col>
              <Input
                type="input"
                item={data}
                setItem={setData}
                params={`quantityPerTon`}
                label={`Quantidade / ton. (PPM)`}
                inputType="number"
              />
            </Col>
            <Col>
              <Input
                type="input"
                item={data}
                setItem={setData}
                params={`feedPhase`}
                label={`Fase da ração`}
                inputType="text"
              />
            </Col>
            <Col size={3} />
          </Row>

          <Row>
            <RepetitionBox
              data={data}
              setData={setData}
              index={1}
              updateBeat={updateBeat}
            />
            <RepetitionBox
              data={data}
              setData={setData}
              index={2}
              updateBeat={updateBeat}
            />
            <RepetitionBox
              data={data}
              setData={setData}
              index={3}
              updateBeat={updateBeat}
            />
          </Row>
        </Col>
      )}
    </Modal>
  );
}
