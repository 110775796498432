import { Body, Header } from "components/Table/style";
import { Input } from "components/Input";
import { TableContent } from "components/Table/style";
import { useState } from "react";
import { Button } from "components/Button";
import { Row } from "./Row";
import { Icons } from "components/Icons";

export function MacroFormulaTab({
  macroFormula,
  setMacroFormula,
  ingredients,
  columns,
}) {
  const [selectedIngredient, setSelectedIngredient] = useState("");
  const [selectedRows, setSelectedRows] = useState({});

  const toggleSelectAll = () => {
    const newSelectedRows = {};
    const allSelected =
      Object.keys(selectedRows).length === macroFormula.ingredients.length &&
      Object.values(selectedRows).every(Boolean);
    macroFormula.ingredients.forEach((ingredient) => {
      newSelectedRows[ingredient.row] = !allSelected;
    });
    setSelectedRows(newSelectedRows);
  };
  const toggleSelectRow = (rowName) => {
    setSelectedRows((prev) => ({
      ...prev,
      [rowName]: !prev[rowName],
    }));
  };

  const addIngredient = () => {
    if (!selectedIngredient.trim()) return;
    const ingredientToAdd = ingredients.find(
      (ing) => ing.id === selectedIngredient
    );
    if (!ingredientToAdd) return;
    const rowName = `ingredient${macroFormula.ingredients.length + 1}`;
    setMacroFormula((prev) => ({
      ...prev,
      [rowName]: { ...columns },
      ingredients: [
        ...prev.ingredients,
        { name: ingredientToAdd.value, row: rowName },
      ],
    }));
    setSelectedIngredient("");
  };

  const deleteSelectedRows = () => {
    setMacroFormula((prevMacroFormula) => {
      const updatedIngredients = prevMacroFormula.ingredients.filter(
        (ingredient) => !selectedRows[ingredient.row]
      );

      const newMacroFormula = {
        ...prevMacroFormula,
        ingredients: updatedIngredients,
      };

      updatedIngredients.forEach((ingredient) => {
        Object.keys(columns).forEach((column) => {
          if (!updatedIngredients.find((ing) => ing.row === ingredient.row)) {
            delete newMacroFormula[ingredient.row][column];
          }
        });
      });

      return newMacroFormula;
    });

    setSelectedRows({});
  };

  const SelectContent = ({ title, placeholder }) => {
    return (
      <tr>
        <td style={{ paddingTop: 0 }}>
          <span>
            <b>{title}</b>
          </span>
        </td>

        <td>
          <Input
            type="select"
            placeholder="Selecione uma opção"
            item={selectedIngredient}
            setItem={setSelectedIngredient}
            options={ingredients.map((ingredient) => ({
              label: ingredient.value,
              value: ingredient.id,
            }))}
          />
        </td>
        <td style={{ paddingTop: 0 }}>
          <Button
            color="white"
            bg="default"
            border="default"
            disabled={!selectedIngredient}
            onClick={addIngredient}
          >
            Adicionar
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <TableContent>
        <SelectContent
          title={"Ingredientes"}
          placeholder={"Selecione uma opção"}
        />
      </TableContent>
      {macroFormula.ingredients.length > 0 && (
        <TableContent>
          <Header>
            <tr>
              <th>
                <Input
                  type="checkbox"
                  onChange={toggleSelectAll}
                  checked={
                    Object.keys(selectedRows).length ===
                      macroFormula.ingredients.length &&
                    Object.values(selectedRows).every(Boolean)
                  }
                />
              </th>
              <th>
                <span>Níves/Fases</span>
              </th>
              <th>
                <span>Pré-inicial</span>
              </th>
              <th>
                <span>Inicial</span>
              </th>
              <th>
                <span>Crescimento</span>
              </th>
              <th>
                <span>Engorda</span>
              </th>
              <th>
                <span>Abate</span>
              </th>
              <th>
                <th>
                  <Icons
                    type="trash"
                    size="24"
                    color="#7E8299"
                    onClick={deleteSelectedRows}
                  />
                </th>
              </th>
            </tr>
          </Header>
          <Body>
            {macroFormula.ingredients.map((ingredient, index) => (
              <Row
                key={index}
                ingredient={ingredient}
                macroFormula={macroFormula}
                setMacroFormula={setMacroFormula}
                selected={selectedRows[ingredient.row] || false}
                toggleSelect={() => toggleSelectRow(ingredient.row)}
              />
            ))}
          </Body>
        </TableContent>
      )}
    </>
  );
}
