import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import { Customer } from "./Customer";
import { FarmData } from "./FarmData";
import { Feces } from "./Feces";
import UpdateList from "contexts/updateList";
import { api } from "services/api";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";
import { Environment } from "./Environment";
import { Production } from "./Production";
import { EggyQuality } from "./EggQuality";
import { Sanity } from "./Sanity";
import { Result } from "./Result";
import {
  convertDateToString,
  convertStringToDate,
} from "utils/globalFunctions";

export function CreateEdit(props) {
  const initialBox = useMemo(
    () => ({
      birds: [],
      good: 0,
      humid: 0,
      gas: 0,
      feedPassage: 0,
      limestonePresence: 0,
      mucusPresence: 0,
      intestinalFlaking: 0,
    }),
    []
  );
  const initialBird = useMemo(
    () => ({
      good: "",
      humid: "",
      gas: "",
      feedPassage: "",
      limestonePresence: "",
      mucusPresence: "",
      intestinalFlaking: "",
    }),
    []
  );

  const initialBoxes = () => {
    const response = [];
    const birds = [];
    for (let i = 0; i < 10; i++) {
      birds.push(JSON.parse(JSON.stringify(initialBird)));
    }
    for (let i = 0; i < 3; i++) {
      response.push(JSON.parse(JSON.stringify(initialBox)));
      response[i].birds = JSON.parse(JSON.stringify(birds));
    }
    return response;
  };

  const { setUpdateList } = useContext(UpdateList);

  const [preNecropsy, setPreNecropsy] = useState({
    _id: "",
    customer: props.customer || null,
    technicalManager: null,
    visitDate: "",
    core: "",
    lote: "",
    aviary: "",
    lineage: "",
    age: "",
    treatment: "",
    start: "",
    end: "",
    warp: "",
    birds: "",
    accommodation: "",
    boxes: initialBoxes(),
    environment: {
      externalCleaning: "",
      internalCleaning: "",
      ventilationSystem: "",
      luxLighting: "",
      footbathPresence: "",
      exclusiveFootwearUse: "",
    },
    production: {
      viab: "",
      bodyWeight: "",
      totalEgg: "",
      averageProductionWeek: "",
    },
    eggQuality: {
      weight: "",
      small: "",
      doubleYolk: "",
      discart: "",
      cracked: "",
      fissura: "",
      litter: "",
    },
    sanity: {
      vivacity: "",
      bedQuality: "",
      snore: "",
      sneeze: "",
      coryza: "",
      swollenHead: "",
    },
    numberOfBoxes: 3,
    numberOfBirds: 10,
  });

  const [loadHistoric, setLoadHistoric] = useState(false);

  const [loading, setLoading] = useState(true);

  const [getPreNecropsy, setGetPreNecropsy] = useState(true);

  const [config, setConfig] = useState([]);

  const [tab, setTab] = useState(0);

  const [cobb, setCobb] = useState([]);

  const [results, setResults] = useState({
    environment: 0,
    sanity: 0,
    production: 0,
    eggQuality: 0,
    feces: 0,
    total: 0,
  });

  const getEstimatedVIAB = useCallback(() => {
    return preNecropsy.age &&
      preNecropsy.warp &&
      cobb.filter(({ age }) => age === +preNecropsy.age)[0]
      ? cobb.filter(({ age }) => age === +preNecropsy.age)[0][preNecropsy.warp]
          ?.weeklyMortality
      : 0;
  }, [preNecropsy, cobb]);

  const getEstimatedBodyWeight = useCallback(
    () =>
      preNecropsy.age &&
      preNecropsy.warp &&
      cobb.filter(({ age }) => age === +preNecropsy.age)[0]
        ? preNecropsy.warp === "fastWarping"
          ? cobb.filter(({ age }) => age === +preNecropsy.age)[0][
              preNecropsy.warp
            ]?.bodyWeight
          : cobb.filter(({ age }) => age === +preNecropsy.age)[0][
              preNecropsy.warp
            ]?.bodyWeight.dark.value
        : 0,
    [preNecropsy, cobb]
  );

  const getEstimatedTotalEgg = useCallback(
    () =>
      preNecropsy.age &&
      preNecropsy.warp &&
      cobb.filter(({ age }) => age === +preNecropsy.age)[0]
        ? cobb.filter(({ age }) => age === +preNecropsy.age)[0][
            preNecropsy.warp
          ]?.totalEggs
        : 0,
    [preNecropsy, cobb]
  );

  const getEstimatedTotalEggsWeekly = useCallback(
    () =>
      preNecropsy.age &&
      preNecropsy.warp &&
      cobb.filter(({ age }) => age === +preNecropsy.age)[0]
        ? cobb.filter(({ age }) => age === +preNecropsy.age)[0][
            preNecropsy.warp
          ]?.totalEggsWeekly
        : 0,
    [preNecropsy, cobb]
  );

  const getEstimatedEggQuality = useCallback(
    (param, multi) =>
      preNecropsy.age &&
      preNecropsy.warp &&
      cobb.filter(({ age }) => age === +preNecropsy.age)[0]
        ? cobb.filter(({ age }) => age === +preNecropsy.age)[0][
            preNecropsy.warp
          ][param] * multi
        : 0,
    [preNecropsy, cobb]
  );

  const changeBoxes = useCallback(
    async (e) => {
      const value = +e.target.value;
      const preNecropsyCopy = JSON.parse(JSON.stringify(preNecropsy));
      if (preNecropsyCopy.numberOfBoxes < value) {
        for (let i = preNecropsyCopy.numberOfBoxes; i < value; i++) {
          const copyInitialBox = JSON.parse(JSON.stringify(initialBox));
          for (let j = 0; j < preNecropsyCopy.numberOfBirds; j++) {
            copyInitialBox.birds.push(JSON.parse(JSON.stringify(initialBird)));
          }
          preNecropsyCopy.boxes.push(
            JSON.parse(JSON.stringify(copyInitialBox))
          );
        }
      } else {
        for (let i = preNecropsyCopy.numberOfBoxes; i > value; i--) {
          preNecropsyCopy.boxes.splice(i - 1, 1);
        }
      }
      preNecropsyCopy.numberOfBoxes = value;
      setPreNecropsy(preNecropsyCopy);
    },
    [initialBox, initialBird, preNecropsy]
  );

  const changeBirds = useCallback(
    async (e) => {
      const value = +e.target.value;
      const preNecropsyCopy = JSON.parse(JSON.stringify(preNecropsy));
      for (let i = 0; i < preNecropsyCopy.numberOfBoxes; i++) {
        if (preNecropsyCopy.numberOfBirds < value) {
          for (let j = preNecropsyCopy.numberOfBirds; j < value; j++) {
            preNecropsyCopy.boxes[i].birds.push(
              JSON.parse(JSON.stringify(initialBird))
            );
          }
        } else {
          for (let j = preNecropsyCopy.numberOfBirds; j > value; j--) {
            preNecropsyCopy.boxes[i].birds.splice(j - 1, 1);
          }
        }
      }

      preNecropsyCopy.numberOfBirds = value;
      setPreNecropsy(preNecropsyCopy);
    },
    [initialBird, preNecropsy]
  );

  const savePreNecropsy = useCallback(
    async (e) => {
      const requestPreNecropsy = JSON.parse(JSON.stringify(preNecropsy));
      requestPreNecropsy.results = results;
      if (requestPreNecropsy.visitDate) {
        requestPreNecropsy.visitDate = convertStringToDate(
          requestPreNecropsy.visitDate
        );
      }
      if (requestPreNecropsy.start) {
        requestPreNecropsy.start = convertStringToDate(
          requestPreNecropsy.start
        );
      }
      if (requestPreNecropsy.end) {
        requestPreNecropsy.end = convertStringToDate(requestPreNecropsy.end);
      }
      if (!requestPreNecropsy._id) {
        const response = await Swal.fire({
          title: "Salvar Pré-Necrópsia",
          text: "Deseja confirmar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Salvar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .post("meggproductionprenecropsy", requestPreNecropsy)
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });
        if (response.value) {
          Swal.fire(
            "Salvar Necrópsia",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          if (!response.value.err) {
            requestPreNecropsy._id = response.value.data.id;
            setPreNecropsy(requestPreNecropsy);
          }
          setUpdateList(true);
        }
      } else {
        const response = await Swal.fire({
          title: "Editar Pré-Necrópsia",
          text: "Deseja confirmar edição?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Editar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .put(
                "meggproductionprenecropsy/" + requestPreNecropsy._id,
                requestPreNecropsy
              )
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });

        if (response.value) {
          Swal.fire(
            "Editar Necrópsia",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          setUpdateList(true);
        }
      }
    },
    [preNecropsy, setUpdateList, results]
  );

  useEffect(() => {
    const loadNecropsy = async () => {
      setLoading(true);
      const response = await api.get("meggproductionprenecropsy/config");
      if (response.data) {
        setConfig(response.data);
      }
      if (props.id) {
        const responsePreNecropsy = await api.get(
          `meggproductionprenecropsy/${props.id}`
        );
        if (responsePreNecropsy.data) {
          if (responsePreNecropsy.data.visitDate) {
            responsePreNecropsy.data.visitDate = convertDateToString(
              responsePreNecropsy.data.visitDate
            );
          }
          if (responsePreNecropsy.data.start) {
            responsePreNecropsy.data.start = convertDateToString(
              responsePreNecropsy.data.start
            );
          }
          if (responsePreNecropsy.data.end) {
            responsePreNecropsy.data.end = convertDateToString(
              responsePreNecropsy.data.end
            );
          }
          setPreNecropsy(JSON.parse(JSON.stringify(responsePreNecropsy.data)));
          setLoadHistoric(true);
        }
      }
      const responseCobb = await api.get("hbcobbconfig");
      setCobb(responseCobb.data);
      setLoading(false);
    };
    if (getPreNecropsy) {
      setGetPreNecropsy(false);
      loadNecropsy();
    }
  }, [getPreNecropsy, preNecropsy, props.id, config]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="preNecropsy" color="#009870" size={30} />
          <h2>Pré-necropsia</h2>
        </>
      }
      footer={
        tab === 7 && (
          <Button
            color="white"
            bg="default"
            border="default"
            onClick={savePreNecropsy}
          >
            Salvar
          </Button>
        )
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Cliente" />
            <Tab label="Dados da Granja" />
            <Tab label="Excretas" />
            <Tab label="Ambiente" />
            <Tab label="Producão" />
            <Tab label="Qualidade de ovos" />
            <Tab label="sanidade" />
            <Tab label="Resultados" />
          </Tabs>

          {tab === 0 && (
            <Customer
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              loadHistoric={loadHistoric}
              disableCustomer={props.disableCustomer}
              setLoadHistoric={setLoadHistoric}
            />
          )}
          {tab === 1 && (
            <FarmData
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              changeBoxes={changeBoxes}
              changeBirds={changeBirds}
            />
          )}
          {tab === 2 && (
            <Feces
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              config={config}
            />
          )}
          {tab === 3 && (
            <Environment
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              config={config}
            />
          )}
          {tab === 4 && (
            <Production
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              getEstimatedBodyWeight={getEstimatedBodyWeight}
              getEstimatedTotalEgg={getEstimatedTotalEgg}
              getEstimatedTotalEggsWeekly={getEstimatedTotalEggsWeekly}
              getEstimatedVIAB={getEstimatedVIAB}
              cobb={cobb}
            />
          )}
          {tab === 5 && (
            <EggyQuality
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              getEstimated={getEstimatedEggQuality}
              cobb={cobb}
            />
          )}
          {tab === 6 && (
            <Sanity
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              config={config}
            />
          )}
          {tab === 7 && (
            <Result
              preNecropsy={preNecropsy}
              setPreNecropsy={setPreNecropsy}
              config={config}
              results={results}
              setResults={setResults}
              getEstimatedBodyWeight={getEstimatedBodyWeight}
              getEstimatedTotalEgg={getEstimatedTotalEgg}
              getEstimatedTotalEggsWeekly={getEstimatedTotalEggsWeekly}
              getEstimatedVIAB={getEstimatedVIAB}
              getEstimatedEggQuality={getEstimatedEggQuality}
            />
          )}
        </>
      )}
    </Modal>
  );
}
