import { useState, useEffect, useContext } from "react";
import { ScoreItem } from "components/ScoreItem";
import { SubHeader } from "components/SubHeader";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
  Separator,
  SpanLink,
} from "styles";
import { api } from "services/api";
import isMobile from "is-mobile";
import Score from "react-apexcharts";
import {
  getScoreColor,
  getScoreText,
  getColor,
  getTotalScore,
} from "utils/globalFunctions";
import format from "date-fns/format";
import { ptBR } from "date-fns/locale";
import { Button } from "components/Button";
import { CustomerItem } from "components/CustomerItem";
import { Icons } from "components/Icons";
import { ViewLoader } from "components/ViewLoader";
import { Map } from "components/Map";
import { Body, Header, TableContent } from "components/Table/style";
import { CreateEdit as CreateEditNecropsy } from "../Customer/Connect/Broiler/Necropsy/CreateEdit";
import ProfileContext from "contexts/profile";
import { Redirect } from "react-router";
import { CreateEdit as CreateEditSlaughter } from "../Customer/Connect/Slaughter/Slaughter/CreateEdit";

export function Home(props) {
  const colors = ["default", "blue", "danger", "indigo", "cyan"];

  const { roles, customer } = useContext(ProfileContext);

  const [lastCustomers, setLastCustomers] = useState([]);

  const [lastNecropsies, setLastNecropsies] = useState([]);

  //const [lastSlaughters, setLastSlaughters] = useState([]);

  const [map, setMap] = useState([]);

  const [showStateCustomers, setShowStateCustomers] = useState(null);

  const [loading, setLoading] = useState(true);

  const [showNecropsy, setShowNecropsy] = useState(false);

  const [necropsyId, setNecropsyId] = useState("");

  const [showSlaughter, setShowSlaughter] = useState(false);

  //const [slaughterId, setSlaughterId] = useState("");

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await api.get("dashboard/home");
      setLastCustomers(response.data.lastCustomers);
      setLastNecropsies(response.data.necropsy);
      //(response.data.slaughter);
      setMap(response.data.responseStates);
      setLoading(false);
    };
    if (loading) {
      loadData();
    }
  }, [loading]);

  return roles?.indexOf("cliente") > -1 ? (
    <Redirect
      to={
        customer.length > 1
          ? "customers/1/30/index/%7B%7D"
          : `/customer/${customer}`
      }
    />
  ) : (
    <>
      {showNecropsy && (
        <CreateEditNecropsy
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={props.location}
          match={props.match}
          id={necropsyId}
        />
      )}
      {showSlaughter && (
        <CreateEditSlaughter
          show={showSlaughter}
          setShow={setShowSlaughter}
          location={props.location}
        />
      )}
      <ViewLoader isLoading={loading} />
      {!loading && (
        <>
          <SubHeader
            {...props}
            title="Home"
            breadcrumbs={[
              {
                label: "Home",
              },
            ]}
            icon={<Icons type="home" size="24" />}
          />
          <Row>
            <Col className="animate__animated animate__fadeInRight">
              <Block>
                <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_azul_wave_tall.png">
                  Score por estado, últimos 60 dias
                </BlockHeader>
                <BlockBody>
                  <Row>
                    <Col>
                      <Map
                        map={map}
                        setShowStateCustomers={setShowStateCustomers}
                      />
                    </Col>
                    <Col>
                      <BlockBodyContent>
                        {showStateCustomers ? (
                          <TableContent>
                            <Header>
                              <tr>
                                <th>Cliente</th>
                                <th>Estado</th>
                                <th>Aves Necropsiadas</th>
                                <th>Score</th>
                                <th></th>
                              </tr>
                            </Header>
                            <Body>
                              {showStateCustomers.map(
                                ({ name, state, score, birds, _id }) => (
                                  <tr key={_id}>
                                    <td>{name}</td>
                                    <td>{state}</td>
                                    <td>{birds}</td>
                                    <td>
                                      <Score
                                        options={{
                                          plotOptions: {
                                            radialBar: {
                                              startAngle: -135,
                                              endAngle: 225,
                                              hollow: {
                                                margin: 0,
                                                size: "70%",
                                                background: "#f9f9fc ",
                                                image: undefined,
                                                imageOffsetX: 0,
                                                imageOffsetY: 0,
                                                position: "front",
                                              },
                                              track: {
                                                background: getColor(
                                                  getScoreColor(score),
                                                  0.2
                                                ),
                                                strokeWidth: "67%",
                                                margin: 0, // margin is in pixels
                                              },

                                              dataLabels: {
                                                showOn: "always",
                                                name: {
                                                  offsetY: -5,
                                                  show: true,
                                                  color: getScoreColor(score),
                                                  fontSize: "7px",
                                                },
                                                value: {
                                                  formatter: function (val) {
                                                    return (
                                                      val / 10
                                                    ).toLocaleString();
                                                  },
                                                  color: getScoreColor(score),
                                                  fontSize: "10px",
                                                  show: true,
                                                  offsetY: -5,
                                                },
                                              },
                                            },
                                          },
                                          fill: {
                                            colors: [getScoreColor(score)],
                                            type: "gradient",
                                            gradient: {
                                              shade: "dark",
                                              type: "horizontal",

                                              gradientToColors: [
                                                getScoreColor(score),
                                              ],
                                              inverseColors: true,
                                              opacityFrom: 1,
                                              opacityTo: 0.8,
                                              stops: [0, 100],
                                            },
                                          },
                                          stroke: {
                                            lineCap: "round",
                                          },
                                          labels: [getScoreText(score)],
                                        }}
                                        series={[score * 10]}
                                        type="radialBar"
                                        width={isMobile() ? "90%" : "110"}
                                      />
                                    </td>
                                    <td>
                                      <Button
                                        color="white"
                                        bg="default"
                                        border="default"
                                        size="sm"
                                        style={{ float: "right" }}
                                        to={`/customer/${_id}`}
                                      >
                                        <Icons
                                          type="etc"
                                          size={15}
                                          color="#fff"
                                        />
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              )}
                              <tr>
                                <td colSpan={5}>
                                  <Button
                                    color="white"
                                    bg="default"
                                    border="default"
                                    size="sm"
                                    style={{ float: "right" }}
                                    onClick={() => setShowStateCustomers(null)}
                                  >
                                    Voltar
                                  </Button>
                                </td>
                              </tr>
                            </Body>
                          </TableContent>
                        ) : (
                          <TableContent>
                            <Header>
                              <tr>
                                <th>Estado</th>
                                <th>Aves Necropsiadas</th>
                                <th>Score</th>
                              </tr>
                            </Header>
                            <Body>
                              {map.map(({ state, score, birds, customers }) => (
                                <tr key={state}>
                                  <td>
                                    <SpanLink
                                      onClick={() =>
                                        setShowStateCustomers(customers)
                                      }
                                    >
                                      {state}
                                    </SpanLink>
                                  </td>
                                  <td>
                                    <span>{birds}</span>
                                  </td>
                                  <td>
                                    <Score
                                      options={{
                                        plotOptions: {
                                          radialBar: {
                                            startAngle: -135,
                                            endAngle: 225,
                                            hollow: {
                                              margin: 0,
                                              size: "70%",
                                              background: "#f9f9fc ",
                                              image: undefined,
                                              imageOffsetX: 0,
                                              imageOffsetY: 0,
                                              position: "front",
                                            },
                                            track: {
                                              background: getColor(
                                                getScoreColor(score),
                                                0.2
                                              ),
                                              strokeWidth: "67%",
                                              margin: 0, // margin is in pixels
                                            },

                                            dataLabels: {
                                              showOn: "always",
                                              name: {
                                                offsetY: -5,
                                                show: true,
                                                color: getScoreColor(score),
                                                fontSize: "7px",
                                              },
                                              value: {
                                                formatter: function (val) {
                                                  return (
                                                    val / 10
                                                  ).toLocaleString();
                                                },
                                                color: getScoreColor(score),
                                                fontSize: "10px",
                                                show: true,
                                                offsetY: -5,
                                              },
                                            },
                                          },
                                        },
                                        fill: {
                                          colors: [getScoreColor(score)],
                                          type: "gradient",
                                          gradient: {
                                            shade: "dark",
                                            type: "horizontal",

                                            gradientToColors: [
                                              getScoreColor(score),
                                            ],
                                            inverseColors: true,
                                            opacityFrom: 1,
                                            opacityTo: 0.8,
                                            stops: [0, 100],
                                          },
                                        },
                                        stroke: {
                                          lineCap: "round",
                                        },
                                        labels: [getScoreText(score)],
                                      }}
                                      series={[score * 10]}
                                      type="radialBar"
                                      width={isMobile() ? "90%" : "110"}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </Body>
                          </TableContent>
                        )}
                      </BlockBodyContent>
                    </Col>
                  </Row>
                </BlockBody>
              </Block>
            </Col>
          </Row>
          <Row>
            <Col className="animate__animated animate__fadeInLeft">
              <Block>
                <BlockHeader>Últimas Necropsias</BlockHeader>
                <BlockBody>
                  {lastNecropsies.map((item) => (
                    <ScoreItem
                      key={item._id}
                      title={item.customer?.name}
                      scoreColor={getScoreColor(getTotalScore(item.birds))}
                      text={[item.integrated, item.incubatory]}
                      description={[
                        item.visitDate
                          ? `Visita ${format(
                              new Date(item.visitDate),
                              "dd MMM y",
                              {
                                locale: ptBR,
                              }
                            )}`
                          : null,
                        `${item.birds.length} aves`,
                        item.lineage,
                        item.age
                          ? `idade:
                      ${item.age}`
                          : null,
                      ]}
                      score={
                        <Score
                          options={{
                            plotOptions: {
                              radialBar: {
                                startAngle: -135,
                                endAngle: 225,
                                hollow: {
                                  margin: 0,
                                  size: "70%",
                                  background: "#f9f9fc ",
                                  image: undefined,
                                  imageOffsetX: 0,
                                  imageOffsetY: 0,
                                  position: "front",
                                },
                                track: {
                                  background: getColor(
                                    getScoreColor(getTotalScore(item.birds)),
                                    0.2
                                  ),
                                  strokeWidth: "67%",
                                  margin: 0, // margin is in pixels
                                },

                                dataLabels: {
                                  showOn: "always",
                                  name: {
                                    offsetY: -5,
                                    show: true,
                                    color: getScoreColor(
                                      getTotalScore(item.birds)
                                    ),
                                    fontSize: "7px",
                                  },
                                  value: {
                                    formatter: function (val) {
                                      return (val / 10).toLocaleString();
                                    },
                                    color: getScoreColor(
                                      getTotalScore(item.birds)
                                    ),
                                    fontSize: "10px",
                                    show: true,
                                    offsetY: -5,
                                  },
                                },
                              },
                            },
                            fill: {
                              colors: [
                                getScoreColor(getTotalScore(item.birds)),
                              ],
                              type: "gradient",
                              gradient: {
                                shade: "dark",
                                type: "horizontal",

                                gradientToColors: [
                                  getScoreColor(getTotalScore(item.birds)),
                                ],
                                inverseColors: true,
                                opacityFrom: 1,
                                opacityTo: 0.8,
                                stops: [0, 100],
                              },
                            },
                            stroke: {
                              lineCap: "round",
                            },
                            labels: [getScoreText(getTotalScore(item.birds))],
                          }}
                          series={[getTotalScore(item.birds) * 10]}
                          type="radialBar"
                          width={isMobile() ? "90%" : "110"}
                        />
                      }
                      button={
                        <Button
                          bg={getScoreColor(getTotalScore(item.birds))}
                          border={getScoreColor(getTotalScore(item.birds))}
                          onClick={() => {
                            setNecropsyId(item._id);
                            setShowNecropsy(true);
                          }}
                          color="white"
                          size="sm"
                        >
                          <Icons type="etc" color="#fff" size="15" />
                        </Button>
                      }
                    />
                  ))}
                  {lastNecropsies[0] && (
                    <>
                      <Separator />
                      <Button
                        to={`/customer/connect/broiler/necropsy/1/30/-code/%7B%7D/`}
                        color="default"
                        bg="light"
                        border="light"
                        style={{ width: "100%" }}
                      >
                        Acessar Página de Necropsias&nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="18px"
                          height="18px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                              fill="#009870"
                              fillRule="nonzero"
                            />
                            <path
                              d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                              fill="#009870"
                              fillRule="nonzero"
                              opacity="0.3"
                              transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                            />
                          </g>
                        </svg>
                      </Button>
                    </>
                  )}
                </BlockBody>
              </Block>
            </Col>
            <Col className="animate__animated animate__fadeInRight">
              <Block>
                <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_verde_escuro_wave_tall.png">
                  Últimos Clientes Acessados
                </BlockHeader>
                <BlockBody>
                  <div>
                    {lastCustomers.map((item, index) => (
                      <CustomerItem
                        key={item._id}
                        {...item}
                        bg={`var(--${colors[index]})`}
                        button={
                          <Button
                            to={`/customer/${item._id}`}
                            bg={`var(--${colors[index]})`}
                            border={`var(--${colors[index]})`}
                            color="white"
                            size="sm"
                          >
                            <Icons type="etc" color="#fff" size="15" />
                          </Button>
                        }
                      />
                    ))}
                  </div>
                  <div>
                    <Separator />
                    <Button
                      to={`/customers/1/30/index/%7B%7D`}
                      color="default"
                      bg="light"
                      border="light"
                      style={{ width: "100%" }}
                    >
                      Acessar Página de Clientes&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                            fill="#009870"
                            fillRule="nonzero"
                          />
                          <path
                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                            fill="#009870"
                            fillRule="nonzero"
                            opacity="0.3"
                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                          />
                        </g>
                      </svg>
                    </Button>
                  </div>
                </BlockBody>
              </Block>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
