import React, { useEffect } from "react";
import { Input } from "components/Input";
import { format } from "date-fns";
import subDays from "date-fns/subDays";
import { Col, Row } from "styles";

export function FarmData({ necropsy, setNecropsy, birds, changeBirds }) {
  useEffect(() => {
    try {
      if (necropsy.visitDate) {
        const arrayDate = necropsy.visitDate.split("/");
        const d = new Date(arrayDate[2], +arrayDate[1] - 1, arrayDate[0]);
        if (!isNaN(d.getTime()) && necropsy.age) {
          setNecropsy((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState));
            newState.accommodatingDate = format(
              subDays(d, +prevState.age),
              "dd/MM/yyyy"
            );
            return newState;
          });
        }
      }
    } catch (e) {}
  }, [necropsy.visitDate, necropsy.age, setNecropsy]);

  return (
    <>
      <Row>
        <Col>
          <Input
            required
            item={necropsy}
            setItem={setNecropsy}
            params="technicalManager"
            type="autocomplete"
            label="Responsável Técnico"
            paramsGet={["code", "name"]}
            paramsLabel={["code", "name"]}
            select="code name"
            model="user"
            placeholder="Digite o nome ou o código do Técnico"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="visitDate"
            type="inputMask"
            label="Data da Visita"
            mask="99/99/9999"
            required
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="weight1"
            type="input"
            inputType="number"
            label="Peso 7 dias (Kg)"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="weight2"
            type="input"
            inputType="number"
            label="Peso 14 dias (Kg)"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="weight3"
            type="input"
            inputType="number"
            label="Peso 21 dias (Kg)"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="weight4"
            type="input"
            inputType="number"
            label="Peso 28 dias (Kg)"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="weight5"
            type="input"
            inputType="number"
            label="Peso 35 dias (Kg)"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="weight6"
            type="input"
            inputType="number"
            label="Peso 42 dias (Kg)"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            onChange={changeBirds}
            value={birds}
            type="select"
            label="Nº Aves"
            options={[1, 2, 3, 4, 5].map((item) => ({ value: item }))}
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="integrated"
            type="input"
            label="Integrado"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="lotNumber"
            type="input"
            label="Nº Lote"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="bedNumber"
            type="input"
            label="Nº Cama"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="lineage"
            type="select"
            placeholder="Linhagem"
            label="Linhagem"
            options={["Cobb", "Ross", "Hubbard", "Misto"].map((item) => ({
              value: item,
            }))}
            required
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="age"
            inputType="number"
            type="input"
            label="Idade do Lote"
            required
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="incubatory"
            type="input"
            label="Incubatório"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="interval"
            type="input"
            label="Intervalo"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="density"
            type="input"
            label="Densidade"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="mortality"
            type="input"
            label="Mortalidade (%)"
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="gender"
            type="select"
            placeholder="Selecione o sexo"
            label="Sexo"
            options={["Macho", "Femea", "Misto"].map((item) => ({
              value: item,
            }))}
            required
          />
        </Col>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="accommodatingDate"
            type="inputMask"
            label="Data do Alojamento"
            mask="99/99/9999"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={necropsy}
            setItem={setNecropsy}
            params="nutrition"
            type="input"
            label="Nutrição"
          />
        </Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </>
  );
}
