import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function EnziPacProProtease(props) {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "185px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual produto?</span>
                </th>
                <th>
                  <span>Qual finalidade?</span>
                </th>
                <th>
                  <span>Qual dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Pré</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.pre.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.pre.goal"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.pre.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
              <tr>
                <td>Inicial</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.initial.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.initial.goal"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.initial.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
              <tr>
                <td>Crescimento</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.growth.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.growth.goal"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.growth.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
              <tr>
                <td>Desenvolvimento</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.development.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.development.goal"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.development.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>EnzyPac está aprovado? Se não, por qual motivo?</label>
          <Input
            type="checkbox"
            params="enzymes.enziPacProProtease.approved.checked"
            item={props.megg}
            setItem={props.setMegg}
            label="Aprovado"
          />
          <Input
            type="input"
            label=" "
            params="enzymes.enziPacProProtease.approved.reason"
            item={props.megg}
            setItem={props.setMegg}
            disabled={props.megg.enzymes.enziPacProProtease.approved.checked}
            placeholder="Digite o motivo"
          />
        </Col>
      </Row>
    </>
  );
}
