import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function TherapeuticMedication(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "50px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual tipo?</span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th>
            <span>Qual dose?</span>
          </th>
          <th style={{ width: "50px" }}>
            <span>Via Águal?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Cria</td>
          <td>
            <Input
              type="selectOnly"
              params="health.therapeuticMedication.create.type"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Tipo"
              options={props.config
                .filter(
                  (item) => item.key === "healthTherapeuticMedicationType"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.therapeuticMedication.create.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter(
                  (item) => item.key === "healthTherapeuticMedicationProduct"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.create.dose"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
          <td>
            <Input
              type="checkbox"
              params="health.therapeuticMedication.create.water"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Recria</td>
          <td>
            <Input
              type="selectOnly"
              params="health.therapeuticMedication.recreate.type"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Tipo"
              options={props.config
                .filter(
                  (item) => item.key === "healthTherapeuticMedicationType"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.therapeuticMedication.recreate.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter(
                  (item) => item.key === "healthTherapeuticMedicationProduction"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.recreate.dose"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
          <td>
            <Input
              type="checkbox"
              params="health.therapeuticMedication.recreate.water"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>

        <tr>
          <td>Produção</td>
          <td>
            <Input
              type="selectOnly"
              params="health.therapeuticMedication.production.type"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Tipo"
              options={props.config
                .filter(
                  (item) => item.key === "healthTherapeuticMedicationType"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.therapeuticMedication.production.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter(
                  (item) => item.key === "healthTherapeuticMedicationProduction"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.production.dose"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
          <td>
            <Input
              type="checkbox"
              params="health.therapeuticMedication.production.water"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
