import styled from "styled-components";

export const BeatWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100px;
  height: 200px;
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid #495057;
  position: relative;
`;

export const RepetitionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 2rem 1rem 2rem 1rem;
  border: 1px solid #0e6ab0;
  border-radius: 5px;
`;
export const BeatFill = styled.div`
  width: 100%;
  height: ${(props) => props.height || 0};
  background-color: green;

  border-radius: 8px;
`;

export const InputPercentage = styled.input`
  display: block;
  max-width: 80%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  // color: #495057;
  color: #000000;
  background-color: inherit;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const CleaningText = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
