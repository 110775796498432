import { Input } from "components/Input";
import { Body, Header, TableContent } from "components/Table/style";
import { Col, Row } from "styles";

export function EggyQuality({ preNecropsy, setPreNecropsy, getEstimated }) {
  return (
    <Row>
      <Col>
        <TableContent>
          <Header>
            <tr>
              <th></th>
              <th>Estimado</th>
              <th></th>
            </tr>
          </Header>
          <Body>
            <tr>
              <td>Peso do Ovo g</td>
              <td>
                {getEstimated("eggWeight", 1).toFixed(2).replace(".", ",")}
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.weight"
                  placeholder="Peso do Ovo g"
                  inputType="number"
                />
              </td>
            </tr>
            <tr>
              <td>Ovo pequeno</td>
              <td>
                {getEstimated("smallEggs", 100).toFixed(2).replace(".", ",")}%
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.small"
                  placeholder="Ovo pequeno %"
                  inputType="number"
                />
              </td>
            </tr>
            <tr>
              <td>Ovo dupla gema</td>
              <td>
                {getEstimated("doubleEggYolk", 100)
                  .toFixed(2)
                  .replace(".", ",")}
                %
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.doubleYolk"
                  placeholder="Ovo dupla gema %"
                  inputType="number"
                />
              </td>
            </tr>
            <tr>
              <td>Ovo Descarte</td>
              <td>
                {getEstimated("discartEggs", 100).toFixed(2).replace(".", ",")}%
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.discart"
                  placeholder="Ovo Descarte %"
                  inputType="number"
                />
              </td>
            </tr>
            <tr>
              <td>Ovo com fissura </td>
              <td>
                {getEstimated("fissuraEggs", 100).toFixed(2).replace(".", ",")}%
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.fissura"
                  placeholder="Povo com fissura %"
                  inputType="number"
                />
              </td>
            </tr>
            <tr>
              <td>Ovo Trincado</td>
              <td>
                {getEstimated("crackedEggs", 100).toFixed(2).replace(".", ",")}%
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.cracked"
                  placeholder="Ovo Trincado %"
                  inputType="number"
                />
              </td>
            </tr>
            <tr>
              <td>Ovos de cama</td>
              <td>
                {getEstimated("litterEggs", 100).toFixed(2).replace(".", ",")}%
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="eggQuality.litter"
                  placeholder="Ovos de cama %"
                  inputType="number"
                />
              </td>
            </tr>
          </Body>
        </TableContent>
      </Col>
    </Row>
  );
}
