import { Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Icons } from "components/Icons";
import { Image } from "components/Image";
import { SubHeader } from "components/SubHeader";
import { api } from "services/api";
import { Block, BlockBody } from "styles";
import { FactoryRegistration } from "./FactoryRegistration";
import { InspectionTermBPF } from "./InspectionTermBPF";
import { Maintenance } from "./Maintenance";
import { MixerData } from "./MixerData";
import { Gate798 } from "./Gate798";
import { NutritionalLevels } from "./NutritionalLevels";

export function CreateEdit(props) {
  const [tab, setTab] = useState(+props.match.params.tab);
  const [name, setName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [isMegg, setIsMegg] = useState("");
  const [isSwine, setIsSwine] = useState("");
  const history = useHistory();
  useEffect(() => {
    const getFactoryName = async () => {
      const response = await api.get(
        `/factory/getname/${props.match.params.id}`
      );
      setName(`${response.data.unit} - ${response.data.city}`);
      const responseCustomer = await api
        .get(`connect/customer/${props.match.params.customer}`)
        .catch(() => ({ error: true }));
      setCustomerName(responseCustomer.data.name);
      setIsMegg(responseCustomer.data.megg);
      setIsSwine(responseCustomer.data.swine);
    };
    if (props.match.params.id) {
      getFactoryName();
    }
  }, [props.match.params]);

  useEffect(() => {
    setTab(+props.match.params.tab);
  }, [props.match.params.tab]);

  return (
    <>
      <SubHeader
        {...props}
        title={props.match.params.id ? name : "Nova Fábrica"}
        route="factory"
        breadcrumbs={[
          { label: "Connect" },
          {
            label: customerName,
            to: `/customer/${props.match.params.customer}`,
          },
          {
            label: "Fábricas",
            to: `/customer/factory/1/30/index/{}/${props.match.params.customer}`,
          },
          { label: props.match.params.id ? name : "Nova Fábrica" },
        ]}
        otherButtons={[
          isMegg ? (
            <Image
              src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_megg_small.png"
              style={{ height: 45 }}
            />
          ) : null,
        ]}
        icon={<Icons type="factory" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockBody>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              history.push(
                `/customer/factory/${props.match.params.customer}/1/30/-${
                  value === 0 ? "initialDescription.visitDate" : "date"
                }/{}/${value}${
                  props.match.params.id ? `/${props.match.params.id}` : ""
                }`
              );
            }}
          >
            <Tab label="Cadastro de fábrica" />
            <Tab
              label="Termo de fiscalização BPF"
              disabled={!props.match.params.id}
            />
            <Tab label="Portaria 798" disabled={!props.match.params.id} />
            <Tab
              label="Dados do misturador"
              disabled={!props.match.params.id}
            />

            <Tab label="Manutenção" disabled={!props.match.params.id} />
            <Tab
              label="Níveis nutricionais"
              disabled={!props.match.params.id}
            />
          </Tabs>
          {tab === 0 && <FactoryRegistration {...props} />}
          {tab === 1 && <InspectionTermBPF {...props} />}
          {tab === 2 && <Gate798 {...props} isSwine={isSwine} />}
          {tab === 3 && <MixerData {...props} />}
          {tab === 4 && <Maintenance {...props} />}
          {tab === 5 && <NutritionalLevels {...props} />}
        </BlockBody>
      </Block>
    </>
  );
}
