import {
  Background,
  Body,
  Container,
  Content,
  Dialog,
  Footer,
  Header,
  Title,
} from "./style";
import { Icons } from "../Icons";

export function Modal({ header, show, setShow, footer, children, close }) {
  return (
    show && (
      <>
        <Background show={show} />
        <Dialog show={show} className="animate__animated animate__fadeInUp">
          <Container>
            <Content>
              <Header>
                <Title>{header}</Title>

                <Icons type="close" color="#747888" size={24} onClick={close} />
              </Header>
              <Body>{children}</Body>
              <Footer>{footer}</Footer>
            </Content>
          </Container>
        </Dialog>
      </>
    )
  );
}
