import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { FirstLetter } from "../FirstLetter";
import {
  Container,
  Content,
  Data,
  Text,
  Title,
  UserFirstNameContainer,
} from "./style";

export function ReportConsolidatedItem({ user, date, button, inserts }) {
  return (
    <Container>
      <Content>
        <Data>
          <UserFirstNameContainer>
            <FirstLetter bg="#009870" color="#fff" size="50">
              {user.name}
            </FirstLetter>
          </UserFirstNameContainer>
          <div>
            <Title>
              <h5> {user.name}</h5>&nbsp;
              {"-"}&nbsp;
              {format(new Date(date), "dd ' de ' MMM', 'yyyy',' H:mm", {
                locale: ptBR,
              })}
            </Title>
            <Text>Dados inseridos: {inserts.count}</Text>
          </div>
        </Data>
        {button}
      </Content>
    </Container>
  );
}
