import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function MycotoxinAdsorbents(props) {
  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              textAlign: "end",
              marginRight: "40px",
            }}
          >
            <Input
              type="checkbox"
              params="additions.mycotoxinAdsorbents.unknownConcept"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              label="Cliente não conhece o conceito"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "185px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual Produto?</span>
                </th>
                <th>
                  <span>Qual Finalidade?</span>
                </th>
                <th>
                  <span>Qual Dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Pré Postura</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.preLay.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.preLay.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.preLay.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Pré Pico</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.prePeak.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.prePeak.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.prePeak.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Pico de Postura</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layPeak.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layPeak.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layPeak.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 01</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal01.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal01.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal01.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 01</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal02.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal02.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal02.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 03</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal03.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal03.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal03.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 04</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal04.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal04.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal04.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 05</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal05.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal05.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal05.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 06</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal06.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal06.reason"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.mycotoxinAdsorbents.layFinal06.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>
    </>
  );
}
