import Chart from "react-apexcharts";
import { Col } from "styles";
import { slaughterConfig } from "utils/globalData";
import {
  getColor,
  getScoreSlaughterColor,
  getScoreSlaughterText,
} from "utils/globalFunctions";
import { ItemScore } from "../style";

const BoxScore = ({ params, response, customTitle }) => {
  const splitParams = params.split(".");
  const config =
    params === "legCorns"
      ? slaughterConfig[params]
      : slaughterConfig[splitParams[0]][splitParams[1]];
  const { totalPerScore } = response;
  return (
    <Col>
      <ItemScore>
        <span className="kt-svg-icon center">
          {config.icon || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  opacity="0.3"
                  x="4"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="4"
                  y="10"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="10"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="10"
                  y="10"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="16"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="16"
                  y="10"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="4"
                  y="16"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="10"
                  y="16"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="16"
                  y="16"
                  width="4"
                  height="4"
                  rx="2"
                />
              </g>
            </svg>
          )}
        </span>
        <span
          className="kt-widget17__subtitle"
          style={{
            fontSize: customTitle || null,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {config.label}
        </span>

        <div className="col radial-chart">
          <Chart
            options={{
              plotOptions: {
                radialBar: {
                  startAngle: -135,
                  endAngle: 225,
                  hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#ffffff",
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                  },
                  track: {
                    background: getColor(
                      getScoreSlaughterColor(
                        Math.round(totalPerScore[params] * 100) / 100
                      ),
                      0.2
                    ),
                    strokeWidth: "67%",
                    margin: 0, // margin is in pixels
                  },

                  dataLabels: {
                    showOn: "always",
                    name: {
                      offsetY: -20,
                      show: true,
                      color: getScoreSlaughterColor(
                        Math.round(totalPerScore[params] * 100) / 100
                      ),
                      fontSize: "13px",
                    },
                    value: {
                      formatter: function (val) {
                        return (
                          Math.round(val * 100) / 100 < 0
                            ? 0
                            : Math.round(val * 100) / 100 / 10
                        ).toLocaleString();
                      },
                      color: getScoreSlaughterColor(
                        Math.round(totalPerScore[params] * 100) / 100
                      ),
                      fontSize: "20px",
                      show: true,
                    },
                  },
                },
              },
              fill: {
                colors: [
                  getScoreSlaughterColor(
                    Math.round(totalPerScore[params] * 100) / 100
                  ),
                ],
                type: "gradient",
                gradient: {
                  shade: "dark",
                  type: "horizontal",

                  gradientToColors: [
                    getScoreSlaughterColor(
                      Math.round(totalPerScore[params] * 100) / 100
                    ),
                  ],
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 0.8,
                  stops: [0, 100],
                },
              },
              stroke: {
                lineCap: "round",
              },
              labels: [getScoreSlaughterText(totalPerScore[params])],
            }}
            series={[(Math.round(totalPerScore[params] * 100) / 100) * 10]}
            type="radialBar"
            width="100%"
          />
        </div>
      </ItemScore>
    </Col>
  );
};

export default BoxScore;
