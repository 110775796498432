import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";
import { Input } from "components/Input";

export function PhytaseHidosing(props) {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "50px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual tipo?</span>
                </th>
                <th>
                  <span>Qual Produto?</span>
                </th>
                <th>
                  <span>Qual dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Cria</td>
                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.phytaseHidosing.create.type"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Tipo"
                    options={props.config
                      .filter((item) => item.key === "enzymesPhytaseType")
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.phytaseHidosing.create.product"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Produto"
                    options={props.config
                      .filter((item) => item.key === "enzymesPhytaseProduct")
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.create.dose"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Recria</td>
                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.phytaseHidosing.recreate.type"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Tipo"
                    options={props.config
                      .filter((item) => item.key === "enzymesPhytaseType")
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.phytaseHidosing.recreate.product"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Produto"
                    options={props.config
                      .filter((item) => item.key === "enzymesPhytaseProduct")
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.recreate.dose"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Produção</td>
                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.phytaseHidosing.production.type"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Tipo"
                    options={props.config
                      .filter((item) => item.key === "enzymesPhytaseType")
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.phytaseHidosing.production.product"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Produto"
                    options={props.config
                      .filter((item) => item.key === "enzymesPhytaseProduct")
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.production.dose"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <label className="form-label">
            Microtech está aprovada? Se não, por qual motivo?
          </label>
          <Row>
            <Col>
              <Input
                type="checkbox"
                params="enzymes.phytaseHidosing.approved.checked"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                label="Aprovado"
                className="kt-checkbox  kt-checkbox-right"
              />
            </Col>
          </Row>

          <Input
            type="inputOnly"
            params="enzymes.phytaseHidosing.approved.reason"
            item={props.heavyBroiler}
            setItem={props.setHeavyBroiler}
            disabled={
              props.heavyBroiler.enzymes.phytaseHidosing.approved.checked
            }
            placeholder="Digite o motivo"
          />
        </Col>
      </Row>
    </>
  );
}
