import { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Button } from "components/Button/style";
import { Body, Header, TableContent } from "components/Table/style";
import { Image } from "components/Image";
import { Input } from "components/Input";
import { Icons } from "components/Icons";

export function VentralInspection({ slaughter, setSlaughter, config, params }) {
  const configTable = [
    { label: "Rompimento de pele", param: "skinBreakage" },
    { label: "Queimadura na pele do peito", param: "chestSkinBurn" },
    {
      label: "Celulite no músculo do peito",
      param: "celluliteBelowChest",
    },
    { label: "Hemorragia na pele do peito", param: "chestSkinBleeding" },
    { label: "Hemorragia nas asas", param: "bleedingWings" },
    { label: "Deslocamento de asa", param: "displacementWings" },
    { label: "Fratura de asas", param: "fractureWings" },
  ];

  const [info, setInfo] = useState(null);

  return info ? (
    <Card style={{ height: "100%", marginBottom: "15px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <strong>
          <i className="far fa-question-circle" /> &nbsp;
          {info.label}
        </strong>
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={() => setInfo(null)}
          size="sm"
        >
          Voltar
        </Button>
      </CardHeader>
      <CardBody>
        <TableContent>
          <Header>
            <tr>
              <th style={{ width: "100px" }}>Score</th>
              <th>Descrição</th>
              <th style={{ width: "500px" }}>Imagem</th>
            </tr>
          </Header>
          <Body>
            {info.options.map((item) => (
              <tr className="kt-datatable__row" key={item._id}>
                <td>
                  <span>
                    <b>{item.score === "light" ? "Leve" : "Severa"}</b>
                  </span>
                </td>
                <td>{item.description}</td>
                <td>
                  <Image
                    className="img-fluid"
                    src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/slaughter-${item.key.replace(
                      /\./g,
                      "-"
                    )}-${item._id}.${item.extention}`}
                  />
                </td>
              </tr>
            ))}
          </Body>
        </TableContent>
      </CardBody>
    </Card>
  ) : (
    <Row>
      <Col>
        <TableContent>
          <Header>
            <tr>
              <th style={{ width: 200 }}></th>
              <th
                colSpan={2}
                style={{ textAlign: "center" }}
                className="text-primary"
              >
                Leve
              </th>
              <th
                colSpan={2}
                style={{ textAlign: "center" }}
                className="text-danger"
              >
                Severa
              </th>
            </tr>
            <tr>
              <th>Lesão</th>
              <th style={{ textAlign: "center" }} className="text-primary">
                Macho
              </th>
              <th style={{ textAlign: "center" }} className="text-primary">
                Fêmea
              </th>
              <th style={{ textAlign: "center" }} className="text-danger">
                Macho
              </th>
              <th style={{ textAlign: "center" }} className="text-danger">
                Fêmea
              </th>
            </tr>
          </Header>
          <Body>
            {configTable.map(({ param, label }) => (
              <tr key={param}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{label}</span>
                    <div
                      onClick={() =>
                        setInfo(config["ventralInspection-" + param])
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icons type="question" color="#009870" size={18} />
                    </div>
                  </div>
                </td>
                <td>
                  <Input
                    item={slaughter}
                    setItem={setSlaughter}
                    params={`ventralInspection.${param}.light.male`}
                    type="inputOnly"
                    placeholder="Nº Aves"
                  />
                </td>
                <td>
                  <Input
                    item={slaughter}
                    setItem={setSlaughter}
                    params={`ventralInspection.${param}.light.female`}
                    type="inputOnly"
                    placeholder="Nº Aves"
                  />
                </td>
                <td>
                  <Input
                    item={slaughter}
                    setItem={setSlaughter}
                    params={`ventralInspection.${param}.severe.male`}
                    type="inputOnly"
                    placeholder="Nº Aves"
                  />
                </td>
                <td>
                  <Input
                    item={slaughter}
                    setItem={setSlaughter}
                    params={`ventralInspection.${param}.severe.female`}
                    type="inputOnly"
                    placeholder="Nº Aves"
                  />
                </td>
              </tr>
            ))}
          </Body>
        </TableContent>
      </Col>
    </Row>
  );
}
