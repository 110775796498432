import { useState, useEffect } from "react";
import { PercentageInput } from "./style";

export function RecoveryRate({ handlePercentage, beat, data, setData }) {
  const [rate, setRate] = useState("");

  useEffect(() => {
    setRate(data.microtracerBeats[`beat${beat}`].recoveryRate);
  }, [data, beat]);

  const getResultByRate = (rate) => {
    const recoveryResults = {
      below: "Abaixo",
      ideal: "Ideal",
      above: "Acima",
    };
    if (rate < 80) {
      return recoveryResults.below;
    } else if (rate > 120) {
      return recoveryResults.above;
    } else {
      return recoveryResults.ideal;
    }
  };

  const handleRate = (e) => {
    const newRate = handlePercentage(e, setRate);

    if (newRate && !isNaN(+newRate)) {
      const result = getResultByRate(newRate);
      setData((prevState) => {
        const newData = { ...prevState };
        newData.microtracerBeats[`beat${beat}`].recoveryRate = newRate;
        newData.microtracerBeats[`beat${beat}`].recoveryResult = result;
        return newData;
      });
    }
  };

  return (
    <div style={{ padding: "5px" }}>
      <PercentageInput
        value={rate}
        onChange={(e) => handleRate(e)}
        type="number"
      />
    </div>
  );
}
