import { Input } from "components/Input";
import { ptBR } from "react-date-range/dist/locale";
import { Block, BlockBody, BlockHeader, Separator } from "styles";
import {
  HistoricOption,
  HistoricOptionContent,
  ReportButton,
  SliderContent,
  SliderContentInput,
} from "../style";
import { Icons } from "components/Icons";
import { Required } from "components/Input/style";
import { useState } from "react";
import { Slider } from "@material-ui/core";
import { DateRange } from "react-date-range";
import ReactLoading from "react-loading";
import { api } from "services/api";
import { historicOptions, typesOfInjuries } from "../const";
import { endOfDay, startOfDay } from "date-fns";
import Swal from "sweetalert2";

export function Filters({ loading, setLoading, props }) {
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [input, setInput] = useState({
    group: "Semanal",
    createdBy: null,
    customer: props.match.params.id,
    age: [0, 60],
    injuryType: [],
    lineage: "",
    technicalManager: null,
    technicalManagerList: [],
    comparison: false,
    broilerList: [],
  });

  const [broilerInput, setBroilerInput] = useState("");

  const [injuryTypeInput, setInjuryTypeInput] = useState("");

  const injuryTypeInputHandler = (value) => {
    setInput((prevState) => {
      const nextState = JSON.parse(JSON.stringify(prevState));
      nextState.injuryType.push(value);
      return nextState;
    });
  };

  const broilerInputHandler = (value) => {
    setInput((prevState) => {
      const nextState = JSON.parse(JSON.stringify(prevState));
      nextState.broilerList.push(value);
      return nextState;
    });
  };

  const reportHandler = () => {
    if (input.injuryType.length === 0) {
      return Swal.fire(
        "Relatório",
        "Selecione pelo menos um tipo de lesão",
        "error"
      );
    }
    const query = { ...input, dateRange };
    setLoading(true);
    props.history.replace(
      `/customer/connect/broiler/report/${
        props.match.params.id
      }/${JSON.stringify(query)}`
    );
  };

  return (
    <Block>
      <BlockHeader>Filtros</BlockHeader>
      <BlockBody>
        <Input
          type="select"
          label="Agrupar"
          item={input}
          setItem={setInput}
          params="group"
          options={[{ value: "Semanal" }, { value: "Mensal" }]}
          disabled={loading}
        />
        <Input
          item={input}
          setItem={setInput}
          params="technicalManager"
          type="autocomplete"
          label="Responsável Técnico"
          paramsGet={["code", "name"]}
          paramsLabel={["code", "name"]}
          response={async (data) => {
            if (data[0]) {
              try {
                const req = {
                  model: "customer",
                  sort: "responsibles.user.name",
                  select: "responsibles.user",
                  populate: "responsibles.user",
                  query: {
                    _id: props.match.params.id,
                    responsibles: {
                      $elemMatch: { user: { $in: data.map(({ _id }) => _id) } },
                    },
                  },
                };
                const response = await api.post("filter/autocomplete", req);
                const users = [];
                response.data.forEach(({ responsibles }) =>
                  responsibles.forEach(({ user }) => {
                    console.log(user);
                    if (users.indexOf(user) === -1) {
                      users.push(user);
                    }
                  })
                );

                return data.filter(({ _id }) => users.indexOf(_id) > -1);
              } catch (e) {
                console.log(e);
                return [];
              }
            }
            return data;
          }}
          select="code name"
          model="user"
          placeholder="Digite o nome ou o código do Técnico"
        />
        {/* <Input
          type="checkbox"
          item={input}
          setItem={setInput}
          params="comparison"
          label="Comparar Técnicos"
        /> */}
        <HistoricOptionContent>
          {input.technicalManagerList.map((item, index) => (
            <HistoricOption key={item}>
              {item.name}
              <Icons
                type="close"
                size={10}
                color="#fff"
                onClick={() =>
                  setInput((prevState) => {
                    const nextState = JSON.parse(JSON.stringify(prevState));
                    nextState.technicalManagerList.splice(index, 1);
                    return nextState;
                  })
                }
              ></Icons>
            </HistoricOption>
          ))}
        </HistoricOptionContent>

        <Input
          type="select"
          label={
            <>
              Tipo de Lesão<Required>*</Required>
            </>
          }
          item={injuryTypeInput}
          setItem={setInjuryTypeInput}
          onChange={(e) => injuryTypeInputHandler(e.target.value)}
          placeholder="Selecione o tipo de lesão"
          params="injuryType"
          options={typesOfInjuries
            .filter(({ value }) => input.injuryType.indexOf(value) === -1)
            .map((item) => item)}
          disabled={loading}
        />
        <HistoricOptionContent>
          {input.injuryType.map((item, index) => (
            <HistoricOption key={item}>
              {typesOfInjuries.filter(({ value }) => value === item)[0].label}
              <Icons
                type="close"
                size={10}
                color="#fff"
                onClick={() =>
                  setInput((prevState) => {
                    const nextState = JSON.parse(JSON.stringify(prevState));
                    nextState.injuryType.splice(index, 1);
                    return nextState;
                  })
                }
              ></Icons>
            </HistoricOption>
          ))}
        </HistoricOptionContent>
        <Input
          item={input}
          setItem={setInput}
          params="lineage"
          type="select"
          placeholder="Todas"
          label="Linhagem"
          options={["Cobb", "Ross", "Hubbard", "Misto"].map((item) => ({
            value: item,
          }))}
        />
        <Input
          item={broilerInput}
          onChange={(e) => broilerInputHandler(e.target.value)}
          type="select"
          setItem={setBroilerInput}
          placeholder="Selecione as opções"
          label="Histórico de programa"
          options={historicOptions
            .filter(({ value }) => input.broilerList.indexOf(value) === -1)
            .map((item) => item)}
        />
        <HistoricOptionContent>
          {input.broilerList.map((item, index) => (
            <HistoricOption key={item}>
              {historicOptions.filter(({ value }) => value === item)[0].label}
              <Icons
                type="close"
                size={10}
                color="#fff"
                onClick={() =>
                  setInput((prevState) => {
                    const nextState = JSON.parse(JSON.stringify(prevState));
                    nextState.broilerList.splice(index, 1);
                    return nextState;
                  })
                }
              ></Icons>
            </HistoricOption>
          ))}
        </HistoricOptionContent>

        <SliderContent className="form-group">
          <label className="form-label">Idade do lote</label>
          <Slider
            value={input.age}
            onChange={(e, newValue) =>
              setInput((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState));
                newState.age = newValue;
                return newState;
              })
            }
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={0}
            max={60}
          />
          <SliderContentInput>
            <Input
              type="inputOnly"
              className="form-control "
              placeholder="De"
              item={input}
              params="age.0"
              setItem={setInput}
            />
            <Input
              type="inputOnly"
              className="form-control "
              placeholder="Até"
              item={input}
              params="age.1"
              setItem={setInput}
            />
          </SliderContentInput>
        </SliderContent>
        <div className="form-group">
          <label className="form-label">Período de Análise</label>
          <DateRange
            className="date-block"
            editableDateInputs={true}
            onChange={(item) => setDateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            locale={ptBR}
            endDatePlaceholder="Até"
            startDatePlaceholder="De"
            dateDisplayFormat="dd/MM/yyyy"
            color="#009870"
            weekdayDisplayFormat="eeeee"
            rangeColors={["#009870"]}
            disabled={loading}
          />
        </div>
        <Separator></Separator>
        <ReportButton
          color="white"
          bg="default"
          border="default"
          disabled={loading}
          onClick={reportHandler}
        >
          {loading ? (
            <ReactLoading type="spin" color="#ffffff" height={19} width={19} />
          ) : (
            <>
              <i className="fas fa-cogs" /> Gerar Relatório
            </>
          )}
        </ReportButton>
      </BlockBody>
    </Block>
  );
}
