import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    align-items: stretch;
    transition: height 0.3s ease;
    position: relative;
    z-index: 2;
    height: 80px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #fff;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0;
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
  @media (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 30px;
  }
  @media (min-width: 1439px) {
    width: 1380px;
    margin: 0 auto;
  }
`;

export const Brand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: flex-begin;
  flex-direction: row;
  padding: 0;
  position: relative;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const LogoLink = styled(Link)`
  width: 140px;
  display: flex;
  justify-content: flex-begin;
  align-items: flex-end;
`;

export const Logo = styled.img`
  width: 100%;
  @media (min-width: 1025px) {
    display: inline-block;
  }
`;
