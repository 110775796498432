import { useEffect, useState } from "react";
import { Icons } from "components/Icons";
import { Image } from "components/Image";
import { SubHeader } from "components/SubHeader";
import { Table } from "components/Table";
import { api } from "services/api";
import { StepButtons } from "../../Connect/StepButtons";
import { StepButtonsMegg } from "../../Megg/StepButtons";

export function List(props) {
  const tableParams = [
    {
      key: "unit",
      label: "Unidade",
      filter: "text",
      placeholder: "Digite a unidade",
      mobile: {
        type: "title",
      },
    },
    {
      key: "city",
      label: "Cidade",
      filter: "text",
      mobile: {
        type: "small",
      },
    },
    {
      key: "monthlyProduction",
      label: "Produção Mensal",
      type: "number",
      filter: "range",
      mobile: {
        type: "item",
      },
    },
    {
      key: "date",
      label: "Data",
      type: "date",
      filter: "date",
      mobile: {
        type: "title",
      },
    },
    {
      key: "evaluation",
      label: "Avaliação",
      type: "custom",
      mobile: {
        type: "status",
      },
      response: (item) => {
        return <Icons type="checklist" size={24} color="#009870" />;
      },
    },
  ];

  const [customerName, setCustomerName] = useState("");

  const [isMegg, setIsMegg] = useState("");

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
      setIsMegg(!!response.data.megg);
    };
    if (props.match.params.id) {
      getCustomerName();
    }
  }, [props.match.params.id]);

  return (
    <>
      <SubHeader
        {...props}
        title={customerName || "Fábricas"}
        route="factory"
        breadcrumbs={[
          {
            label: customerName,
            to: "/customer/" + props.match.params.id,
          },
          {
            label: "Fábricas",
          },
        ]}
        newLabel="Nova Fábrica"
        newLink={`/customer/factory/${props.match.params.id}/1/30/-initialDescription.visitDate/{}/0`}
        icon={<Icons type="factory" size="24" color="#fff" />}
        otherButtons={[
          isMegg ? (
            <Image
              src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_megg_small.png"
              style={{ height: 45 }}
            />
          ) : null,
        ]}
      />
      {isMegg === "" ? null : isMegg ? (
        <StepButtonsMegg tab={"3"} customer={{ _id: props.match.params.id }} />
      ) : (
        <StepButtons tab={"3"} customer={{ _id: props.match.params.id }} />
      )}

      <Table
        cols={tableParams}
        editLink={`/customer/factory/${props.match.params.id}/1/30/-initialDescription.visitDate/{}/0/`}
        emptyText={"Nenhuma fábrica localizada!"}
        route={`/factory/${props.match.params.id}`}
        {...props}
      />
    </>
  );
}
