import React, { useEffect } from "react";
import { format } from "date-fns";
import subDays from "date-fns/subDays";
import { Col, Row } from "styles";
import { Input } from "components/Input";

export function FarmData({
  preNecropsy,
  setPreNecropsy,
  changeBoxes,
  changeBirds,
}) {
  useEffect(() => {
    if (preNecropsy.visitDate) {
      const arrayDate = preNecropsy.visitDate.split("/");
      const d = new Date(arrayDate[2], +arrayDate[1] - 1, arrayDate[0]);
      if (!isNaN(d.getTime()) && preNecropsy.age) {
        setPreNecropsy((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState.accommodatingDate = format(
            subDays(d, +prevState.age),
            "dd/MM/yyyy"
          );
          return newState;
        });
      }
    }
  }, [preNecropsy.visitDate, preNecropsy.age, setPreNecropsy]);

  return (
    <>
      <Row>
        <Col>
          <Input
            required
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="technicalManager"
            type="autocomplete"
            label="Responsável Técnico"
            paramsGet={["code", "name"]}
            paramsLabel={["code", "name"]}
            select="code name"
            model="user"
            placeholder="Digite o nome ou o código do Técnico"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="core"
            type="input"
            label="Nucleo"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="lote"
            type="input"
            label="Lote"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="visitDate"
            type="inputMask"
            label="Data da Visita"
            mask="99/99/9999"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="lineage"
            type="select"
            placeholder="Linhagem"
            label="Linhagem"
            options={["Cobb"].map((item) => ({
              value: item,
            }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            type="input"
            label="Idade(Semanas)"
            params="age"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="Tratamento"
            type="input"
            label="Integrado"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="treatment"
            type="input"
            placeholder="Medicamento"
            label="Tratamento"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="start"
            type="inputMask"
            label="Início de tratamento"
            mask="99/99/9999"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="end"
            type="inputMask"
            label="Fim de tratamento"
            mask="99/99/9999"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="warp"
            type="select"
            label="Empenamento"
            placeholder="Selecione o tipo de empenamento"
            options={[
              { value: "fastWarping", label: "Rápido" },
              { value: "slowWarping", label: "Lento" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="accommodation"
            type="input"
            label="Numero de aves alojadas"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="birds"
            type="input"
            label="Numero de aves Atual"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="interval"
            type="input"
            label="Intervalo"
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="numberOfBoxes"
            onChange={changeBoxes}
            type="select"
            placeholder="Selecione o qtd de boxes"
            label="Numero de boxes"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({
              value: item,
            }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="numberOfBirds"
            onChange={changeBirds}
            type="select"
            placeholder="Selecione o qtd de boxes"
            label="Análises / Fezes / Boxe"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({
              value: item,
            }))}
          />
        </Col>
      </Row>
    </>
  );
}
