import React from "react";
import { Table } from "../../style";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";

export function ZincOxide(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "185px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual PPM?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Pré</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.zincOxide.pre.ppm"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Inicial</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.zincOxide.initial.ppm"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Crescimento 1</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.zincOxide.growth.ppm"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Crescimento 2</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.zincOxide.growth2.ppm"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Final</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.zincOxide.final.ppm"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
