import { Row, Col } from "styles";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";
export function Others(props) {
  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              marginLeft: "40px",
            }}
          >
            <Input
              type="checkbox"
              params="additions.others.emulsifiers"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              label="Utiliza Emulsificantes"
              className="kt-checkbox mt-2"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              marginLeft: "40px",
            }}
          >
            <label>
              Utiliza Pigmentante
              {props.heavyBroiler.additions.others.pigmentation && ", Qual"}?
            </label>
            <Row>
              <Col>
                <Input
                  type="checkbox"
                  params="additions.others.pigmentation"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                  label="&nbsp;"
                  className="kt-checkbox pr-15"
                />
              </Col>
            </Row>
            {props.heavyBroiler.additions.others.pigmentation && (
              <Input
                type="selectOnly"
                params="additions.others.pigmentationType"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder={
                  props.heavyBroiler.additions.others.pigmentation
                    ? "Selecione o pigmentante"
                    : ""
                }
                disabled={!props.heavyBroiler.additions.others.pigmentation}
                options={
                  props.heavyBroiler.additions.others.pigmentation
                    ? props.config
                        .filter(
                          (item) => item.key === "premixCommonDescription"
                        )
                        .map((item) => ({ value: item.description }))
                    : []
                }
              />
            )}
          </div>
        </Col>
      </Row>
      {props.heavyBroiler.additions.others.pigmentation && (
        <Table>
          <Header>
            <tr>
              <th style={{ width: "50px" }}>
                <span></span>
              </th>
              <th>
                <span>Qual PPM Amarelo?</span>
              </th>
              <th>
                <span>Qual PPM Vermelho?</span>
              </th>
            </tr>
          </Header>
          <Body>
            <tr>
              <td>
                <span>Cria</span>
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="additions.others.create.yellowppm"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="additions.others.create.redppm"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>Recria</span>
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="additions.others.recreate.yellowppm"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="additions.others.recreate.redppm"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>Produção</span>
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="additions.others.production.yellowppm"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="additions.others.production.redppm"
                  item={props.heavyBroiler}
                  setItem={props.setHeavyBroiler}
                />
              </td>
            </tr>
          </Body>
        </Table>
      )}
    </>
  );
}
