import { useState, useEffect } from "react";

import ReactLoading from "react-loading";
import Swal from "sweetalert2";

import { Input } from "components/Input";
import { api } from "services/api";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";
import { Col, Row } from "styles";
import { HistoricOption, HistoricOptionContent } from "../style";

const Enviar = (props) => {
  const [input, setInput] = useState({
    technicalManager: null,
    technicalManagerList: [],
  });

  const [loading, setLoading] = useState(false);

  const sendReport = async () => {
    try {
      const response = await Swal.fire({
        title: "Salvar Relatório",
        text: "Deseja enviar o relatório para os usuários selecionados?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Não, cancelar!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`/connect/reportbroiler/send`, {
              reportId: props.id,
              users: input.technicalManagerList.map(({ _id }) => _id),
            })
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value.err) {
        Swal.fire("Enviar Relatório", "Erro ao enviar relatório!", "error");
      } else {
        Swal.fire(
          "Enviar Relatório",
          "Relatório enviado com sucesso!",
          "success"
        );
      }
    } catch (e) {
      Swal.fire("Enviar Relatório", "Erro ao enviar relatório!", "error");
    }
  };
  useEffect(() => {
    if (input.technicalManager) {
      setInput((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState.technicalManagerList.push({
          _id: prevState.technicalManager._id,
          name: prevState.technicalManager.name,
        });
        newState.technicalManager = null;
        return newState;
      });
    }
  }, [input.technicalManager]);

  return (
    <Modal
      show={props.show}
      close={props.onHide}
      header={
        <>
          <Icons type="send" color="#009870" size={30} />
          <h2>Enviar Relatório</h2>
        </>
      }
      footer={
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={sendReport}
          disabled={!input.technicalManagerList[0] || loading}
        >
          {loading ? (
            <div style={{ display: "flex" }}>
              <ReactLoading
                type="spin"
                color="#ffffff"
                height={19}
                width={19}
              />
              &nbsp;
              <span>Aguarde, isso pode levar alguns minutos </span>
            </div>
          ) : (
            <>
              <i className="fas fa-mail-bulk" />
              &nbsp; Enviar Relatório
            </>
          )}
        </Button>
      }
    >
      <Row>
        <Col>
          <Input
            item={input}
            setItem={setInput}
            params="technicalManager"
            type="autocomplete"
            label="Responsável Técnico"
            paramsGet={["code", "name", "username"]}
            paramsLabel={["code", "name"]}
            response={async (data) => {
              if (data[0]) {
                try {
                  const req = {
                    model: "customer",
                    sort: "responsibles.user.name",
                    select: "responsibles.user",
                    populate: "responsibles.user",
                    query: {
                      _id: props.customer,
                      responsibles: {
                        $elemMatch: {
                          user: { $in: data.map(({ _id }) => _id) },
                        },
                      },
                    },
                  };
                  const response = await api.post("filter/autocomplete", req);
                  const users = [];
                  response.data.forEach(({ responsibles }) =>
                    responsibles.forEach(({ user }) => {
                      if (users.indexOf(user) === -1) {
                        users.push(user);
                      }
                    })
                  );

                  return data.filter(({ _id }) => users.indexOf(_id) > -1);
                } catch (e) {
                  return [];
                }
              }
              return data;
            }}
            select="code name username"
            model="user"
            placeholder="Digite o nome ou o código do Técnico"
          />
          <HistoricOptionContent>
            {input.technicalManagerList.map((item, index) => (
              <HistoricOption key={item}>
                {item.name}
                <Icons
                  type="close"
                  size={10}
                  color="#fff"
                  onClick={() =>
                    setInput((prevState) => {
                      const nextState = JSON.parse(JSON.stringify(prevState));
                      nextState.technicalManagerList.splice(index, 1);
                      return nextState;
                    })
                  }
                ></Icons>
              </HistoricOption>
            ))}
          </HistoricOptionContent>
        </Col>
      </Row>
    </Modal>
  );
};

export default Enviar;
