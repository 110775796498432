import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function CopperSulfate(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "185px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual PPM?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Pré Postura</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.preLay.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Pré Pico</td>
          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.prePeak.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Pico de Postura</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layPeak.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 01</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layFinal01.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 02</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layFinal02.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 03</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layFinal03.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 04</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layFinal04.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 05</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layFinal05.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 06</td>

          <td>
            <Input
              type="inputOnly"
              params="minerals.copperSulfate.layFinal06.ppm"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
