import { Table } from "./styles";
import { Body, Header } from "components/Table/style";
import { Input } from "components/Input";

export function ConsumeTab({ consume, setConsume }) {
  const getAverageValue = (rowValuesObject) => {
    const valuesList = Object.values(rowValuesObject).slice(0, -1);
    if (valuesList.length === 0) return 0;
    const sum = valuesList.reduce((acc, curr) => acc + Number(curr), 0);
    return sum / valuesList.length;
  };
  const handleChange = (e, path) => {
    setConsume((prev) => {
      const newState = { ...prev };
      newState[path.row][path.column] = e.target.value;
      if (path.column !== "weightedAverage") {
        getAverageValue(newState[path.row]);
        newState[path.row]["weightedAverage"] = getAverageValue(
          newState[path.row]
        );
      }
      return newState;
    });
  };
  return (
    <Table>
      <Header>
        <tr>
          <th>
            <span></span>
          </th>
          <th>
            <span>Pré-inicial</span>
          </th>
          <th>
            <span>Inicial</span>
          </th>
          <th>
            <span>Crescimento</span>
          </th>
          <th>
            <span>Engorda</span>
          </th>
          <th>
            <span>Abate</span>
          </th>
          <th>
            <span>Média Ponderada</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>
            <span>
              <b>{`Consumo (%)`}</b>
            </span>
          </td>

          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Pré-inicial"
              value={consume.percentageConsume.preInitial}
              onChange={(e) =>
                handleChange(e, {
                  row: "percentageConsume",
                  column: "preInitial",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Inicial"
              value={consume.percentageConsume.initial}
              onChange={(e) =>
                handleChange(e, {
                  row: "percentageConsume",
                  column: "initial",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Crescimento"
              value={consume.percentageConsume.growth}
              onChange={(e) =>
                handleChange(e, {
                  row: "percentageConsume",
                  column: "growth",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Engorda"
              value={consume.percentageConsume.fatening}
              onChange={(e) =>
                handleChange(e, {
                  row: "percentageConsume",
                  column: "fatening",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Abate"
              value={consume.percentageConsume.slaughter}
              onChange={(e) =>
                handleChange(e, {
                  row: "percentageConsume",
                  column: "slaughter",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Média Ponderada"
              value={consume.percentageConsume.weightedAverage}
              onChange={(e) =>
                handleChange(e, {
                  row: "percentageConsume",
                  column: "weightedAverage",
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>
              <b>Gramas de ração / Ave</b>
            </span>
          </td>

          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Pré-inicial"
              value={consume.feedPerBird.preInitial}
              onChange={(e) =>
                handleChange(e, {
                  row: "feedPerBird",
                  column: "preInitial",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Inicial"
              value={consume.feedPerBird.initial}
              onChange={(e) =>
                handleChange(e, {
                  row: "feedPerBird",
                  column: "initial",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Crescimento"
              value={consume.feedPerBird.growth}
              onChange={(e) =>
                handleChange(e, {
                  row: "feedPerBird",
                  column: "growth",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Engorda"
              value={consume.feedPerBird.fatening}
              onChange={(e) =>
                handleChange(e, {
                  row: "feedPerBird",
                  column: "fatening",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Abate"
              value={consume.feedPerBird.slaughter}
              onChange={(e) =>
                handleChange(e, {
                  row: "feedPerBird",
                  column: "slaughter",
                })
              }
            />
          </td>
          <td>
            <Input
              type="input"
              inputType="number"
              placeholder="Média Ponderada"
              value={consume.feedPerBird.weightedAverage}
              onChange={(e) =>
                handleChange(e, {
                  row: "feedPerBird",
                  column: "weightedAverage",
                })
              }
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
