import {
  convertDateToString,
  getScoreText,
  getScoreColor,
} from "utils/globalFunctions";
import { months } from "utils/globalData";
import {
  Block,
  BlockBody,
  BlockHeader,
  Card,
  CardBody,
  CardHeader,
} from "styles";
import { Body, Header, TableContent } from "components/Table/style";
function HistoricTable({ perPeriod, type, broiler, broilerList }) {
  const getCards = () => {
    const colors = ["primary", "info", "danger", "dark", "warning"];

    let i = -1;
    return broilerList.map((item, index) => {
      if (i > 3) {
        i = 0;
      } else {
        i++;
      }
      return (
        <Card
          id={`historicPerPeriod-${index}`}
          key={`historicPerPeriod-${index}`}
        >
          <CardHeader>
            <strong>
              {broiler.filter(({ value }) => value === item)[0].label}
            </strong>
          </CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th className=" text-right">Fase</th>
                  <th className=" text-right">Pré</th>
                  <th className=" text-right">Inicial</th>
                  <th className=" text-right">Crescimento 1</th>
                  <th className=" text-right">Crescimento 2</th>
                  <th className=" text-right">Final</th>
                  <th className=" text-right">Score</th>
                </tr>
              </Header>
              <Body>
                {perPeriod.map((historic, index) =>
                  historic ? (
                    <tr key={index}>
                      <td className={` text-${colors[i]}`}>
                        {(type === "Semanal"
                          ? `Semana ${historic.week}`
                          : months[historic.month]) +
                          "/" +
                          historic.year +
                          ` - De ${convertDateToString(
                            type === "Semanal"
                              ? historic.startOfWeek
                              : historic.startOfMonth
                          )} até ${convertDateToString(
                            type === "Semanal"
                              ? historic.endOfWeek
                              : historic.endOfMonth
                          )}`}
                      </td>
                      <td className={` text-${colors[i]}`}>
                        {((item) => {
                          const historic = perPeriod
                            .sort(({ historic }) => !!historic)
                            .map(({ historic }) => historic)[index];

                          return `${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["moleculePre"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.pre?.product || ""
                          }
                          /
                          ${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["dosePre"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.pre?.[
                                  item === "minerals.organics"
                                    ? "reason"
                                    : "dose" || ""
                                ]
                          }`;
                        })(item)}
                      </td>
                      <td className={` text-${colors[i]}`}>
                        {((item) => {
                          const historic = perPeriod
                            .sort(({ historic }) => !!historic)
                            .map(({ historic }) => historic)[index];

                          return `${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["moleculeInitial"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.initial?.product || ""
                          }
                          /
                          ${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["doseInitial"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.initial?.[
                                  item === "minerals.organics"
                                    ? "reason"
                                    : "dose"
                                ] || ""
                          }`;
                        })(item)}
                      </td>
                      <td className={` text-${colors[i]}`}>
                        {((item) => {
                          const historic = perPeriod
                            .sort(({ historic }) => !!historic)
                            .map(({ historic }) => historic)[index];

                          return `${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["moleculeGrowth"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.growth?.product || ""
                          }
                          /
                          ${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["doseGrowth"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.growth?.[
                                  item === "minerals.organics"
                                    ? "reason"
                                    : "dose"
                                ] || ""
                          }`;
                        })(item)}
                      </td>
                      <td className={` text-${colors[i]}`}>
                        {((item) => {
                          const historic = perPeriod
                            .sort(({ historic }) => !!historic)
                            .map(({ historic }) => historic)[index];

                          return `${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["moleculeGrowth2"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.growth2?.product || ""
                          }
                          /
                          ${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["doseGrowth2"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.growth2?.[
                                  item === "minerals.organics"
                                    ? "reason"
                                    : "dose"
                                ] || ""
                          }`;
                        })(item)}
                      </td>
                      <td className={` text-${colors[i]}`}>
                        {((item) => {
                          const historic = perPeriod
                            .sort(({ historic }) => !!historic)
                            .map(({ historic }) => historic)[index];

                          return `${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["moleculeFinal"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.final?.product || ""
                          }
                          /
                          ${
                            item.split(".")[1] === "anticoccidian"
                              ? historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.["doseFinal"] || ""
                              : historic?.[item.split(".")[0]]?.[
                                  item.split(".")[1]
                                ]?.final?.[
                                  item === "minerals.organics"
                                    ? "reason"
                                    : "dose" || ""
                                ]
                          }`;
                        })(item)}
                      </td>
                      <td>
                        {(() => {
                          const totalScore = perPeriod
                            .sort(({ historic }) => !!historic)
                            .map(({ totalScore }) => totalScore)[index];

                          return [
                            <strong
                              style={{ color: getScoreColor(+totalScore) }}
                            >
                              {totalScore.replace(".", ",")}
                            </strong>,
                            <small
                              className="ml-1"
                              style={{ color: getScoreColor(+totalScore) }}
                            >
                              ({getScoreText(+totalScore)})
                            </small>,
                          ];
                        })()}
                      </td>
                    </tr>
                  ) : null
                )}
              </Body>
            </TableContent>
          </CardBody>
        </Card>
      );
    });
  };
  return (
    <Block>
      <BlockHeader> Programa por Período</BlockHeader>
      <BlockBody>
        <div style={{ marginBottom: "15px" }}>{getCards()}</div>
      </BlockBody>
    </Block>
  );
}

export default HistoricTable;
