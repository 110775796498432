import { useState, useCallback, useEffect, useContext } from "react";

import Chart from "react-apexcharts";
import isMobile from "is-mobile";
import {
  getColor,
  getScoreSlaughterColor,
  getScoreSlaughterText,
} from "utils/globalFunctions";
import { api } from "services/api";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import { CreateEdit } from "../CreateEdit";
import { Button } from "components/Button";
import { saveAs } from "file-saver";
import Profile from "contexts/profile";
import ReactLoading from "react-loading";

export function List(props) {
  const tableParams = [
    {
      key: "customer",
      params: "name",
      label: "Cliente",
      placeholder: "Nome ou Código",
      filter: !props.match.params.id && "text",
      mobile: {
        type: "title",
      },
    },
    {
      key: "responsible",
      label: "Responsável",
      filter: "text",
      placeholder: "Digite o nome",
      mobile: {
        type: "item",
      },
    },
    {
      key: "batchCode",
      label: "Código do Lote",
      filter: "text",
      placeholder: "Digite o nome",
      mobile: {
        type: "item",
      },
    },
    {
      key: "evaluationDate",
      label: "Data da Avaliação",
      type: "date",
      filter: "date",
      mobile: {
        type: "small",
      },
    },

    {
      key: "score",
      label: "Score",
      type: "custom",
      mobile: {
        type: "status",
      },
      response: (item) => {
        return (
          <div style={{ width: "120px" }}>
            <Chart
              options={{
                plotOptions: {
                  radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                      margin: 0,
                      size: "70%",
                      background: "#f9f9fc ",
                      image: undefined,
                      imageOffsetX: 0,
                      imageOffsetY: 0,
                      position: "front",
                    },
                    track: {
                      background: getColor(
                        getScoreSlaughterColor(
                          Math.round(item.total * 100) / 100 < 0
                            ? 0
                            : Math.round(item.total * 100) / 100
                        ),
                        0.2
                      ),
                      strokeWidth: "67%",
                      margin: 0, // margin is in pixels
                    },

                    dataLabels: {
                      showOn: "always",
                      name: {
                        offsetY: -5,
                        show: true,
                        color: getScoreSlaughterColor(
                          Math.round(item.total * 100) / 100
                        ),
                        fontSize: "7px",
                      },
                      value: {
                        formatter: function (val) {
                          return (Math.round(val * 100) / 100).toLocaleString();
                        },
                        color: getScoreSlaughterColor(
                          Math.round(item.total * 100) / 100
                        ),
                        fontSize: "10px",
                        show: true,
                        offsetY: -5,
                      },
                    },
                  },
                },
                fill: {
                  colors: [
                    getScoreSlaughterColor(Math.round(item.total * 100) / 100),
                  ],
                  type: "gradient",
                  gradient: {
                    shade: "dark",
                    type: "horizontal",

                    gradientToColors: [
                      getScoreSlaughterColor(
                        Math.round(item.total * 100) / 100
                      ),
                    ],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100],
                  },
                },
                stroke: {
                  lineCap: "round",
                },
                labels: [
                  getScoreSlaughterText(Math.round(item.total * 100) / 100),
                ],
              }}
              series={[(Math.round(item.total * 100) / 100) * 10]}
              type="radialBar"
              width={isMobile() ? "40%" : "100%"}
            />
          </div>
        );
      },
    },
  ];

  const profile = useContext(Profile);

  const [modalShow, setModalShow] = useState(false);

  const [exportLoading, setExportLoading] = useState(false);

  const [id, setId] = useState("");

  const [customerName, setCustomerName] = useState("");
  const exportSlaughter = async () => {
    try {
      setExportLoading(true);
      const response = await api.get(`/broilerslaughter/list`, {
        responseType: "arraybuffer",
        params: { ...props.match.params, export: true },
      });
      const blob = new Blob([response.data], {
        type: "application/excel",
      });
      saveAs(blob, `relatório de abates.xlsx`);
      setExportLoading(false);
    } catch (e) {
      setExportLoading(false);
    }
  };

  const editHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(true);
  }, []);

  useEffect(() => {
    const loadReport = async () => {
      const responseCustomer = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(JSON.parse(JSON.stringify(responseCustomer.data.name)));
    };
    if (props.match.params.id) {
      loadReport();
    }
  }, [props.match.params]);

  return (
    <>
      {modalShow && (
        <CreateEdit
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
        />
      )}

      <SubHeader
        {...props}
        title={customerName || "Abates"}
        route="broilerslaughter"
        breadcrumbs={[
          {
            label: customerName,
            to: "/connect/customer/" + props.match.params.id,
          },
          {
            label: "Frango de Corte",
          },
          {
            label: "Abates",
          },
        ]}
        icon={<Icons type="slaughter" size="24" color="#fff" />}
        otherButtons={
          profile.roles && profile.roles.indexOf("admin") > -1
            ? [
                <Button
                  type="button"
                  bg="primary"
                  color="white"
                  border="primary"
                  onClick={exportSlaughter}
                  key="export"
                >
                  {exportLoading ? (
                    [
                      "Aguarde",
                      <>&nbsp;</>,
                      <ReactLoading
                        type="spin"
                        color="#fff"
                        height={15}
                        width={15}
                      />,
                    ]
                  ) : (
                    <>
                      <Icons type="download" color="white" size="20" /> Exportar
                      Necropsias
                    </>
                  )}
                </Button>,
              ]
            : []
        }
      />
      <Table
        cols={tableParams}
        editHandler={editHandler}
        emptyText={"Nenhum abate localizado!"}
        route={`/broilerslaughter/list`}
        {...props}
      />
    </>
  );
}
