import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import { ViewLoader } from "components/ViewLoader";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import {
  Block,
  BlockBody,
  BlockHeader,
  Card,
  CardBody,
  CardHeader,
  Col,
  Group,
  Row,
  Separator,
} from "styles";
import { Body, Header, TableContent } from "components/Table/style";
import { Button } from "components/Button/style";
import { DateButton } from "components/Filter/style";
import { Input } from "components/Input";
import { api } from "services/api";
import { Image } from "components/Image";
import { Tab, Tabs } from "@material-ui/core";

export function Slaughter(props) {
  const initialInputId = (label) => ({
    new: {
      score: "",
      description: "",
      img: {
        files: [],
        fileName: "",
      },
      loading: false,
      points: "",
    },
    edit: {
      score: "",
      description: "",
      img: {
        files: [],
        fileName: "",
      },
      _id: "",
      loading: false,
      points: "",
    },
    label,
  });

  const [inputs, setInputs] = useState({
    "ventralInspection-skinBreakage": initialInputId("Rompimento de pele"),
    "ventralInspection-chestSkinBurn": initialInputId("Rompimento de pele"),
    "ventralInspection-celluliteBelowChest": initialInputId("Celulite (peito)"),
    "ventralInspection-chestSkinBleeding": initialInputId(
      "Hemorragia da pele do peito"
    ),
    "ventralInspection-bleedingWings": initialInputId("Hemorragia nas asas"),
    "ventralInspection-displacementWings": initialInputId(
      "Deslocamento de asas"
    ),
    "ventralInspection-fractureWings": initialInputId("Fratura de asas"),
    "dorsalInspection-tailSkinBreakage": initialInputId(
      "Rompimento da pele cauda"
    ),
    "dorsalInspection-dermatitis": initialInputId("Dermatite"),
    "dorsalInspection-scratchedWoundNew": initialInputId(
      "Arranhados/ Ferida NOVO"
    ),
    "dorsalInspection-scratchedWoundOld": initialInputId(
      "Arranhados/ Ferida VELHO"
    ),
    "dorsalInspection-bleedingThighs": initialInputId(
      "Hemorragia nas coxas/sobre coxa"
    ),
    "dorsalInspection-thighFracture": initialInputId("Fratura das coxas"),
    "dorsalInspection-dorsalMyopathy": initialInputId("Miopatia dorsal"),
    "internalInspection-punctateChestHemorrhage": initialInputId(
      "Hemorragia puntiforme peito"
    ),
    "internalInspection-thighsPunctiformHemorrhage": initialInputId(
      "Hemo- Puntiforme coxas/ sobre coxa"
    ),
    "internalInspection-whiteStreak": initialInputId("Estria branca"),
    "internalInspection-woodChest": initialInputId("Peito madeira"),
    "internalInspection-chestSpaghetti": initialInputId("Peito Spaghetti"),
    legCorns: initialInputId("Calos de patas"),
  });

  const [percent, setPercent] = useState({
    ventralInspection: "",
    dorsalInspection: "",
    internalInspection: "",
    legCorns: "",
  });

  const [tab, setTab] = useState(0);

  const [configs, setConfigs] = useState([]);

  const [getConfigs, setGetConfigs] = useState(true);

  const [loadRemove, setLoadRemove] = useState("");

  const [loading, setLoading] = useState(true);

  const setEdit = (key, { score, description, _id, points }) => {
    const copyInputs = JSON.parse(JSON.stringify(inputs));
    copyInputs[key].edit._id = _id;
    copyInputs[key].edit.score = score;
    copyInputs[key].edit.description = description;
    copyInputs[key].edit.points = points;
    setInputs(copyInputs);
  };

  const cancelEdit = (key) => {
    const copyInputs = JSON.parse(JSON.stringify(inputs));
    copyInputs[key].edit = {
      score: "",
      description: "",
      img: {
        files: [],
        fileName: "",
      },
      _id: "",
      points: "",
      loading: false,
    };
    setInputs(copyInputs);
  };

  const removeItem = async (config) => {
    const result = await Swal.fire({
      text: "Deseja remover o item",
      title: "remover Item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Remover item!",
    });
    if (result.value) {
      setLoadRemove(config._id);
      const response = await api
        .delete("broilerslaughterconfig", {
          data: { id: config._id },
        })
        .catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        }));
      setLoadRemove("");
      setGetConfigs(true);
      if (response.err) {
        return Swal.fire("remover Item", "Erro ao remover item", "error");
      }
      await api.delete("fileUpload", {
        data: {
          id: `slaughter-${config.key.replace(/\./g, "-")}-${config.score}.${
            config.extention
          }`,
        },
      });
    }
  };

  const saveItem = async (key, input, edit = false) => {
    let copyInputs = JSON.parse(JSON.stringify(inputs));
    const param = edit ? "edit" : "new";
    const request = edit ? "put" : "post";
    copyInputs[key][param].loading = true;
    setInputs(copyInputs);
    const { score, description, _id, points } = input[param];
    const { fileName, files } = input[param].img;
    const extention = files[0]
      ? fileName.split(".")[fileName.split(".").length - 1]
      : "";
    const data = {
      key,
      score,
      description,
      points,
    };
    if (extention) {
      data.extention = extention;
    }
    const response = await api[request](
      `broilerslaughterconfig${edit ? "/" + _id : ""}`,
      data
    ).catch((err) => ({
      err: true,
      data: { message: err.response.data.message },
    }));

    if (response.err) {
      copyInputs = JSON.parse(JSON.stringify(inputs));
      copyInputs[key][param].loading = false;
      setInputs(copyInputs);
      return Swal.fire(
        `${edit ? "Edit" : "Add"} Item`,
        response.data.message,
        "error"
      );
    }

    if (files[0]) {
      const formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append(
        "file",
        files[0],
        `slaughter-${key.replace(/\./g, "-")}-${response.data.id}.${extention}`
      );
      const responseImage = await api
        .post("fileUpload", formData, config)
        .catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        }));

      if (responseImage.err) {
        Swal.fire(
          "Enviar Imagem",
          "Não foi possível salvar a imagem",
          "warning"
        );
      }
    }
    copyInputs = JSON.parse(JSON.stringify(inputs));
    copyInputs[key] = initialInputId(inputs[key].label);
    setInputs(copyInputs);
    setGetConfigs(true);
  };

  const slaughterConfigsLoader = (key) => {
    const input = inputs[key];
    return (
      <Col>
        <Card>
          <CardHeader>{input.label}</CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th style={{ width: "100px" }}>Score</th>
                  <th>Descrição</th>
                  <th style={{ width: "92px" }}>Pontos</th>
                  <th style={{ width: "150px" }}>Imagem</th>
                  <th></th>
                </tr>
              </Header>
              <Body>
                {configs
                  .filter((config) => config.key === key)
                  .map((config) =>
                    input.edit._id === config._id ? (
                      <tr key={config._id}>
                        <td>
                          <Input
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.score`}
                            type="selectOnly"
                            placeholder="Selecione o Score"
                            options={[
                              { label: "Leve", value: "light" },
                              { label: "Severo", value: "severe" },
                            ]}
                          />
                        </td>
                        <td>
                          <Input
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.description`}
                            type="inputOnly"
                            placeholder="Descrição"
                          />
                        </td>
                        <td>
                          <Input
                            inputType="number"
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.points`}
                            type="inputOnly"
                            placeholder="Pontos"
                          />
                        </td>
                        <td>
                          <Input
                            type="inputFile"
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.img`}
                            accept="image/*"
                            placeholder="imagem"
                          />
                        </td>
                        <td>
                          {input.edit.loading ? (
                            <ReactLoading
                              type="spin"
                              color="#009870"
                              height={30}
                              width={30}
                            />
                          ) : (
                            <Group>
                              <Button
                                type="button"
                                bg="basf"
                                border="basf"
                                color="white"
                                onClick={() => saveItem(key, input, true)}
                              >
                                <Icons type="edit" color="#FFF" size={15} />
                              </Button>
                              <Button
                                type="button"
                                bg="light"
                                border="secondary"
                                color="secondary"
                                onClick={() => cancelEdit(key)}
                              >
                                <Icons type="close" color="#7E8299" size={15} />
                              </Button>
                            </Group>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr key={config._id}>
                        <td>
                          <span>
                            <b>
                              {config.score === "light"
                                ? "Leve"
                                : config.score === "severe"
                                ? "Severo"
                                : ""}
                            </b>
                          </span>
                        </td>
                        <td>{config.description}</td>
                        <td>
                          {config.points ? config.points.toLocaleString() : 0}
                        </td>
                        <td>
                          <Image
                            className="img-fluid"
                            src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/slaughter-${config.key.replace(
                              /\./g,
                              "-"
                            )}-${config._id}.${config.extention}`}
                          />
                        </td>
                        <td>
                          {loadRemove === config._id ? (
                            <ReactLoading
                              type="spin"
                              color="#009870"
                              height={30}
                              width={30}
                            />
                          ) : (
                            <Group>
                              <Button
                                type="button"
                                bg="basf"
                                border="basf"
                                color="white"
                                onClick={() => setEdit(key, config)}
                              >
                                <Icons type="edit" color="#FFF" size={15} />
                              </Button>
                              <Button
                                type="button"
                                bg="danger"
                                border="danger"
                                color="white"
                                onClick={() => removeItem(config)}
                              >
                                <Icons type="trash" color="#FFF" size={15} />
                              </Button>
                            </Group>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                <tr>
                  <td>
                    <Input
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.score`}
                      type="selectOnly"
                      placeholder="Selecione o Score"
                      options={[
                        { label: "Leve", value: "light" },
                        { label: "Severo", value: "severe" },
                      ]}
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    <Input
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.description`}
                      type="inputOnly"
                      placeholder="Descrição"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    <Input
                      inpuType="number"
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.points`}
                      type="inputOnly"
                      placeholder="Pontos"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    <Input
                      type="inputFile"
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.img`}
                      accept="image/*"
                      placeholder="Imagem"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    {input.new.loading ? (
                      <ReactLoading
                        type="spin"
                        color="#0a91cf"
                        height={30}
                        width={30}
                      />
                    ) : (
                      <DateButton
                        type="button"
                        bg="default"
                        border="default"
                        color="white"
                        onClick={() => saveItem(key, input)}
                      >
                        <Icons type="save" color="#FFF" size={15} />
                      </DateButton>
                    )}
                  </td>
                </tr>
              </Body>
            </TableContent>
          </CardBody>
        </Card>
      </Col>
    );
  };

  const savePercent = async () => {
    const response = await Swal.fire({
      text: "Deseja salvar alterações",
      title: "Salvar Alterações!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, alterar porcentagens!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post("broilerslaughterpercentconfig", {
            ventralInspection: percent.ventralInspection,
            dorsalInspection: percent.dorsalInspection,
            internalInspection: percent.internalInspection,
            legCorns: percent.legCorns,
          })
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Salvar Abate",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
    }
  };

  useEffect(() => {
    const loadConfigs = async () => {
      const response = await api.get("broilerslaughterconfig");
      setConfigs(response.data);
      const responsePercent = await api.get("broilerslaughterpercentconfig");
      if (responsePercent.data) {
        setPercent(responsePercent.data);
      }
      setLoading(false);
    };
    if (getConfigs) {
      setGetConfigs(false);
      loadConfigs();
    }
  }, [getConfigs]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Abate"
        breadcrumbs={[
          { label: "Configuração" },
          {
            label: "Frango de Corte",
          },
          {
            label: "Abate",
          },
        ]}
        icon={<Icons type="slaughter" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Inspeção ventral" />
            <Tab label="Inspeção dorsal" />
            <Tab label="Inspeção interna" />
            <Tab label="Calos de patas" />
            <Tab label="Porcentagens" />
          </Tabs>
        </BlockHeader>
        <BlockBody>
          {tab === 0 && (
            <>
              <Row>
                {slaughterConfigsLoader("ventralInspection-skinBreakage")}
                {slaughterConfigsLoader("ventralInspection-chestSkinBurn")}
              </Row>
              <Row>
                {slaughterConfigsLoader(
                  "ventralInspection-celluliteBelowChest"
                )}
                {slaughterConfigsLoader("ventralInspection-chestSkinBleeding")}
              </Row>
              <Row>
                {slaughterConfigsLoader("ventralInspection-bleedingWings")}
                {slaughterConfigsLoader("ventralInspection-displacementWings")}
              </Row>
              <Row>
                {slaughterConfigsLoader("ventralInspection-fractureWings")}
                <Col />
              </Row>
            </>
          )}
          {tab === 1 && (
            <>
              <Row>
                {slaughterConfigsLoader("dorsalInspection-tailSkinBreakage")}
                {slaughterConfigsLoader("dorsalInspection-dermatitis")}
              </Row>
              <Row>
                {slaughterConfigsLoader("dorsalInspection-scratchedWoundNew")}
                {slaughterConfigsLoader("dorsalInspection-scratchedWoundOld")}
              </Row>
              <Row>
                {slaughterConfigsLoader("dorsalInspection-bleedingThighs")}
                {slaughterConfigsLoader("dorsalInspection-thighFracture")}
              </Row>
              <Row>
                {slaughterConfigsLoader("dorsalInspection-dorsalMyopathy")}
                <Col />
              </Row>
            </>
          )}
          {tab === 2 && (
            <>
              <Row>
                {slaughterConfigsLoader(
                  "internalInspection-punctateChestHemorrhage"
                )}
                {slaughterConfigsLoader(
                  "internalInspection-thighsPunctiformHemorrhage"
                )}
              </Row>
              <Row>
                {slaughterConfigsLoader("internalInspection-whiteStreak")}
                {slaughterConfigsLoader("internalInspection-woodChest")}
              </Row>

              <Row>
                {slaughterConfigsLoader("internalInspection-chestSpaghetti")}
                <Col />
              </Row>
            </>
          )}
          {tab === 3 && (
            <>
              <Row>
                {slaughterConfigsLoader("legCorns")}
                <Col />
              </Row>
            </>
          )}
          {tab === 4 && (
            <>
              <Row>
                <Col>
                  <Input
                    type="input"
                    label="Inspeção ventral"
                    item={percent}
                    setItem={setPercent}
                    params="ventralInspection"
                    inputType="number"
                  />
                </Col>

                <Col>
                  <Input
                    type="input"
                    label="Inspeção dorsal"
                    item={percent}
                    setItem={setPercent}
                    params="dorsalInspection"
                    inputType="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="input"
                    label="Inspeção interna"
                    item={percent}
                    setItem={setPercent}
                    params="internalInspection"
                    inputType="number"
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    label="Calos de patas"
                    item={percent}
                    setItem={setPercent}
                    params="legCorns"
                    inputType="number"
                  />
                </Col>
              </Row>
              <Separator></Separator>
              <Row>
                <Col style={{ alignItems: "center" }}>
                  <Button
                    bg="default"
                    border="default"
                    color="white"
                    onClick={savePercent}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </BlockBody>
      </Block>
    </>
  );
}
