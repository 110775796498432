import { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import { ScoreBirds } from "./ScoreBirds";
import { ScoreTotal } from "./ScoreTotal";
import { Data } from "./Data";
import UpdateList from "../../../../../../../../contexts/updateList";
import { api } from "../../../../../../../../services/api";
import { Modal } from "../../../../../../../../components/Modal";
import { Icons } from "../../../../../../../../components/Icons";
import { Button } from "../../../../../../../../components/Button";

export function CreateEdit(props) {
  const initialBird = {
    integument: {
      meggProductionOralInjury: "",
      meggProductionMuscleHemorrhage: "",
      meggProductionAbdominalFat: "",
      meggProductionFemurHeadNecrosis: "",
    },
    respiratorySystem: {
      meggProductionAerosaculite: "",
      meggProductionParanasalSinuses: "",
      meggProductionNasalDischarge: "",
      meggProductionTracheitis: "",
    },
    immuneSystem: {
      meggProductionSuffusions: "",
      meggProductionPeyerPlate: "",
      meggProductionHemorrhagicCecalTonsils: "",
    },
    digestiveSystem: {
      meggProductiongizzardErosion: "",
      meggProductionPancreasAtrophy: "",
      meggProductionDuodenitis: "",
      meggProductionLiver: "",
      meggProductionIntestinalTone: "",
      meggProductionIntestinalWallThickness: "",
      meggProductionFluidContent: "",
      meggProductionMucusPresence: "",
      meggProductionPresenceOfBile: "",
      meggProductionFlatWorms: "",
      meggProductionNecroticEnteritis: "",
      meggProductionFoodPass: "",
      meggProductionCellPeeling: "",
      meggProductionIntestinalgas: "",
      meggProductionHyperemia: "",
      meggProductionCecalGas: "",
    },
    reproductiveSystem: {
      meggProductionFollicleFormation: "",
      meggProductionAtresicFollicle: "",
    },
    total: 10,
  };

  const initialConfig = {
    integument: {
      meggProductionOralInjury: { style: "", options: [], label: "Lesão Oral" },
      meggProductionMuscleHemorrhage: {
        style: "",
        options: [],
        label: "Hemorragia Muscular",
      },
      meggProductionAbdominalFat: {
        style: "",
        options: [],
        label: "Gordura Abdominal",
      },
      meggProductionFemurHeadNecrosis: {
        style: "",
        options: [],
        label: "Nedrose da Cab. Femur",
      },
    },
    respiratorySystem: {
      meggProductionAerosaculite: {
        style: "",
        options: [],
        label: "Aerossaculite",
      },
      meggProductionParanasalSinuses: {
        style: "",
        options: [],
        label: "Seios Paranasais",
      },
      meggProductionNasalDischarge: {
        style: "",
        options: [],
        label: "Secreção Nasal",
      },
      meggProductionTracheitis: { style: "", options: [], label: "Traqueite" },
    },
    immuneSystem: {
      meggProductionSuffusions: { style: "", options: [], label: "Sufusões" },
      meggProductionPeyerPlate: {
        style: "",
        options: [],
        label: "Placa de Peyer",
      },
      meggProductionHemorrhagicCecalTonsils: {
        style: "",
        options: [],
        label: "tonsilas Cecais Hemorrágicas",
      },
    },
    digestiveSystem: {
      meggProductiongizzardErosion: {
        style: "",
        options: [],
        label: "Erosão de Moela",
      },
      meggProductionPancreasAtrophy: {
        style: "",
        options: [],
        label: "Atrofia de Pâncreas",
      },
      meggProductionDuodenitis: { style: "", options: [], label: "Duodenite" },
      meggProductionLiver: { style: "", options: [], label: "Fígado" },
      meggProductionIntestinalTone: {
        style: "",
        options: [],
        label: "Tônus Intestinal",
      },
      meggProductionIntestinalWallThickness: {
        style: "",
        options: [],
        label: "Espessura da parede intestinal",
      },
      meggProductionFluidContent: {
        style: "",
        options: [],
        label: "Conteúdo Fluido",
      },
      meggProductionMucusPresence: {
        style: "",
        options: [],
        label: "Presença de Muco",
      },
      meggProductionPresenceOfBile: {
        style: "",
        options: [],
        label: "Presença de Bile",
      },
      meggProductionFlatWorms: {
        style: "",
        options: [],
        label: "Vermes chatos",
      },
      meggProductionNecroticEnteritis: {
        style: "",
        options: [],
        label: "Enterite Necrótica",
      },
      meggProductionFoodPass: {
        style: "",
        options: [],
        label: "Passagem de Alimento",
      },
      meggProductionCellPeeling: {
        style: "",
        options: [],
        label: "Descamação Celular",
      },
      meggProductionIntestinalgas: {
        style: "",
        options: [],
        label: "Gás intestinal",
      },
      meggProductionHyperemia: {
        style: "",
        options: [],
        label: "Hiperemia (vermelhidão)",
      },
      meggProductionCecalGas: { style: "", options: [], label: "Gás cecal" },
    },
    reproductiveSystem: {
      meggProductionFollicleFormation: {
        style: "",
        options: [],
        label: "Formação De Foliculos",
      },
      meggProductionAtresicFollicle: {
        style: "",
        options: [],
        label: "Foliculo Atrésico",
      },
    },
  };

  const { setUpdateList } = useContext(UpdateList);

  const [necropsy, setNecropsy] = useState({
    _id: "",
    customer: props.customer,
    preNecropsy: props.id,
    birds: [
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
    ],
  });

  const [loadHistoric, setLoadHistoric] = useState(false);

  const [loading, setLoading] = useState(true);

  const [birds, setBirds] = useState(2);

  const [getNecropsy, setGetNecropsy] = useState(true);

  const [config, setConfig] = useState(initialConfig);

  const [tab, setTab] = useState(0);

  const getProps = (prop) => ({ prop, params: Object.keys(config[prop]) });

  const sumBirds = (copyNecropsy) => {
    copyNecropsy.birds.forEach((bird) => {
      let sum = 0;
      Object.keys(bird).forEach((prop) => {
        if (prop !== "total") {
          Object.keys(bird[prop]).forEach((param) => {
            if (prop !== "_id" && bird[prop][param]) {
              const option = config[prop][param].options.find(
                (item) => item.score === bird[prop][param]
              );
              if (option && option.points) {
                sum += +option.points;
              }
            }
          });
        }
      });
      bird.total = 10 + sum;
    });
    setNecropsy(JSON.parse(JSON.stringify(copyNecropsy)));
  };

  const changeBirds = async (e) => {
    const value = +e.target.value;
    const necropsyCopy = JSON.parse(JSON.stringify(necropsy));
    if (necropsy.birds.length < value) {
      for (let i = necropsy.birds.length; i < value; i++) {
        necropsyCopy.birds.push(JSON.parse(JSON.stringify(initialBird)));
      }
    } else {
      const lostBirds = [];
      for (let i = value; i < necropsy.birds.length; i++) {
        lostBirds.push(i + 1);
      }
      const response = await Swal.fire({
        title: "Diminuir número de Aves",
        text: `Os dados da${lostBirds[1] ? "s" : ""} ave${
          lostBirds[1] ? "s" : ""
        } ${lostBirds.reduce(
          (text, value, index) =>
            `${text}${index === lostBirds.length - 1 ? " e" : ","} ${value}`
        )} serão perdidos, deseja continuar`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        showLoaderOnConfirm: true,
        preConfirm: () => true,
      });
      if (response.dismiss) return;
      for (let i = necropsy.birds.length; i > value; i--) {
        necropsyCopy.birds.splice(i - 1, 1);
      }
    }
    setBirds(value);
    setNecropsy(necropsyCopy);
  };
  const saveNecropsy = async (e) => {
    const requestNecropsy = { ...necropsy };

    if (!requestNecropsy._id) {
      const response = await Swal.fire({
        title: "Salvar Necrópsia",
        text: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Salvar!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("meggproduction", requestNecropsy).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Salvar Necrópsia",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          requestNecropsy._id = response.value.data.id;
          setNecropsy(requestNecropsy);
        }
        setUpdateList(true);
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Necrópsia",
        text: "Deseja confirmar edição?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("meggproduction/" + requestNecropsy._id, requestNecropsy)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Necrópsia",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setUpdateList(true);
      }
    }
  };

  useEffect(() => {
    const loadNecropsy = async () => {
      setLoading(true);
      const response = await api.get("necropsyconfig");
      if (response.data) {
        const copyConfig = JSON.parse(JSON.stringify(config));
        response.data.forEach((item) => {
          const keys = item.key.split(".");
          if (copyConfig[keys[0]] && copyConfig[keys[0]][keys[1]]) {
            copyConfig[keys[0]][keys[1]].options.push({ ...item });
          }
        });
        setConfig(copyConfig);
      }
      if (props.id) {
        const responseNecropsy = await api.get(`meggproduction/${props.id}`);
        if (responseNecropsy.data) {
          setNecropsy(JSON.parse(JSON.stringify(responseNecropsy.data)));
          setBirds(responseNecropsy.data.birds.length);
          setLoadHistoric(true);
        }
      }
      setLoading(false);
    };
    if (getNecropsy) {
      setGetNecropsy(false);
      loadNecropsy();
    }
  }, [getNecropsy, necropsy, props.id, config]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="necropsy" color="#009870" size={30} />
          <h2>Necropsia</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveNecropsy}
        >
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Dados" />
            <Tab label="Tegumentar" />
            <Tab label="Sistema Respiratório" />
            <Tab label="Sistema Imunológico" />
            <Tab label="Sistema Digestório" />
            <Tab label="Sistema Reprodutivo" />
            <Tab label="Score Total" />
          </Tabs>
          <div
            className="kt-separator kt-separator--solid"
            style={{ marginTop: 0 }}
          />
          {tab === 0 && (
            <Data
              birds={birds}
              changeBirds={changeBirds}
              necropsy={necropsy}
              setNecropsy={setNecropsy}
              loadHistoric={loadHistoric}
              disableCustomer={props.disableCustomer}
              setLoadHistoric={setLoadHistoric}
            />
          )}

          {tab === 1 && (
            <ScoreBirds
              necropsy={necropsy}
              setNecropsy={setNecropsy}
              config={config.integument}
              {...getProps("integument")}
              sumBirds={sumBirds}
            />
          )}
          {tab === 2 && (
            <ScoreBirds
              necropsy={necropsy}
              setNecropsy={setNecropsy}
              config={config.respiratorySystem}
              {...getProps("respiratorySystem")}
              sumBirds={sumBirds}
            />
          )}

          {tab === 3 && (
            <ScoreBirds
              necropsy={necropsy}
              setNecropsy={setNecropsy}
              config={config.immuneSystem}
              {...getProps("immuneSystem")}
              sumBirds={sumBirds}
            />
          )}
          {tab === 4 && (
            <ScoreBirds
              necropsy={necropsy}
              setNecropsy={setNecropsy}
              config={config.digestiveSystem}
              {...getProps("digestiveSystem")}
              sumBirds={sumBirds}
            />
          )}
          {tab === 5 && (
            <ScoreBirds
              necropsy={necropsy}
              setNecropsy={setNecropsy}
              config={config.reproductiveSystem}
              {...getProps("reproductiveSystem")}
              sumBirds={sumBirds}
            />
          )}
          {tab === 6 && <ScoreTotal necropsy={necropsy} />}
        </>
      )}
    </Modal>
  );
}
