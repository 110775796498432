import { Body, Header } from "components/Table/style";
import { Table } from "../../style";
import { months } from "utils/globalData";
import { Input } from "components/Input";

export function PerformanceEnhancer(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "50px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th colSpan="3">
            <span>Qual período de rotação?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Cria</td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.create.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter(
                  (item) => item.key === "healthPerformanceEnhancerProduct"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.create.from"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o mês"
              options={months.map((month) => ({ value: month }))}
            />
          </td>
          <td style={{ width: "50px" }}>
            <span>Até</span>
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.create.to"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o mês"
              options={months.map((month) => ({ value: month }))}
            />
          </td>
        </tr>
        <tr>
          <td>Recria</td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.recreate.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter(
                  (item) => item.key === "healthPerformanceEnhancerProduct"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.recreate.from"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o mês"
              options={months.map((month) => ({ value: month }))}
            />
          </td>
          <td style={{ width: "50px" }}>
            <span>Até</span>
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.recreate.to"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o mês"
              options={months.map((month) => ({ value: month }))}
            />
          </td>
        </tr>
        <tr>
          <td>Produção</td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.production.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter(
                  (item) => item.key === "healthPerformanceEnhancerProduct"
                )
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.production.from"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o mês"
              options={months.map((month) => ({ value: month }))}
            />
          </td>
          <td style={{ width: "50px" }}>
            <span>Até</span>
          </td>
          <td>
            <Input
              type="selectOnly"
              params="health.performanceEnhancer.production.to"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o mês"
              options={months.map((month) => ({ value: month }))}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
