import styled from "styled-components";
import { TableContent } from "components/Table/style";

export const Table = styled(TableContent)`
  width: 100%;
  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3) {
    width: 50px;
  }
`;

export const ContentQuestion = styled.div`
  display: flex;
  cursor: pointer;
`;

export const HeaderQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
