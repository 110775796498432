import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function OrganicAcids(props) {
  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              textAlign: "end",
              marginRight: "40px",
            }}
          >
            <Input
              type="checkbox"
              params="additions.organicAcids.unknownConcept"
              item={props.megg}
              setItem={props.setMegg}
              label="Cliente não conhece o conceito"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "185px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual Produto?</span>
                </th>
                <th>
                  <span>Qual Finalidade?</span>
                </th>
                <th>
                  <span>Qual Dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Pré</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.pre.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.pre.reason"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.pre.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
              <tr>
                <td>Inicial</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.initial.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.initial.reason"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.initial.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
              <tr>
                <td>Crescimento</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.growth.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.growth.reason"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.growth.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
              <tr>
                <td>Desenvolvimento</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.development.product"
                    item={props.megg}
                    setItem={props.setMegg}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.development.reason"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="additions.organicAcids.development.dose"
                    item={props.megg}
                    setItem={props.setMegg}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>
    </>
  );
}
