import { Input } from "components/Input";
import { Col, Row, Separator } from "styles";
import { Steps } from "./Steps";

export function Premix(props) {
  const inputHandler = (e) => {
    const copyHeavyBroiler = { ...props.heavyBroiler };
    copyHeavyBroiler.premixProductionYear = e.target.value;
    copyHeavyBroiler.premixSteps.create.feedProductionYear = (
      e.target.value * 0.024
    ).toFixed(2);
    copyHeavyBroiler.premixSteps.recreate.feedProductionYear = (
      e.target.value * 0.125
    ).toFixed(2);
    copyHeavyBroiler.premixSteps.production.feedProductionYear = (
      e.target.value * 0.851
    ).toFixed(2);
    props.setHeavyBroiler(copyHeavyBroiler);
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            type="input"
            label="Produção Ração/Ano"
            inputType="number"
            value={props.heavyBroiler.premixProductionYear}
            onChange={inputHandler}
          />
        </Col>
        <Col>
          <Input
            type="select"
            params="premixComposition"
            item={props.heavyBroiler}
            setItem={props.setHeavyBroiler}
            label="Composição do Premix"
            placeholder="Selecione o Composição"
            options={props.config
              .filter((item) => item.key === "premixComposition")
              .map((item) => ({ value: item.description }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            params="premixPlaxma"
            item={props.heavyBroiler}
            setItem={props.setHeavyBroiler}
            label="Utiliza Plamas"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            params="premixHemoglobin"
            item={props.heavyBroiler}
            setItem={props.setHeavyBroiler}
            label="Utiliza Hemoglobina"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            params="premixAnimalFeed"
            item={props.heavyBroiler}
            setItem={props.setHeavyBroiler}
            label="Usa Ração Animal"
          />
        </Col>
        {props.heavyBroiler.premixAnimalFeed && (
          <Col>
            <Input
              type="select"
              params="premixAnimalFeedType"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione um opção"
              options={props.config
                .filter((item) => item.key === "premixAnimalFeedType")
                .map((item) => ({ value: item.description }))}
            />
          </Col>
        )}
      </Row>
      <Separator />
      <Steps {...props} />
    </>
  );
}
