import React from "react";
import { Input } from "components/Input";
import { Col, Row } from "styles";
import Steps from "./Steps";

export function Premix(props) {
  const inputHandler = (e) => {
    const copyMeggProduction = { ...props.meggProduction };
    copyMeggProduction.premixProductionYear = e.target.value;
    copyMeggProduction.premixSteps.preLay.feedProductionYear = (
      e.target.value * 0.053
    ).toFixed(2);
    copyMeggProduction.premixSteps.prePeak.feedProductionYear = (
      e.target.value * 0.211
    ).toFixed(2);
    copyMeggProduction.premixSteps.layPeak.feedProductionYear = (
      e.target.value * 0.568
    ).toFixed(2);
    copyMeggProduction.premixSteps.layFinal.feedProductionYear = (
      e.target.value * 0.168
    ).toFixed(2);
    props.setMeggProduction(copyMeggProduction);
  };

  return (
    <>
      <Row>
        <Col size={1}>
          <Input
            type="input"
            label="Produção Ração/Ano"
            inputType="number"
            value={props.meggProduction.premixProductionYear}
            onChange={inputHandler}
          />
        </Col>
        <Col size={2}>
          <Input
            type="select"
            params="premixComposition"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            label="Composição do Premix"
            placeholder="Selecione o Composição"
            options={props.config
              .filter((item) => item.key === "premixComposition")
              .map((item) => ({ value: item.description }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            params="premixPlaxma"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            label="Utiliza Plasma"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            params="premixHemoglobin"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            label="Utiliza Hemoglobina"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            params="premixAnimalFeed"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            label="Usa Ração Animal"
          />
        </Col>
        <Col>
          <Input
            type="select"
            params="premixAnimalFeedType"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            placeholder="Selecione um opção"
            options={props.config
              .filter((item) => item.key === "premixAnimalFeedType")
              .map((item) => ({ value: item.description }))}
          />
        </Col>
      </Row>
      <div
        className="kt-separator kt-separator--solid"
        style={{ marginTop: 0 }}
      />
      <Steps {...props} />
    </>
  );
}
