import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";
import { Input } from "components/Input";

export function CarbohydraseBlends(props) {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "50px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual produto?</span>
                </th>
                <th>
                  <span>Qual finalidade?</span>
                </th>
                <th>
                  <span>Qual dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Cria</td>

                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.carbohydraseBlends.create.product"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Produto"
                    options={props.config
                      .filter(
                        (item) =>
                          item.key === "enzymesCarbohydraseBlendsProduct"
                      )
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.carbohydraseBlends.create.goal"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.carbohydraseBlends.create.dose"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Recria</td>

                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.carbohydraseBlends.recreate.product"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Produto"
                    options={props.config
                      .filter(
                        (item) =>
                          item.key === "enzymesCarbohydraseBlendsProduct"
                      )
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.carbohydraseBlends.recreate.goal"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.carbohydraseBlends.recreate.dose"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Produção</td>

                <td>
                  <Input
                    type="selectOnly"
                    params="enzymes.carbohydraseBlends.production.product"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                    placeholder="Selecione o Produto"
                    options={props.config
                      .filter(
                        (item) =>
                          item.key === "enzymesCarbohydraseBlendsProduct"
                      )
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.carbohydraseBlends.production.goal"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.carbohydraseBlends.production.dose"
                    item={props.heavyBroiler}
                    setItem={props.setHeavyBroiler}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Rovabio está aprovado? Se não, por qual motivo?</label>
          <Row>
            <Col>
              <Input
                type="checkbox"
                params="enzymes.carbohydraseBlends.approved.checked"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                label="Aprovado"
                className="kt-checkbox  kt-checkbox-right"
              />
            </Col>
          </Row>
          <Input
            type="inputOnly"
            params="enzymes.carbohydraseBlends.approved.reason"
            item={props.heavyBroiler}
            setItem={props.setHeavyBroiler}
            disabled={
              props.heavyBroiler.enzymes.carbohydraseBlends.approved.checked
            }
            placeholder="Digite o motivo"
          />
        </Col>
      </Row>
    </>
  );
}
