import { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Organics } from "./Organics";
import { ZincOxide } from "./ZincOxide";
import { CopperSulfate } from "./CopperSulfate";

export function Minerals(props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Minerais Orgânicos" />
        <Tab label="Oxido de Zinco" />
        <Tab label="Sulfato de Cobre" />
      </Tabs>
      {tab === 0 && <Organics {...props} />}
      {tab === 1 && <ZincOxide {...props} />}
      {tab === 2 && <CopperSulfate {...props} />}
    </>
  );
}
