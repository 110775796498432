import React from "react";
import {
  getScoreColor,
  getScoreText,
  getColor,
} from "../../../../../../../../../utils/globalFunctions";
import Chart from "react-apexcharts";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "../../../../../../../../../styles";

export function ScoreTotal({ necropsy }) {
  return (
    <Row>
      {necropsy.birds.map((bird, index) => (
        <Col key={index}>
          <Card>
            <CardHeader>Ave {index + 1}</CardHeader>
            <CardBody>
              <Chart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreColor(necropsy.birds[index].total),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -20,
                          show: true,
                          color: getScoreColor(necropsy.birds[index].total),
                          fontSize: "13px",
                        },
                        value: {
                          formatter: function (val) {
                            return (
                              val / 10 < 0 ? 0 : val / 10
                            ).toLocaleString();
                          },
                          color: getScoreColor(necropsy.birds[index].total),
                          fontSize: "20px",
                          show: true,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [getScoreColor(necropsy.birds[index].total)],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [
                        getScoreColor(necropsy.birds[index].total),
                      ],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [getScoreText(necropsy.birds[index].total)],
                }}
                series={[necropsy.birds[index].total * 10]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
