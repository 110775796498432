import { useCallback, useState } from "react";
import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { Image } from "components/Image";
import { Input } from "components/Input";
import { Body, Header, TableContent } from "components/Table/style";
import { Card, CardBody, CardHeader } from "styles";

export function Feces({ preNecropsy, setPreNecropsy, config }) {
  const fecesTypes = [
    "good",
    "humid",
    "gas",
    "feedPassage",
    "limestonePresence",
    "mucusPresence",
    "intestinalFlaking",
  ];

  const [info, setInfo] = useState(null);

  const birdHandler = useCallback(
    (indexBoxe, indexBird, key, value) => {
      const copyPreNecropsy = JSON.parse(JSON.stringify(preNecropsy));
      copyPreNecropsy.boxes[indexBoxe].birds[indexBird][key] = value;
      copyPreNecropsy.boxes[indexBoxe][key] =
        (copyPreNecropsy.boxes[indexBoxe].birds
          .map((item) => (item[key] ? 1 : 0))
          .reduce((a, b) => a + b) /
          +copyPreNecropsy.numberOfBirds) *
        100;
      if (key === "good" && value) {
        Object.keys(copyPreNecropsy.boxes[indexBoxe].birds[indexBird]).forEach(
          (param) => {
            if (param !== "good") {
              copyPreNecropsy.boxes[indexBoxe].birds[indexBird][param] = false;
            }
          }
        );
      }
      setPreNecropsy(copyPreNecropsy);
    },
    [preNecropsy, setPreNecropsy]
  );

  return info ? (
    <Card style={{ height: "100%", marginBottom: "15px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <strong>
          <i className="far fa-question-circle" /> &nbsp;
          {info.label}
        </strong>
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={() => setInfo(null)}
          size="sm"
        >
          Voltar
        </Button>
      </CardHeader>
      <CardBody>
        <TableContent>
          <Header>
            <tr>
              <th style={{ width: "100px" }}>Score</th>
              <th>Descrição</th>
              <th style={{ width: "500px" }}>Imagem</th>
            </tr>
          </Header>
          <Body>
            {info.options.map((item) => (
              <tr key={item._id}>
                <td>
                  <span>
                    <b>{item.score}</b>
                  </span>
                </td>
                <td>{item.description}</td>
                <td>
                  <Image
                    className="img-fluid"
                    src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/necropsy-${item.key.replace(
                      /\./g,
                      "-"
                    )}-${item._id}.${item.extention}`}
                  />
                </td>
              </tr>
            ))}
          </Body>
        </TableContent>
      </CardBody>
    </Card>
  ) : (
    <TableContent>
      <Body>
        <tr>
          <th colSpan={9}>
            <span
              style={{
                padding: "5px 10px",
                background: "#F64E60",
                color: "#fff",
                border: "1px solid #dc3545",
                borderRadius: "4px",
              }}
            >
              PREENCHA TODAS AS FEZES ANALISADAS!
            </span>
          </th>
        </tr>
        {preNecropsy.boxes.map(({ birds }, indexBoxe) => [
          <tr key={`header${indexBoxe}`}>
            <th>Tipo de Excretas</th>
            <th>Boa</th>
            <th>Umidas</th>
            <th>Gás</th>
            <th>Passagem de ração</th>
            <th>Presença de calcario</th>
            <th>Presença de muco</th>
            <th>Descamação Intestinal</th>
            <th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span></span>
                <Icons
                  type="question"
                  color="#009870"
                  size={18}
                  onClick={() =>
                    setInfo({
                      label: "Tipos de fezes",
                      options: config.filter(
                        ({ key }) => key.split(".")[0] === "feces"
                      ),
                    })
                  }
                />
              </div>
            </th>
          </tr>,
          ...birds.map((item, indexBirds) => (
            <tr key={`box${indexBoxe}bird${indexBirds}`}>
              <td
                style={
                  !fecesTypes.filter(
                    (key) => preNecropsy.boxes[indexBoxe].birds[indexBirds][key]
                  )[0]
                    ? { color: "#F64E60" }
                    : null
                }
              >
                {indexBirds + 1}
              </td>
              {fecesTypes.map((key) => (
                <td
                  key={`box${indexBoxe}bird${indexBirds}${key}`}
                  style={{ width: "150px" }}
                >
                  <Input
                    item={preNecropsy}
                    setItem={setPreNecropsy}
                    params={`boxes.${indexBoxe}.birds.${indexBirds}.${key}`}
                    className="form-control form-control-sm"
                    type="checkbox"
                    disabled={
                      preNecropsy.boxes[indexBoxe].birds[indexBirds].good &&
                      key !== "good"
                    }
                    label={
                      preNecropsy.boxes[indexBoxe].birds[indexBirds][key]
                        ? "Sim"
                        : "Não"
                    }
                    onChange={(e) =>
                      birdHandler(indexBoxe, indexBirds, key, e.target.checked)
                    }
                  />
                </td>
              ))}
            </tr>
          )),
          <tr key={`total${indexBoxe}`}>
            <td>
              <strong className="text-primary">
                Total Boxe 0{indexBoxe + 1}
              </strong>
            </td>
            {[
              "good",
              "humid",
              "gas",
              "feedPassage",
              "limestonePresence",
              "mucusPresence",
              "intestinalFlaking",
            ].map((key, index) => (
              <td key={key}>
                <strong className="text-primary">
                  {preNecropsy.boxes[indexBoxe][key].toFixed(0)}%
                </strong>
              </td>
            ))}
          </tr>,
        ])}
      </Body>
    </TableContent>
  );
}
