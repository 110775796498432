import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";

import { Separator } from "styles";

import { H2, H3 } from "../style";
import { Table } from "../Table";

import ReactLoading from "react-loading";

export function Module1(props) {
  const [data, setData] = useState({
    a: {
      1: "",
      2: "",
      responsableName: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
    },
    b: {
      12: "",
    },
    c: {
      13: "",
      14: "",
      15: "",
    },
    d: {
      16: "",
    },
    e: {
      17: "",
      18: "",
      19: "",
      20: "",
    },
    f: {
      21: "",
    },
    f1: {
      22: "",
      23: "",
      24: "",
      25: "",
    },
    f2: {
      26: "",
      27: "",
    },
    f3: {
      28: "",
    },
    f4: {
      29: "",
      30: "",
      31: "",
    },
    f5: {
      32: "",
      33: "",
      34: "",
      35: "",
      36: "",
      37: "",
      38: "",
      39: "",
    },
    f6: {
      40: "",
      41: "",
    },
    f7: {
      42: "",
    },
    f8: {
      43: "",
    },
    f9: {
      44: "",
      45: "",
    },
    description: "",
  });

  const [loading, setLoading] = useState(true);

  const [getData, setGetData] = useState(true);

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };

    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar criação da Termo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar Avaliação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`inspectiontermbpf/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Criar Termo",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }

    /*  if (!props.id) {
      const response = await Swal.fire({
        title: "Criar Termo",
        text: "Deseja confirmar criação da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`inspectiontermbpf/${props.match.params.id}`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setData((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState._id = response.value.data.id;
          return newState;
        });
        props.setUpdateList(true);
      }
    } else {
      query._id = props.id;
      const response = await Swal.fire({
        title: "Editar Termo",
        text: "Deseja confirmar edição da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Termo!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(
              `inspectiontermbpf/${props.match.params.id}/${props.id}`,
              query
            )
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        props.setUpdateList(true);
      }
    } */
  }, [data, props]);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `inspectiontermbpf/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire("Editar Termo", "Não foi possível buscar a Termo", "error");
        }
        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>ANEXO IV B – Modelo de Termo de Fiscalização - BPF</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <H2>Módulo I</H2>
          <H3>Boas Práticas de fabricação e autocontrole</H3>
          <Separator />
          <Table
            module={data}
            setModule={setData}
            title="A. DOCUMENTAÇÃO E REGISTRO"
            body={[
              [
                "",
                "DL",
                "1.",
                "Registro do estabelecimento está vigente e opera em atividades/categorias nele autorizadas.",
                "a.1",
              ],
              [
                "",
                "D",
                "2.",
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "300px" }}>
                    Dispõe de responsável técnico.
                  </div>
                  <Input
                    type="inputOnly"
                    item={data}
                    setItem={setData}
                    params="a.responsableName"
                    placeholder="Cite nome e inscrição no conselho profissional."
                  />
                </div>,
                "a.2",
              ],
              [
                "",
                "DL",
                "3.",
                "Planos de ação anteriores a essa fiscalização foram cumpridos (RR)",
                "a.3",
              ],
              [
                "O",
                "DL",
                "4.",
                "Se o estabelecimento fabrica produto com medicamento ou com produtos medicados, existe autorização vigente. (RR)",
                "a.4",
              ],
              [
                "",
                "DL",
                "5.",
                "A prestação ou contratação de serviços de terceiros para fabricação ou fracionamento foi autorizada.",
                "a.5",
              ],
              [
                "",
                "DL",
                "6.",
                "Relatório mensal do quantitativo fabricado, comercializado, importado e/ou exportado, é enviado dentro do prazo.",
                "a.6",
              ],
              [
                "O",
                "DL",
                "7.",
                "A composição declarada nos rótulos dos produtos acabados guarda correlação com os produtos utilizados na fabricação, conforme ficha de produção. (RR)",
                "a.7",
              ],
              [
                "",
                "DL",
                "8.",
                "Os produtos acabados atendem à legislação vigente quanto ao registro, rotulagem, propaganda e quanto aos critérios e procedimentos para a fabricação, fracionamento, importação e comercialização dos produtos dispensados de registro. (RR)",
                "a.8",
              ],
              [
                "",
                "DL",
                "9.",
                "Reutiliza embalagens mediante autorização prévia do MAPA.",
                "a.9",
              ],
              [
                "",
                "DL",
                "10.",
                "Atendeu à intimação dentro do prazo estipulado. (RR)",
                "a.10",
              ],
              [
                "",
                "D",
                "11.",
                "Comunicou as alterações físicas, de elementos informativos e documentais.",
                "a.a11",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="B. CONDIÇÕES GERAIS DA ÁREA EXTERNA"
            body={[
              [
                "",
                "L",
                "12.",
                "As condições externas da indústria atendem aos critérios de Boas Práticas de Fabricação.",
                "b.12",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="C. CONDIÇÕES GERAIS DA ÁREA INTERNA"
            body={[
              [
                "",
                "L",
                "13.",
                "As condições externas da indústria atendem aos critérios de Boas Práticas de Fabricação.",
                "c.13",
              ],
              [
                "",
                "L",
                "14.",
                "Áreas, locais e estruturas são compatíveis com o volume de produção e atendem aos requisitos de armazenamento, dimensionamento e conservação exigidos pelas matérias-primas, insumos, produtos intermediários e produtos acabados desde a recepção até a expedição.",
                "c.14",
              ],
              [
                "",
                "L",
                "15.",
                "Equipamentos da linha de produção são compatíveis e em número adequado à atividade.",
                "c.15",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="D. TREINAMENTOS"
            body={[
              [
                "",
                "DL",
                "16.",
                "Existe programa de treinamento de funcionários relativo à higiene pessoal e aspectos higiênicosanitários e há evidências de que os treinamentos são eficientes.",
                "d.16",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="E. CONTROLE DO PROCESSO PRODUTIVO"
            body={[
              [
                "",
                "D",
                "17.",
                "O monitoramento dos parâmetros de processo é feito dentro da frequência prevista, tem sido capaz de identificar falhas e os registros dos parâmetros e das medidas corretivas estão disponíveis. (RR)",
                "d.17",
              ],
              [
                "O",
                "D",
                "18.",
                "Os ingredientes ou aditivos produzidos a partir de matérias-primas brutas são monitorados por meio de análises laboratoriais para a garantia da segurança, qualidade e inocuidade dos produtos. (RR)",
                "d.18",
              ],
              [
                "",
                "D",
                "19.",
                "A homogeneidade dos lotes baseia-se em testes validados de homogeneidade de mistura e é validada. (RR)",
                "d.19",
              ],
              [
                "",
                "D",
                "20.",
                "A empresa faz análise laboratorial periódica como forma de verificação do processo produtivo, registra e toma medidas corretivas adequadas na ocorrência de desvios. (RR)",
                "d.20",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F. AVALIAÇÃO DOS ELEMENTOS DE CONTROLE"
            body={[
              [
                "",
                "D",
                "21.",
                "O manual de boas práticas de fabricação contempla os POPs obrigatórios e todas as etapas de produção estão descritas por meio de fluxograma ou memorial descritivo.",
                "f.21",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.1. QUALIFICAÇÃO DE FORNECEDORES E CONTROLE DE MATÉRIAS-PRIMAS, INGREDIENTES E EMBALAGENS"
            body={[
              [
                "",
                "DL",
                "22.",
                "O POP define os critérios e os procedimentos para a qualificação de fornecedores e estes são atendidos. (RR)",
                "f1.22",
              ],
              [
                "",
                "DL",
                "23.",
                "O POP define os critérios, parâmetros e os procedimentos para o recebimento de matérias-primas e ingredientes e embalagens, e estes são atendidos. (RR)",
                "f1.23",
              ],
              [
                "O",
                "L",
                "24.",
                "As matérias-primas e ingredientes estão dentro do prazo de validade. (RR)",
                "f1.24",
              ],
              [
                "O",
                "DL",
                "25.",
                "Adquire e utiliza apenas produtos permitidos por legislação e conforme indicações e modo de uso considerando o tipo de produto que elabora e as espécies/categorias. (RR)",
                "f1.25",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.2. LIMPEZA/HIGIENIZAÇÃO DE INSTALAÇÕES, EQUIPAMENTOS E UTENSÍLIOS"
            body={[
              [
                "",
                "L",
                "26.",
                "Todas as áreas, instalações, equipamentos e utensílios estão relacionadas no POP.",
                "f2.26",
              ],
              [
                "",
                "DL",
                "27.",
                "Para as áreas, instalações, equipamentos e utensílios do item anterior foram descritos critérios, frequências e os procedimentos de limpeza/higienização e há evidências de que estes são atendidos. (RR)",
                "f2.27",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.3. HIGIENE E SAÚDE DO PESSOAL"
            body={[
              [
                "",
                "D",
                "28.",
                "O POP especifica os procedimentos em relação ao uso e higiene dos uniformes, hábitos higiênicos, higiene pessoal, higiene antes e durante as operações e estes são atendidos.",
                "f3.28",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.4. POTABILIDADE DA ÁGUA E HIGIENIZAÇÃO DO RESERVATÓRIO"
            body={[
              [
                "",
                "D",
                "29.",
                "O POP descreve todas as etapas desde obtenção até o destino da água utilizada e este corresponde à prática.",
                "f4.29",
              ],
              [
                "",
                "D",
                "30.",
                "O POP especifica o padrão de potabilidade da água e estes são atendidos.",
                "f4.30",
              ],
              [
                "",
                "D",
                "31.",
                "O POP especifica os procedimentos de limpeza e higienização de reservatórios e estes são atendidos.",
                "f4.31",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.5. PREVENÇÃO DE CONTAMINAÇÃO CRUZADA"
            body={[
              [
                "",
                "D",
                "32.",
                "O POP identifica os perigos para cada etapa do processo, descreve as medidas preventivas correspondentes e estas são atendidas.",
                "f5.32",
              ],
              [
                "",
                "D",
                "33.",
                "A análise laboratorial proposta é capaz de detectar os perigos relacionados pela empresa para as matérias-primas, produtos e processo produtivo e são tomadas medidas corretivas, quando dos desvios. (RR)",
                "f5.33",
              ],
              [
                "",
                "DL",
                "34.",
                "Caso utilize sequenciamento de produção como medida preventiva, descreve os critérios que determinam a sequência. (RR)",
                "f5.34",
              ],
              [
                "O",
                "DL",
                "35.",
                "Caso o sequenciamento de produção seja imprescindível como medida preventiva, os registros de produção permitem determinar a sequência real de produção e condizem com os critérios definidos no item anterior. (RR)",
                "f5.35",
              ],
              [
                "",
                "D",
                "36.",
                "Caso a limpeza de linha (arraste, flushing ou outro método) seja imprescindível como medida preventiva, o procedimento de limpeza foi validado. (RR)",
                "f5.36",
              ],
              [
                "",
                "DL",
                "37.",
                "Caso a limpeza de linha (arraste, flushing ou outro método) seja imprescindível como medida preventiva, o procedimento validado de limpeza de linha é respeitado.",
                "f5.37",
              ],
              [
                "",
                "DL",
                "38.",
                "Caso a limpeza de linha (arraste, flushing ou outro método) seja imprescindível como medida preventiva, os registros de produção permitem determinar a destinação do resíduo de limpeza ou do material utilizado.",
                "f5.38",
              ],
              [
                "",
                "DL",
                "39.",
                "Caso a limpeza de linha (arraste ou flushing) seja imprescindível como medida preventiva e o material seja reprocessado, o reprocessamento é seguro, existe descrição clara do procedimento e registros documentam sua execução.",
                "f5.39",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.6. MANUTENÇÃO E CALIBRAÇÃO DE EQUIPAMENTOS E INSTRUMENTOS"
            body={[
              [
                "",
                "D",
                "40.",
                "Todas as áreas, instalações, equipamentos e utensílios estão relacionados no POP de manutenção e calibração.",
                "f6.40",
              ],
              [
                "",
                "DL",
                "41.",
                "Para as áreas, instalações, equipamentos e utensílios do item anterior foram descritos critérios, frequências, os procedimentos de manutenção e calibração e há evidências de que estes são atendidos. (RR)",
                "f6.41",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.7. CONTROLE INTEGRADO DE PRAGAS"
            body={[
              [
                "",
                "D",
                "42.",
                "O POP especifica as medidas preventivas e corretivas adotadas para o controle de pragas e há evidências de que estas são atendidas. (RR)",
                "f7.42",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.8. CONTROLE DE RESÍDUOS E EFLUENTES"
            body={[
              [
                "",
                "D",
                "43.",
                "O POP especifica os procedimentos em relação ao controle de resíduos e efluentes e há evidências de que estes são atendidos.",
                "f8.43",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="F.9. RASTREABILIDADE E RECOLHIMENTO DE PRODUTOS"
            body={[
              [
                "O",
                "D",
                "44.",
                "Foi possível realizar a rastreabilidade de um lote de produto acabado. (RR)",
                "f9.44",
              ],
              [
                "O",
                "D",
                "45.",
                "Mantém os registros e os tratamentos dados às reclamações de consumidores.",
                "f9.45",
              ],
            ]}
          />
          <Input
            type="textarea"
            item={data}
            setItem={setData}
            params="description"
            label="DESCREVER AS NÃO CONFORMIDADES:"
            rows={20}
          />
        </>
      )}
    </Modal>
  );
}
