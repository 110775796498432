import { Row, Col } from "styles";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function MycotoxinAdsorbents(props) {
  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              textAlign: "end",
              marginRight: "40px",
            }}
          >
            <Input
              type="checkbox"
              params="additions.mycotoxinAdsorbents.unknownConcept"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              label="Cliente não conhece o conceito"
              className="kt-checkbox kt-checkbox-right mt-2"
            />
          </div>
        </Col>
      </Row>
      <Table>
        <Header>
          <tr>
            <th style={{ width: "50px" }}>
              <span></span>
            </th>
            <th>
              <span>Qual Produto?</span>
            </th>
            <th>
              <span>Qual Finalidade?</span>
            </th>
          </tr>
        </Header>
        <Body>
          <tr>
            <td>Cria</td>
            <td>
              <Input
                type="selectOnly"
                params="additions.mycotoxinAdsorbents.create.product"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder="Selecione o Produto"
                options={props.config
                  .filter(
                    (item) => item.key === "additionsMycotoxinAdsorbentsProduct"
                  )
                  .map((item) => ({ value: item.description }))}
              />
            </td>
            <td>
              <Input
                type="inputOnly"
                params="additions.mycotoxinAdsorbents.create.reason"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
              />
            </td>
          </tr>
          <tr>
            <td>Recria</td>
            <td>
              <Input
                type="selectOnly"
                params="additions.mycotoxinAdsorbents.recreate.product"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder="Selecione o Produto"
                options={props.config
                  .filter(
                    (item) => item.key === "additionsMycotoxinAdsorbentsProduct"
                  )
                  .map((item) => ({ value: item.description }))}
              />
            </td>
            <td>
              <Input
                type="inputOnly"
                params="additions.mycotoxinAdsorbents.recreate.reason"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
              />
            </td>
          </tr>
          <tr>
            <td>Produção</td>
            <td>
              <Input
                type="selectOnly"
                params="additions.mycotoxinAdsorbents.production.product"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder="Selecione o Produto"
                options={props.config
                  .filter(
                    (item) => item.key === "additionsMycotoxinAdsorbentsProduct"
                  )
                  .map((item) => ({ value: item.description }))}
              />
            </td>
            <td>
              <Input
                type="inputOnly"
                params="additions.mycotoxinAdsorbents.production.reason"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
              />
            </td>
          </tr>
        </Body>
      </Table>
    </>
  );
}
