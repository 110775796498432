import { createRef, useContext, useState } from "react";
import { useOutsideClick } from "services/outsideClick";
import {
  Container,
  DropdownConnect,
  DropdownConnectContent,
  DropdownConnectToggle,
  UserDataContent,
  UserDataDropdown,
  UserDataDropdownToggle,
  UserDataIcon,
  UserDataUsername,
  UserDataWelcome,
} from "./style";
import DropdownMenuRight from "./DropdownMenuRight";
import ProfileContext from "contexts/profile";
import { getFirstLetter, hasPermission } from "utils/globalFunctions";
import { Logo } from "../style";

export function MenuRight({
  showTopBar,
  setShowTopBar,
  setShowSideMenuRight,
  location,
}) {
  const ref = createRef();

  const profile = useContext(ProfileContext);

  const [showDropdown, setShowDropdown] = useState("");
  const [tabDropdown, setTabDropdown] = useState(0);

  useOutsideClick(ref, () => {
    if (showTopBar) {
      setShowTopBar("");
    }
  });

  return (
    <Container show={showTopBar} ref={ref}>
      {hasPermission(profile, "connect", "accessOnly") && (
        <DropdownConnect>
          <DropdownConnectContent
            onClick={() => {
              setShowDropdown((prevState) => {
                if (prevState) {
                  console.log(prevState);
                  return "";
                }
                return "true";
              });
              setTabDropdown(profile.roles?.indexOf("cliente") < 0 ? 0 : 1);
            }}
          >
            <DropdownConnectToggle>
              <Logo
                style={{ width: 100 }}
                alt="Logo"
                src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_connect_nova.png"
              />
            </DropdownConnectToggle>
          </DropdownConnectContent>
          <DropdownMenuRight
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            tabDropdown={tabDropdown}
            setTabDropdown={setTabDropdown}
            location={location}
          />
        </DropdownConnect>
      )}
      <UserDataContent>
        <UserDataDropdown>
          <UserDataDropdownToggle
            onClick={() =>
              setShowSideMenuRight((prevState) =>
                prevState === "true" ? "" : "true"
              )
            }
          >
            <UserDataWelcome>Olá</UserDataWelcome>
            <UserDataUsername>{profile.name?.split(" ")[0]}</UserDataUsername>
            <UserDataIcon>
              <b>{getFirstLetter(profile.name)}</b>
            </UserDataIcon>
          </UserDataDropdownToggle>
        </UserDataDropdown>
      </UserDataContent>
    </Container>
  );
}
