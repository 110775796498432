import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function Anticoccidian(props) {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "185px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual molécula utiliza atualmente?</span>
                </th>
                <th>
                  <span>Qual PPM?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>
                  <span>Pré</span>
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.moleculePre"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite a molécula"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.dosePre"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Inicial</span>
                </td>

                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.moleculeInitial"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite a molécula"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.doseInitial"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Crescimento 1</span>
                </td>

                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.moleculeGrowth"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite a molécula"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.doseGrowth"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Crescimento 2</span>
                </td>

                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.moleculeGrowth2"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite a molécula"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.doseGrowth2"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Final</span>
                </td>

                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.moleculeFinal"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite a molécula"
                    options={props.config
                      .filter(
                        (item) => item.key === "healthAnticoccidianMolecule"
                      )
                      .map((item) => ({ value: item.description }))}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="health.anticoccidian.doseFinal"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            params="health.anticoccidian.additional"
            item={props.broiler}
            setItem={props.setBroiler}
            label="Utiliza tratamento adicional a
            coccidiose"
            className="kt-checkbox mt-2"
          />
        </Col>
      </Row>
    </>
  );
}
