import { Input } from "components/Input";
import { Col, Row, Separator } from "styles";
import { H2 } from "../style";

export function FactoryAndProductInStockEvaluation({
  evaluation,
  setEvaluation,
}) {
  return (
    <>
      <H2>Armazenamento</H2>
      <Row>
        <Col>
          <Row>
            <Col size={2}>
              É feito FEFO (primeiro que vence, primeiro que sai)?
            </Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.fefo"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.fefo"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>Os produtos estão armazenados sobre estrados?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.storedOnPallets"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.storedOnPallets"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>Os produtos estão afastados das paredes?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.awayFromTheWalls"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.awayFromTheWalls"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>
              Os produtos estão armazenados ao abrigo da luz solar?
            </Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.storedAwayFromSunlight"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.storedAwayFromSunlight"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>O armazenamento está livre de umidade?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.storedFreeOfHumidity"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.storedFreeOfHumidity"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>A embalagem está íntegra?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.packagingIsIntact"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.packagingIsIntact"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>
              As sacarias tiveram ou estão em contato com outros produtos
              armazenados?
            </Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.sacksWithOthersProducts"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.sacksWithOthersProducts"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>
              É feita a medição diária de temperatura do galpão de
              armazenamento?
            </Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.dailyHouseTemperatureMeasurement"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.storage.dailyHouseTemperatureMeasurement"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="input"
            label="*Informar o CV da última medição"
            item={evaluation}
            setItem={setEvaluation}
            params="factoryAndProductInStockEvaluation.storage.CVOfLastMeasurement"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="textarea"
            label="*Justificar os itens assinalados como NÃO:"
            item={evaluation}
            rows="5"
            setItem={setEvaluation}
            params="factoryAndProductInStockEvaluation.storage.JustifyTheItemsMarkedAsNo"
          />
        </Col>
      </Row>
      <Separator />
      <H2>Silos, misturador e linha de produção</H2>
      <Row>
        <Col>
          <Row>
            <Col size={2}>Os equipamentos estão limpos?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.equipmentsAreClean"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.equipmentsAreClean"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>Tem acumulo de ração no misturador ou na linha?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.accumulationOfFeedInTheMixerOrInTheLine"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.accumulationOfFeedInTheMixerOrInTheLine"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>Os silos estão bem vedados?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.silosAreWellSealed"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.silosAreWellSealed"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>O misturador está aterrado?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.mixerIsGrounded"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.mixerIsGrounded"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>A balança de pesagem está calibrada?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.weighingScaleIsCalibrated"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.weighingScaleIsCalibrated"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>A sequência de produção está correta?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.productionSequenceIsCorrect"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.productionSequenceIsCorrect"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>É feita limpeza de misturador entre as batidas?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.mixerCleaningBetweenBeats"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.mixerCleaningBetweenBeats"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>O CV do misturador foi verificado?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.MixerCVHasBeenChecked"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.MixerCVHasBeenChecked"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>É usado medicamento na fábrica?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.drugAtTheFactory"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.drugAtTheFactory"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>
              Os ingredientes de baixa inclusão são pré-misturados antes de
              entrarem no misturador?
            </Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.lowInclusionIngredientsArePreMixed"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.lowInclusionIngredientsArePreMixed"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="input"
            label="*Informar o CV da última medição"
            item={evaluation}
            setItem={setEvaluation}
            params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.CVOfLastMeasurement"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            type="textarea"
            label="*Justificar os itens assinalados como NÃO:"
            item={evaluation}
            rows="5"
            setItem={setEvaluation}
            params="factoryAndProductInStockEvaluation.silosMixerAndProductionLine.JustifyTheItemsMarkedAsNo"
          />
        </Col>
      </Row>
    </>
  );
}
/* 
      silosMixerAndProductionLine: {
        equipmentsAreClean: false,
        accumulationOfFeedInTheMixerOrInTheLine: false,
        silosAreWellSealed: false,
        mixerIsGrounded: false,
        weighingScaleIsCalibrated: false,
        productionSequenceIsCorrect: false,
        mixerCleaningBetweenBeats: false,
        MixerCVHasBeenChecked: false,
        drugAtTheFactory: false,
        lowInclusionIngredientsArePreMixed: false,
        CVOfLastMeasurement: "",
        JustifyTheItemsMarkedAsNo: "",
      },
*/
