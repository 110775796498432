import React from "react";
import { Col, Row, Separator } from "styles";
import { Input } from "components/Input";

export function Customer(props) {
  const places = [
    "China",
    "América Latina",
    "América do Norte",
    "Europa",
    "Russia",
    "Oriente Médio",
    "Japão",
  ];

  const placeHandler = (value, place) => {
    const copyMegg = { ...props.megg };
    if (value) {
      copyMegg.exportPlaces.push(place);
    } else {
      const index = copyMegg.exportPlaces.findIndex((item) => item === place);
      if (index > -1) {
        copyMegg.exportPlaces.splice(index, 1);
      }
    }
    props.setMegg(copyMegg);
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            type="input"
            params="dailySlaughter"
            item={props.megg}
            setItem={props.setMegg}
            inputType="number"
            label="Abates Diários"
          />
        </Col>
        <Col>
          <Input
            type="select"
            params="healthScore"
            item={props.megg}
            setItem={props.setMegg}
            label="Escore sanitário"
            placeholder="Selecione o escore"
            options={props.config
              .filter((item) => item.key === "customerHealthScore")
              .map((item) => ({ value: item.description }))}
          />
        </Col>
        <Col>
          <Input
            type="input"
            params="healthChallenge"
            item={props.megg}
            setItem={props.setMegg}
            label="Quais os desafios sanitários?"
          />
        </Col>
        <Col>
          <Input
            item={props.megg}
            setItem={props.setMegg}
            params="date"
            type="inputMask"
            label="Data Alteração"
            mask="99/99/9999"
          />
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <Input
            type="input"
            params="knownAgents"
            item={props.megg}
            setItem={props.setMegg}
            label="Os agentes são conhecidos? Se sim, quais são?"
          />
        </Col>
        <Col size={1}>
          <Input
            type="select"
            params="antibiogramRecent"
            item={props.megg}
            setItem={props.setMegg}
            label="Foi feito antibiograma recentemente?"
            placeholder="Selecione uma opção"
            options={[
              { value: "Sim", label: "Sim" },
              { value: "Não", label: "Não" },
            ]}
          />
        </Col>
      </Row>
      <Separator />
      <Row>
        <Col flexRow={true}>
          <Input
            type="checkbox"
            params="export"
            item={props.megg}
            setItem={props.setMegg}
            label="Exportador"
          />
          <Input
            type="checkbox"
            params="internMarket"
            item={props.megg}
            setItem={props.setMegg}
            label="Mercado Interno"
          />
        </Col>
      </Row>
      {props.megg.export ? (
        <Row>
          <Col size={1}>
            <Input
              type="select"
              params="exportCuts"
              item={props.megg}
              setItem={props.setMegg}
              label="Quais Cortes?"
              placeholder="Selecione uma tipo de corte"
            />
          </Col>
          <Col size={3} flexRow={true}>
            {places.map((place) => (
              <Input
                key={place}
                type="checkbox"
                label={place}
                onChange={(e) => placeHandler(e.target.checked, place)}
                checked={
                  props.megg.exportPlaces.findIndex((item) => item === place) >
                  -1
                }
              />
            ))}
          </Col>
        </Row>
      ) : null}
    </>
  );
}
