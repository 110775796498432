import Score from "react-apexcharts";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import isMobile from "is-mobile";
import { Bar } from "react-chartjs-2";
import { Button } from "components/Button";
import { HistoricItem } from "components/historicItem";
import { Icons } from "components/Icons";
import { ReportConsolidatedItem } from "components/ReportConsolidatedItem";
import { ScoreItem } from "components/ScoreItem";
import ReactLoading from "react-loading";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "styles";
import {
  getColor,
  getScoreColor,
  getScoreText,
  getTotalScore,
} from "utils/globalFunctions";
import { useCallback, useState } from "react";
import { CreateEdit as CreateEditHistoric } from "./Historic/CreateEdit";
import { Input } from "components/Input";
import { saveAs } from "file-saver";
import { api } from "services/api";
import { CreateEdit as CreateEditNecropsy } from "./Necropsy/CreateEdit";

export function Broiler({
  historicBroiler,
  graphLastMonth,
  getReportData,
  getReportlabels,
  consolidated,
  lastNecropsies,
  customer,
  location,
  consolidatedInput,
  setConsolidatedInput,
  uploading,
  uploadConsolidatedData,
  match,
}) {
  const [showModalHistoric, setShowModalHistoric] = useState(false);

  const [showNecropsy, setShowNecropsy] = useState(false);

  const [necropsyId, setNecropsyId] = useState("");

  const [historicId, setHistoricId] = useState(null);

  const [downloading, setDownloading] = useState(false);

  const downloadReportConsolidated = useCallback(async (item, index) => {
    const downloadingArray = [];
    downloadingArray[index] = true;
    setDownloading(downloadingArray);
    const response = await api.get(
      `broilerconsolidated/reportconsolidated/export/${item._id}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/excel",
    });
    saveAs(blob, `relatório de upload ${item.date}.xlsx`);
    setDownloading(false);
  }, []);

  return (
    <>
      {showNecropsy && (
        <CreateEditNecropsy
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={location}
          customer={customer}
          id={necropsyId}
          match={match}
        />
      )}

      {showModalHistoric && (
        <CreateEditHistoric
          show={showModalHistoric}
          setShow={setShowModalHistoric}
          customer={customer._id}
          id={historicId}
          onHide={() => {
            setShowModalHistoric(false);
            setHistoricId(null);
          }}
          location={location}
        />
      )}
      <>
        <Row>
          <Col size={1.3} className="animate__animated animate__fadeInLeft">
            <Block>
              <BlockHeader>
                <span>
                  <Icons type="broiler" size="20" color="#009870" />
                  &nbsp;Histórico de Programas
                </span>
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  size="sm"
                  onClick={() => {
                    setHistoricId(null);
                    setShowModalHistoric(true);
                  }}
                >
                  <Icons type="substract" size="20" color="#fff" />
                  Programa Atual
                </Button>
              </BlockHeader>
              <BlockBody>
                {historicBroiler.map((item) => (
                  <HistoricItem
                    key={item._id}
                    {...item}
                    button={
                      <Button
                        bg={`var(--default)`}
                        border={`var(--default)`}
                        color="white"
                        size="sm"
                        onClick={() => {
                          setHistoricId(item._id);
                          setShowModalHistoric(true);
                        }}
                      >
                        <Icons type="etc" color="#fff" size="15" />
                      </Button>
                    }
                  />
                ))}
                <Separator />
                <Button
                  color="default"
                  bg="light"
                  border="light"
                  style={{ width: "100%" }}
                  to={`/customer/connect/broiler/historic/1/30/index/{}/${customer._id}`}
                >
                  Acessar Histórico Completo&nbsp;
                  <Icons type="angleDoubleRight" size={20} color="#009870" />
                </Button>
              </BlockBody>
            </Block>
          </Col>
          <Col size={2} className="animate__animated animate__fadeInRight">
            <Block>
              <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_azul_wave_tall.png">
                Lesões por Score | Último Mês
                <div>
                  <Button
                    color="white"
                    bg="basf"
                    border="basf"
                    to={`/customer/connect/broiler/report/${customer._id}/1/30/-date/{}`}
                    style={{ marginRight: 15 }}
                  >
                    <Icons type="list" size="20" color="#fff" />
                    &nbsp; Relatórios Salvos
                  </Button>
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    to={`/customer/connect/broiler/report/${customer._id}/{}`}
                  >
                    <Icons type="plus" size="20" color="#fff" />
                    &nbsp; Novo Relatório
                  </Button>
                </div>
              </BlockHeader>
              <BlockBody>
                <BlockBodyContent>
                  {!!graphLastMonth.scores && (
                    <Bar
                      data={getReportData()}
                      options={{
                        labels: getReportlabels(),

                        elements: {
                          line: {
                            fill: false,
                          },
                        },
                        scales: {
                          xAxes: [
                            {
                              display: true,
                              gridLines: {
                                display: false,
                              },
                              stacked: true,
                              labels: getReportlabels(),
                            },
                          ],
                          yAxes: [
                            {
                              stacked: true,
                              type: "linear",
                              display: true,
                              position: "left",
                              id: "y-axis-1",
                              gridLines: {
                                display: false,
                              },
                              labels: {
                                show: true,
                              },
                              ticks: {
                                beginAtZero: true,
                                max: 10,
                              },
                            },
                            {
                              type: "linear",
                              display: true,
                              position: "right",
                              id: "y-axis-2",
                              gridLines: {
                                display: false,
                              },
                              labels: {
                                show: true,
                              },
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                        showAllTooltips: true,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, chart) {
                              const { label, data } =
                                chart.datasets[tooltipItem.datasetIndex];
                              return (
                                label +
                                ": " +
                                data[tooltipItem.index]
                                  .toString()
                                  .replace(".", ",") +
                                (label !== "Score-Connect (Digestivo)"
                                  ? "%"
                                  : "")
                              );
                            },
                            title: function (tooltipItem, data) {
                              return data.labels[tooltipItem[0].index];
                            },
                          },
                        },
                      }}
                    />
                  )}
                </BlockBodyContent>
              </BlockBody>
            </Block>
          </Col>
        </Row>
        <Row>
          <Col size={1.3} className="animate__animated animate__fadeInLeft">
            <Block>
              <BlockHeader>
                <span>Consolidado</span>
                <Button
                  color="white"
                  bg="basf"
                  border="basf"
                  size="sm"
                  href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Modelo_Upload_Dados.xlsx"
                >
                  <Icons type="download" size="20" color="#fff" />
                  &nbsp; Planilha Modelo
                </Button>
              </BlockHeader>
              <BlockBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Input
                    type="inputFile"
                    disabled={uploading}
                    item={consolidatedInput}
                    setItem={setConsolidatedInput}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    placeholder="Selecione o arquivo para upload"
                  />
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    onClick={uploadConsolidatedData}
                    disabled={!consolidatedInput.files[0]}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icons type="cloudUpload" size={18} color="#fff" />
                  </Button>
                </div>

                <Separator />
                {consolidated.map((item, index) => (
                  <ReportConsolidatedItem
                    key={item._id}
                    {...item}
                    button={
                      <Button
                        bg={`var(--default)`}
                        border={`var(--default)`}
                        color="white"
                        size="sm"
                        disabled={downloading[index]}
                        onClick={() => downloadReportConsolidated(item, index)}
                      >
                        {downloading[index] ? (
                          <ReactLoading
                            type="spin"
                            color="#ffffff"
                            height={14}
                            width={14}
                          />
                        ) : (
                          <Icons type="download" color="#fff" size="15" />
                        )}
                      </Button>
                    }
                  />
                ))}
                <Separator />
                <Button
                  color="default"
                  bg="light"
                  border="light"
                  style={{ width: "100%" }}
                  to={`/customer/connect/broiler/consolidated/1/30/-date/{}/${customer._id}/1`}
                >
                  Acessar Página de Uploads&nbsp;
                  <Icons type="angleDoubleRight" size={20} color="#009870" />
                </Button>
              </BlockBody>
            </Block>
          </Col>
          <Col size={2} className="animate__animated animate__fadeInRight">
            <Block>
              <BlockHeader>
                <span>Últimas necropsias</span>
                <div>
                  <Button
                    color="white"
                    bg="basf"
                    border="basf"
                    size="sm"
                    target="_blank"
                    style={{ marginRight: "10px" }}
                    href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Planilha%20necropsia%2022%2005.pdf"
                  >
                    <Icons type="download" size="20" color="#fff" />
                    &nbsp; Planilha Campo
                  </Button>
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    size="sm"
                    onClick={() => {
                      setNecropsyId("");
                      setShowNecropsy(true);
                    }}
                  >
                    <Icons type="plus" size="20" color="#fff" />
                    &nbsp; Nova Necropsia
                  </Button>
                </div>
              </BlockHeader>
              <BlockBody>
                {lastNecropsies.map((item) => (
                  <ScoreItem
                    key={item._id}
                    title={item.customer?.name}
                    scoreColor={getScoreColor(getTotalScore(item.birds))}
                    text={[item.integrated, item.incubatory]}
                    description={[
                      item.visitDate
                        ? `Visita ${format(
                            new Date(item.visitDate),
                            "dd MMM y",
                            {
                              locale: ptBR,
                            }
                          )}`
                        : null,
                      `${item.birds.length} aves`,
                      item.lineage,
                      item.age
                        ? `idade:
                      ${item.age}`
                        : null,
                    ]}
                    score={
                      <Score
                        options={{
                          plotOptions: {
                            radialBar: {
                              startAngle: -135,
                              endAngle: 225,
                              hollow: {
                                margin: 0,
                                size: "70%",
                                background: "#f9f9fc ",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                              },
                              track: {
                                background: getColor(
                                  getScoreColor(getTotalScore(item.birds)),
                                  0.2
                                ),
                                strokeWidth: "67%",
                                margin: 0, // margin is in pixels
                              },

                              dataLabels: {
                                showOn: "always",
                                name: {
                                  offsetY: -5,
                                  show: true,
                                  color: getScoreColor(
                                    getTotalScore(item.birds)
                                  ),
                                  fontSize: "7px",
                                },
                                value: {
                                  formatter: function (val) {
                                    return (val / 10).toLocaleString();
                                  },
                                  color: getScoreColor(
                                    getTotalScore(item.birds)
                                  ),
                                  fontSize: "10px",
                                  show: true,
                                  offsetY: -5,
                                },
                              },
                            },
                          },
                          fill: {
                            colors: [getScoreColor(getTotalScore(item.birds))],
                            type: "gradient",
                            gradient: {
                              shade: "dark",
                              type: "horizontal",

                              gradientToColors: [
                                getScoreColor(getTotalScore(item.birds)),
                              ],
                              inverseColors: true,
                              opacityFrom: 1,
                              opacityTo: 0.8,
                              stops: [0, 100],
                            },
                          },
                          stroke: {
                            lineCap: "round",
                          },
                          labels: [getScoreText(getTotalScore(item.birds))],
                        }}
                        series={[getTotalScore(item.birds) * 10]}
                        type="radialBar"
                        width={isMobile() ? "90%" : "110"}
                      />
                    }
                    button={
                      <Button
                        bg={getScoreColor(getTotalScore(item.birds))}
                        border={getScoreColor(getTotalScore(item.birds))}
                        onClick={() => {
                          setNecropsyId(item._id);
                          setShowNecropsy(true);
                        }}
                        color="white"
                        size="sm"
                      >
                        <Icons type="etc" color="#fff" size="15" />
                      </Button>
                    }
                  />
                ))}
                {lastNecropsies[0] && (
                  <>
                    <Separator />
                    <Button
                      to={`/customer/connect/broiler/necropsy/1/30/-code/%7B%7D/${customer._id}`}
                      color="default"
                      bg="light"
                      border="light"
                      style={{ width: "100%" }}
                    >
                      Acessar Página de Necropsias&nbsp;
                      <Icons
                        type="angleDoubleRight"
                        size={20}
                        color="#009870"
                      />
                    </Button>
                  </>
                )}
              </BlockBody>
            </Block>
          </Col>
        </Row>
      </>
    </>
  );
}
