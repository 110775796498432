import { Header, Body, Container, BottomContent } from "components/Table/style";
import { MicrotracerTable, BeatsHeader } from "./style";
import { RecoveryRate } from "./RecoveryRate";
import { Probability } from "./Probability";
import { ProbResult } from "./ProbResult";
import { RecoveryResult } from "./RecoveryResult";
import { Conclusion } from "./Conclusion";
import { handlePercentage } from "utils/globalFunctions";

export function MicroTracerMethod({ data, setData }) {
  const beatsArray = Array.from(
    { length: data.amountOfBeats },
    (_, index) => index + 1
  );

  return (
    <Container>
      <MicrotracerTable width={data.amountOfBeats}>
        <Header>
          <tr>
            <th scope="row" />
            {beatsArray.map((index) => (
              <BeatsHeader scope="row">{`Batida ${index}`}</BeatsHeader>
            ))}
          </tr>
        </Header>
        <Body>
          <tr>
            <th scope="row">{`TAXA DE RECUPERAÇÃO (%)`}</th>
            {beatsArray.map((beat, index) => (
              <td>
                <RecoveryRate
                  key={index}
                  handlePercentage={handlePercentage}
                  data={data}
                  setData={setData}
                  beat={beat}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th scope="row">{`PROBABILIDADE (%)`}</th>
            {beatsArray.map((beat, index) => (
              <td>
                <Probability
                  key={index}
                  handlePercentage={handlePercentage}
                  data={data}
                  setData={setData}
                  beat={beat}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th scope="row">{`RESULTADO DA PROBABILIDADE`}</th>
            {beatsArray.map((beat, index) => (
              <td>
                <ProbResult key={index} data={data} beat={beat} />
              </td>
            ))}
          </tr>
          <tr>
            <th scope="row">{`RESULTADO RECUPERAÇÃO`}</th>
            {beatsArray.map((beat, index) => (
              <td>
                <RecoveryResult key={index} data={data} beat={beat} />
              </td>
            ))}
          </tr>
          <tr>
            <th>{`CONCLUSÃO`}</th>
            {beatsArray.map((beat, index) => (
              <td>
                <Conclusion
                  key={index}
                  data={data}
                  beat={beat}
                  setData={setData}
                />
              </td>
            ))}
          </tr>
        </Body>
        <BottomContent></BottomContent>
      </MicrotracerTable>
    </Container>
  );
}
