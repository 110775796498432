import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useContext, useEffect, useState } from "react";
import { Button } from "components/Button";
import { useOutsideClick } from "services/outsideClick";
import ProfileContext from "contexts/profile";
import { logout } from "services/auth";
import {
  CloseButton,
  Container,
  Header,
  ItemContent,
  ItemContentText,
  ItemIcon,
  ItemsContainer,
  ItemText,
  ItemTitle,
  Profile,
  ProfileData,
  ProfileDataEmail,
  ProfileDataLogout,
  ProfileDataLogoutMobile,
  ProfileDataName,
  ProfileDataRole,
  ProfileFirstLetter,
  ProfileImg,
  ProfilePhoto,
} from "./style";
import { getFirstLetter } from "utils/globalFunctions";
import { Separator } from "styles";
import { Icons } from "components/Icons";
import Refs from "contexts/refs";

export function SideMenuRight({ setShowSideMenuRight, showSideMenuRight }) {
  const { name, responsibility, username, image } = useContext(ProfileContext);

  const { refSide } = useContext(Refs);

  const [hour, setHour] = useState("");

  useOutsideClick(refSide, () => {
    setShowSideMenuRight("");
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setHour(
        format(new Date(), "eeee, dd 'de' MMMM 'de' yyyy, HH:mm", {
          locale: ptBR,
        })
      );
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container on={showSideMenuRight} ref={refSide}>
      <Header>
        <small>{hour}</small>
        <CloseButton onClick={() => setShowSideMenuRight("")}>
          <Icons type="close" color="#009870" size={24} />
        </CloseButton>
      </Header>
      <Profile>
        <ProfilePhoto>
          {image ? (
            <ProfileImg />
          ) : (
            <ProfileFirstLetter>
              <h1>{getFirstLetter(name)}</h1>
            </ProfileFirstLetter>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="13px"
            height="13px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <circle
                fill="#1bc5bd"
                cx="12"
                cy="12"
                r="8"
                stroke="#fff"
                strokeWidth="3"
              />
            </g>
          </svg>
        </ProfilePhoto>
        <ProfileData>
          <ProfileDataName>{name}</ProfileDataName>
          <ProfileDataRole>{responsibility}</ProfileDataRole>
          <ProfileDataLogout>
            <ProfileDataEmail>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                    fill="#009870"
                  ></path>
                  <circle
                    fill="#009870"
                    opacity="0.3"
                    cx="19.5"
                    cy="17.5"
                    r="2.5"
                  ></circle>
                </g>
              </svg>
              <span>{username}</span>
            </ProfileDataEmail>
            <Button
              bg="light"
              color="default"
              border="light"
              onClick={logout}
              type="button"
            >
              Logout
            </Button>
          </ProfileDataLogout>
        </ProfileData>
      </Profile>
      <ProfileDataLogoutMobile>
        <ProfileDataEmail>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <path
                d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                fill="#009870"
              ></path>
              <circle
                fill="#009870"
                opacity="0.3"
                cx="19.5"
                cy="17.5"
                r="2.5"
              ></circle>
            </g>
          </svg>
          <span>{username}</span>
        </ProfileDataEmail>
        <Button
          bg="light"
          color="default"
          border="light"
          onClick={logout}
          type="button"
        >
          Logout
        </Button>
      </ProfileDataLogoutMobile>

      <Separator />
      <ItemsContainer>
        <ItemContent to="/profile" onClick={() => setShowSideMenuRight("")}>
          <ItemIcon>
            <Icons type="profile" size={24} color="#007bff" />
          </ItemIcon>
          <ItemContentText>
            <ItemTitle>Meus Dados</ItemTitle>
            <ItemText>Configurações do usuário</ItemText>
          </ItemContentText>
        </ItemContent>
        <ItemContent to="/password" onClick={() => setShowSideMenuRight("")}>
          <ItemIcon>
            <Icons type="password" size={24} color="#fd7e14" />
          </ItemIcon>
          <ItemContentText>
            <ItemTitle>Minha Senha</ItemTitle>
            <ItemText>Alterar senha atual</ItemText>
          </ItemContentText>
        </ItemContent>
      </ItemsContainer>
    </Container>
  );
}
