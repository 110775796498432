import { TableContent } from "components/Table/style";
import styled from "styled-components";

export const Table = styled(TableContent)`
  width: 100%;
  th,
  td {
    :first-child {
      text-align: right;
    }
  }
`;
