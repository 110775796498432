import {
  WelcomeContent,
  WelcomeSubTitle,
  WelcomeText,
  WelcomeTitle,
} from "../style";

export const Welcome = () => (
  <WelcomeContent>
    <WelcomeTitle>Bem-vindos ao Connect</WelcomeTitle>
    <WelcomeSubTitle>
      Toda a produção <br />
      na palma da sua mão
    </WelcomeSubTitle>
    <WelcomeText>
      <b>A plataforma mais completa</b> para compilar e analisar, em rempo real,
      todos os setores da produção animal.{" "}
      <b>Conecta todas as fases da cadeia produtiva</b> em um só lugar,
      oferecendo tecnologia, agilidade e eficiência para acessar seus dados de
      qualquer lugar
    </WelcomeText>
  </WelcomeContent>
);
