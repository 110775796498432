import React, {
  useState,
  useCallback,
  createRef,
  useEffect,
  useContext,
} from "react";
import { useOutsideClick } from "services/outsideClick";
import { menuconfig } from "./menuConfig";
import Profile from "contexts/profile";
import isMobile from "is-mobile";
import {
  Container,
  Content,
  Dropdown,
  DropdownMenu,
  DropdownSubMenu,
  Li,
  LinkDropdown,
  LinkDropdownSub,
  LinkMenu,
  Span,
  Ul,
} from "./style";
import { ArrowIcon } from "components/ArrowIcon";
import { hasPermission } from "utils/globalFunctions";

export function MenuLeft({ location, setShowSideMenu, showSideMenu }) {
  const ref = createRef();

  const profile = useContext(Profile);

  const [showDropdownMenu, setShowDropdownMenu] = useState([]);
  const [showDropdownSubMenu, setShowDropdownSubMenu] = useState(
    menuconfig.map(() => [])
  );

  useOutsideClick(ref, () => {
    setShowDropdownMenu([]);
    setShowDropdownSubMenu(menuconfig.map(() => []));
    setShowSideMenu("");
  });

  const showDropdownHandler = useCallback(
    (index) => {
      setShowDropdownMenu((prevState) => {
        const response = [];
        response[index] = !prevState[index];
        return response;
      });
      if (menuconfig[index].subMenu) {
        menuconfig[index].subMenu.forEach((subItem, indexSub) => {
          if (
            location.pathname.split("/")[1] === menuconfig[index].module &&
            location.pathname.split("/")[2] === subItem.module
          ) {
            setShowDropdownSubMenu((prevState) => {
              const response = menuconfig.map(() => []);
              response[index][indexSub] = !prevState[index][indexSub];
              return response;
            });
          }
        });
      }
    },
    [location.pathname]
  );

  const showDropdownSubHandler = useCallback(
    (index, indexSub) =>
      setShowDropdownSubMenu((prevState) => {
        const response = menuconfig.map(() => []);
        response[index][indexSub] = !prevState[index][indexSub];
        return response;
      }),
    []
  );

  useEffect(() => {
    if (showSideMenu === "true") {
      menuconfig.forEach((item, index) => {
        if (location.pathname.split("/")[1] === item.module) {
          setShowDropdownMenu((prevState) => {
            const response = [];
            response[index] = !prevState[index];
            return response;
          });

          if (item.subMenu) {
            item.subMenu.forEach((subItem, indexSub) => {
              if (location.pathname.split("/")[2] === subItem.module) {
                setShowDropdownSubMenu((prevState) => {
                  const response = menuconfig.map(() => []);
                  response[index][indexSub] = !prevState[index][indexSub];
                  return response;
                });
              }
            });
          }
        }
      });
    }
  }, [showSideMenu, location]);

  return (
    <Container
      ref={ref}
      style={
        showSideMenu === "true"
          ? {
              left: 0,
            }
          : null
      }
    >
      <Content>
        <Ul>
          {menuconfig.map((item, index) =>
            hasPermission(profile, item.module, "accessOnly") ? (
              item.to ? (
                <Li
                  key={item.module + index}
                  onClick={() => {
                    setShowDropdownMenu([]);
                    setShowDropdownSubMenu(menuconfig.map(() => []));
                    setShowSideMenu("");
                  }}
                  ref={ref}
                >
                  <LinkMenu
                    isactive={(
                      location.pathname.split("/")[1] === item.module
                    ).toString()}
                    to={item.to}
                  >
                    <Span>{item.label}</Span>
                  </LinkMenu>
                </Li>
              ) : (
                <Li key={item.module} ref={ref}>
                  <Dropdown
                    onClick={() => showDropdownHandler(index)}
                    isactive={(
                      location.pathname.split("/")[1] === item.module
                    ).toString()}
                  >
                    <Span>{item.label}</Span>
                    <ArrowIcon show={showDropdownMenu[index]} />
                  </Dropdown>
                  <DropdownMenu
                    style={
                      isMobile()
                        ? null
                        : showDropdownMenu[index]
                        ? {
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            transform: "translate(2px, 39px)",
                            top: "21px",
                            display: "block",
                          }
                        : {
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            margin: "0px",
                            opacity: "0",
                            pointerEvents: "none",
                          }
                    }
                  >
                    {item.subMenu.map((itemDropdown, indexDropdown) =>
                      hasPermission(
                        profile,
                        itemDropdown.module,
                        "accessOnly"
                      ) ? (
                        itemDropdown.to ? (
                          <LinkDropdown
                            key={itemDropdown.module}
                            to={itemDropdown.to}
                            onClick={() => {
                              setShowDropdownMenu([]);
                              setShowDropdownSubMenu(menuconfig.map(() => []));
                              setShowSideMenu("");
                            }}
                            isselected={showDropdownMenu[index]?.toString()}
                            isactive={(
                              location.pathname.split("/")[1] === item.module &&
                              location.pathname.split("/")[2] ===
                                itemDropdown.module
                            ).toString()}
                          >
                            {itemDropdown.label}
                          </LinkDropdown>
                        ) : (
                          <React.Fragment key={itemDropdown.module}>
                            <DropdownSubMenu
                              isselected={showDropdownMenu[index]?.toString()}
                              isactive={(
                                location.pathname.split("/")[1] ===
                                  item.module &&
                                location.pathname.split("/")[2] ===
                                  itemDropdown.module
                              ).toString()}
                              onClick={() =>
                                showDropdownSubHandler(index, indexDropdown)
                              }
                            >
                              <span>{itemDropdown.label}</span>
                              <ArrowIcon
                                show={showDropdownSubMenu[index][indexDropdown]}
                              />
                            </DropdownSubMenu>
                            {itemDropdown.subMenu.map((itemSubmenu) =>
                              hasPermission(
                                profile,
                                itemSubmenu.module,
                                "accessOnly"
                              ) ? (
                                <LinkDropdownSub
                                  key={itemSubmenu.module}
                                  to={itemSubmenu.to}
                                  onClick={() => {
                                    setShowDropdownSubMenu(
                                      menuconfig.map(() => [])
                                    );
                                    setShowDropdownMenu([]);
                                    setShowSideMenu("");
                                  }}
                                  isselected={showDropdownSubMenu[index][
                                    indexDropdown
                                  ]?.toString()}
                                  isactive={(
                                    location.pathname.split("/")[1] ===
                                      item.module &&
                                    location.pathname.split("/")[2] ===
                                      itemDropdown.module &&
                                    location.pathname.split("/")[3] ===
                                      itemSubmenu.module
                                  ).toString()}
                                >
                                  {itemSubmenu.label}
                                </LinkDropdownSub>
                              ) : null
                            )}
                          </React.Fragment>
                        )
                      ) : null
                    )}
                  </DropdownMenu>
                </Li>
              )
            ) : null
          )}
        </Ul>
      </Content>
    </Container>
  );
}
