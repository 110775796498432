import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 auto;
`;

export const Content = styled.div`
  @media (max-width: 1024px) {
    padding: 15px;
  }
  @media (min-width: 1025px) {
    padding: 30px;
  }
  @media (min-width: 1439px) {
    width: 1380px;
    margin: 0 auto;
  }
`;
