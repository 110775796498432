import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Input } from "components/Input";
export function CompleteSlaughter({ checklist, setChecklist }) {
  return (
    <>
      <Card>
        <CardHeader>Espera caminhões</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                params="waitTrucks.fans"
                type="select"
                label="Ventiladores "
                placeholder="Selecione uma Opção"
                options={[{ value: "Sim" }, { value: "Não" }]}
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                params="waitTrucks.nebulization"
                type="select"
                label="Nebulização"
                placeholder="Selecione uma Opção"
                options={[{ value: "Sim" }, { value: "Não" }]}
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="waitTrucks.waitTime"
                label="Tempo de Espera"
                placeholder="Digite o tempo de espera"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="waitTrucks.covered"
                label="Coberto"
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                params="waitTrucks.tempAndHumidityThermometer"
                type="select"
                label="Termômetro temp. e umi"
                placeholder="Selecione uma Opção"
                options={[{ value: "Sim" }, { value: "Não" }]}
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="waitTrucks.monitoringInsideBoxes"
                label="Monitoramento nas Dentro das Caixas"
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                params="waitTrucks.wetTruck"
                type="select"
                label="Caminhão molhado"
                placeholder="Selecione uma Opção"
                options={[{ value: "Sim" }, { value: "Não" }]}
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="waitTrucks.percMortality"
                label="% Mortalidade"
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="waitTrucks.totalFastingPeriod"
                label="Período Total de Jejum"
                placeholder=""
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Abate Insensibilização</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="stunning.curveInTheLines"
                label="Curvar nas Linhas "
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="stunning.lowFrequency"
                label="Frequência Baixa 50 60 Hz"
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="stunning.highFrequency"
                label="Frequência alta 200 a 1500 Hz"
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="stunning.resistanceInOhms"
                label="Resistência em Ohms (água) 1000 a 2600 Ohms"
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="stunning.mAPerBird"
                label="mA por ave 100 a 200 mA/ave"
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="stunning.presentsTonicPhaseOfBirds"
                label="Apresenta fase tônica das aves"
                placeholder=""
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Abate Escaldagem</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="scalding.scaldingTime"
                label="Tempo de escaldagem"
                placeholder=""
              />
            </Col>
            <Col>
              <Input
                item={checklist}
                setItem={setChecklist}
                type="input"
                params="scalding.waterTemperature"
                label="Temperatura da água"
                placeholder=""
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
