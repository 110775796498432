import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function EssencialOils(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "50px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th>
            <span>Qual Finalidade?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Cria</td>
          <td>
            <Input
              type="selectOnly"
              params="additions.essencialOils.create.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter((item) => item.key === "additionsEssencialOilsProduct")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.essencialOils.create.reason"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Recria</td>
          <td>
            <Input
              type="selectOnly"
              params="additions.essencialOils.recreate.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter((item) => item.key === "additionsEssencialOilsProduct")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.essencialOils.recreate.reason"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Produção</td>
          <td>
            <Input
              type="selectOnly"
              params="additions.essencialOils.production.product"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Selecione o Produto"
              options={props.config
                .filter((item) => item.key === "additionsEssencialOilsProduct")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.essencialOils.production.reason"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
