import { useCallback, useEffect, useState } from "react";
import { Icons } from "components/Icons";
import { Image } from "components/Image";
import { SubHeader } from "components/SubHeader";
import { Table } from "components/Table";
import { api } from "services/api";
import { CreateEdit } from "../CreateEdit";

export function List(props) {
  const tableParams = [
    {
      key: "date",
      label: "Data",
      filter: "date",
      type: "date",
      mobile: {
        type: "title",
      },
    },
    {
      key: "dailySlaughter",
      label: "Abates Diários",

      filter: "range",
      placeholder: "Abates Diários",
      mobile: {
        type: "item",
      },
    },
    {
      key: "healthScore",
      label: "Escore Sanitário",
      placeholder: "Escore Sanitário",
      filter: "select",
      options: [
        { value: "Excelente" },
        { value: "Bom" },
        { value: "Mediano" },
        { value: "Ruim" },
        { value: "Péssimo" },
      ],
      mobile: {
        type: "item",
      },
    },
  ];

  const [modalShow, setModalShow] = useState("");

  const [historicId, setHistoricId] = useState(null);

  const [customerName, setCustomerName] = useState("");

  const handleModal = useCallback(async (item) => {
    setHistoricId(item._id);
    setModalShow("megg");
  }, []);

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
    };
    getCustomerName();
  }, [props.match.params.id]);

  return (
    <>
      {modalShow && (
        <CreateEdit
          show={modalShow}
          setShow={setModalShow}
          customer={props.match.params.id}
          id={historicId}
          onHide={() => {
            setModalShow(false);
            setHistoricId(null);
          }}
          location={props.location}
        />
      )}
      <SubHeader
        {...props}
        title={customerName}
        route="megghistoric"
        breadcrumbs={[
          {
            label: customerName,
            to: "/customer/" + props.match.params.id,
          },
          {
            label: "Megg",
          },
          {
            label: "Cria-Recria",
          },
          { label: "Histórico de Programas" },
        ]}
        icon={<Icons type="megg" size="24" color="#fff" />}
        otherButtons={[
          <Image
            src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_megg_small.png"
            style={{ height: 45 }}
          />,
        ]}
      />
      <Table
        cols={tableParams}
        editHandler={handleModal}
        emptyText={"Nenhum programa localizado!"}
        route={`/megghistoric/list`}
        {...props}
      />
    </>
  );
}
