import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Table } from "components/Table";
import { Body, Header, TableContent } from "components/Table/style";
import { ViewLoader } from "components/ViewLoader";
import { api } from "services/api";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { convertDateToString } from "utils/globalFunctions";
import { Evaluation } from "./Evaluation";
import UpdateList from "contexts/updateList";

export function FactoryRegistration(props) {
  const { setUpdateList } = useContext(UpdateList);

  const tableParams = [
    {
      key: "initialDescription",
      params: "technician.name",
      label: "Técnico Responsável",
      filter: "text",
      placeholder: "Digite a nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "initialDescription",
      params: "visitDate",
      label: "Data da Visita",
      filter: "date",
      type: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "initialDescription",
      params: "temperature",
      label: "Temperatura média últimos 7 dias",
      type: "number",
      filter: "range",
      mobile: {
        type: "item",
      },
    },
  ];

  const [data, setData] = useState({
    city: "",
    unit: "",
    monthlyProduction: "",
    date: "",
    technicalManager: null,
    oil: "",
    pelletized: {
      preInitial: "",
      initial: "",
      growth1: "",
      growth2: "",
      slaughter: "",
    },
  });

  const [getFactory, setGetFactory] = useState(true);

  const [loading, setLoading] = useState(true);

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const editEvaluationHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(true);
  }, []);

  const saveFactory = useCallback(async () => {
    if (!props.match.params.id) {
      const response = await Swal.fire({
        title: "Criar Fábrica",
        text: "Deseja confirmar criação da Fábrica",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Fábrica!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post("factory/" + props.match.params.customer, data)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Fábrica",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          props.history.replace(
            `/customer/factory/${props.match.params.customer}/${props.match.params.page}` +
              `/${props.match.params.limit}/${props.match.params.sort}/${props.match.params.query}` +
              `/${props.match.params.tab}/${response.value.data.id}`
          );
          setGetFactory(true);
        }
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Fábrica",
        text: "Deseja confirmar edição da Fábrica",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Fábrica!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(
              `factory/${props.match.params.customer}/${props.match.params.id}`,
              data
            )
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Fábrica",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetFactory(true);
      }
    }
  }, [data, props]);

  useEffect(() => {
    const loadFactory = async () => {
      if (props.match.params.id) {
        setLoading(true);
        const response = await api.get(
          `factory/${props.match.params.customer}/${props.match.params.id}/`
        );
        if (!response.data) {
          Swal.fire(
            "Editar Fábrica",
            "Não foi possível buscar a Fábrica",
            "error"
          );
        }
        response.data.date = convertDateToString(response.data.date);

        setData(response.data);
      }
      setLoading(false);
    };
    if (getFactory) {
      setGetFactory(false);
      loadFactory();
    }
  }, [props.match, getFactory]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <>
        {modalShow && (
          <Evaluation
            show={modalShow}
            setShow={setModalShow}
            location={props.location}
            id={id}
            match={props.match}
            setUpdateList={setUpdateList}
          />
        )}
        <Row>
          <Col>
            <Input
              item={data}
              setItem={setData}
              params="city"
              type="input"
              label="Cidade"
            />
          </Col>
          <Col>
            <Input
              item={data}
              setItem={setData}
              params="unit"
              type="input"
              label="Unidade"
            />
          </Col>
          <Col>
            <Input
              item={data}
              setItem={setData}
              params="monthlyProduction"
              type="input"
              inputType="number"
              label="Produção Mensal (Ton/Mês)"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              item={data}
              setItem={setData}
              params="oil"
              type="select"
              label="Injeção de oleo pós pellet"
              placeholder="Selecione uma opção"
              options={[{ value: "Sim" }, { value: "Não" }]}
            />
          </Col>
          <Col>
            <Input
              item={data}
              setItem={setData}
              params={`technicalManager`}
              type="autocomplete"
              label={"Técnico"}
              paramsGet={["code", "name"]}
              paramsLabel={["code", "name"]}
              select={"code name"}
              model={"user"}
              placeholder={"Digite o Código ou o nome do Técnico"}
            />
          </Col>
          <Col>
            <Input
              item={data}
              setItem={setData}
              params="date"
              type="input"
              label="Data de Cadastro"
              disabled="true"
            />
          </Col>
        </Row>
        <Card>
          <CardHeader style={{ justifyContent: "start" }}>
            Ração peletizada
          </CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th>Pré Inicial</th>
                  <th>Inicial</th>
                  <th>Crescimento 1</th>
                  <th>Crescimento 2</th>
                  <th>Abate</th>
                </tr>
              </Header>
              <Body>
                <tr>
                  <td>
                    <Input
                      item={data}
                      setItem={setData}
                      params="pelletized.preInitial"
                      type="select"
                      placeholder="Selecione uma opção"
                      options={[{ value: "Sim" }, { value: "Não" }]}
                    />
                  </td>
                  <td>
                    <Input
                      item={data}
                      setItem={setData}
                      params="pelletized.initial"
                      type="select"
                      placeholder="Selecione uma opção"
                      options={[{ value: "Sim" }, { value: "Não" }]}
                    />
                  </td>
                  <td>
                    <Input
                      item={data}
                      setItem={setData}
                      params="pelletized.growth1"
                      type="select"
                      placeholder="Selecione uma opção"
                      options={[{ value: "Sim" }, { value: "Não" }]}
                    />
                  </td>
                  <td>
                    <Input
                      item={data}
                      setItem={setData}
                      params="pelletized.growth2"
                      type="select"
                      placeholder="Selecione uma opção"
                      options={[{ value: "Sim" }, { value: "Não" }]}
                    />
                  </td>
                  <td>
                    <Input
                      item={data}
                      setItem={setData}
                      params="pelletized.slaughter"
                      type="select"
                      placeholder="Selecione uma opção"
                      options={[{ value: "Sim" }, { value: "Não" }]}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="white"
                        bg="default"
                        border="default"
                        onClick={saveFactory}
                      >
                        Salvar Cadastro de Fábrica
                      </Button>
                    </div>
                  </td>
                </tr>
              </Body>
            </TableContent>
          </CardBody>
        </Card>
        {props.match.params.id && (
          <Card>
            <CardHeader>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                Avaliação da fábrica e produtos em estoque
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  size="sm"
                  onClick={() => setModalShow(true)}
                >
                  Nova Avaliação
                </Button>
              </div>
            </CardHeader>

            <CardBody>
              <Table
                noLoad={true}
                cols={tableParams}
                editHandler={editEvaluationHandler}
                emptyText={"Nenhuma Avaliação localizada!"}
                route={`/factoryevaluation/${props.match.params.id}`}
                {...props}
              />
            </CardBody>
          </Card>
        )}
      </>
    </>
  );
}
