import styled from "styled-components";
//import bg from "utils/images/header4.png";

export const Container = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_azul_escuro_ajustado_para_claro.png");
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
`;
