import { createRef, useEffect, useState } from "react";
import { isAuthenticated, logout } from "services/auth";
import Profile from "contexts/profile";
import UpdateListContext from "contexts/updateList";
import Refs from "contexts/refs";
import { Redirect } from "react-router-dom";
import { Container, Content } from "./style";
import { Header } from "./Common/Header";
import { HeaderMobile } from "./Common/HeaderMobile";
import { SideMenuRight } from "./Common/Header/MenuRight/SideMenuRight";
import { api } from "services/api";
import { Footer } from "./Common/Footer";
import { Routes } from "./Routes";

export function App(props) {
  const [profile, setProfile] = useState({});

  const [showSideMenu, setShowSideMenu] = useState("");

  const [showSideMenuRight, setShowSideMenuRight] = useState("");

  const [showTopBar, setShowTopBar] = useState("");

  const [updateList, setUpdateList] = useState(false);

  const refSide = createRef();

  useEffect(() => {
    const loadUser = async () => {
      const response = await api.get("/auth/userprofile").catch((err) => err);
      if (!response.data) {
        return logout();
      }

      setProfile(response.data);
    };
    loadUser();
  }, []);

  return isAuthenticated() ? (
    <Refs.Provider value={refSide}>
      <Profile.Provider value={profile}>
        <UpdateListContext.Provider
          value={{
            updateList,
            setUpdateList,
          }}
        >
          <Container>
            <HeaderMobile
              setShowSideMenu={setShowSideMenu}
              setShowTopBar={setShowTopBar}
            />
            <Content>
              <Header
                refSide={refSide}
                {...props}
                showSideMenu={showSideMenu}
                setShowSideMenu={setShowSideMenu}
                setShowTopBar={setShowTopBar}
                showTopBar={showTopBar}
                showSideMenuRight={showSideMenuRight}
                setShowSideMenuRight={setShowSideMenuRight}
              />
              <Routes />
              <Footer />
            </Content>
          </Container>
          <SideMenuRight
            showSideMenuRight={showSideMenuRight}
            setShowSideMenuRight={setShowSideMenuRight}
          />
        </UpdateListContext.Provider>
      </Profile.Provider>
    </Refs.Provider>
  ) : (
    <Redirect to="/login" />
  );
}
