import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function Organics(props) {
  return (
    <>
      <div
        style={{
          textAlign: "end",
          marginRight: "40px",
        }}
      >
        <Input
          type="checkbox"
          params="minerals.organics.unknownConcept"
          item={props.heavyBroiler}
          setItem={props.setHeavyBroiler}
          label="Cliente não conhece o conceito"
          className="kt-checkbox kt-checkbox-right mt-2"
        />
      </div>
      <Table>
        <Header>
          <tr>
            <th style={{ width: "50px" }}>
              <span></span>
            </th>
            <th>
              <span>Qual Produto?</span>
            </th>
            <th>
              <span>Qual Finalidade?</span>
            </th>
          </tr>
        </Header>
        <Body>
          <tr>
            <td>Cria</td>
            <td>
              <Input
                type="selectOnly"
                params="minerals.organics.create.product"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder="Selecione o Produto"
                options={props.config
                  .filter((item) => item.key === "mineralsOrganicsProduct")
                  .map((item) => ({ value: item.description }))}
              />
            </td>
            <td>
              <Input
                type="inputOnly"
                params="minerals.organics.create.reason"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
              />
            </td>
          </tr>
          <tr>
            <td>Recria</td>
            <td>
              <Input
                type="selectOnly"
                params="minerals.organics.recreate.product"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder="Selecione o Produto"
                options={props.config
                  .filter((item) => item.key === "mineralsOrganicsProduct")
                  .map((item) => ({ value: item.description }))}
              />
            </td>
            <td>
              <Input
                type="inputOnly"
                params="minerals.organics.recreate.reason"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
              />
            </td>
          </tr>
          <tr>
            <td>Produção</td>
            <td>
              <Input
                type="selectOnly"
                params="minerals.organics.production.product"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
                placeholder="Selecione o Produto"
                options={props.config
                  .filter((item) => item.key === "mineralsOrganicsProduct")
                  .map((item) => ({ value: item.description }))}
              />
            </td>
            <td>
              <Input
                type="inputOnly"
                params="minerals.organics.production.reason"
                item={props.heavyBroiler}
                setItem={props.setHeavyBroiler}
              />
            </td>
          </tr>
        </Body>
      </Table>
    </>
  );
}
