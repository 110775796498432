import { useEffect, useState } from "react";
import isMobile from "is-mobile";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Body, Header, TableContent } from "components/Table/style";
import { Input } from "components/Input";
import { api } from "services/api";

export function Customer({
  preNecropsy,
  setPreNecropsy,
  loadHistoric,
  setLoadHistoric,
  disableCustomer,
}) {
  const [historic, setHistoric] = useState({});

  useEffect(() => {
    const getCustomerHistoric = async () => {
      setLoadHistoric(false);
      const response = await api.get(
        `hbprenecropsy/historic/${preNecropsy.customer._id}`
      );
      setHistoric(response.data);
    };
    if (loadHistoric) {
      getCustomerHistoric();
    }
  }, [preNecropsy.customer, setLoadHistoric, loadHistoric]);

  return (
    <>
      {!disableCustomer && (
        <Row>
          <Col>
            <Input
              item={preNecropsy}
              setItem={setPreNecropsy}
              params="customer"
              type="autocomplete"
              disabled={!!disableCustomer}
              label="Cliente"
              paramsGet={["code", "name"]}
              paramsLabel={["code", "name", "state", "city"]}
              select="code name state city"
              model="customer"
              placeholder="Digite o nome ou o código do cliente e selecione"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <h3>
            Programa em Andamento{" "}
            {disableCustomer ? ` - ${preNecropsy.customer?.name}` : ""}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: "15px" }}>
          <Card>
            <CardHeader>Saúde</CardHeader>
            <CardBody>
              <TableContent>
                <Header>
                  <tr>
                    <th style={{ width: isMobile() ? "50px" : "130px" }}>
                      Fase
                    </th>
                    {isMobile() ? (
                      <th className="text-primary">Programa</th>
                    ) : (
                      <>
                        <th className="text-info">Melhorador de desempenho</th>
                        <th className="text-info">Dose PPM</th>
                      </>
                    )}
                  </tr>
                </Header>
                <Body>
                  <tr>
                    <td>Pré</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.health?.performanceEnhancer?.pre
                                ?.product
                            }
                            : {historic?.health?.performanceEnhancer?.pre?.dose}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-info">
                          {historic?.health?.performanceEnhancer?.pre?.product}
                        </td>
                        <td className="text-info">
                          {historic?.health?.performanceEnhancer?.pre?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Inicial</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.health?.performanceEnhancer?.initial
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.health?.performanceEnhancer?.initial
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-info">
                          {
                            historic?.health?.performanceEnhancer?.initial
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {historic?.health?.performanceEnhancer?.initial?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.1" : "Crescimento 1"}</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.health?.performanceEnhancer?.growth
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.health?.performanceEnhancer?.growth
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-info">
                          {
                            historic?.health?.performanceEnhancer?.growth
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {historic?.health?.performanceEnhancer?.growth?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cresc.2" : "Crescimento 2"}</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.health?.performanceEnhancer?.growth2
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.health?.performanceEnhancer?.growth2
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-info">
                          {
                            historic?.health?.performanceEnhancer?.growth2
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {historic?.health?.performanceEnhancer?.growth2?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Final</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.health?.performanceEnhancer?.final
                                ?.product
                            }
                            :{" "}
                            {historic?.health?.performanceEnhancer?.final?.dose}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-info">
                          {
                            historic?.health?.performanceEnhancer?.final
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {historic?.health?.performanceEnhancer?.final?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                </Body>
              </TableContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>Aditivos</CardHeader>
            <CardBody>
              <TableContent>
                <Header>
                  <tr>
                    <th style={{ width: isMobile() ? "50px" : "130px" }}>
                      Fase
                    </th>
                    {isMobile() ? (
                      <th className="text-primary">Programa</th>
                    ) : (
                      <>
                        <th className="text-primary">Ácidos orgânicos</th>
                        <th className="text-primary">Dose</th>
                        <th className="text-dark">Óleos essenciais</th>
                        <th className="text-dark">Dose</th>
                        <th className="text-danger">Probióticos</th>
                        <th className="text-danger">Dose</th>
                        <th className="text-info">Prebioticos</th>
                        <th className="text-info">Dose</th>
                        <th className="text-warning">
                          Adsorvente de micotoxinas
                        </th>
                        <th className="text-warning">Dose</th>
                      </>
                    )}
                  </tr>
                </Header>
                <Body>
                  <tr>
                    <td>Pré</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {historic?.additions?.organicAcids?.pre?.product}:{" "}
                            {historic?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.essencialOils?.pre?.product}:{" "}
                            {historic?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.probiotics?.pre?.product}:{" "}
                            {historic?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.prebiotics?.pre?.product}:{" "}
                            {historic?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.pre?.product}
                        </td>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.pre?.dose}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.pre?.product}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.pre?.dose}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.pre?.product}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.pre?.dose}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.pre?.product}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.pre?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.pre
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {historic?.additions?.mycotoxinAdsorbents?.pre?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Inicial</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {historic?.additions?.organicAcids?.pre?.product}:{" "}
                            {historic?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.essencialOils?.pre?.product}:{" "}
                            {historic?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.probiotics?.pre?.product}:{" "}
                            {historic?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.prebiotics?.pre?.product}:{" "}
                            {historic?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.initial?.product}
                        </td>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.initial?.dose}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.initial?.product}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.initial?.dose}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.initial?.product}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.initial?.dose}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.initial?.product}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.initial?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.initial
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.initial
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.1" : "Crescimento 1"}</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {historic?.additions?.organicAcids?.pre?.product}:{" "}
                            {historic?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.essencialOils?.pre?.product}:{" "}
                            {historic?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.probiotics?.pre?.product}:{" "}
                            {historic?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.prebiotics?.pre?.product}:{" "}
                            {historic?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.growth?.product}
                        </td>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.growth?.dose}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.growth?.product}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.growth?.dose}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.growth?.product}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.growth?.dose}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.growth?.product}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.growth?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.growth
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.growth
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.2" : "Crescimento 2"}</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {historic?.additions?.organicAcids?.pre?.product}:{" "}
                            {historic?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.essencialOils?.pre?.product}:{" "}
                            {historic?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.probiotics?.pre?.product}:{" "}
                            {historic?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.prebiotics?.pre?.product}:{" "}
                            {historic?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.growth2?.product}
                        </td>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.growth2?.dose}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.growth2?.product}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.growth2?.dose}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.growth2?.product}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.growth2?.dose}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.growth2?.product}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.growth2?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.growth2
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.growth2
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Final</td>
                    {isMobile() ? (
                      <td>
                        <div className=" text-left">
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {historic?.additions?.organicAcids?.pre?.product}:{" "}
                            {historic?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.essencialOils?.pre?.product}:{" "}
                            {historic?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.probiotics?.pre?.product}:{" "}
                            {historic?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {historic?.additions?.prebiotics?.pre?.product}:{" "}
                            {historic?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              historic?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.final?.product}
                        </td>
                        <td className="text-primary">
                          {historic?.additions?.organicAcids?.final?.dose}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.final?.product}
                        </td>
                        <td className="text-dark">
                          {historic?.additions?.essencialOils?.final?.dose}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.final?.product}
                        </td>
                        <td className="text-danger">
                          {historic?.additions?.probiotics?.final?.dose}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.final?.product}
                        </td>
                        <td className="text-info">
                          {historic?.additions?.prebiotics?.final?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.final
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            historic?.additions?.mycotoxinAdsorbents?.final
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                </Body>
              </TableContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
