import { Body, Header } from "components/Table/style";
import { TableContent } from "components/Table/style";
import { Row } from "./Row";
import { Button } from "components/Button";
import { useEffect, useState } from "react";
import { Input } from "components/Input";
import styled from "styled-components";
import { Icons } from "components/Icons";

const InputText = styled.input`
  display: block;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 30px;
`;

export function LevelsTab({ levels, setLevels, columns }) {
  const [levelName, setLevelName] = useState("");
  const [selectedRows, setSelectedRows] = useState({});

  const addLevel = () => {
    if (!levelName.trim()) return;
    const filter = levels.paths?.filter((path) => path.custom);
    const rowName = `newLevel${filter.length + 1}`;
    setLevels((prevLevels) => {
      const newLevels = { ...prevLevels, [rowName]: { ...columns } };
      newLevels.paths = [
        ...prevLevels.paths,
        { title: levelName, row: rowName, custom: true },
      ];
      return newLevels;
    });
    setLevelName("");
  };

  const setName = (e) => {
    setLevelName(e.target.value);
  };

  useEffect(() => {
    setSelectedRows((prevState) => {
      const newSelectedRows = {};
      levels.paths.forEach((path, index) => {
        newSelectedRows[index] = prevState[index] ?? false;
      });
      return newSelectedRows;
    });
  }, [levels.paths]);

  const toggleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelectedRows = {};
    levels.paths.forEach((_, index) => {
      newSelectedRows[index] = isChecked;
    });
    setSelectedRows(newSelectedRows);
    console.log(newSelectedRows);
  };

  const toggleSelect = (index) => {
    setSelectedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const deleteSelectedRows = () => {
    setLevels((prevLevels) => {
      const newPaths = prevLevels.paths.filter(
        (_, index) => !selectedRows[index]
      );
      const newLevels = {
        ...prevLevels,
        paths: newPaths,
      };

      newPaths.forEach((path) => {
        delete newLevels[path.row];
      });

      return newLevels;
    });
  };

  return (
    <>
      <TableContent>
        <Header>
          <tr>
            <th>
              <Input
                type="checkbox"
                onChange={toggleSelectAll}
                checked={
                  Object.keys(selectedRows).length > 0 &&
                  Object.values(selectedRows).every(Boolean)
                }
              />
            </th>
            <th>
              <span>Níves/Fases</span>
            </th>
            <th>
              <span>Pré-inicial</span>
            </th>
            <th>
              <span>Inicial</span>
            </th>
            <th>
              <span>Crescimento</span>
            </th>
            <th>
              <span>Engorda</span>
            </th>
            <th>
              <span>Abate</span>
            </th>
            <th>
              <Icons
                type="trash"
                size="24"
                color="#7E8299"
                onClick={deleteSelectedRows}
              />
            </th>
          </tr>
        </Header>
        <Body>
          {levels?.paths?.map((path, index) => (
            <Row
              key={index}
              index={index}
              path={path}
              levels={levels}
              setLevels={setLevels}
              weightedAverage={false}
              showCheckbox={true}
              selected={selectedRows[index]}
              toggleSelect={() => toggleSelect(index)}
            />
          ))}

          <tr>
            <td colSpan="4">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <InputText
                  type="input"
                  placeholder="Novo nutriente"
                  value={levelName}
                  onChange={setName}
                />
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  style={{ flexShrink: 0 }}
                  onClick={addLevel}
                >
                  Adicionar nível
                </Button>
              </div>
            </td>
          </tr>
          <Row
            path={{ title: "Preço Médio / Fase", row: "averagePricePerPhase" }}
            levels={levels}
            setLevels={setLevels}
            weightedAverage={true}
            showCheckbox={false}
          />
        </Body>
      </TableContent>
    </>
  );
}
