import React, { useCallback, useMemo } from "react";
import { getScoreColor, getScoreText, getColor } from "utils/globalFunctions";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Input } from "components/Input";
import { TitleGPD } from "../style";
import { necropsyConfig } from "utils/globalData";

export function ScoreTotal({ necropsy, setNecropsy }) {
  const gpdConfig = useMemo(
    () => [
      {
        w1: necropsy.weight0,
        w2: necropsy.weight1,
        indexAge: 7,
      },
      {
        w1: necropsy.weight1,
        w2: necropsy.weight2,
        indexAge: 14,
      },
      {
        w1: necropsy.weight2,
        w2: necropsy.weight3,
        indexAge: 21,
      },
      {
        w1: necropsy.weight3,
        w2: necropsy.weight4,
        indexAge: 28,
      },
      {
        w1: necropsy.weight4,
        w2: necropsy.weight5,
        indexAge: 35,
      },
      {
        w1: necropsy.weight5,
        w2: necropsy.weight6,
        indexAge: 42,
      },
    ],
    [necropsy]
  );

  const getScoreGPD = useCallback(
    (w1, w2, indexAge, indexLoop) => {
      try {
        const startWeekIndex =
          indexLoop === 0 ? 1 : gpdConfig[indexLoop - 1].indexAge;

        let gpds = 0;
        for (let i = startWeekIndex; i <= indexAge; i++) {
          gpds +=
            necropsyConfig.others[necropsy.lineage][i].weight -
            necropsyConfig.others[necropsy.lineage][i - 1].weight;
        }
        gpds = gpds / 7;

        const response = parseFloat((((w2 - w1) / 7 / gpds) * 10).toFixed(2));

        return response > 10 ? 10 : response < 0 ? 0 : response;
      } catch (e) {
        return 0;
      }
    },
    [gpdConfig, necropsy.lineage]
  );

  return (
    <>
      <Row>
        {necropsy.birds.map((bird, index) => (
          <Col key={index}>
            <Card>
              <CardHeader>Ave {index + 1}</CardHeader>
              <CardBody>
                <Chart
                  options={{
                    plotOptions: {
                      radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                          margin: 0,
                          size: "70%",
                          background: "#f9f9fc ",
                          image: undefined,
                          imageOffsetX: 0,
                          imageOffsetY: 0,
                          position: "front",
                        },
                        track: {
                          background: getColor(
                            getScoreColor(necropsy.birds[index].total),
                            0.2
                          ),
                          strokeWidth: "67%",
                          margin: 0, // margin is in pixels
                        },

                        dataLabels: {
                          showOn: "always",
                          name: {
                            offsetY: -15,
                            show: true,
                            color: getScoreColor(necropsy.birds[index].total),
                            fontSize:
                              getScoreText(necropsy.birds[index].total) ===
                              "Muita Atenção"
                                ? "9px"
                                : "13px",
                          },
                          value: {
                            formatter: function (val) {
                              return (
                                val / 10 < 0 ? 0 : val / 10
                              ).toLocaleString();
                            },
                            color: getScoreColor(necropsy.birds[index].total),
                            fontSize: "20px",
                            show: true,
                            offsetY: 0,
                          },
                        },
                      },
                    },
                    fill: {
                      colors: [getScoreColor(necropsy.birds[index].total)],
                      type: "gradient",
                      gradient: {
                        shade: "dark",
                        type: "horizontal",

                        gradientToColors: [
                          getScoreColor(necropsy.birds[index].total),
                        ],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 0.8,
                        stops: [0, 100],
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: [getScoreText(necropsy.birds[index].total)],
                  }}
                  series={[
                    necropsy.birds[index].total * 10 > 0
                      ? necropsy.birds[index].total * 10
                      : 0,
                  ]}
                  type="radialBar"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <TitleGPD>Score GPD</TitleGPD>
      <Row>
        {gpdConfig.map(({ w1, w2, indexAge }, index) => (
          <Col key={indexAge}>
            <Card>
              <CardHeader>Peso {indexAge} Dias</CardHeader>
              <CardBody>
                <Chart
                  options={{
                    plotOptions: {
                      radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                          margin: 0,
                          size: "70%",
                          background: "#f9f9fc ",
                          image: undefined,
                          imageOffsetX: 0,
                          imageOffsetY: 0,
                          position: "front",
                        },
                        track: {
                          background: getColor(
                            getScoreColor(getScoreGPD(w1, w2, indexAge, index)),
                            0.2
                          ),
                          strokeWidth: "67%",
                          margin: 0, // margin is in pixels
                        },

                        dataLabels: {
                          showOn: "always",
                          name: {
                            offsetY: -5,
                            show: true,
                            color: getScoreColor(
                              getScoreGPD(w1, w2, indexAge, index)
                            ),
                            fontSize:
                              getScoreText(
                                getScoreGPD(w1, w2, indexAge, index)
                              ) === "Muita Atenção"
                                ? "4px"
                                : "9px",
                          },
                          value: {
                            formatter: function (val) {
                              return (
                                val / 10 < 0 ? 0 : val / 10
                              ).toLocaleString();
                            },
                            color: getScoreColor(
                              getScoreGPD(w1, w2, indexAge, index)
                            ),
                            fontSize: "12px",
                            show: true,
                            offsetY: -5,
                          },
                        },
                      },
                    },
                    fill: {
                      colors: [
                        getScoreColor(getScoreGPD(w1, w2, indexAge, index)),
                      ],
                      type: "gradient",
                      gradient: {
                        shade: "dark",
                        type: "horizontal",

                        gradientToColors: [
                          getScoreColor(getScoreGPD(w1, w2, indexAge, index)),
                        ],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 0.8,
                        stops: [0, 100],
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: [
                      getScoreText(getScoreGPD(w1, w2, indexAge, index)),
                    ],
                  }}
                  series={[
                    getScoreGPD(w1, w2, indexAge, index) * 10 > 0
                      ? getScoreGPD(w1, w2, indexAge, index) * 10
                      : 0,
                  ]}
                  type="radialBar"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <Input
            type="textarea"
            item={necropsy}
            setItem={setNecropsy}
            rows={6}
            params="obs"
            label="Observação"
          />
        </Col>
      </Row>
    </>
  );
}
