import { convertDateToString, getScoreColor } from "utils/globalFunctions";
import {
  Block,
  BlockBody,
  BlockHeader,
  Card,
  CardBody,
  CardHeader,
} from "styles";
import { Body, Header, TableContent } from "components/Table/style";
import React from "react";
function HistoricTable({ historic, heavyBroiler, heavyBroilerList }) {
  const getCards = () => {
    const colors = ["primary", "info", "danger", "dark", "warning"];
    let i = -1;
    return heavyBroilerList.map((item, index) => {
      if (i > 3) {
        i = 0;
      } else {
        i++;
      }
      return (
        <React.Fragment key={index}>
          <Card>
            <CardHeader>
              <strong>
                {heavyBroiler.filter(({ value }) => value === item)[0].label}
              </strong>
            </CardHeader>
            <CardBody>
              <TableContent>
                <Header>
                  <tr>
                    <th className=" text-right" style={{ width: "130px" }}>
                      Fase
                    </th>
                    {historic
                      .filter((historicItem) => !!historicItem)
                      .map((historicItem, index) => (
                        <th
                          className={` text-${colors[i]}`}
                          key={historicItem._id + index}
                        >
                          {`Semana ${historicItem.age} (${convertDateToString(
                            historicItem.date
                          ).replace(/\//g, "-")})`}
                        </th>
                      ))}
                  </tr>
                </Header>
                <Body>
                  <tr>
                    <td className=" text-right">Cria</td>
                    {historic
                      .filter((historicItem) => !!historicItem)
                      .map((historicItem, index) => (
                        <td className={` text-${colors[i]}`} key={index}>
                          {
                            historicItem?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.create?.product
                          }
                          /
                          {
                            historicItem?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.create?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]
                          }
                        </td>
                      ))}
                  </tr>
                  <tr>
                    <td className=" text-right">Recria</td>
                    {historic
                      .filter((historicItem) => !!historicItem)
                      .map((historicItem, index) => (
                        <td className={` text-${colors[i]}`} key={index}>
                          {
                            historicItem?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.recreate?.product
                          }
                          /
                          {
                            historicItem?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.recreate?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]
                          }
                        </td>
                      ))}
                  </tr>
                  <tr>
                    <td className=" text-right">Produção</td>
                    {historic
                      .filter((historicItem) => !!historicItem)
                      .map((historicItem, index) => (
                        <td className={` text-${colors[i]}`} key={index}>
                          {
                            historicItem?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.production?.product
                          }
                          /
                          {
                            historicItem?.[item.split(".")[0]]?.[
                              item.split(".")[1]
                            ]?.production?.[
                              item === "minerals.organics" ? "reason" : "dose"
                            ]
                          }
                        </td>
                      ))}
                  </tr>
                  <tr>
                    <td className=" text-right">
                      <strong>Score/Lote</strong>
                    </td>
                    {historic
                      .filter((historicItem) => !!historicItem)
                      .map((historicItem, index) => (
                        <td className={` `} key={index}>
                          {historicItem.lotes.map((lote, index) => (
                            <React.Fragment key={lote + index}>
                              <strong
                                style={
                                  lote.results
                                    ? {
                                        color: getScoreColor(
                                          +lote.results.total
                                        ),
                                      }
                                    : null
                                }
                              >
                                {lote.results ? (
                                  <>
                                    lote:{lote.lote} , Score:{" "}
                                    {lote.results
                                      ? lote.results.total
                                          .toFixed(1)
                                          .replace(".", ",")
                                      : ""}
                                    &nbsp;
                                  </>
                                ) : null}
                              </strong>

                              <br />
                            </React.Fragment>
                          ))}
                        </td>
                      ))}
                  </tr>
                </Body>
              </TableContent>
            </CardBody>
          </Card>
        </React.Fragment>
      );
    });
  };
  return (
    <Block id="historicTable">
      <BlockHeader> Programa por Idade</BlockHeader>
      <BlockBody>
        <div style={{ marginBottom: "15px" }}>{getCards()}</div>
      </BlockBody>
    </Block>
  );
}

export default HistoricTable;
