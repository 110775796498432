export const menuconfig = [
  {
    to: "/",
    label: "Home",
    icon: "flaticon-home-2",
    module: "connect",
  },
  {
    to: "/customers/1/30/index/{}",
    label: "Clientes",
    icon: "flaticon-home-2",
    module: "connect",
  },
  {
    label: "Cadastro Base",
    icon: "flaticon-interface-3",
    module: "basicregistration",
    subMenu: [
      {
        label: "Usuários",
        module: "user",
        to: "/basicregistration/user/1/30/index/{}",
      },
      {
        label: "Perfís",
        module: "profile",
        to: "/basicregistration/profile/1/30/index/{}",
      },
      {
        label: "Clientes",
        module: "customer",
        to: "/basicregistration/customer/1/30/index/{}",
      },
    ],
  },

  {
    label: "Configuração",
    icon: "fa fa-chart-bar",
    module: "config",
    subMenu: [
      {
        label: "Frango de Corte",
        module: "broilerconfig",
        subMenu: [
          {
            label: "Necropsia",
            module: "broilernecrosyconfig",
            to: "/config/connect/broiler/necropsy",
          },

          {
            label: "Abate",
            module: "slaughterconfig",
            to: "/config/connect/slaughter",
          },
          {
            label: "Fábrica",
            module: "factoryconfig",
            to: "/config/connect/factory/1/30",
          },
        ],
      },
      {
        label: "Matriz Pesada",
        module: "heavybroilerconfig",
        subMenu: [
          {
            label: "Pré-necropsia",
            module: "heavybroilerprenecrosyconfig",
            to: "/config/connect/heavybroiler/prenecropsy",
          },
          {
            label: "Necropsia",
            module: "heavybroilernecrosyconfig",
            to: "/config/connect/heavybroiler/necropsy",
          },
        ],
      },
      {
        label: "MEgg",
        module: "meggconfig",
        subMenu: [
          {
            label: "Producao",
            module: "meggproductionconfig",
            to: "/config/megg/production",
          },
          {
            label: "Cria-recria",
            module: "meggcreaterecreateconfig",
            to: "/config/megg/createrecreate",
          },
        ],
      },
    ],
  },
];
