import { Input } from "components/Input";
import { Col, Row, Separator } from "styles";
import { H3 } from "../style";

export function RecommendedCollectionAndAnalysis({
  evaluation,
  setEvaluation,
}) {
  return (
    <>
      <H3>
        *Devem ser coletadas amostras de <b>5 sacos diferentes</b>, quando
        encontrada inconformidade em produtos e enviar em forma de pool.
      </H3>
      <H3>
        *Deve ser coletada ração dos <b>sacos</b> e dos <b>cochos</b> dos
        animais em 5 pontos diferentes e enviar em 2 amostras em forma de pool.
      </H3>
      <H3>
        *Sempre enviar amostras dos macroingredientes usados preferencialmente
        no lote de <b>ração com problema</b> (milho, soja, outros).
      </H3>
      <Separator />
      <Row>
        <Col>
          <label>
            <b>Empedramento</b> - enviar amostra do produto e solicitar a
            seguinte análise
          </label>
          <Input
            type="checkbox"
            label="Umidade"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.cobblestone.humidity"
          />
        </Col>
        <Col>
          <label>
            <b>Odor inconforme</b> - enviar amostra de produto, da ração acabada
            e solicitar as seguintes análises
          </label>
          <Input
            type="checkbox"
            label="Acidez"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.nonconformingOdor.acidity"
          />
          <div style={{ marginBottom: "10px" }} />
          <Input
            type="checkbox"
            label="Peróxido"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.nonconformingOdor.peroxide"
          />
          <div style={{ marginBottom: "10px" }} />
          <Input
            type="checkbox"
            label="Ranço"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.nonconformingOdor.rancid"
          />
        </Col>
        <Col>
          <label>
            <b>Canibalismo</b> - enviar amostra de produto, da ração acabada e
            solicitar as seguintes análises
          </label>
          <Input
            type="checkbox"
            label="PB"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.cannibalism.PB"
          />{" "}
          <div style={{ marginBottom: "10px" }} />
          <Input
            type="checkbox"
            label="Ca"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.cannibalism.Ca"
          />{" "}
          <div style={{ marginBottom: "10px" }} />
          <Input
            type="checkbox"
            label="P"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.cannibalism.P"
          />{" "}
          <div style={{ marginBottom: "10px" }} />
          <Input
            type="checkbox"
            label="Na"
            item={evaluation}
            setItem={setEvaluation}
            params="recommendedCollectionAndAnalysis.cannibalism.Na"
          />
        </Col>
      </Row>
    </>
  );
}
