import { ConclusionArea } from "./style";

export function Conclusion({ beat, data, setData }) {
  const text = data.microtracerBeats[`beat${beat}`].conclusion;

  const handleText = (e) => {
    const { value } = e.target;
    setData((prevState) => {
      const newData = { ...prevState };
      newData.microtracerBeats[`beat${beat}`].conclusion = value;
      return newData;
    });
  };
  return (
    <div style={{ padding: "5px" }}>
      <ConclusionArea value={text} onChange={(e) => handleText(e)} />
    </div>
  );
}
