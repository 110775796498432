import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { OrganicAcids } from "./OrganicAcids";
import { MycotoxinAdsorbents } from "./MycotoxinAdsorbents";
import { EssencialOils } from "./EssencialOils";
import { Prebiotics } from "./Prebiotics";
import { Probiotics } from "./Probiotics";
import { Others } from "./Others";

export function Additions(props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Ácidos Orgânicos" />
        <Tab label="Absorventes de Micotoxina" />
        <Tab label="Óleos Essenciais" />
        <Tab label="Prebióticos" />
        <Tab label="Probióticos" />
        <Tab label="Outros" />
      </Tabs>
      {tab === 0 && <OrganicAcids {...props} />}
      {tab === 1 && <MycotoxinAdsorbents {...props} />}
      {tab === 2 && <EssencialOils {...props} />}
      {tab === 3 && <Prebiotics {...props} />}
      {tab === 4 && <Probiotics {...props} />}
      {tab === 5 && <Others {...props} />}
    </>
  );
}
