import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const showStyle = css`
  position: absolute;
  transform: translate3d(-229px, 60px, 0);
  top: 0px;
  left: 0px;
  will-change: transform;
  display: block;
  @media (max-width: 1024px) {
    width: 346px;
    transform: translate3d(42px, 49px, 0);
  }
`;

export const Container = styled.div`
  width: 450px;
  display: none;
  font-size: 1rem;
  color: #3f4254;
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.42rem;
  -webkit-box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);

  ${({ show }) => (show === "true" ? showStyle : "")}
`;

export const Header = styled.div`
  background-image: url("https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_azul_wave_tall.png");
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  @media (max-width: 1024px) {
    border-radius: 0;
  }
`;

export const HeaderTitle = styled.div`
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  span {
    color: #fff;
    font-weight: 500;
    font-size: 1.3rem;
  }
`;

export const TabTitle = styled.ul`
  border-bottom-color: rgba(255, 255, 255, 0.7);
  border-bottom-width: 3px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 0.75rem;
  box-shadow: inset 0px -2px 0 #e4e6ef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  @media (max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const TabTitleItem = styled.li`
  margin-bottom: -3px;
  margin: 0 0 -1px 0;

  span {
    cursor: pointer;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    border: 0;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease,
      -webkit-box-shadow 0.15s ease;
    padding: 0.85rem 0;
    margin: 0 1rem;
    border-bottom: 3px solid transparent;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
    @media (max-width: 1024px) {
      font-size: 0.8rem;
      margin: 0 0.5rem;
    }
    ${({ isactive }) =>
      isactive === "true"
        ? "border-bottom-color: var(--default);color:#fff"
        : ""}
  }
`;
export const ItemsContainer = styled.div`
  padding: 1rem;
  margin: 0;
  display: block;
  list-style: none;
`;

const itemCss = css`
  padding-left: 0;
  padding-right: 0;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3f4254;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  @media (max-width: 1024px) {
    padding: 0.75rem 1rem;
  }
`;

export const ItemContentLink = styled(Link)`
  ${itemCss}
`;

export const ItemContentButton = styled.div`
  cursor: pointer;
  ${itemCss}
`;

export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
  margin-right: 0.75rem;
  background-color: #f3f6f9;
  width: 40px;
  height: 40px;
`;

export const ItemContentText = styled.div`
  color: #3f4254;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 1rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
`;

export const ItemTitle = styled.div`
  font-weight: 500;
  color: #3f4254;
`;

export const ItemText = styled.div`
  color: #b5b5c3;
  font-size: 1rem;
  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
`;
