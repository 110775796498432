import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function TherapeuticMedication(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "185px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual tipo?</span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th>
            <span>Qual dose?</span>
          </th>
          <th>
            <span>Via Água?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Pré</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.pre.type"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.pre.product"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.pre.dose"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
          <td>
            <Input
              type="checkbox"
              params="health.therapeuticMedication.pre.water"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
        </tr>
        <tr>
          <td>Inicial</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.initial.type"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.initial.product"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.initial.dose"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.initial.water"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
        </tr>

        <tr>
          <td>Crescimento</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.growth.type"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.growth.product"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.growth.dose"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.growth.water"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
        </tr>
        <tr>
          <td>desenvolvimento</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.development.type"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.development.product"
              item={props.megg}
              setItem={props.setMegg}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.development.dose"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.development.water"
              item={props.megg}
              setItem={props.setMegg}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
