import { useCallback, useContext, useState } from "react";
import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import updateList from "contexts/updateList";
import { Card, CardBody, CardHeader } from "styles";
import { Module1 } from "./Module1";
import { Module2 } from "./Module2";
import { Module3 } from "./Module3";
import { PriorInspection } from "./PriorInspection";

export function InspectionTermBPF(props) {
  const moduleLabel = {
    Module1: "Módulo I",
    Module2: "Módulo II",
    Module3: "Módulo III-IV-V",
    PriorInspection: "Fiscalização Prévia",
  };

  const tableParams = [
    {
      key: "user",
      params: "name",
      label: "Cadastrado por",
      filter: "text",
      placeholder: "Digite a nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "date",
      label: "Data da Visita",
      filter: "date",
      type: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "module",
      label: "Modelo de Termo",
      type: "custom",
      filter: "select",
      options: [
        { value: "Module1", label: "Módulo 1" },
        { value: "Module2", label: "Módulo 2" },
        { value: "Module3", label: "Módulo 3" },
        { value: "PriorInspection", label: "Fiscalização Prévia" },
      ],
      mobile: {
        type: "item",
      },
      response: ({ termType }) => moduleLabel[termType],
    },
  ];

  const { setUpdateList } = useContext(updateList);

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const editTermHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(row.termType);
  }, []);

  const createTermHandler = useCallback((termType) => {
    setId("");
    setModalShow(termType);
  }, []);

  return (
    <>
      {modalShow === "Module1" && (
        <Module1
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}
      {modalShow === "Module2" && (
        <Module2
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}
      {modalShow === "Module3" && (
        <Module3
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}
      {modalShow === "PriorInspection" && (
        <PriorInspection
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}
      <Card>
        <CardHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Termo de fiscalização BPF</div>
            <div style={{ display: "flex" }}>
              <Button
                type="button"
                target="_blank"
                href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/[AF]%2020210624_Mcassab_orientac%CC%A7o%CC%83es%20BPF_GP_1039.pdf"
                color="default"
                bg="light"
                border="default"
                size="sm"
              >
                <Icons type="download" color="#009870" size={18} />
                Orientações BPF
              </Button>
              <Button
                color="dark"
                bg="light"
                border="dark"
                size="sm"
                onClick={() => createTermHandler("PriorInspection")}
                style={{ marginLeft: "10px" }}
                type="button"
              >
                Fiscalização Prévia
              </Button>

              <Button
                color="white"
                bg="default"
                border="default"
                size="sm"
                onClick={() => createTermHandler("Module1")}
                style={{ marginLeft: "10px" }}
                type="button"
              >
                Módulo I
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                color="white"
                bg="basf"
                border="basf"
                size="sm"
                onClick={() => createTermHandler("Module2")}
                type="button"
              >
                Módulo II
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                color="white"
                bg="dark"
                border="dark"
                size="sm"
                onClick={() => createTermHandler("Module3")}
                type="button"
              >
                Módulos III-IV-V
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            noLoad={true}
            cols={tableParams}
            editHandler={editTermHandler}
            emptyText={"Nenhum termo localizado!"}
            route={`/inspectiontermbpf/${props.match.params.id}`}
            {...props}
          />
        </CardBody>
      </Card>
    </>
  );
}
