import { useCallback, useState } from "react";
import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { Image } from "components/Image";
import { Input } from "components/Input";
import { Body, Header, TableContent } from "components/Table/style";
import { Card, CardBody, CardHeader, Col, Row } from "styles";

export function Sanity({ preNecropsy, setPreNecropsy, config }) {
  const [info, setInfo] = useState(null);

  const getLabel = useCallback(
    (label, param) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{label}</span>
        <Icons
          type="question"
          color="#009870"
          size={18}
          onClick={() =>
            setInfo({
              label,
              options: config.filter(({ key }) => key === param),
            })
          }
        />
      </div>
    ),
    [config]
  );

  return info ? (
    <Card style={{ height: "100%", marginBottom: "15px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <strong>
          <i className="far fa-question-circle" /> &nbsp;
          {info.label}
        </strong>
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={() => setInfo(null)}
          size="sm"
        >
          Voltar
        </Button>
      </CardHeader>
      <CardBody>
        <TableContent>
          <Header>
            <tr>
              <th style={{ width: "100px" }}>Score</th>
              <th>Descrição</th>
              <th style={{ width: "500px" }}>Imagem</th>
            </tr>
          </Header>
          <Body>
            {info.options.map((item) => (
              <tr key={item._id}>
                <td>
                  <span>
                    <b>{item.score}</b>
                  </span>
                </td>
                <td>{item.description}</td>
                <td>
                  <Image
                    className="img-fluid"
                    src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/necropsy-${item.key.replace(
                      /\./g,
                      "-"
                    )}-${item._id}.${item.extention}`}
                  />
                </td>
              </tr>
            ))}
          </Body>
        </TableContent>
      </CardBody>
    </Card>
  ) : (
    <>
      <Row>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.vivacity"
            type="select"
            placeholder="0"
            label={getLabel("Vivacidade", "sanity.hbVivacity")}
            options={config
              .filter(({ key }) => key === "sanity.hbVivacity")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.bedQuality"
            type="select"
            placeholder="0"
            label={getLabel("Qualidade de Cama", "sanity.hbBedQuality")}
            options={config
              .filter(({ key }) => key === "sanity.hbBedQuality")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.prolapse"
            type="select"
            placeholder="0"
            label={getLabel("Prolapso", "sanity.hbProlapse")}
            options={config
              .filter(({ key }) => key === "sanity.hbProlapse")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.snore"
            type="select"
            placeholder="0"
            label={getLabel("Ronqueira", "sanity.hbSnore")}
            options={config
              .filter(({ key }) => key === "sanity.hbSnore")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.sneeze"
            type="select"
            placeholder="0"
            label={getLabel("Espirro", "sanity.hbSneeze")}
            options={config
              .filter(({ key }) => key === "sanity.hbSneeze")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.coryza"
            type="select"
            placeholder="0"
            label={getLabel("Coriza", "sanity.hbCoryza")}
            options={config
              .filter(({ key }) => key === "sanity.hbCoryza")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
        <Col>
          <Input
            item={preNecropsy}
            setItem={setPreNecropsy}
            params="sanity.swollenHead"
            type="select"
            placeholder="0"
            label={getLabel("Cabeça Inchada", "sanity.hbSwollenHead")}
            options={config
              .filter(({ key }) => key === "sanity.hbSwollenHead")
              .map(({ score }) => ({ value: score }))}
          />
        </Col>
        <Col></Col>
      </Row>
    </>
  );
}
