import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Col, Row } from "styles";
import { Tab, Tabs } from "@material-ui/core";
import ReactLoading from "react-loading";
import { StepContainer, StepDot, StepLabel, StepLine } from "./styles";

export function CreateEditMixer(props) {
  const [mixer, setMixer] = useState({
    title: "",
    mixerType: "",
    typeOfMixingShaft: "",
    distance: "",
    injectorNozzles: "",
    leakageInTheGate: "",
    amountOfLiquid: "",
    age: "",
    capacity: "",
    weight: "",
    grounding: "",
    totalMixTime: "",
    nutrientTimeMacro: "",
    nutrientTimeMicro: "",
    startLiquidInjection: "",
    endLiquidInjection: "",
    wetMixTime: "",
    amountOfBeats: "",
    cvBeats: [],
    residualCvBeat: "",
    cvAverage: "",
    recoveryRates: [],
    residualRecoveryRate: "",
    recoveryRateAverage: "",
    ground: "",
    crossContaminationResult: "",
  });


  const [loading, setLoading] = useState(true);

  const [getMixer, setGetMixer] = useState(true);

  const [tab, setTab] = useState(0);

  const timeSum = useCallback(() => {
    const params = [
      "nutrientTimeMacro",
      "nutrientTimeMicro",
      "startLiquidInjection",
      "endLiquidInjection",
      "wetMixTime",
    ];
    return params.map((param) => +mixer[param] || 0).reduce((a, b) => a + b);
  }, [mixer]);

  const amountOfBeatsHandler = useCallback((e) => {
    setMixer((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.amountOfBeats = +e.target.value;
      const beats = [];
      for (let i = 0; i < newState.amountOfBeats - 1; i++) {
        beats.push("");
      }
      newState.cvBeats = [...beats];
      newState.recoveryRates = [...beats];
      return newState;
    });
  }, []);

  const getAverage = (value, param, paramAv, index) => {
    setMixer((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[param][index] = value;
      newState[paramAv] =
        Math.round(
          (newState[param].reduce((a, b) => +(a || 0) + +(b || 0)) /
            (newState.amountOfBeats - 1)) *
            100
        ) / 100;
      return newState;
    });
  };

  const saveMixer = useCallback(async () => {
    const query = JSON.parse(JSON.stringify(mixer));

    query.factory = props.match.params.id;

    const response = await Swal.fire({
      title: "Salvar Misturador",
      text: "Deseja confirmar salvar Misturador",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Salvar Avaliação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`mixer/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });

    if (response.value) {
      Swal.fire(
        "Criar Misturador",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setMixer((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }
    /*  if (!props.id) {
      const response = await Swal.fire({
        title: "Salvar Misturador",
        text: "Deseja confirmar salvar Misturador",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Salvar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`mixer/${props.match.params.id}`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Misturador",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setMixer((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState._id = response.value.data.id;
          return newState;
        });
        props.setUpdateList(true);
      }
    } else {
      query._id = props.id;
      const response = await Swal.fire({
        title: "Editar Misturador",
        text: "Deseja confirmar edição da Misturador",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Misturador!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(`mixer/${props.match.params.id}/${props.id}`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Misturador",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        props.setUpdateList(true);
      }
    } */
  }, [mixer, props]);

  useEffect(() => {
    const loadMixer = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `mixer/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire(
            "Editar Misturador",
            "Não foi possível buscar o Misturador",
            "error"
          );
        }
        setMixer(response.data);
      }
      setLoading(false);
    };
    if (getMixer) {
      setGetMixer(false);
      loadMixer();
    }
  }, [props.id, props.match.params.id, getMixer]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Dados do Misturador</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveMixer}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Dados do Misturador" />
            <Tab label="Tempo de mistura" />
            <Tab label="Qualidade de mistura" />
          </Tabs>
          {tab === 0 && (
            <>
              <Row>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="title"
                    label="Descrição"
                    placeholder="Digite o nome do misturador"
                  />
                </Col>
                <Col>
                  <Input
                    type="select"
                    item={mixer}
                    setItem={setMixer}
                    params="mixerType"
                    label="Tipo Misturador"
                    placeholder="Selecione uma opção"
                    options={[
                      {
                        value: "Vertical",
                      },
                      { value: "Horizontal" },
                    ]}
                  />
                </Col>
                <Col>
                  <Input
                    type="select"
                    item={mixer}
                    setItem={setMixer}
                    params="typeOfMixingShaft"
                    label="Tipo de Eixo de Mistura"
                    placeholder="Selecione uma opção"
                    options={[
                      { value: "Helicóide/Duplo" },
                      { value: "Helicóide/Pás" },
                      { value: "Único/Duplo" },
                      { value: "Único/Pás" },
                    ]}
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="distance"
                    label="Distância da Parede"
                    placeholder="(mm)"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="select"
                    item={mixer}
                    setItem={setMixer}
                    params="injectorNozzles"
                    label="Bicos Injetores "
                    placeholder="Selecione uma opção"
                    options={[
                      {
                        value: "Fluxo",
                      },
                      { value: "Contra Fluxo" },
                    ]}
                  />
                </Col>
                <Col>
                  <Input
                    type="select"
                    item={mixer}
                    setItem={setMixer}
                    params="leakageInTheGate"
                    label="Vazamento na Comporta"
                    placeholder="Selecione uma opção"
                    options={[{ value: "Sim" }, { value: "Não" }]}
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="amountOfLiquid"
                    label="Quant. de Liquido/ton."
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="age"
                    label="Idade Aproximada"
                    placeholder=""
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="capacity"
                    label="Capacidade em Litros "
                    placeholder=""
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="weight"
                    label="Peso da batida"
                    placeholder=""
                  />
                </Col>
                <Col>
                  <Input
                    type="select"
                    item={mixer}
                    setItem={setMixer}
                    params="grounding"
                    label="Aterramento"
                    placeholder="Selecione uma opção"
                    options={[{ value: "Sim" }, { value: "Não" }]}
                  />
                </Col>
              </Row>
            </>
          )}
          {tab === 1 && (
            <>
              <Row>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="totalMixTime"
                    label="Tempo total de mistura"
                    placeholder="(Segundos)"
                    inputType="number"
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="nutrientTimeMacro"
                    label="Tempo nutrientes  macro"
                    placeholder="(Segundos)"
                    inputType="number"
                    disabled={!mixer.totalMixTime}
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="nutrientTimeMicro"
                    label="Tempo nutrientes micro"
                    placeholder="(Segundos)"
                    inputType="number"
                    disabled={!mixer.totalMixTime}
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="startLiquidInjection"
                    label="Inicio injeção de liquido"
                    placeholder="(Segundos)"
                    inputType="number"
                    disabled={!mixer.totalMixTime}
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="endLiquidInjection"
                    label="Fim injeção de liquidos"
                    placeholder="(Segundos)"
                    inputType="number"
                    disabled={!mixer.totalMixTime}
                  />
                </Col>
                <Col>
                  <Input
                    type="input"
                    item={mixer}
                    setItem={setMixer}
                    params="wetMixTime"
                    label="Tempo mistura úmida"
                    placeholder="(Segundos)"
                    inputType="number"
                    disabled={!mixer.totalMixTime}
                  />
                </Col>
              </Row>
              <Row>
                <Col flexRow>
                  <StepContainer
                    size={((mixer.nutrientTimeMacro / timeSum()) * 100).toFixed(
                      0
                    )}
                  >
                    <StepLine />
                    <StepDot show={!!mixer.nutrientTimeMacro} />
                    <StepLabel show={!!mixer.nutrientTimeMacro}>
                      Macro: {mixer.nutrientTimeMacro}seg
                    </StepLabel>
                  </StepContainer>
                  <StepContainer
                    size={((mixer.nutrientTimeMicro / timeSum()) * 100).toFixed(
                      0
                    )}
                  >
                    <StepLine />
                    <StepDot show={!!mixer.nutrientTimeMicro} />
                    <StepLabel show={!!mixer.nutrientTimeMicro}>
                      Micro: {mixer.nutrientTimeMicro}seg
                    </StepLabel>
                  </StepContainer>
                  <StepContainer
                    size={(
                      ((+mixer.endLiquidInjection +
                        +mixer.startLiquidInjection) /
                        timeSum()) *
                      100
                    ).toFixed(0)}
                  >
                    <StepLine />
                    <StepDot show={!!mixer.endLiquidInjection} />
                    <StepLabel show={!!mixer.endLiquidInjection}>
                      Líquidos:{" "}
                      {+mixer.endLiquidInjection + +mixer.startLiquidInjection}
                      seg
                    </StepLabel>
                  </StepContainer>
                  <StepContainer
                    size={((mixer.wetMixTime / timeSum()) * 100).toFixed(0)}
                  >
                    <StepLine />
                    <StepDot show={!!mixer.wetMixTime} />
                    <StepLabel show={!!mixer.wetMixTime}>
                      Mistura úmida : {mixer.wetMixTime}seg
                    </StepLabel>
                  </StepContainer>
                  <StepContainer size={0}>
                    <StepLine />
                    <StepDot show={!!mixer.wetMixTime} />
                    <StepLabel show={true}>&nbsp;</StepLabel>
                  </StepContainer>
                </Col>
              </Row>
            </>
          )}
          {tab === 2 && (
            <>
              <Row>
                <Col>
                  <Input
                    type="select"
                    item={mixer}
                    setItem={setMixer}
                    params="amountOfBeats"
                    label="Quantidades de batidas para teste"
                    placeholder="Selecione a qtd de batidas"
                    inputType="number"
                    value={mixer.amountOfBeats}
                    onChange={amountOfBeatsHandler}
                    options={[
                      {
                        value: "1",
                      },
                      {
                        value: "2",
                      },
                      {
                        value: "3",
                      },
                      {
                        value: "4",
                      },
                      {
                        value: "5",
                      },
                    ]}
                  />
                </Col>
                <Col size={3} />
              </Row>
              {mixer.amountOfBeats && (
                <>
                  <Row>
                    {mixer.cvBeats.map((item, index) => (
                      <Col>
                        <Input
                          key={`cvBeats${index}`}
                          type="input"
                          item={mixer}
                          setItem={setMixer}
                          params={`cvBeats.${index}`}
                          label={`CV batida ${index + 1}`}
                          inputType="number"
                          onChange={(e) =>
                            getAverage(
                              e.target.value,
                              "cvBeats",
                              "cvAverage",
                              index
                            )
                          }
                          value={mixer.cvBeats[index]}
                        />
                      </Col>
                    ))}
                    <Col>
                      <Input
                        type="input"
                        item={mixer}
                        setItem={setMixer}
                        params={`cvAverage`}
                        label={`Média`}
                        inputType="number"
                      />
                    </Col>
                    <Col>
                      <Input
                        type="input"
                        item={mixer}
                        setItem={setMixer}
                        params={`residualCvBeat`}
                        label={`CV batida ${mixer.amountOfBeats} residual`}
                        inputType="number"
                      />
                    </Col>
                  </Row>
                  <Row>
                    {mixer.recoveryRates.map((item, index) => (
                      <Col>
                        <Input
                          key={`recoveryRates${index}`}
                          type="input"
                          item={mixer}
                          setItem={setMixer}
                          params={`recoveryRates.${index}`}
                          label={`Taxa de recuperação ${index + 1}`}
                          inputType="number"
                          onChange={(e) =>
                            getAverage(
                              e.target.value,
                              "recoveryRates",
                              "recoveryRateAverage",
                              index
                            )
                          }
                          value={mixer.recoveryRates[index]}
                        />
                      </Col>
                    ))}
                    <Col>
                      <Input
                        type="input"
                        item={mixer}
                        setItem={setMixer}
                        params={`recoveryRateAverage`}
                        label={`Média`}
                        inputType="number"
                      />
                    </Col>
                    <Col>
                      <Input
                        type="input"
                        item={mixer}
                        setItem={setMixer}
                        params={`residualRecoveryRate`}
                        label={`Taxa de recuperação ${mixer.amountOfBeats}`}
                        inputType="number"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type="input"
                        item={mixer}
                        setItem={setMixer}
                        params={`ground`}
                        label={`Aterramento`}
                        inputType="number"
                      />
                    </Col>
                    <Col>
                      <Input
                        type="input"
                        item={mixer}
                        setItem={setMixer}
                        params={`crossContaminationResult`}
                        label={`Resultado contaminação Cruzada`}
                        inputType="number"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
}
