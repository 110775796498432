import styled from "styled-components";

export const StepContainer = styled.div`
  width: ${({ size }) => (isNaN(size) || !size ? 0 : size)}%;
  min-width: ${({ size }) => (isNaN(size) || !size ? 0 : 100)}px;
  transition: 1s;
  position: relative;
`;

export const StepLine = styled.div`
  height: 4px;
  background-color: var(--default);
  width: 100%;
  border-radius: 3px;
`;

export const StepDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  position: absolute;
  top: -3px;
  left: -2px;
  background-color: var(--default);
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const StepLabel = styled.h3`
  font-size: 12px;
  padding-top: 10px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: 2s;
  white-space: nowrap;
`;
