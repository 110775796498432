import { Svg } from "./style";

export function Icons({ type, color, size, onClick }) {
  const listIcons = {
    phone: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        color={color}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={24} />
          <path d="M11.914857,14.1427403 L14.1188827,11.9387145 C14.7276032,11.329994 14.8785122,10.4000511 14.4935235,9.63007378 L14.3686433,9.38031323 C13.9836546,8.61033591 14.1345636,7.680393 14.7432841,7.07167248 L17.4760882,4.33886839 C17.6713503,4.14360624 17.9879328,4.14360624 18.183195,4.33886839 C18.2211956,4.37686904 18.2528214,4.42074752 18.2768552,4.46881498 L19.3808309,6.67676638 C20.2253855,8.3658756 19.8943345,10.4059034 18.5589765,11.7412615 L12.560151,17.740087 C11.1066115,19.1936265 8.95659008,19.7011777 7.00646221,19.0511351 L4.5919826,18.2463085 C4.33001094,18.1589846 4.18843095,17.8758246 4.27575484,17.613853 C4.30030124,17.5402138 4.34165566,17.4733009 4.39654309,17.4184135 L7.04781491,14.7671417 C7.65653544,14.1584211 8.58647835,14.0075122 9.35645567,14.3925008 L9.60621621,14.5173811 C10.3761935,14.9023698 11.3061364,14.7514608 11.914857,14.1427403 Z" />
        </g>
      </Svg>
    ),
    facebook: (
      <Svg
        role="img"
        xmlns="http://www.w3.org/2000/Svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        onClick={onClick}
        color={color}
      >
        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
      </Svg>
    ),
    whatsapp: (
      <Svg
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/Svg"
        width={size}
        height={size}
        onClick={onClick}
        color={color}
      >
        <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
      </Svg>
    ),
    arrowDown: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        color={color}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
            fillRule="nonzero"
            transform="translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999) "
          />
        </g>
      </Svg>
    ),
    close: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <g
            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
            fill={color}
          >
            <rect x={0} y={7} width={16} height={2} rx={1} />
            <rect
              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
              x={0}
              y={7}
              width={16}
              height={2}
              rx={1}
            />
          </g>
        </g>
      </Svg>
    ),
    instagram: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
        />
      </Svg>
    ),
    etc: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={24} />
          <circle fill={color} cx={5} cy={12} r={2} />
          <circle fill={color} cx={12} cy={12} r={2} />
          <circle fill={color} cx={19} cy={12} r={2} />
        </g>
      </Svg>
    ),
    home: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        version="1.1"
        width={size}
        height={size}
        onClick={onClick}
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} onClick={onClick} />
          <path
            fill={color}
            d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z"
          />
        </g>
      </Svg>
    ),
    users: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={24} />
          <path
            d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
            fill={color}
            opacity="0.7"
          />
          <path
            d="M12,13 C10.8954305,13 10,12.1045695 10,11 C10,9.8954305 10.8954305,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,13 12,13 Z"
            fill={color}
            opacity="0.7"
          />
          <path
            d="M7.00036205,18.4995035 C7.21569918,15.5165724 9.36772908,14 11.9907452,14 C14.6506758,14 16.8360465,15.4332455 16.9988413,18.5 C17.0053266,18.6221713 16.9988413,19 16.5815,19 C14.5228466,19 11.463736,19 7.4041679,19 C7.26484009,19 6.98863236,18.6619875 7.00036205,18.4995035 Z"
            fill={color}
            opacity="0.9"
          />
        </g>
      </Svg>
    ),
    logoMCassab: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 320 90"
        style={{ enableBackground: "new 0 0 320 90" }}
        xmlSpace="preserve"
        width={size}
      >
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `\n\t.st0{fill:${color};}\n\t.st1{fill:${color};}\n\t.st2{fill:${color};}\n\t.st3{fill:${color};}\n`,
          }}
        />
        <switch>
          <g>
            <g id="Ass._H_Direita_-_CMYK_1_">
              <g>
                <g>
                  <path
                    className="st0"
                    d="M12,32.3c0.8,1.4,1.7,3.1,2.7,5.2c1,2,2,4.2,3.1,6.4c1,2.3,2.1,4.5,3.1,6.9c1,2.3,2,4.5,2.8,6.4       c0.9-2,1.8-4.1,2.8-6.4c1-2.3,2-4.6,3.1-6.9c1-2.3,2.1-4.4,3.1-6.4c1-2,1.9-3.8,2.7-5.2h6.3c0.3,2.8,0.7,5.8,1,9       c0.3,3.2,0.6,6.4,0.8,9.8c0.2,3.3,0.5,6.7,0.7,10c0.2,3.4,0.4,6.6,0.5,9.8h-6.9c-0.2-4.5-0.3-9.1-0.5-13.9       c-0.2-4.8-0.5-9.4-0.9-13.8c-0.4,0.9-0.9,1.9-1.5,3.1c-0.6,1.2-1.2,2.5-1.8,3.9c-0.6,1.4-1.3,2.8-1.9,4.3       c-0.7,1.5-1.3,2.9-1.9,4.3c-0.6,1.4-1.2,2.7-1.7,3.9c-0.5,1.2-1,2.2-1.3,3.1H21c-0.4-0.9-0.8-1.9-1.3-3.1       c-0.5-1.2-1.1-2.5-1.7-3.9c-0.6-1.4-1.2-2.8-1.9-4.3c-0.7-1.5-1.3-2.9-1.9-4.3c-0.6-1.4-1.2-2.7-1.8-3.9c-0.6-1.2-1-2.2-1.5-3       c-0.4,4.5-0.7,9.1-0.9,13.8C9.7,61.9,9.6,66.5,9.4,71H2.5c0.2-3.2,0.3-6.5,0.5-9.9c0.2-3.4,0.4-6.8,0.7-10.2       c0.2-3.4,0.5-6.6,0.8-9.8c0.3-3.2,0.6-6.1,1-8.8H12z"
                  />
                  <path
                    className="st0"
                    d="M65.6,71.8c-2.9,0-5.5-0.4-7.8-1.3c-2.3-0.9-4.3-2.2-5.9-3.9c-1.6-1.7-2.8-3.8-3.7-6.3       c-0.9-2.5-1.3-5.4-1.3-8.6c0-3.2,0.5-6.1,1.5-8.6c1-2.5,2.3-4.6,4-6.3c1.7-1.7,3.7-3,6-3.9c2.3-0.9,4.8-1.3,7.4-1.3       c1.6,0,3,0.1,4.4,0.4c1.3,0.2,2.4,0.5,3.4,0.8c1,0.3,1.8,0.6,2.4,0.9c0.6,0.3,1.1,0.6,1.3,0.7L75.4,40       c-0.9-0.6-2.2-1.1-3.8-1.6c-1.7-0.5-3.5-0.8-5.4-0.8c-1.7,0-3.2,0.3-4.7,0.9c-1.5,0.6-2.7,1.4-3.8,2.6       c-1.1,1.2-1.9,2.6-2.5,4.4c-0.6,1.7-0.9,3.8-0.9,6.1c0,2,0.2,3.9,0.7,5.6c0.5,1.7,1.2,3.2,2.1,4.4c1,1.2,2.2,2.2,3.7,2.9       c1.5,0.7,3.3,1,5.4,1c2.5,0,4.5-0.3,6.1-0.8c1.6-0.5,2.8-1,3.6-1.4l1.9,5.7c-0.4,0.3-1.1,0.6-1.8,0.9c-0.8,0.3-1.7,0.6-2.7,0.9       c-1,0.3-2.2,0.5-3.5,0.7C68.5,71.8,67.1,71.8,65.6,71.8z"
                  />
                  <path
                    className="st0"
                    d="M92.3,41.1c2.2,0,4.1,0.3,5.6,0.8c1.5,0.6,2.7,1.3,3.7,2.3c0.9,1,1.6,2.2,2,3.7c0.4,1.4,0.6,3,0.6,4.7       v17.7c-1,0.2-2.6,0.5-4.7,0.8c-2.1,0.3-4.5,0.5-7.1,0.5c-1.7,0-3.3-0.2-4.8-0.5c-1.5-0.3-2.7-0.9-3.7-1.6       c-1-0.7-1.8-1.7-2.4-2.9c-0.6-1.2-0.9-2.7-0.9-4.4c0-1.7,0.3-3.1,1-4.2c0.7-1.2,1.5-2.1,2.6-2.8c1.1-0.7,2.4-1.2,3.9-1.6       c1.5-0.3,3-0.5,4.6-0.5c0.7,0,1.5,0,2.3,0.1c0.8,0.1,1.7,0.3,2.6,0.5v-1.1c0-0.8-0.1-1.5-0.3-2.2c-0.2-0.7-0.5-1.3-1-1.9       c-0.5-0.5-1.1-1-1.8-1.3c-0.8-0.3-1.7-0.4-2.9-0.4c-1.6,0-3,0.1-4.3,0.3c-1.3,0.2-2.4,0.5-3.2,0.8l-0.8-5.5       c0.9-0.3,2.1-0.6,3.7-0.9C88.7,41.3,90.4,41.1,92.3,41.1z M92.8,66.3c2.1,0,3.7-0.1,4.7-0.3v-7.5c-0.4-0.1-0.9-0.2-1.6-0.3       C95.2,58,94.5,58,93.6,58c-0.7,0-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.3-2.1,0.6c-0.6,0.3-1.1,0.7-1.5,1.3c-0.4,0.5-0.6,1.2-0.6,2       c0,1.6,0.5,2.7,1.5,3.3C89.8,66,91.1,66.3,92.8,66.3z"
                  />
                  <path
                    className="st0"
                    d="M118.5,66.2c1.8,0,3.1-0.2,3.9-0.6c0.8-0.4,1.2-1.2,1.2-2.2c0-1-0.4-1.8-1.3-2.4c-0.9-0.6-2.3-1.3-4.3-2.1       c-1.2-0.4-2.4-0.9-3.4-1.4c-1-0.5-1.9-1.1-2.6-1.8c-0.7-0.7-1.3-1.5-1.8-2.4c-0.4-0.9-0.6-2.1-0.6-3.5c0-2.7,1-4.8,3-6.3       c2-1.5,4.6-2.3,8-2.3c1.7,0,3.3,0.2,4.9,0.5c1.6,0.3,2.7,0.6,3.5,0.9l-1.2,5.5c-0.7-0.3-1.7-0.6-2.8-0.9       c-1.2-0.3-2.5-0.4-4-0.4c-1.4,0-2.5,0.2-3.3,0.7c-0.9,0.5-1.3,1.2-1.3,2.1c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,1       c0.4,0.3,0.9,0.6,1.6,0.9c0.7,0.3,1.5,0.6,2.5,1c1.6,0.6,3,1.2,4.1,1.8c1.1,0.6,2,1.2,2.8,2c0.7,0.7,1.3,1.6,1.6,2.5       c0.3,0.9,0.5,2,0.5,3.3c0,2.8-1,4.9-3.1,6.3c-2.1,1.4-5,2.1-8.8,2.1c-2.6,0-4.6-0.2-6.2-0.6c-1.6-0.4-2.7-0.8-3.3-1l1.2-5.6       c1,0.4,2.2,0.8,3.6,1.2C115.2,66,116.8,66.2,118.5,66.2z"
                  />
                  <path
                    className="st0"
                    d="M142.9,66.2c1.8,0,3.1-0.2,3.9-0.6c0.8-0.4,1.2-1.2,1.2-2.2c0-1-0.4-1.8-1.3-2.4c-0.9-0.6-2.3-1.3-4.3-2.1       c-1.2-0.4-2.4-0.9-3.4-1.4c-1-0.5-1.9-1.1-2.7-1.8c-0.7-0.7-1.3-1.5-1.8-2.4c-0.4-0.9-0.6-2.1-0.6-3.5c0-2.7,1-4.8,3-6.3       c2-1.5,4.6-2.3,8-2.3c1.7,0,3.3,0.2,4.9,0.5c1.6,0.3,2.7,0.6,3.5,0.9l-1.2,5.5c-0.7-0.3-1.7-0.6-2.8-0.9       c-1.2-0.3-2.5-0.4-4-0.4c-1.4,0-2.5,0.2-3.3,0.7c-0.9,0.5-1.3,1.2-1.3,2.1c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,1       c0.4,0.3,0.9,0.6,1.6,0.9c0.7,0.3,1.5,0.6,2.5,1c1.6,0.6,3,1.2,4.1,1.8c1.1,0.6,2,1.2,2.8,2c0.7,0.7,1.3,1.6,1.6,2.5       c0.3,0.9,0.5,2,0.5,3.3c0,2.8-1,4.9-3.1,6.3s-5,2.1-8.8,2.1c-2.6,0-4.6-0.2-6.2-0.6c-1.6-0.4-2.7-0.8-3.3-1l1.2-5.6       c1,0.4,2.2,0.8,3.6,1.2C139.5,66,141.1,66.2,142.9,66.2z"
                  />
                  <path
                    className="st0"
                    d="M169.7,41.1c2.2,0,4.1,0.3,5.6,0.8c1.5,0.6,2.7,1.3,3.7,2.3c0.9,1,1.6,2.2,2,3.7c0.4,1.4,0.6,3,0.6,4.7       v17.7c-1,0.2-2.6,0.5-4.7,0.8c-2.1,0.3-4.5,0.5-7.1,0.5c-1.7,0-3.3-0.2-4.8-0.5c-1.5-0.3-2.7-0.9-3.7-1.6       c-1-0.7-1.8-1.7-2.4-2.9c-0.6-1.2-0.9-2.7-0.9-4.4c0-1.7,0.3-3.1,1-4.2c0.7-1.2,1.5-2.1,2.7-2.8c1.1-0.7,2.4-1.2,3.9-1.6       c1.5-0.3,3-0.5,4.6-0.5c0.7,0,1.5,0,2.3,0.1c0.8,0.1,1.7,0.3,2.6,0.5v-1.1c0-0.8-0.1-1.5-0.3-2.2c-0.2-0.7-0.5-1.3-1-1.9       c-0.5-0.5-1.1-1-1.8-1.3c-0.8-0.3-1.7-0.4-2.9-0.4c-1.6,0-3,0.1-4.3,0.3c-1.3,0.2-2.4,0.5-3.2,0.8l-0.8-5.5       c0.9-0.3,2.1-0.6,3.7-0.9C166.1,41.3,167.8,41.1,169.7,41.1z M170.3,66.3c2.1,0,3.7-0.1,4.7-0.3v-7.5c-0.4-0.1-0.9-0.2-1.6-0.3       c-0.7-0.1-1.5-0.2-2.3-0.2c-0.7,0-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.3-2.1,0.6c-0.6,0.3-1.1,0.7-1.5,1.3c-0.4,0.5-0.6,1.2-0.6,2       c0,1.6,0.5,2.7,1.5,3.3C167.2,66,168.5,66.3,170.3,66.3z"
                  />
                  <path
                    className="st0"
                    d="M212.7,56.4c0,2.3-0.3,4.5-1,6.3c-0.7,1.9-1.7,3.5-3,4.8c-1.3,1.3-2.8,2.4-4.7,3.1       c-1.8,0.7-3.9,1.1-6.2,1.1c-2.2,0-4.4-0.2-6.4-0.5c-2-0.3-3.7-0.7-5-1.1V28.8l6.8-1.1v15.1c0.8-0.4,1.7-0.8,2.9-1.1       c1.1-0.3,2.4-0.5,3.8-0.5c2,0,3.9,0.4,5.5,1.1c1.6,0.7,3,1.8,4,3.1c1.1,1.3,1.9,2.9,2.5,4.8C212.4,52.1,212.7,54.2,212.7,56.4z        M205.8,56.3c0-2.9-0.6-5.1-1.7-6.8c-1.1-1.7-2.9-2.5-5.4-2.5c-1.1,0-2.2,0.2-3.2,0.5c-1,0.3-1.8,0.7-2.4,1.1v16.8       c0.5,0.1,1.1,0.2,1.9,0.3c0.8,0.1,1.7,0.1,2.8,0.1c2.5,0,4.4-0.8,5.9-2.5C205.1,61.6,205.8,59.3,205.8,56.3z"
                  />
                </g>
                <path
                  className="st1"
                  d="M270.9,72.7c-0.3,0-0.5,0-0.7,0c-3.3-0.3-6.3-1.2-9-2.7c-2.8-1.6-5.3-4-7.3-7c-6.5-9.9-6.4-24-4.8-31.1      c2.4-10.3,6.6-18.1,12.3-23.1c4.2-3.6,9.3-5.7,14-5.7l0.1,0l0,0c4.4,0.2,8.3,1.5,11.4,4c2.8,2.2,5.1,5.2,6.9,9      c1.4,3.1,2.5,6.6,3.1,10.6c0.5,3.3,0.8,6.8,0.7,10.2c-0.1,2.8-0.4,5.4-0.8,7.4c-2.3,10.1-6.6,17.8-12.8,22.9      C279.2,71.3,274,72.7,270.9,72.7z M275.5,13.3c-10.1,0-17.2,7.5-20.1,21.1c-1.3,6-0.6,15.4,4.1,21.8c1.4,1.9,3.1,3.4,4.9,4.4      c1.7,0.9,3.6,1.5,5.7,1.6c0.2,0,0.5,0,0.7,0c6.7,0,15.2-6.6,18-18.9c0.3-1.1,0.5-2.8,0.6-4.4c0.2-2.4,0.2-4.8,0-7.1      c-0.2-2.9-0.7-5.5-1.5-7.8C285.7,17.6,281.6,14,275.5,13.3L275.5,13.3z"
                />
                <path
                  className="st2"
                  d="M305.2,69.2c-6,0-12.7-3.5-16.4-6.7l0,0l0,0c0,0,0.1-0.1,0.1-0.1l0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4      c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.4,0.5-0.7,0.7-1.1      l0-0.1l0.2,0.1c2,1.3,6,4,10.5,4c1.4,0,2.8-0.3,4.1-0.9c2.3-1,3.7-2.7,4.3-5.1c1.1-4.6-1.2-10.7-3.9-14.1      c-6.2-7.7-11.9-11.6-17.1-11.6c-0.7,0-1.5,0.1-2.2,0.2c-2.6,0.6-4.5,2.4-5.5,5.2c-1.6,4.7-0.2,11.1,3.5,15.7l0,0l0,0      c-0.3,0.5-0.5,0.8-0.7,1.1l-0.1,0.1c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.7,0.9-0.9,1      c0,0-0.1,0.1-0.1,0.1l-0.1,0.1l0-0.1c-4.4-6.3-9.1-15.6-6.6-23.7c1.4-4.6,4.7-7,6.4-7.6c1.9-0.7,3.9-1.1,5.9-1.1      c11.7,0,21.5,12.2,23.3,14.6c2.4,3.2,7.3,12.9,6,20.7c-0.6,3.5-2.4,6.3-5.3,8.3C310.1,68.6,307.8,69.2,305.2,69.2L305.2,69.2z"
                />
                <path
                  className="st3"
                  d="M258.5,25.1c-0.1,0.2-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.7-0.5,1.2c-0.2,0.5-0.4,1-0.6,1.6      c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.4-0.4,1.1-0.6,1.9c-0.2,0.7-0.3,1.4-0.4,1.7c5.2-1.1,15.7,4.7,5.1,25.5      c-2.4,4.6-14.4,21.6-25.2,17c-5.2-2.2-5-7.7-4.2-13.7c1-8,8.7-20.8,17.1-25.6c0-0.3,0.2-1.2,0.3-2c0.2-0.9,1-4.8,1.2-5.3      c0.1-0.3,0.1-0.3,0.1-0.5c-17.8,11.4-23.2,28.6-24.8,33.9c-3.9,12.6,0.4,23.8,8.3,24.6c16.7,1.7,29.3-18.9,33-24.5      c5.5-8.3,13.5-37.2-6.3-37.2C259.7,25,259.1,25.1,258.5,25.1"
                />
              </g>
            </g>
          </g>
        </switch>
      </Svg>
    ),
    trash: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} onClick={onClick} />
          <path
            d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
            fill={color}
            fillRule="nonzero"
          />
          <path
            d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
            fill={color}
            opacity="0.3"
          />
        </g>
      </Svg>
    ),
    asc: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={24} height={24} />
          <path
            d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    desc: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} onClick={onClick} />
          <path
            d="M5.5,6 C6.32842712,6 7,6.67157288 7,7.5 L7,18.5 C7,19.3284271 6.32842712,20 5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,7.5 C4,6.67157288 4.67157288,6 5.5,6 Z M11.5,11 C12.3284271,11 13,11.6715729 13,12.5 L13,18.5 C13,19.3284271 12.3284271,20 11.5,20 C10.6715729,20 10,19.3284271 10,18.5 L10,12.5 C10,11.6715729 10.6715729,11 11.5,11 Z M17.5,15 C18.3284271,15 19,15.6715729 19,16.5 L19,18.5 C19,19.3284271 18.3284271,20 17.5,20 C16.6715729,20 16,19.3284271 16,18.5 L16,16.5 C16,15.6715729 16.6715729,15 17.5,15 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    filter: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} onClick={onClick} />
          <path
            d="M5,4 L19,4 C19.2761424,4 19.5,4.22385763 19.5,4.5 C19.5,4.60818511 19.4649111,4.71345191 19.4,4.8 L14,12 L14,20.190983 C14,20.4671254 13.7761424,20.690983 13.5,20.690983 C13.4223775,20.690983 13.3458209,20.6729105 13.2763932,20.6381966 L10,19 L10,12 L4.6,4.8 C4.43431458,4.5790861 4.4790861,4.26568542 4.7,4.1 C4.78654809,4.03508894 4.89181489,4 5,4 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    edit: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} onClick={onClick} />
          <path
            d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
          />
          <rect
            fill={color}
            opacity="0.3"
            x={5}
            y={20}
            width={15}
            height={2}
            rx={1}
          />
        </g>
      </Svg>
    ),
    angleDoubleRight: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
            fill={color}
            fillRule="nonzero"
          />
          <path
            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
            fill={color}
            fillRule="nonzero"
            opacity="0.3"
            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
          />
        </g>
      </Svg>
    ),
    angleDoubleLeft: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
          />
          <path
            d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
            fill={color}
            fillRule="nonzero"
            opacity="0.3"
            transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
          />
        </g>
      </Svg>
    ),
    angleRight: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
          />
        </g>
      </Svg>
    ),
    angleLeft: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(12.000003, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-12.000003, -11.999999) "
          />
        </g>
      </Svg>
    ),
    send: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width={size} height={size} />
          <rect
            fill={color}
            opacity="0.3"
            transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) "
            x="8"
            y="6"
            width="2"
            height="12"
            rx="1"
          />
          <path
            d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z"
            fill={color}
            fillRule="nonzero"
            opacity="0.3"
            transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "
          />
          <path
            d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "
          />
        </g>
      </Svg>
    ),
    profile: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
            fill={color}
            opacity="0.3"
          />
          <path
            d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
            fill={color}
            opacity="0.3"
          />
          <path
            d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
            fill={color}
            opacity="0.3"
          />
        </g>
      </Svg>
    ),
    customer: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <circle fill={color} opacity="0.3" cx={12} cy={12} r={10} />
          <path
            d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 L7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
            fill={color}
            opacity="0.3"
          />
        </g>
      </Svg>
    ),
    plus: (
      <Svg
        xmlns="http://www.w3.org/2000/Svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect fill={color} x={4} y={11} width={16} height={2} rx={1} />
          <rect
            fill={color}
            opacity="0.6"
            transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
            x={4}
            y={11}
            width={16}
            height={2}
            rx={1}
          />
        </g>
      </Svg>
    ),
    necropsy: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <rect
            fill={color}
            opacity="0.3"
            x={4}
            y={4}
            width={4}
            height={4}
            rx={2}
          />
          <rect fill={color} x={4} y={10} width={4} height={4} rx={2} />
          <rect fill={color} x={10} y={4} width={4} height={4} rx={2} />
          <rect fill={color} x={10} y={10} width={4} height={4} rx={2} />
          <rect fill={color} x={16} y={4} width={4} height={4} rx={2} />
          <rect fill={color} x={16} y={10} width={4} height={4} rx={2} />
          <rect fill={color} x={4} y={16} width={4} height={4} rx={2} />
          <rect fill={color} x={10} y={16} width={4} height={4} rx={2} />
          <rect fill={color} x={16} y={16} width={4} height={4} rx={2} />
        </g>
      </Svg>
    ),
    save: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
            fill={color}
            fillRule="nonzero"
          />
          <rect
            fill={color}
            opacity="0.3"
            x={12}
            y={4}
            width={3}
            height={5}
            rx="0.5"
          />
        </g>
      </Svg>
    ),
    question: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <circle fill={color} opacity="0.3" cx={12} cy={12} r={10} />
          <path
            d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    broiler: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width={size} height={size}></rect>
          <g
            transform="translate(12.000000, 12.500000) rotate(-315.000000) translate(-12.000000, -12.500000) translate(5.000000, 1.000000)"
            fill={color}
          >
            <path d="M7,1.77635684e-15 L7,1.77635684e-15 C10.2482778,1.17965788e-15 12.8815273,2.63324947 12.8815273,5.88152731 C12.8815273,6.47647336 12.7912576,7.06797572 12.6138001,7.63583982 L10.894825,13.1365601 C10.3624694,14.840098 8.78478119,16 7,16 L7,16 C5.21521881,16 3.63753062,14.840098 3.10517502,13.1365601 L1.38619995,7.63583982 C0.417319895,4.53542367 2.14527132,1.2366073 5.24568748,0.267727252 C5.81355158,0.090269721 6.40505394,1.88564666e-15 7,1.77635684e-15 Z"></path>
            <path
              d="M5.5,20.0853529 L5.5,18 L8.5,18 L8.5,20.0853529 C9.08259619,20.2912711 9.5,20.8468906 9.5,21.5 C9.5,22.3284271 8.82842712,23 8,23 C7.61582278,23 7.26537825,22.8555732 7,22.6180533 C6.73462175,22.8555732 6.38417722,23 6,23 C5.17157288,23 4.5,22.3284271 4.5,21.5 C4.5,20.8468906 4.91740381,20.2912711 5.5,20.0853529 Z"
              opacity="0.3"
            ></path>
          </g>
        </g>
      </Svg>
    ),
    userData: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        className="kt-svg-icon"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z"
            fill={color}
            opacity="0.3"
          />
          <path
            d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    substract: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z"
            fill={color}
            fillRule="nonzero"
          />
          <path
            d="M10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L10.1818182,16 C8.76751186,16 8,15.2324881 8,13.8181818 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 Z"
            fill={color}
            opacity="0.7"
          />
        </g>
      </Svg>
    ),
    download: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
            fill={color}
            fillRule="nonzero"
            opacity="0.3"
          />
          <rect
            fill={color}
            opacity="0.3"
            transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) "
            x={11}
            y={1}
            width={2}
            height={14}
            rx={1}
          />
          <path
            d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) "
          />
        </g>
      </Svg>
    ),
    cloudUpload: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
            fill={color}
            opacity="0.7"
          />
          <path
            d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(11.959697, 18.661508) rotate(-90.000000) translate(-11.959697, -18.661508) "
          />
        </g>
      </Svg>
    ),
    list: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
            fill={color}
            opacity="0.3"
          />
          <path
            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
            fill={color}
          />
          <rect
            fill={color}
            opacity="0.3"
            x={10}
            y={9}
            width={7}
            height={2}
            rx={1}
          />
          <rect
            fill={color}
            opacity="0.3"
            x={7}
            y={9}
            width={2}
            height={2}
            rx={1}
          />
          <rect
            fill={color}
            opacity="0.3"
            x={7}
            y={13}
            width={2}
            height={2}
            rx={1}
          />
          <rect
            fill={color}
            opacity="0.3"
            x={10}
            y={13}
            width={7}
            height={2}
            rx={1}
          />
          <rect
            fill={color}
            opacity="0.3"
            x={7}
            y={17}
            width={2}
            height={2}
            rx={1}
          />
          <rect
            fill={color}
            opacity="0.3"
            x={10}
            y={17}
            width={7}
            height={2}
            rx={1}
          />
        </g>
      </Svg>
    ),
    lineChart: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
            fill={color}
            fillRule="nonzero"
          />
          <path
            d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
            fill={color}
            fillRule="nonzero"
            opacity="0.8"
          />
        </g>
      </Svg>
    ),
    password: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <mask fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g />
          <path
            d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    slaughter: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <rect fill={color} x={4} y={4} width={7} height={7} rx="1.5" />
          <path
            d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
            fill={color}
            opacity="0.3"
          />
        </g>
      </Svg>
    ),
    checklist: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <path
            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
            fill={color}
            opacity="0.3"
          />
          <path
            d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
            fill={color}
          />
          <path
            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
            fill={color}
          />
        </g>
      </Svg>
    ),
    preNecropsy: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <rect x={0} y={0} width={size} height={size} />
          <rect fill={color} x={4} y={16} width={4} height={4} rx={1} />
          <rect fill={color} x={4} y={10} width={4} height={4} rx={1} />
          <rect fill={color} x={10} y={16} width={4} height={4} rx={1} />
          <rect
            fill={color}
            opacity="0.3"
            x={10}
            y={10}
            width={4}
            height={4}
            rx={1}
          />
          <rect fill={color} x={4} y={4} width={4} height={4} rx={1} />
          <rect fill={color} x={16} y={16} width={4} height={4} rx={1} />
        </g>
      </Svg>
    ),
    bucket: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width={size} height={size} />
          <path
            d="M5,5 L5,15 C5,15.5948613 5.25970314,16.1290656 5.6719139,16.4954176 C5.71978107,16.5379595 5.76682388,16.5788906 5.81365532,16.6178662 C5.82524933,16.6294602 15,7.45470952 15,7.45470952 C15,6.9962515 15,6.17801499 15,5 L5,5 Z M5,3 L15,3 C16.1045695,3 17,3.8954305 17,5 L17,15 C17,17.209139 15.209139,19 13,19 L7,19 C4.790861,19 3,17.209139 3,15 L3,5 C3,3.8954305 3.8954305,3 5,3 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(10.000000, 11.000000) rotate(-315.000000) translate(-10.000000, -11.000000) "
          />
          <path
            d="M20,22 C21.6568542,22 23,20.6568542 23,19 C23,17.8954305 22,16.2287638 20,14 C18,16.2287638 17,17.8954305 17,19 C17,20.6568542 18.3431458,22 20,22 Z"
            fill={color}
            opacity="0.3"
          />
        </g>
      </Svg>
    ),
    factory: (
      <Svg
        width={size}
        height={size}
        onClick={onClick}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsSlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path d="M384.42,200.223V149.27l-127.58,50.953V149.27l-127.58,50.953v-43.712v-30.417V75.394H0v50.699v30.417v280.095h512V149.27    L384.42,200.223z M30.417,105.811h68.426v20.282H30.417V105.811z M481.583,406.189L481.583,406.189H30.417V156.511h68.426v88.613    l127.58-50.953v50.953l127.58-50.953v50.953l127.58-50.953V406.189z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M98.844,282.492v80.163h80.164v-80.163H98.844z M148.591,332.239h-19.33V312.91h19.33V332.239z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M226.423,282.492v80.163h80.165v-80.163H226.423z M276.171,332.239h-19.33V312.91h19.33V332.239z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M354.003,282.492v80.163h80.164v-80.163H354.003z M403.75,332.239h-19.33V312.91h19.33V332.239z" />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </Svg>
    ),
    gear: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        onClick={onClick}
        fill={color}
        viewBox="0 0 24 24"
      >
        <path d="M24 14.187v-4.374c-2.148-.766-2.726-.802-3.027-1.529-.303-.729.083-1.169 1.059-3.223l-3.093-3.093c-2.026.963-2.488 1.364-3.224 1.059-.727-.302-.768-.889-1.527-3.027h-4.375c-.764 2.144-.8 2.725-1.529 3.027-.752.313-1.203-.1-3.223-1.059l-3.093 3.093c.977 2.055 1.362 2.493 1.059 3.224-.302.727-.881.764-3.027 1.528v4.375c2.139.76 2.725.8 3.027 1.528.304.734-.081 1.167-1.059 3.223l3.093 3.093c1.999-.95 2.47-1.373 3.223-1.059.728.302.764.88 1.529 3.027h4.374c.758-2.131.799-2.723 1.537-3.031.745-.308 1.186.099 3.215 1.062l3.093-3.093c-.975-2.05-1.362-2.492-1.059-3.223.3-.726.88-.763 3.027-1.528zm-4.875.764c-.577 1.394-.068 2.458.488 3.578l-1.084 1.084c-1.093-.543-2.161-1.076-3.573-.49-1.396.581-1.79 1.693-2.188 2.877h-1.534c-.398-1.185-.791-2.297-2.183-2.875-1.419-.588-2.507-.045-3.579.488l-1.083-1.084c.557-1.118 1.066-2.18.487-3.58-.579-1.391-1.691-1.784-2.876-2.182v-1.533c1.185-.398 2.297-.791 2.875-2.184.578-1.394.068-2.459-.488-3.579l1.084-1.084c1.082.538 2.162 1.077 3.58.488 1.392-.577 1.785-1.69 2.183-2.875h1.534c.398 1.185.792 2.297 2.184 2.875 1.419.588 2.506.045 3.579-.488l1.084 1.084c-.556 1.121-1.065 2.187-.488 3.58.577 1.391 1.689 1.784 2.875 2.183v1.534c-1.188.398-2.302.791-2.877 2.183zm-7.125-5.951c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5z" />
      </Svg>
    ),
    tools: (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        fill={color}
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width={size} height={size} />
          <path
            d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z"
            fill={color}
          />
          <path
            d="M16.6568542,5.92893219 L18.0710678,7.34314575 C18.4615921,7.73367004 18.4615921,8.36683502 18.0710678,8.75735931 L16.6913928,10.1370344 C16.3008685,10.5275587 15.6677035,10.5275587 15.2771792,10.1370344 L13.8629656,8.7228208 C13.4724413,8.33229651 13.4724413,7.69913153 13.8629656,7.30860724 L15.2426407,5.92893219 C15.633165,5.5384079 16.26633,5.5384079 16.6568542,5.92893219 Z"
            fill={color}
            opacity="0.3"
          />
        </g>
      </Svg>
    ),
    calendar: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={onClick}
        fill={color}
        viewBox="0 0 610.398 610.398"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M159.567,0h-15.329c-1.956,0-3.811,0.411-5.608,0.995c-8.979,2.912-15.616,12.498-15.616,23.997v10.552v27.009v14.052
			c0,2.611,0.435,5.078,1.066,7.44c2.702,10.146,10.653,17.552,20.158,17.552h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553
			V35.544V24.992C180.791,11.188,171.291,0,159.567,0z"
            />
            <path
              d="M461.288,0h-15.329c-11.724,0-21.224,11.188-21.224,24.992v10.552v27.009v14.052c0,13.804,9.5,24.992,21.224,24.992
			h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553V35.544V24.992C482.507,11.188,473.007,0,461.288,0z"
            />
            <path
              d="M539.586,62.553h-37.954v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.247,0-40.349-19.79-40.349-44.117
			V62.553H199.916v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.248,0-40.349-19.79-40.349-44.117V62.553H70.818
			c-21.066,0-38.15,16.017-38.15,35.764v476.318c0,19.784,17.083,35.764,38.15,35.764h468.763c21.085,0,38.149-15.984,38.149-35.764
			V98.322C577.735,78.575,560.671,62.553,539.586,62.553z M527.757,557.9l-446.502-0.172V173.717h446.502V557.9z"
            />
            <path
              d="M353.017,266.258h117.428c10.193,0,18.437-10.179,18.437-22.759s-8.248-22.759-18.437-22.759H353.017
			c-10.193,0-18.437,10.179-18.437,22.759C334.58,256.074,342.823,266.258,353.017,266.258z"
            />
            <path
              d="M353.017,348.467h117.428c10.193,0,18.437-10.179,18.437-22.759c0-12.579-8.248-22.758-18.437-22.758H353.017
			c-10.193,0-18.437,10.179-18.437,22.758C334.58,338.288,342.823,348.467,353.017,348.467z"
            />
            <path
              d="M353.017,430.676h117.428c10.193,0,18.437-10.18,18.437-22.759s-8.248-22.759-18.437-22.759H353.017
			c-10.193,0-18.437,10.18-18.437,22.759S342.823,430.676,353.017,430.676z"
            />
            <path
              d="M353.017,512.89h117.428c10.193,0,18.437-10.18,18.437-22.759c0-12.58-8.248-22.759-18.437-22.759H353.017
			c-10.193,0-18.437,10.179-18.437,22.759C334.58,502.71,342.823,512.89,353.017,512.89z"
            />
            <path
              d="M145.032,266.258H262.46c10.193,0,18.436-10.179,18.436-22.759s-8.248-22.759-18.436-22.759H145.032
			c-10.194,0-18.437,10.179-18.437,22.759C126.596,256.074,134.838,266.258,145.032,266.258z"
            />
            <path
              d="M145.032,348.467H262.46c10.193,0,18.436-10.179,18.436-22.759c0-12.579-8.248-22.758-18.436-22.758H145.032
			c-10.194,0-18.437,10.179-18.437,22.758C126.596,338.288,134.838,348.467,145.032,348.467z"
            />
            <path
              d="M145.032,430.676H262.46c10.193,0,18.436-10.18,18.436-22.759s-8.248-22.759-18.436-22.759H145.032
			c-10.194,0-18.437,10.18-18.437,22.759S134.838,430.676,145.032,430.676z"
            />
            <path
              d="M145.032,512.89H262.46c10.193,0,18.436-10.18,18.436-22.759c0-12.58-8.248-22.759-18.436-22.759H145.032
			c-10.194,0-18.437,10.179-18.437,22.759C126.596,502.71,134.838,512.89,145.032,512.89z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    ),
  };

  return listIcons[type] || null;
}
