import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const activeCss = css`
  border-bottom: 2px solid #009870;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Container = styled(Link)`
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 20%);
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 3.5rem;
  transition: all 0.5s;
  margin-bottom: 20px;
  ${({ active }) => (active ? activeCss : "")}
  :hover {
    box-shadow: 2px 2px 15px 0 rgb(82 63 105 / 40%);
  }
  :before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: -1;
  }
  :after {
    background: #efefef;
    content: " ";
    width: 1000px;
    height: 1025px;
    position: absolute;
    bottom: 65%;
    left: -250px;
    border-radius: 35%;
    z-index: 0;
    animation: wave 10s linear infinite;
  }
  div,
  svg,
  img {
    z-index: 1;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }
  h1 {
    font-size: 1.2rem;
    color: var(--text);
  }
  span {
    font-size: 1.2rem;
    color: var(--gray);
    padding-top: 5px;
  }
`;
