import { useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Tab, Tabs } from "@material-ui/core";
import { Body, Header, TableContent } from "components/Table/style";
import { Col, Group, Row } from "styles";
import { compareValues } from "utils/globalFunctions";
import {
  HistoricOption,
  HistoricOptionContent,
} from "../../../../Connect/Broiler/Report/CreateReport/style";

export function Config(props) {
  const { config, setConfig, setGetData } = props;

  const [code, setCode] = useState({});

  const [equipment, setEquipment] = useState({});

  const [tab, setTab] = useState(0);

  const [days, setDays] = useState("");

  const addCode = () => {
    setConfig((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.codes.push(code);
      newState.codes = newState.codes.sort(compareValues("number", "asc"));
      return newState;
    });
    setCode({});
  };

  const removeCode = (index) => {
    setConfig((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.codes.splice(index, 1);
      return newState;
    });
    setCode({});
  };

  const addEquipment = () => {
    setConfig((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.equipments.push({ ...equipment, frequency: [] });
      newState.equipments = newState.equipments.sort(
        compareValues("number", "asc")
      );
      return newState;
    });
    setEquipment({});
  };

  const removeEquipment = (index) => {
    setConfig((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.equipments.splice(index, 1);
      return newState;
    });
  };

  const addFrequency = (e) => {
    e.preventDefault();
    setConfig((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.equipments = newState.equipments.map((equipment) => {
        equipment.frequency.push({
          days: +days,
          codes: [],
        });
        equipment.frequency = equipment.frequency.sort(
          compareValues("days", "asc")
        );
        return equipment;
      });
      return newState;
    });
    setDays("");
  };

  const removeFrequency = (index) => {
    setConfig((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.equipments = newState.equipments.map((equipment) => {
        equipment.frequency.splice(index, 1);
        return equipment;
      });

      return newState;
    });
  };

  const codeInputHandler = (value, index, indexFreq) => {
    setConfig((prevState) => {
      const nextState = JSON.parse(JSON.stringify(prevState));
      nextState.equipments[index].frequency[indexFreq].codes.push(value);
      return nextState;
    });
  };

  const saveConfig = async (e) => {
    const response = await Swal.fire({
      title: "Salvar Configurações",
      text: "Deseja salvar configurações de manutençao",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Salvar Configurações!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post("maintenanceconfig/" + props.match.params.id, config)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });

    if (response.value) {
      Swal.fire(
        "Salvar Configurações",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setGetData(true);
    }
  };

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="gear" color="#009870" size={30} />
          <h2 style={{ marginLeft: "10px" }}>
            Configuração de manutenção de Equipamentos
          </h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveConfig}
        >
          Salvar
        </Button>
      }
    >
      <>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          onChange={(e, value) => {
            setTab(value);
          }}
        >
          <Tab label="Tipo de manutenção" />
          <Tab label="Equipamentos" />
          <Tab label="Frenquência" />
        </Tabs>

        {tab === 0 && (
          <>
            <Row>
              <Col size={1}>
                <Input
                  type="input"
                  label="Código"
                  item={code}
                  setItem={setCode}
                  params="number"
                  inputType="number"
                />
              </Col>
              <Col size={2}>
                <Input
                  type="input"
                  label="Descrição"
                  item={code}
                  setItem={setCode}
                  params="description"
                />
              </Col>
              <Col size={2}>
                <label>&nbsp;</label>
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  onClick={addCode}
                >
                  Incluir Tipo de Manutenção
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableContent>
                  <Header>
                    <tr>
                      <th>Código</th>
                      <th>Descrição</th>
                      <th style={{ width: "70px" }}>
                        <Icons size={24} color="#F64E60" type="trash" />
                      </th>
                    </tr>
                  </Header>
                  <Body>
                    {config.codes.map((item, index) => (
                      <tr key={index + (item.codigo || "")}>
                        <td>{item.number}</td>
                        <td>{item.description}</td>
                        <td>
                          <Button
                            size="sm"
                            color="white"
                            bg="danger"
                            border="danger"
                            onClick={() => removeCode(index)}
                          >
                            <Icons size={12} color="#fff" type="trash" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </Body>
                </TableContent>
              </Col>
            </Row>
          </>
        )}
        {tab === 1 && (
          <>
            <Row>
              <Col size={1}>
                <Input
                  type="input"
                  label="Código"
                  item={equipment}
                  setItem={setEquipment}
                  params="number"
                  inputType="number"
                />
              </Col>
              <Col size={2}>
                <Input
                  type="input"
                  label="Equipamento"
                  item={equipment}
                  setItem={setEquipment}
                  params="equipment"
                />
              </Col>
              <Col size={2}>
                <label>&nbsp;</label>
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  onClick={addEquipment}
                >
                  Incluir Equipamento
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableContent>
                  <Header>
                    <tr>
                      <th>Código</th>
                      <th>Equipamento</th>
                      <th style={{ width: "70px" }}>
                        <Icons size={24} color="#F64E60" type="trash" />
                      </th>
                    </tr>
                  </Header>
                  <Body>
                    {config.equipments.map((item, index) => (
                      <tr key={index + (item.codigo || "")}>
                        <td>{item.number}</td>
                        <td>{item.equipment}</td>
                        <td>
                          <Button
                            size="sm"
                            color="white"
                            bg="danger"
                            border="danger"
                            onClick={() => removeEquipment(index)}
                          >
                            <Icons size={12} color="#fff" type="trash" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </Body>
                </TableContent>
              </Col>
            </Row>
          </>
        )}
        {tab === 2 && (
          <>
            <Row>
              <Col>
                <TableContent>
                  <Header>
                    <tr>
                      <th rowSpan={2} style={{ width: 70 }}>
                        Código
                      </th>
                      <th style={{ width: 250 }} rowSpan={2}>
                        Equipamento
                      </th>
                      <th
                        colSpan={
                          config.equipments[0]
                            ? config.equipments[0].frequency.length
                            : 0
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          Frenquência (Dias){" "}
                          {config.equipments[0] && (
                            <form onSubmit={addFrequency}>
                              <Group>
                                <Input
                                  type="inputOnly"
                                  placeholder="Dias*"
                                  item={days}
                                  setItem={setDays}
                                  required
                                />
                                <Button
                                  color="white"
                                  bg="default"
                                  border="default"
                                >
                                  <Icons size={18} color="#fff" type="plus" />
                                </Button>
                              </Group>
                            </form>
                          )}
                        </div>
                      </th>
                    </tr>
                    {config.equipments[0] && (
                      <tr>
                        {config.equipments[0].frequency.map((item, index) => (
                          <th key={index}>{item.days}</th>
                        ))}
                      </tr>
                    )}
                  </Header>
                  <Body>
                    {config.equipments.map((item, index) => (
                      <tr key={index + (item.codigo || "")}>
                        <td>{item.number}</td>
                        <td>{item.equipment}</td>
                        {config.equipments[0] &&
                          config.equipments[0].frequency.map(
                            (item, indexFreq) => (
                              <td key={indexFreq}>
                                <Input
                                  item={config}
                                  onChange={(e) =>
                                    codeInputHandler(
                                      e.target.value,
                                      index,
                                      indexFreq
                                    )
                                  }
                                  type="selectOnly"
                                  placeholder="Selecione o Tipo de Manutenção"
                                  options={config.codes
                                    .filter(
                                      ({ number }) =>
                                        config.equipments[index].frequency[
                                          indexFreq
                                        ].codes.indexOf(number) === -1
                                    )
                                    .map(({ number, description }) => ({
                                      value: number,
                                      label: `${number}-${description}`,
                                    }))}
                                />
                                <HistoricOptionContent>
                                  {config.equipments[index].frequency[
                                    indexFreq
                                  ].codes.map((item, indexCode) => (
                                    <HistoricOption key={item}>
                                      <b>{item}</b>
                                      <Icons
                                        type="close"
                                        size={10}
                                        color="#fff"
                                        onClick={() =>
                                          setConfig((prevState) => {
                                            const nextState = JSON.parse(
                                              JSON.stringify(prevState)
                                            );
                                            nextState.equipments[
                                              index
                                            ].frequency[indexFreq].codes.splice(
                                              indexCode,
                                              1
                                            );
                                            return nextState;
                                          })
                                        }
                                      ></Icons>
                                    </HistoricOption>
                                  ))}
                                </HistoricOptionContent>
                              </td>
                            )
                          )}
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      {config.equipments[0] &&
                        config.equipments[0].frequency.map((item, index) => (
                          <td key={index}>
                            <Button
                              size="sm"
                              color="white"
                              bg="danger"
                              border="danger"
                              onClick={() => removeFrequency(index)}
                            >
                              <Icons size={12} color="#fff" type="trash" />
                            </Button>
                          </td>
                        ))}
                    </tr>
                  </Body>
                </TableContent>
              </Col>
            </Row>
          </>
        )}
      </>
    </Modal>
  );
}
