import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TherapeuticMedication } from "./TherapeuticMedication";
import { PerformanceEnhancer } from "./PerformanceEnhancer";

export function Health(props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Medicação Terapeutica" />
        <Tab label="Melhorador de Desempenho" />
      </Tabs>

      {tab === 0 && <TherapeuticMedication {...props} />}
      {tab === 1 && <PerformanceEnhancer {...props} />}
    </>
  );
}
