import { Col } from "styles";
import {
  BeatWrapper,
  BeatFill,
  InputPercentage,
  RepetitionsWrapper,
  CleaningText,
} from "./style";
import { useEffect, useState } from "react";

export function RepetitionBox({ index, data, setData, updateBeat }) {
  const [heightFill, setHeightFill] = useState({
    beat1: "0%",
    beat2: "0%",
    beat3: "0%",
  });

  const [beats, setBeats] = useState({
    beat1: "0%",
    beat2: "Limpeza",
    beat3: "0%",
  });

  const beat1Value = data["repetitions"][`repetition${index}`]["beat1"];
  const beat3Value = data["repetitions"][`repetition${index}`]["beat3"];
  const repetition = data["repetitions"][`repetition${index}`];

  useEffect(() => {
    setHeightFill((prevState) => {
      const updatedHeightFill = { ...prevState };

      for (let i = 1; i <= 3; i++) {
        if (repetition[`beat${i}`]?.toString().endsWith("%")) {
          const value = repetition[`beat${i}`].toString().replace(",", ".");
          updatedHeightFill[`beat${i}`] = value;
        } else if (repetition[`beat${i}`]?.toString()) {
          const value = repetition[`beat${i}`].toString().replace(",", ".");
          updatedHeightFill[`beat${i}`] = `${value}%`;
        }
      }

      return updatedHeightFill;
    });
  }, [repetition]);

  const handleInputPercentage = (e) => {
    const { name: beatIndex, value } = e.target;
    const regex = /^[0-9.,]+$/;
    const countCommas = (value.match(/,/g) || []).length;
    const countDots = (value.match(/\./g) || []).length;

    if (!regex.test(value) || countCommas > 1 || countDots > 1) {
      if (value !== "") {
        return;
      }
    }
    if (countCommas === 1 && countDots === 1) {
      return;
    }
    let number = parseFloat(value);

    if (number < 0) {
      number = 0;
      setBeats((prevState) => ({ ...prevState, [beatIndex]: number }));
      updateBeat(beatIndex, number, index);
      return;
    }

    if (number > 100) {
      number = 100;
      setBeats((prevState) => ({ ...prevState, [beatIndex]: number }));
      updateBeat(beatIndex, number, index);

      return;
    }
    setBeats((prevState) => ({ ...prevState, [beatIndex]: value }));
    updateBeat(beatIndex, value, index);
  };

  const handleBlur = (e) => {
    const { name: beatIndex, value } = e.target;
    if (!value) {
      setBeats((prevState) => ({ ...prevState, [beatIndex]: "0%" }));
      updateBeat(beatIndex, "0%", index);
      return;
    }
    let number;
    if (!value.endsWith("%")) {
      number = parseFloat(value.replace(",", ".")).toFixed(2);
      number = number.toString().replace(".", ",");
      setBeats((prevState) => ({ ...prevState, [beatIndex]: `${number}%` }));
      updateBeat(beatIndex, `${number}%`, index);
    }
  };

  const handleFocus = (e) => {
    const { name: beatIndex } = e.target;
    let number;
    const beat = beats[beatIndex];
    if (beat.endsWith("%")) {
      number = beat.slice(0, -1);
    }
    if (Math.round(number) === 0) {
      setBeats((prevState) => ({ ...prevState, [beatIndex]: "" }));
      updateBeat(beatIndex, "", index);

      return;
    }
    setBeats((prevState) => ({ ...prevState, [beatIndex]: number }));
    updateBeat(beatIndex, number, index);
  };

  return (
    <Col>
      <h2 style={{ textAlign: "center" }}>{`Repetição ${index}`}</h2>

      <RepetitionsWrapper>
        <div>
          <BeatWrapper>
            <BeatFill height={heightFill["beat1"]}></BeatFill>

            <InputPercentage
              name="beat1"
              value={beat1Value}
              onChange={handleInputPercentage}
              onFocus={handleFocus}
              onBlur={handleBlur}
              type="text"
            />
          </BeatWrapper>
          <h3 style={{ textAlign: "center" }}>Batida 1</h3>
        </div>

        <div>
          <BeatWrapper>
            <CleaningText>Limpeza</CleaningText>
          </BeatWrapper>
          <h3 style={{ textAlign: "center" }}>Batida 2</h3>
        </div>

        <div>
          <BeatWrapper>
            <BeatFill height={heightFill["beat3"]}></BeatFill>

            <InputPercentage
              name="beat3"
              value={beat3Value}
              onChange={handleInputPercentage}
              onFocus={handleFocus}
              onBlur={handleBlur}
              type="text"
            />
          </BeatWrapper>
          <h3 style={{ textAlign: "center" }}>Batida 3</h3>
        </div>
      </RepetitionsWrapper>
    </Col>
  );
}
