import React, { useState, useEffect, useContext } from "react";
import { SubHeader } from "components/SubHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { api } from "services/api";
import Swal from "sweetalert2";
import UserProfileContext from "contexts/profile";
import { Input } from "components/Input";
import { hasPermission } from "utils/globalFunctions";
import { Icons } from "components/Icons";
import { ViewLoader } from "components/ViewLoader";
import { Block, BlockBody, BlockHeader, Col, Row, Separator } from "styles";
import { ModuleChildren, ModuleContent, ModuleItem } from "./style";
import { Button } from "components/Button";

export function CreateEdit(props) {
  const profileContext = useContext(UserProfileContext);

  const [profile, setProfile] = useState({
    name: "",
    description: "",
    modules: [],
    roles: [],
    status: true,
    _id: "",
  });

  const [roles, setRoles] = useState([]);

  const [getProfile, setGetProfile] = useState(true);

  const [loading, setLoading] = useState(true);

  const modulesHandler = (modules, e) => {
    return modules.map((module) => {
      const [key, type] = e.target.name.split("-");
      if (
        module.key === key ||
        module.parentList.indexOf(key) > -1 ||
        (module.childrenList.indexOf(key) > -1 && e.target.checked)
      ) {
        module[type] = e.target.checked;
        if (type === "edit") {
          module.accessOnly = e.target.checked;
        }
      }
      if (module.children) {
        module.children = modulesHandler(module.children, e);
      }
      return module;
    });
  };

  const switchHandler = (e) => {
    const newProfile = { ...profile };
    newProfile.modules = modulesHandler(newProfile.modules, e);
    setProfile(newProfile);
  };

  const tableModuleContructor = (items, chidren) =>
    items.map((item) => tableModuleItem(item, chidren));

  const tableModuleItem = (item, chidren) => [
    <ModuleItem
      isChildren={chidren.toString()}
      key={item.key}
      style={item.padding ? { paddingLeft: `${item.padding}px` } : null}
    >
      <span>{item.label}</span>
      <ModuleChildren>
        <FormControlLabel
          control={
            <Switch
              checked={item.edit}
              onChange={switchHandler}
              name={`${item.key}-edit`}
            />
          }
          label="Criar/Editar"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <Switch
              checked={item.accessOnly}
              onChange={switchHandler}
              name={`${item.key}-accessOnly`}
            />
          }
          label="Visualizar"
          labelPlacement="start"
        />
      </ModuleChildren>
    </ModuleItem>,
    item.children ? tableModuleContructor(item.children, true) : null,
  ];

  const rolesHandler = (key) => {
    const copyProfile = JSON.parse(JSON.stringify(profile));
    const index = copyProfile.roles.indexOf(key);
    if (index > -1) {
      copyProfile.roles.splice(index, 1);
    } else {
      copyProfile.roles.push(key);
    }
    setProfile(copyProfile);
  };

  const saveProfile = async (e) => {
    e.preventDefault();
    const requestProfile = { ...profile };
    if (!props.match.params.id) {
      const response = await Swal.fire({
        title: "Criar Perfil",
        text: "Deseja confirmar criação do Perfil",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Perfil!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("profile", requestProfile).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Perfil",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          props.history.replace(
            "/basicregistration/profile/edit/" + response.value.data.id
          );
          setGetProfile(true);
        }
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Perfil",
        text: "Deseja confirmar edição da Perfil",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Perfil!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("profile/" + props.match.params.id, requestProfile)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Perfil",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetProfile(true);
      }
    }
  };

  useEffect(() => {
    const loadProfile = async () => {
      setLoading(true);
      const profileCopy = { ...profile };
      const responseModules = await api.get("modules");
      if (!responseModules.data) {
        Swal.fire(
          "Editar Perfil",
          "Não foi possível buscar o módulos",
          "error"
        );
      }
      const responseRoles = await api.get("roles");
      if (!responseRoles.data) {
        Swal.fire("Editar Perfil", "Não foi possível buscar o roles", "error");
      }
      setRoles(responseRoles.data);
      if (props.match.params.id) {
        const response = await api.get(`profile/${props.match.params.id}`);
        if (!response.data) {
          Swal.fire(
            "Editar Perfil",
            "Não foi possível buscar o perfís",
            "error"
          );
        }
        Object.keys(response.data).forEach((key) => {
          profileCopy[key] = response.data[key];
        });
        const fillModules = (modules, profileModules) => {
          return modules.map((module) => {
            profileModules.forEach((profileModule) => {
              if (module.key === profileModule.key) {
                if (module.children && profileModule.children) {
                  module.children = fillModules(
                    module.children,
                    profileModule.children
                  );
                }
                module.edit = profileModule.edit;
                module.accessOnly = profileModule.accessOnly;
              }
            });
            return module;
          });
        };
        profileCopy.modules = fillModules(
          responseModules.data,
          response.data.modules
        );
      } else {
        profileCopy.modules = responseModules.data;
      }
      setProfile(profileCopy);
      setLoading(false);
    };
    if (getProfile) {
      setGetProfile(false);
      loadProfile();
    }
  }, [props.match, getProfile, profile]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Perfil"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Perfís",
            to: "/basicregistration/profile/1/30/index/{}",
          },
          {
            label: hasPermission(profileContext, "profile", "edit")
              ? "Perfil"
              : props.match.params.id
              ? "Editar Perfil"
              : "Novo Perfil",
          },
        ]}
        icon={<Icons type="profile" size="24" color="#fff" />}
      />
      {!loading && (
        <Block className="animate__animated animate__fadeInUp">
          <BlockHeader>
            Preencha os dados do Perfil
            <Input
              type="switch"
              item={profile}
              setItem={setProfile}
              params="status"
              label="status"
              labelPlacement="start"
            />
          </BlockHeader>
          <BlockBody>
            <form onSubmit={(e) => saveProfile(e)}>
              <Row>
                <Col>
                  <Input
                    type="input"
                    item={profile}
                    setItem={setProfile}
                    params="name"
                    label="Nome"
                    placeholder="Nome"
                    capitalize={true}
                  />
                  <Input
                    type="textarea"
                    item={profile}
                    setItem={setProfile}
                    params="description"
                    label="Descrição"
                    placeholder="Descrição"
                    capitalize={true}
                    rows={5}
                  />

                  <label>Permissões</label>
                  {roles.map((item) => (
                    <Input
                      key={item.key}
                      type="switch"
                      label={`${item.label} - ${item.description}`}
                      onChange={() => rolesHandler(item.key)}
                      value={profile.roles.indexOf(item.key) > -1}
                    />
                  ))}
                </Col>
                <Col>
                  <label className="form-label">Módulos</label>
                  <ModuleContent>
                    {tableModuleContructor(profile.modules, false)}
                  </ModuleContent>
                </Col>
              </Row>
              <Separator />
              {hasPermission(profileContext, "profile", "edit") ? (
                <Row>
                  <Col style={{ alignItems: "center" }}>
                    <Button
                      type="submit"
                      bg="default"
                      border="default"
                      color="white"
                      disabled={!hasPermission(profileContext, "user", "edit")}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </form>
          </BlockBody>
        </Block>
      )}
    </>
  );
}
