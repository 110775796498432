import { PercentageInput } from "./style";
import { useState, useEffect, useCallback } from "react";

export function Probability({ handlePercentage, beat, data, setData }) {
  const [prob, setProb] = useState("");
  const [colorRef, setColorRef] = useState("");

  const getResultByProb = useCallback((prob) => {
    const probResults = {
      uniform: "Mistura uniforme",
      median: "Mistura mediana",
      nonUniform: "Mistura não uniforme",
    };

    const colorRefs = {
      uniform: "var(--green)",
      median: "var(--yellow)",
      nonUniform: "var(--red)",
    };
    if (prob < 1) {
      setColorRef(colorRefs.nonUniform);
      return probResults.nonUniform;
    } else if (prob > 5) {
      setColorRef(colorRefs.uniform);
      return probResults.uniform;
    } else {
      setColorRef(colorRefs.median);
      return probResults.median;
    }
  }, []);

  const handleProbability = (e) => {
    if (!e.target.value) {
      setColorRef("");
    }
    const newProb = handlePercentage(e, setProb);
    if (newProb && !isNaN(+newProb)) {
      const result = getResultByProb(newProb);

      setData((prevState) => {
        const newData = { ...prevState };
        newData.microtracerBeats[`beat${beat}`].probability = newProb;
        newData.microtracerBeats[`beat${beat}`].probResult = result;
        return newData;
      });
    }
  };

  useEffect(() => {
    setProb(data.microtracerBeats[`beat${beat}`].probability);
    if (!isNaN(+data.microtracerBeats[`beat${beat}`].probability)) {
      if (+data.microtracerBeats[`beat${beat}`].probability > 0) {
        getResultByProb(data.microtracerBeats[`beat${beat}`].probability);
      }
    }
  }, [data, beat, getResultByProb]);

  return (
    <div style={{ padding: "5px" }}>
      <PercentageInput
        value={prob}
        type="number"
        colorRef={colorRef}
        onChange={(e) => handleProbability(e)}
      />
    </div>
  );
}
