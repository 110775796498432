import { useState, useEffect, useContext } from "react";
import { api } from "services/api";
import { getInitialObject } from "utils/globalFunctions";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import { Customer } from "./Customer";
import { Premix } from "./Premix";
import { Enzymes } from "./Enzymes";
import { Health } from "./Health";
import { Minerals } from "./Minerals";
import { Additions } from "./Additions";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";
import UpdateList from "contexts/updateList";
export function CreateEdit(props) {
  const { setUpdateList } = useContext(UpdateList);

  const broilerInit = {
    _id: null,
    dailySlaughter: "",
    healthScore: "",
    healthChallenge: "",
    knownAgents: "",
    antibiogramRecent: "",
    export: false,
    internMarket: false,
    exportCuts: "",
    exportPlaces: [],
    premixProductionYear: "",
    premixComposition: "",
    premixPlaxma: false,
    premixHemoglobin: false,
    premixAnimalFeed: false,
    premixAnimalFeedType: "",
    premixSteps: {
      pre: {
        feedProductionYear: "",
        physicalForm: "",
        provider: "",
        commonDescription: "",
        pxClass: "",
      },
      initial: {
        feedProductionYear: "",
        physicalForm: "",
        provider: "",
        commonDescription: "",
        pxClass: "",
      },
      growth: {
        feedProductionYear: "",
        physicalForm: "",
        provider: "",
        commonDescription: "",
        pxClass: "",
      },
      growth2: {
        feedProductionYear: "",
        physicalForm: "",
        provider: "",
        commonDescription: "",
        pxClass: "",
      },
      final: {
        feedProductionYear: "",
        physicalForm: "",
        provider: "",
        commonDescription: "",
        pxClass: "",
      },
    },
    enzymes: {
      phytase: {
        pre: {
          checked: false,
          product: "",
        },
        initial: {
          checked: false,
          product: "",
        },
        growth: {
          checked: false,
          product: "",
        },
        growth2: {
          checked: false,
          product: "",
        },
        final: {
          checked: false,
          product: "",
        },
      },
      phytaseHidosing: {
        pre: {
          checked: false,
          type: "",
          product: "",
          dose: "",
        },
        initial: {
          checked: false,
          type: "",
          product: "",
          dose: "",
        },
        growth: {
          checked: false,
          type: "",
          product: "",
          dose: "",
        },
        growth2: {
          checked: false,
          type: "",
          product: "",
          dose: "",
        },
        final: {
          checked: false,
          type: "",
          product: "",
          dose: "",
        },
        approved: {
          checked: false,
          reason: "",
        },
      },
      carbohydraseBlends: {
        pre: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        approved: {
          checked: false,
          reason: "",
        },
      },
      enziPacProProtease: {
        pre: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          goal: "",
          dose: "",
        },
        approved: {
          checked: false,
          reason: "",
        },
      },
    },
    health: {
      anticoccidian: {
        qualipac: false,
        action: "",
        why: "",
        moleculePre: "",
        moleculeInitial: "",
        moleculeGrowth: "",
        moleculeGrowth2: "",
        moleculeFinal: "",
        rotation: "",
        powderGranular: "",
        additional: false,
      },
      therapeuticMedication: {
        pre: {
          checked: false,
          type: "",
          product: "",
          dose: "",
          water: false,
        },
        initial: {
          checked: false,
          type: "",
          product: "",
          dose: "",
          water: false,
        },
        growth: {
          checked: false,
          type: "",
          product: "",
          dose: "",
          water: false,
        },
        growth2: {
          checked: false,
          type: "",
          product: "",
          dose: "",
          water: false,
        },
        final: {
          checked: false,
          type: "",
          product: "",
          dose: "",
          water: false,
        },
      },
      performanceEnhancer: {
        pre: {
          checked: false,
          product: "",
          from: "",
          to: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          from: "",
          to: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          from: "",
          to: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          from: "",
          to: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          from: "",
          to: "",
          dose: "",
        },
      },
    },
    minerals: {
      organics: {
        pre: {
          checked: false,
          product: "",
          reason: "",
        },
        initial: {
          checked: false,
          product: "",
          reason: "",
        },
        growth: {
          checked: false,
          product: "",
          reason: "",
        },
        growth2: {
          checked: false,
          product: "",
          reason: "",
        },
        final: {
          checked: false,
          product: "",
          reason: "",
        },
        unknownConcept: false,
      },
      zincOxide: {
        pre: {
          checked: false,
          ppm: "",
        },
        initial: {
          checked: false,
          ppm: "",
        },
        growth: {
          checked: false,
          ppm: "",
        },
        growth2: {
          checked: false,
          ppm: "",
        },
        final: {
          checked: false,
          ppm: "",
        },
      },
      copperSulfate: {
        pre: {
          checked: false,
          ppm: "",
        },
        initial: {
          checked: false,
          ppm: "",
        },
        growth: {
          checked: false,
          ppm: "",
        },
        growth2: {
          checked: false,
          ppm: "",
        },
        final: {
          checked: false,
          ppm: "",
        },
      },
    },
    additions: {
      organicAcids: {
        pre: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        unknownConcept: false,
      },
      mycotoxinAdsorbents: {
        pre: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        unknownConcept: false,
      },
      essencialOils: {
        pre: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
      },
      prebiotics: {
        pre: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
      },
      probiotics: {
        pre: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        initial: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        growth2: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
        final: {
          checked: false,
          product: "",
          reason: "",
          dose: "",
        },
      },
      others: {
        emulsifiers: false,
        pigmentation: false,
        pigmentationType: "",
        pre: {
          yellowppm: "",
          redppm: "",
        },
        initial: {
          yellowppm: "",
          redppm: "",
        },
        growth: {
          yellowppm: "",
          redppm: "",
        },
        growth2: {
          yellowppm: "",
          redppm: "",
        },
        final: {
          yellowppm: "",
          redppm: "",
        },
      },
    },
  };

  const [loading, setLoading] = useState(true);

  const [getBroiler, setGetBroiler] = useState(true);

  const [config, setConfig] = useState({});

  const [broiler, setBroiler] = useState(broilerInit);

  const [tab, setTab] = useState(0);

  const saveBroiler = async (e) => {
    const requestBroiler = { ...broiler };
    requestBroiler.customer = props.customer;
    requestBroiler._id = props.id || null;
    const response = await Swal.fire({
      title: "Histórico de Produção de Frango de Corte",
      text: "Deseja salvar alterações?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Salvar!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        props.id
          ? await api
              .put("broilerhistoric/" + props.id, requestBroiler)
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              }))
          : await api.post("broilerhistoric", requestBroiler).catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
    });
    if (response.value) {
      Swal.fire(
        "Histórico de Produção de Frango de Corte",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
    }
    setUpdateList(true);
  };

  useEffect(() => {
    const loadBroiler = async () => {
      setLoading(true);
      const responseConfig = await api.get("broilerhistoric/config", {
        params: {
          query:
            "premixComposition premixAnimalFeedType premixPhysicalForm premixCommonDescription " +
            "customerHealthScore customerExportCuts enzymesPhytaseProduct enzymesPhytaseType " +
            "enzymesCarbohydraseBlendsProduct enzymesEnziPacProProteaseProduct healthAnticoccidianMolecule " +
            "healthTherapeuticMedicationType healthTherapeuticMedicationProduct healthPerformanceEnhancerProduct " +
            "mineralsOrganicsProduct additionsOrganicAcidsProduct additionsMycotoxinAdsorbentsProduct " +
            "additionsEssencialOilsProduct additionsPrebioticsProduct additionsProbioticsProduct othersPigmentationType",
        },
      });

      if (!responseConfig.data) {
        Swal.fire(
          "Editar Configurações",
          "Não foi possível buscar configurações",
          "error"
        );
      }
      setConfig([...responseConfig.data]);

      const response = await api.get(
        props.id
          ? `broilerhistoric/edit/${props.id}`
          : `broilerhistoric/${props.customer}`
      );
      if (!response.data) {
        return setLoading(false);
      }
      const broilerCopy = getInitialObject({ ...broiler }, response.data);

      setBroiler(broilerCopy);
      setLoading(false);
    };
    if (getBroiler) {
      setGetBroiler(false);
      loadBroiler();
    }
  }, [broiler, getBroiler, props.customer, props.id]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="broiler" color="#009870" size={30} />
          <h2>Histórico - Frango de Corte</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveBroiler}
        >
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Cliente" />
            <Tab label="Premix/Ração" />
            <Tab label="Enzimas" />
            <Tab label="Saúde" />
            <Tab label="Minerais" />
            <Tab label="Aditivos" />
          </Tabs>
          {tab === 0 && (
            <Customer
              broiler={broiler}
              setBroiler={setBroiler}
              config={config}
              editing={props.editing}
            />
          )}
          {tab === 1 && (
            <Premix broiler={broiler} setBroiler={setBroiler} config={config} />
          )}
          {tab === 2 && (
            <Enzymes
              broiler={broiler}
              setBroiler={setBroiler}
              config={config}
            />
          )}
          {tab === 3 && (
            <Health broiler={broiler} setBroiler={setBroiler} config={config} />
          )}
          {tab === 4 && (
            <Minerals
              broiler={broiler}
              setBroiler={setBroiler}
              config={config}
            />
          )}

          {tab === 5 && (
            <Additions
              broiler={broiler}
              setBroiler={setBroiler}
              config={config}
            />
          )}
        </>
      )}
    </Modal>
  );
}
