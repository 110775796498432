import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Col, Row } from "styles";
import ReactLoading from "react-loading";
import { MgMethod } from "./MgMethod";
import { MicroTracerMethod } from "./MicrotracerMethod";

import { CrossContamination } from "./CrossContamination";
import { handlePercentage } from "utils/globalFunctions";

export function MixingHomogeneity(props) {
  const [data, setData] = useState({
    inclusionPerTon: "",
    amountOfBeats: "",
    cvBeats: [],
    residualCvBeat: "",
    blankCvBeat: "",
    cvAverage: "",
    recoveryRates: [],
    residualRecoveryRate: "",
    recoveryRateAverage: "",
    crossContamination: "",
    microtracerBeats: {
      beat1: {
        recoveryRate: "",
        probability: "",
        probResult: "-",
        recoveryResult: "-",
        conclusion: "",
      },
      beat2: {
        recoveryRate: "",
        probability: "",
        probResult: "-",
        recoveryResult: "-",
        conclusion: "",
      },
      beat3: {
        recoveryRate: "",
        probability: "",
        probResult: "-",
        recoveryResult: "-",
        conclusion: "",
      },
      beat4: {
        recoveryRate: "",
        probability: "",
        probResult: "-",
        recoveryResult: "-",
        conclusion: "",
      },
      beat5: {
        recoveryRate: "",
        probability: "",
        probResult: "-",
        recoveryResult: "-",
        conclusion: "",
      },
    },
  });
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState(true);

  const amountOfBeatsHandler = useCallback((e) => {
    setData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));

      newState.amountOfBeats = +e.target.value;
      const beats = [];
      for (let i = 0; i < newState.amountOfBeats - 1; i++) {
        beats.push("");
      }
      newState.cvBeats = [...beats];
      newState.recoveryRates = [...beats];
      const amount = +e.target.value;
      const maxBeats = 5;
      const minBeats = 1;
      if (amount < maxBeats) {
        for (let i = amount + 1; i <= maxBeats; i++) {
          newState.microtracerBeats[`beat${i}`].recoveryRate = "";
          newState.microtracerBeats[`beat${i}`].probability = "";
          newState.microtracerBeats[`beat${i}`].probResult = "-";
          newState.microtracerBeats[`beat${i}`].recoveryResult = "-";
          newState.microtracerBeats[`beat${i}`].conclusion = "";
        }
      }
      if (amount < minBeats) {
        newState.crossContamination = "";
      }
      return newState;
    });
  }, []);

  const evaluationMethodHandler = useCallback((e) => {
    setData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.evaluationMethod = e.target.value;
      return newState;
    });
  }, []);

  const getAverage = (value, param, paramAv, index) => {
    setData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[param][index] = value;
      newState[paramAv] =
        Math.round(
          (newState[param].reduce((a, b) => +(a || 0) + +(b || 0)) /
            (newState.amountOfBeats - 1)) *
            100
        ) / 100;
      return newState;
    });
  };

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };

    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar homogeneidade de mistura",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar homogeneidade de mistura!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`gate798/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });

    if (response.value) {
      Swal.fire(
        "Criar homogeneidade de mistura",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }
  }, [data, props]);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `gate798/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire(
            "Editar homogeneidade de mistura",
            "Não foi possível buscar a homogeneidade de mistura",
            "error"
          );
        }
        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Homogeneidade de mistura</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Row>
            <Col size={1}>
              <Input
                type="select"
                item={data}
                setItem={setData}
                params="evaluationMethod"
                label="Método de avaliação de mistura"
                placeholder="Selecione o método"
                inputType="number"
                value={data?.evaluationMethod}
                onChange={evaluationMethodHandler}
                options={[
                  {
                    value: "Mg",
                  },
                  {
                    value: "Microtracers",
                  },
                ]}
              />
            </Col>
            <Col size={1}>
              {data?.evaluationMethod && (
                <Input
                  type="input"
                  item={data}
                  setItem={setData}
                  params={`inclusionPerTon`}
                  label={`Inclusão / ton`}
                  inputType="number"
                />
              )}
            </Col>
            <Col size={2} />
          </Row>

          <Row>
            <Col size={1}>
              <Input
                type="select"
                item={data}
                setItem={setData}
                params="amountOfBeats"
                label="Quantidades de batidas para teste"
                placeholder="Selecione a qtd de batidas"
                inputType="number"
                value={data.amountOfBeats}
                onChange={amountOfBeatsHandler}
                options={[
                  {
                    value: "1",
                  },
                  {
                    value: "2",
                  },
                  {
                    value: "3",
                  },
                  {
                    value: "4",
                  },
                  {
                    value: "5",
                  },
                ]}
              />
            </Col>
            <Col size={1}>
              {data?.evaluationMethod !== "Mg" && data.amountOfBeats && (
                <CrossContamination
                  data={data}
                  setData={setData}
                  handlePercentage={handlePercentage}
                />
              )}
            </Col>
            <Col size={2} />
          </Row>

          {data.amountOfBeats && data?.evaluationMethod && (
            <>
              {data.evaluationMethod === "Mg" ? (
                <MgMethod
                  data={data}
                  setData={setData}
                  getAverage={getAverage}
                />
              ) : (
                <MicroTracerMethod data={data} setData={setData} />
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
}
