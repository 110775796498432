import React, { useState, useCallback } from "react";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Block, BlockBody, BlockHeader } from "styles";
import { Button } from "components/Button/style";
import { Input } from "components/Input";
import { api } from "services/api";
import { Tab, Tabs } from "@material-ui/core";
import { IngredientRow } from "./style";
import { IngredientsList } from "./IngredientsList";

export function Factory(props) {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ingredient, setIngredient] = useState("");
  const [items, setItems] = useState([]);

  const getIngredients = useCallback(async () => {
    try {
      setLoading(true);
      const items = await api.get(`ingredients`);
      const newItems = items.data.docs.map((item) => ({
        _id: item._id,
        name: item.ingredient,
        checked: item.checked,
      }));
      setItems(newItems);
    } catch (error) {
      console.log("Erro ao buscar ingredientes");
    } finally {
      setLoading(false);
    }
  }, []);

  const saveIngredient = async () => {
    if (!ingredient.trim()) return;
    try {
      setLoading(true);
      await api.post(`ingredients`, { ingredient });
      setIngredient("");
      await getIngredients();
    } catch (error) {
      console.log("Erro ao adicionar ingredientes");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <SubHeader
        {...props}
        title="Fábrica"
        breadcrumbs={[
          { label: "Configuração" },
          {
            label: "Frango de Corte",
          },
          {
            label: "Fábrica",
          },
        ]}
        icon={<Icons type="factory" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Ingredientes" />
          </Tabs>
        </BlockHeader>
        <BlockBody>
          {tab === 0 && (
            <>
              <IngredientRow alignItems="flex-start" justifyContent="center">
                <Input
                  placeholder="Novo ingrediente"
                  type="input"
                  item={ingredient}
                  setItem={setIngredient}
                />
                <Button
                  color="white"
                  bg="default"
                  border="none"
                  type="button"
                  disabled={loading}
                  onClick={saveIngredient}
                >
                  <Icons type="plus" color="#fff" size={18} />
                  Adicionar
                </Button>
              </IngredientRow>

              <IngredientsList
                match={props.match}
                items={items}
                setItems={setItems}
                loading={loading}
                setLoading={setLoading}
                getIngredients={getIngredients}
              />
            </>
          )}
        </BlockBody>
      </Block>
    </>
  );
}
