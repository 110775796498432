import { useEffect } from "react";
import { format } from "date-fns";
import subDays from "date-fns/subDays";
import { Input } from "components/Input";
import { Col, Row } from "styles";

export function FarmData({ checklist, setChecklist }) {
  useEffect(() => {
    if (checklist.visitDate) {
      const arrayDate = checklist.visitDate.split("/");
      const d = new Date(arrayDate[2], +arrayDate[1] - 1, arrayDate[0]);
      if (!isNaN(d.getTime()) && checklist.age) {
        setChecklist((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState.accommodatingDate = format(
            subDays(d, +prevState.age),
            "dd/MM/yyyy"
          );
          return newState;
        });
      }
    }
  }, [checklist.visitDate, checklist.age, setChecklist]);

  return (
    <>
      <Row>
        <Col size={2}>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="technicalManager"
            type="input"
            label="Responsável Técnico"
            placeholder="Digite o nome"
          />
        </Col>
        <Col size={1}>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="averageWeight"
            type="input"
            inputType="number"
            label="Peso médio do lote em kg"
          />
        </Col>
        <Col size={1}>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="ageOfWeight"
            type="input"
            inputType="number"
            label="Idade em que foi pesada"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="visitDate"
            type="inputMask"
            label="Data da Visita"
            mask="99/99/9999"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="integrated"
            type="input"
            label="Integrado"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="lotNumber"
            type="input"
            label="Nº Lote"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="bedNumber"
            type="input"
            label="Nº Cama"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="lineage"
            type="select"
            placeholder="Linhagem"
            label="Linhagem"
            options={["Cobb", "Ross", "Hubbard", "Misto"].map((item) => ({
              value: item,
            }))}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="age"
            inputType="number"
            type="input"
            label="Idade do Lote"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="incubatory"
            type="input"
            label="Incubatório"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="interval"
            type="input"
            label="Intervalo"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="density"
            type="input"
            label="Densidade"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="mortality"
            type="input"
            label="Mortalidade"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="gender"
            type="select"
            placeholder="Selecione o sexo"
            label="Sexo"
            options={["Macho", "Femea", "Misto"].map((item) => ({
              value: item,
            }))}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="accommodatingDate"
            type="inputMask"
            label="Data do Alojamento"
            mask="99/99/9999"
          />
        </Col>
      </Row>
    </>
  );
}
