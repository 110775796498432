import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
  }
  @media (max-width: 1024px) {
    background: #fff;
    box-shadow: 0 1px 9px -3px rgb(0 0 0 / 75%);
    z-index: 1001;
    position: fixed;
    -webkit-overflow-scrolling: touch;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    width: 275px !important;
    transition: left 0.3s ease, right 0.3s ease;
    left: -295px;
  }
`;

export const Content = styled.div`
  @media (max-width: 1024px) {
    background-color: #fff;
  }
  @media (min-width: 1025px) {
    display: flex;
    align-items: stretch;
    height: 100%;
    margin: 0;
  }
`;

export const Ul = styled.ul`
  @media (min-width: 1025px) {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
  }
  @media (max-width: 1024px) {
    margin-bottom: 0;
    padding-bottom: 10px;
    margin: 0;
    list-style: none;
    padding: 30px 0;
  }
`;

export const Li = styled.li`
  @media (min-width: 1025px) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0.15rem;
  }
  @media (max-width: 1024px) {
    transition: background-color 0.3s;
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    float: none;
    padding: 0;
  }
`;

const ToggleStyle = css`
  @media (min-width: 1025px) {
    background-color: ${({ isactive }) =>
      isactive === "true" ? "hsla(0, 0%, 100%, 0.1)" : "none"};
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    position: relative;
    vertical-align: middle;
    align-items: stretch;
    outline: none !important;
    text-decoration: none;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    background-color: ${({ isactive }) =>
      isactive === "true" ? "#f1f1f1" : "fff"};
    padding: 9px 30px;
    cursor: pointer;
    min-height: 44px;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    margin: 0;
    text-decoration: none;
    position: relative;
    outline: none;
  }
  &:hover {
    background-color: hsla(0, 0%, 100%, 0.1);
  }
`;

export const LinkMenu = styled(Link)`
  ${ToggleStyle}
`;

export const Span = styled.span`
  @media (min-width: 1025px) {
    color: #74788d;
    font-weight: 500;
    font-size: 1rem;
    text-transform: none;
    width: auto;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0;
  }

  @media (max-width: 1024px) {
    color: #74788d;
    font-weight: 400;
    font-size: 1.02rem;
    text-transform: none;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0;
  }
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  ${ToggleStyle}
  &:hover {
    filter: brightness(0.9) !important;
  }
  svg {
    @media (min-width: 1025px) {
      display: none;
    }
  }
`;

export const DropdownMenu = styled.div`
  @media (min-width: 1024px) {
    right: auto;
    bottom: auto;
    border: 0 !important;
    min-width: 14rem;
    box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
    padding: 1rem 0;
    border-radius: 4px;
    z-index: 95;
    display: none;
    float: left;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    float: none;
    margin: 0;
    padding: 0;
    width: auto !important;
  }
`;

const dropdownSubMenu = css`
  outline: none !important;
  display: flex;
  flex-grow: 1;
  padding: 0.7rem 1.2rem;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #74788d;
  @media (min-width: 1024px) {
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    ${({ isactive }) => (isactive === "true" ? "color:#009870" : "")};
    &:hover {
      color: #595d6e;
      text-decoration: none;
      background-color: #f7f8fa;
      filter: brightness(1) !important;
    }
    &:active {
      color: #009870;
      text-decoration: none;
      background-color: #f3f3f3;
      filter: brightness(1) !important;
    }
  }
  @media (max-width: 1024px) {
    font-size: 0.9rem;
    background-color: #fbfbfb;
    ${({ isactive }) => (isactive === "true" ? "color:#009870" : "")};
    padding-left: 3rem;
    ${({ isselected }) => (isselected !== "true" ? "display:none" : "")}
  }
`;

export const LinkDropdown = styled(Link)`
  ${dropdownSubMenu}
`;
export const DropdownSubMenu = styled.div`
  ${dropdownSubMenu}
  align-items:center;
  justify-content: space-between;
`;

export const LinkDropdownSub = styled(Link)`
  ${dropdownSubMenu}
  @media (min-width: 1024px) {
    ${({ isactive }) => (isactive === "true" ? "color:#009870" : "")};
    padding-left: 2rem;
    ${({ isselected }) => (isselected !== "true" ? "display:none" : "")}
  }
  @media (max-width: 1024px) {
    padding-left: 4rem;
  }
`;
