import styled from "styled-components";
import { TableContent } from "components/Table/style";

export const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 10px;
`;

export const Table = styled(TableContent)`
  th,
  td {
    :first-child {
      text-align: right;
    }
  }
`;
