import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function EnziPacProProtease(props) {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "185px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual produto?</span>
                </th>
                <th>
                  <span>Qual finalidade?</span>
                </th>
                <th>
                  <span>Qual dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Pré Postura</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.preLay.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.preLay.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.preLay.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Pré Pico</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.prePeak.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.prePeak.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.prePeak.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Pico de Postura</td>

                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layPeak.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layPeak.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layPeak.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 01</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal01.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal01.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal01.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 02</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal02.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal02.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal02.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 03</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal03.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal03.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal03.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 04</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal04.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal04.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal04.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 05</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal05.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal05.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal05.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
              <tr>
                <td>Postura 06</td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal06.product"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal06.goal"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.enziPacProProtease.layFinal06.dose"
                    item={props.meggProduction}
                    setItem={props.setMeggProduction}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>EnzyPac está aprovado? Se não, por qual motivo?</label>
          <Input
            type="checkbox"
            params="enzymes.enziPacProProtease.approved.checked"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            label="Aprovado"
          />
          <Input
            type="input"
            label=" "
            params="enzymes.enziPacProProtease.approved.reason"
            item={props.meggProduction}
            setItem={props.setMeggProduction}
            disabled={
              props.meggProduction.enzymes.enziPacProProtease.approved.checked
            }
            placeholder="Digite o motivo"
          />
        </Col>
      </Row>
    </>
  );
}
