import { useCallback } from "react";
import { Body, Header, TableContent } from "components/Table/style";
import { Block, BlockBody, BlockHeader } from "styles";
import { convertDateToString } from "utils/globalFunctions";

export default function HistoricTreatment({ lotesPerTreatment, ages }) {
  const getColor = useCallback((index) => {
    const colors = ["primary", "info", "danger", "dark", "warning"];
    let indexColor = 0;
    for (let i = 0; i < index; i++) {
      indexColor++;
      if (indexColor > 4) {
        indexColor = 0;
      }
    }
    return colors[indexColor];
  }, []);

  return (
    <Block id="historicTreatment">
      <BlockHeader>Tratamento por Idade</BlockHeader>
      <BlockBody>
        <TableContent>
          <Header>
            <tr>
              <th className=" text-right" style={{ width: "130px" }}>
                Lote
              </th>
              {ages.map((age, index) => (
                <th className={` text-${getColor(index)}`} key={age}>
                  {`Semana ${age}`}
                </th>
              ))}
            </tr>
          </Header>
          <Body>
            {Object.keys(lotesPerTreatment).map((key) => (
              <tr key={key}>
                <td>{key}</td>
                {lotesPerTreatment[key].map(
                  ({ start, end, treatment }, index) => (
                    <td
                      className={` text-${getColor(index)}`}
                      key={key + index}
                    >
                      {treatment && (
                        <>
                          <strong>{treatment}</strong>
                          <br />
                          <small>
                            De {convertDateToString(start)} até{" "}
                            {convertDateToString(end)}
                          </small>
                        </>
                      )}
                    </td>
                  )
                )}
              </tr>
            ))}
          </Body>
        </TableContent>
      </BlockBody>
    </Block>
  );
}
