import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Container, Content, Data, Text, Title } from "./style";

export function HistoricItem({ date, dailySlaughter, healthScore, button }) {
  return (
    <Container>
      <Content>
        <Data>
          <div>
            <Title>
              <h5>
                {date
                  ? format(new Date(date), "dd ' de ' MMM', 'yyyy", {
                      locale: ptBR,
                    })
                  : null}
              </h5>
            </Title>
            <Text>
              Abates Diários: {dailySlaughter} - Escore sanitário: {healthScore}
            </Text>
          </div>
        </Data>
        {button}
      </Content>
    </Container>
  );
}
