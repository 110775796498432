import { useState } from "react";
import Chart from "react-apexcharts";
import { Col } from "styles";
import { necropsyConfig } from "utils/globalData";
import { getColor, getScoreColor, getScoreText } from "utils/globalFunctions";
import { ItemScore } from "../../style";

export function BoxScore({ params, response, customTitle, index }) {
  const splitParams = params.split(".");
  const config = necropsyConfig[splitParams[0]][splitParams[1]];
  const totalPerScore = response.totalPerScore[index];
  const [showScores, setShowScores] = useState(false);
  return (
    <Col>
      <ItemScore onClick={() => setShowScores(!showScores)}>
        <span className="kt-svg-icon center">
          {config.icon || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  opacity="0.3"
                  x="4"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="4"
                  y="10"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="10"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="10"
                  y="10"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="16"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="16"
                  y="10"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="4"
                  y="16"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="10"
                  y="16"
                  width="4"
                  height="4"
                  rx="2"
                />
                <rect
                  style={{ fill: config.color }}
                  fill="#000000"
                  x="16"
                  y="16"
                  width="4"
                  height="4"
                  rx="2"
                />
              </g>
            </svg>
          )}
        </span>
        <span
          className="kt-widget17__subtitle"
          style={{
            fontSize: customTitle || null,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {config.label}
        </span>

        <div className="col radial-chart">
          <Chart
            options={{
              plotOptions: {
                radialBar: {
                  startAngle: -135,
                  endAngle: 225,
                  hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#ffffff",
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                  },
                  track: {
                    background: getColor(
                      getScoreColor(totalPerScore[splitParams[1]]),
                      0.2
                    ),
                    strokeWidth: "67%",
                    margin: 0, // margin is in pixels
                  },

                  dataLabels: {
                    showOn: "always",
                    name: {
                      offsetY: -20,
                      show: true,
                      color: getScoreColor(totalPerScore[splitParams[1]]),
                      fontSize: "13px",
                    },
                    value: {
                      formatter: function (val) {
                        return (val / 10 < 0 ? 0 : val / 10).toLocaleString();
                      },
                      color: getScoreColor(totalPerScore[splitParams[1]]),
                      fontSize: "20px",
                      show: true,
                    },
                  },
                },
              },
              fill: {
                colors: [getScoreColor(totalPerScore[splitParams[1]])],
                type: "gradient",
                gradient: {
                  shade: "dark",
                  type: "horizontal",

                  gradientToColors: [
                    getScoreColor(totalPerScore[splitParams[1]]),
                  ],
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 0.8,
                  stops: [0, 100],
                },
              },
              stroke: {
                lineCap: "round",
              },
              labels: [getScoreText(totalPerScore[splitParams[1]])],
            }}
            series={[totalPerScore[splitParams[1]] * 10]}
            type="radialBar"
            width="100%"
          />
        </div>
      </ItemScore>
    </Col>
  );
}
