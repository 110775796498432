import { useState, useCallback } from "react";
import ReactLoading from "react-loading";
import { api } from "services/api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  Background,
  Button,
  Container,
  Content,
  Form,
  InputText,
  LogoConnect,
  LogoConnectContent,
  LogoMcassab,
  PasswordContent,
  WelcomeContainer,
} from "../style";
import { PasswordIcon } from "components/PasswordIcon";
import { Welcome } from "../Welcome";

export function NewPassword(props) {
  const erroMessageInitialState = {
    password: false,
  };

  const [form, setForm] = useState({
    password: "",
    passwordConfirm: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const submitData = useCallback(
    async (e) => {
      e.preventDefault();
      if (!form.password) {
        return setErrorMessage({
          password: !form.password,
        });
      }
      setLoading(true);
      const response = await api
        .put("/auth/createpassword/" + props.match.params.token, form)
        .catch((err) => err);
      setLoading(false);
      if (!response.data) {
        return Swal.fire("Criar Senha", "Token expirado ou inválido", "error");
      }
      Swal.fire("Criar Senha", "Senha criada com sucesso", "success");
      props.history.push("/login");
    },
    [form, props]
  );

  return (
    <Container>
      <Content className="animate__animated animate__bounceInLeft">
        <Form onSubmit={submitData}>
          <LogoConnect src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_connect_nova.png" />
          <label style={{ color: "#414b97" }}>Crie sua nova Senha</label>
          <PasswordContent>
            <InputText
              placeholder="Digite sua nova senha"
              isInvalid={!!errorMessage.password}
              item={form}
              setItem={setForm}
              params="password"
              type="inputOnly"
              inputType={!showPassword && "password"}
            />
            <PasswordIcon
              {...{
                showPassword,
                setShowPassword,
                error: errorMessage.password,
              }}
            />
          </PasswordContent>

          <Button
            className="btn btn-primary btn-lg btn-block"
            type="submit"
            disabled={loading}
          >
            <span>
              {loading ? (
                <ReactLoading
                  style={{
                    fill: "#72b56e",
                    height: "20px",
                    width: "20px",
                    display: "inline-table",
                  }}
                  type="spin"
                  color="#72b56e"
                  height={19}
                  width={19}
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "10px" }}
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <mask fill="white">
                      <use xlinkHref="#path-1" />
                    </mask>
                    <g />
                    <path
                      d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
                      fill="#72b56e"
                    />
                  </g>
                </svg>
              )}
              Criar Nova Senha
            </span>
          </Button>
          <Link style={{ color: "#414b97" }} to="/Login">
            Login
          </Link>
        </Form>
      </Content>
      <Background className="animate__animated animate__bounceInRight">
        <WelcomeContainer>
          <Welcome />
          <LogoConnectContent>
            <LogoMcassab src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-mcassab-branca.png" />
          </LogoConnectContent>
        </WelcomeContainer>
      </Background>
    </Container>
  );
}
