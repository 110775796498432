/* eslint-disable no-unused-vars */
import { Icons } from "components/Icons";
import { SubHeader } from "components/SubHeader";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { Col, Row } from "styles";
import { Filters } from "./Filters";

export function NewReport(props) {
  const [customer, setCustomer] = useState({});
  const [responseReport, setResponseReport] = useState({});
  const [showModalExport, setShowModalExport] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [input, setInput] = useState({
    group: "Semanal",
    createdBy: null,
    customer: props.match.params.id,
    age: [0, 60],
    injuryType: [],
    lineage: "",
    technicalManager: null,
    technicalManagerList: [],
    comparison: false,
    broilerList: [],
  });

  useEffect(() => {
    const getCustomerName = async () => {
      const responseCustomer = await api.get(
        `connect/customer/${props.match.params.id}`
      );
      setCustomer(responseCustomer.data);
    };
    getCustomerName();
  }, [props.match.params.id]);

  return (
    <>
      <SubHeader
        {...props}
        title={customer.name}
        breadcrumbs={[
          {
            label: customer.name,
            to: "/customer/" + props.match.params.id,
          },
          {
            label: "Frango de Corte",
          },
          {
            label: "Relatório",
          },
        ]}
        icon={<Icons type="lineChart" size="24" color="#fff" />}
        toolbar={responseReport.totalBirds > 0 ? ["exportReport"] : null}
        exportReport={() => setShowModalExport(true)}
      />
      <Row>
        <Col size={1}>
          <Filters
            {...{
              loading: loadingReport,
              setLoading: setLoadingReport,
              props,
            }}
          />
        </Col>
        <Col size={3}></Col>
      </Row>
    </>
  );
}
