import Chart from "react-apexcharts";
import {
  getColor,
  getScoreSlaughterColor,
  getScoreSlaughterText,
} from "utils/globalFunctions";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { useEffect } from "react";

export function ScoreTotal({
  slaughter,
  setGraphs,
  graphs,
  total,
  setTotal,
  config,
}) {
  useEffect(() => {
    const getScore = (key, param) => {
      if (!config[key]) {
        return 0;
      }
      return (
        config[key]?.options
          ?.filter(({ score }) => score === param)
          .map(({ points }) => points)[0] || 0
      );
    };

    const scores = {
      ventralInspection: {
        skinBreakage: {
          light: getScore("ventralInspection-skinBreakage", "light"),
          severe: getScore("ventralInspection-skinBreakage", "severe"),
        },
        chestSkinBurn: {
          light: getScore("ventralInspection-chestSkinBurn", "light"),
          severe: getScore("ventralInspection-chestSkinBurn", "severe"),
        },
        celluliteBelowChest: {
          light: getScore("ventralInspection-celluliteBelowChest", "light"),
          severe: getScore("ventralInspection-celluliteBelowChest", "severe"),
        },
        chestSkinBleeding: {
          light: getScore("ventralInspection-chestSkinBleeding", "light"),
          severe: getScore("ventralInspection-chestSkinBleeding", "severe"),
        },
        bleedingWings: {
          light: getScore("ventralInspection-bleedingWings", "light"),
          severe: getScore("ventralInspection-bleedingWings", "severe"),
        },
        displacementWings: {
          light: getScore("ventralInspection-displacementWings", "light"),
          severe: getScore("ventralInspection-displacementWings", "severe"),
        },
        fractureWings: {
          light: getScore("ventralInspection-fractureWings", "light"),
          severe: getScore("ventralInspection-fractureWings", "severe"),
        },
      },
      dorsalInspection: {
        tailSkinBreakage: {
          light: getScore("dorsalInspection-tailSkinBreakage", "light"),
          severe: getScore("dorsalInspection-tailSkinBreakage", "severe"),
        },
        dermatitis: {
          light: getScore("dorsalInspection-dermatitis", "light"),
          severe: getScore("dorsalInspection-dermatitis", "severe"),
        },
        scratchedWoundNew: {
          light: getScore("dorsalInspection-scratchedWoundNew", "light"),
          severe: getScore("dorsalInspection-scratchedWoundNew", "severe"),
        },
        scratchedWoundOld: {
          light: getScore("dorsalInspection-scratchedWoundOld", "light"),
          severe: getScore("dorsalInspection-scratchedWoundOld", "severe"),
        },
        bleedingThighs: {
          light: getScore("dorsalInspection-bleedingThighs", "light"),
          severe: getScore("dorsalInspection-bleedingThighs", "severe"),
        },
        thighFracture: {
          light: getScore("dorsalInspection-thighFracture", "light"),
          severe: getScore("dorsalInspection-thighFracture", "severe"),
        },
        dorsalMyopathy: {
          light: getScore("dorsalInspection-dorsalMyopathy", "light"),
          severe: getScore("dorsalInspection-dorsalMyopathy", "severe"),
        },
      },
      internalInspection: {
        punctateChestHemorrhage: {
          light: getScore(
            "internalInspection-punctateChestHemorrhage",
            "light"
          ),
          severe: getScore(
            "internalInspection-punctateChestHemorrhage",
            "severe"
          ),
        },
        thighsPunctiformHemorrhage: {
          light: getScore(
            "internalInspection-thighsPunctiformHemorrhage",
            "light"
          ),
          severe: getScore(
            "internalInspection-thighsPunctiformHemorrhage",
            "severe"
          ),
        },
        whiteStreak: {
          light: getScore("internalInspection-whiteStreak", "light"),
          severe: getScore("internalInspection-whiteStreak", "severe"),
        },
        woodChest: {
          light: getScore("internalInspection-woodChest", "light"),
          severe: getScore("internalInspection-woodChest", "severe"),
        },
        chestSpaghetti: {
          light: getScore("internalInspection-chestSpaghetti", "light"),
          severe: getScore("internalInspection-chestSpaghetti", "severe"),
        },
      },

      legCorns: {
        light: getScore("legCorns", "light"),
        severe: getScore("legCorns", "severe"),
      },
    };
    const response = [
      {
        label: "Inspeção Ventral",
        param: "ventralInspection",
        params: [
          { name: "skinBreakage" },
          { name: "chestSkinBurn" },
          { name: "celluliteBelowChest" },
          { name: "chestSkinBleeding" },
          { name: "bleedingWings" },
          { name: "displacementWings" },
          { name: "fractureWings" },
        ],
      },
      {
        label: "Inspeção Dorsal",
        param: "dorsalInspection",
        params: [
          { name: "tailSkinBreakage" },
          { name: "dermatitis" },
          { name: "scratchedWoundNew" },
          { name: "scratchedWoundOld" },
          { name: "bleedingThighs" },
          { name: "thighFracture" },
          { name: "dorsalMyopathy" },
        ],
      },
      {
        label: "Inspeção Interna",
        param: "internalInspection",
        params: [
          { name: "punctateChestHemorrhage" },
          { name: "thighsPunctiformHemorrhage" },
          { name: "whiteStreak" },
          { name: "woodChest" },
          { name: "chestSpaghetti" },
        ],
      },
      { label: "Calo de Patas", param: "legCorns" },
    ];

    Object.keys(scores).forEach((key1, index) => {
      if (key1 !== "legCorns") {
        const maxSum =
          Object.keys(scores[key1])
            .map((key2) => scores[key1][key2].severe)
            .reduce((a, b) => a + b) *
          ((+slaughter.numberOfBirdsMale || 0) +
            (+slaughter.numberOfBirdsFemale || 0));
        const sum = Object.keys(scores[key1])
          .map(
            (key2) =>
              scores[key1][key2].severe *
                ((+slaughter[key1][key2].severe.male || 0) +
                  (+slaughter[key1][key2].severe.female || 0)) +
              scores[key1][key2].light *
                ((+slaughter[key1][key2].light.male || 0) +
                  (+slaughter[key1][key2].light.female || 0))
          )
          .reduce((a, b) => a + b);
        response[index].value = 10 - (sum / maxSum) * 10;
        Object.keys(scores[key1]).forEach((key2) => {
          const indexParam = response[index].params
            .map(({ name }) => name)
            .indexOf(key2);
          response[index].params[indexParam].value =
            10 -
            ((scores[key1][key2].severe *
              ((+slaughter[key1][key2].severe.male || 0) +
                (+slaughter[key1][key2].severe.female || 0)) +
              scores[key1][key2].light *
                ((+slaughter[key1][key2].light.male || 0) +
                  (+slaughter[key1][key2].light.female || 0))) /
              (scores[key1][key2].severe *
                ((+slaughter.numberOfBirdsMale || 0) +
                  (+slaughter.numberOfBirdsFemale || 0)))) *
              10;
        });
      } else {
        response[index].value =
          10 -
          ((scores.legCorns.severe *
            ((+slaughter.legCorns.severe.male || 0) +
              (+slaughter.legCorns.severe.female || 0)) +
            scores.legCorns.light *
              ((+slaughter.legCorns.light.male || 0) +
                (+slaughter.legCorns.light.female || 0))) /
            (scores.legCorns.severe *
              ((+slaughter.numberOfBirdsMale || 0) +
                (+slaughter.numberOfBirdsFemale || 0)))) *
            10;
      }
    });

    setTotal({
      label: "Score do Lote",
      param: "total",
      value: response.map(({ value }) => value * 0.25).reduce((a, b) => a + b),
    });
    setGraphs(response);
  }, [slaughter, setTotal, setGraphs, config]);

  return (
    <>
      <Row>
        {graphs.map(({ param, value, label }) => (
          <Col key={param}>
            <Card>
              <CardHeader>{label}</CardHeader>
              <CardBody>
                <span>&nbsp;</span>
                <Chart
                  options={{
                    plotOptions: {
                      radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                          margin: 0,
                          size: "90%",
                          background: "#f9f9fc ",
                          image: undefined,
                          imageOffsetX: 0,
                          imageOffsetY: 0,
                          position: "front",
                        },
                        track: {
                          background: getColor(
                            getScoreSlaughterColor(
                              Math.round(value * 100) / 100
                            ),
                            0.2
                          ),
                          strokeWidth: "150%",
                          margin: 0, // margin is in pixels
                        },

                        dataLabels: {
                          showOn: "always",
                          name: {
                            offsetY: -10,
                            show: true,
                            color: getScoreSlaughterColor(
                              Math.round(value * 100) / 100
                            ),
                            fontSize:
                              getScoreSlaughterText(
                                Math.round(value * 100) / 100
                              ) === "Muita Atenção"
                                ? "9px"
                                : "13px",
                          },
                          value: {
                            formatter: function (val) {
                              return (
                                val / 10 < 0 ? 0 : val / 10
                              ).toLocaleString();
                            },
                            color: getScoreSlaughterColor(
                              Math.round(value * 100) / 100
                            ),
                            fontSize: "20px",
                            show: true,
                            offsetY: 0,
                          },
                        },
                      },
                    },
                    fill: {
                      colors: [
                        getScoreSlaughterColor(Math.round(value * 100) / 100),
                      ],
                      type: "gradient",
                      gradient: {
                        shade: "dark",
                        type: "horizontal",
                        gradientToColors: [
                          getScoreSlaughterColor(Math.round(value * 100) / 100),
                        ],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 0.8,
                        stops: [0, 100],
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: [
                      getScoreSlaughterText(Math.round(value * 100) / 100),
                    ],
                  }}
                  series={[(Math.round(value * 100) / 100) * 10]}
                  type="radialBar"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col></Col>
        <Col>
          {total.param && (
            <Card>
              <CardHeader>{total.label}</CardHeader>
              <CardBody>
                <span>&nbsp;</span>
                <Chart
                  options={{
                    plotOptions: {
                      radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                          margin: 0,
                          size: "90%",
                          background: "#f9f9fc ",
                          image: undefined,
                          imageOffsetX: 0,
                          imageOffsetY: 0,
                          position: "front",
                        },
                        track: {
                          background: getColor(
                            getScoreSlaughterColor(
                              Math.round(total.value * 100) / 100 < 0
                                ? 0
                                : Math.round(total.value * 100) / 100
                            ),
                            0.2
                          ),
                          strokeWidth: "150%",
                          margin: 0, // margin is in pixels
                        },

                        dataLabels: {
                          showOn: "always",
                          name: {
                            offsetY: -10,
                            show: true,
                            color: getScoreSlaughterColor(
                              Math.round(total.value * 100) / 100
                            ),
                            fontSize:
                              getScoreSlaughterText(
                                Math.round(total.value * 100) / 100
                              ) === "Muita Atenção"
                                ? "9px"
                                : "13px",
                          },
                          value: {
                            formatter: function (val) {
                              return (
                                Math.round(val * 100) /
                                100 /
                                10
                              ).toLocaleString();
                            },
                            color: getScoreSlaughterColor(
                              Math.round(total.value * 100) / 100
                            ),
                            fontSize: "20px",
                            show: true,
                            offsetY: 0,
                          },
                        },
                      },
                    },
                    fill: {
                      colors: [
                        getScoreSlaughterColor(
                          Math.round(total.value * 100) / 100
                        ),
                      ],
                      type: "gradient",
                      gradient: {
                        shade: "dark",
                        type: "horizontal",

                        gradientToColors: [
                          getScoreSlaughterColor(
                            Math.round(total.value * 100) / 100
                          ),
                        ],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 0.8,
                        stops: [0, 100],
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: [
                      getScoreSlaughterText(
                        Math.round(total.value * 100) / 100
                      ),
                    ],
                  }}
                  series={[(Math.round(total.value * 100) / 100) * 10]}
                  type="radialBar"
                  width="100%"
                />
              </CardBody>
            </Card>
          )}
        </Col>
        <Col></Col>
      </Row>
    </>
  );
}
