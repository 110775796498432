import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";

import ReactLoading from "react-loading";
import { ContentQuestion, HeaderQuestion, Table } from "../style";
import { Body, Header } from "components/Table/style";

export function FactoryClassification(props) {
  const [data, setData] = useState({
    classification: null,
  });

  const [loading, setLoading] = useState(true);

  const [getData, setGetData] = useState(true);

  const [showDescription, setShowDescription] = useState("");

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };
    console.log(query);
    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar Classificação de fábrica",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar Classificação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`gate798/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Criar Classificação",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }
  }, [data, props]);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `gate798/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire(
            "Editar Classificação",
            "Não foi possível buscar a Classificação",
            "error"
          );
        }
        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Classificação de fábrica</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : showDescription ? (
        <Table>
          <Header>
            <tr>
              <th>
                <HeaderQuestion>
                  {showDescription} - {props.isSwine ? "Suínos" : "Aves"}
                  <Button
                    color="default"
                    bg="light"
                    border="default"
                    onClick={() => setShowDescription("")}
                    size="sm"
                  >
                    Voltar
                  </Button>
                </HeaderQuestion>
              </th>
            </tr>
          </Header>
          <Body>
            {showDescription === "A" ? (
              <>
                <tr>
                  {props.isSwine ? (
                    <td>Unidades de reprodução, desmama e creche</td>
                  ) : (
                    <td>
                      Unidades que atendam somente aves de reprodução
                      (matrizeiros)
                    </td>
                  )}
                </tr>
                <tr>
                  {props.isSwine ? (
                    <td>
                      Unidades de ciclo completo que tenham até 1999 matrizes
                      suínas
                    </td>
                  ) : (
                    <td>Unidades que abatam até 50.000 aves/dia</td>
                  )}
                </tr>
                <tr>
                  {props.isSwine ? (
                    <td>
                      Unidades de desmama e terminação ou só terminação que
                      resulte em 60.000 animais abatidos/ano{" "}
                    </td>
                  ) : (
                    <td>
                      Unidades que atendam até 1.000.000 de aves de postura
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    Unidades que atendam uma das demais espécies de animais de
                    produção, independente do volume de animais atendidos
                  </td>
                </tr>
              </>
            ) : showDescription === "B" ? (
              <>
                <tr>
                  {props.isSwine ? (
                    <td>
                      Unidades de ciclo completo que tenham entre 2.000 e 19.999
                      matrizes
                    </td>
                  ) : (
                    <td>Unidades que abatam até 100.000 aves/dia</td>
                  )}
                </tr>
                <tr>
                  {props.isSwine ? (
                    <td>
                      Unidades de desmama e terminação ou só terminação que
                      resulte entre 60.001 e 480.000 animais abatidos/ano
                    </td>
                  ) : (
                    <td>
                      Unidades que atendam entre 1.000.001 e 2.000.000 de aves
                      de postura
                    </td>
                  )}
                </tr>

                <tr>
                  <td>
                    Unidades que atendam uma das demais espécies de animais de
                    produção, independente do volume de animais atendidos
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  {props.isSwine ? (
                    <td>
                      Unidades de ciclo completo que tenham mais de 20.000
                      matrizes
                    </td>
                  ) : (
                    <td>Unidades que abatam mais de 100.000 aves/dia</td>
                  )}
                </tr>
                <tr>
                  {props.isSwine ? (
                    <td>
                      Unidades de desmama e terminação ou só terminação que
                      resulte em mais de 480.000 animais abatidos/ano
                    </td>
                  ) : (
                    <td>
                      Unidades que atendam mais de 2.000.000 de aves de postura
                    </td>
                  )}
                </tr>
              </>
            )}
          </Body>
        </Table>
      ) : (
        <Table>
          <Header>
            <tr>
              <th colSpan={4}>Selecione a classificação da fábrica</th>
            </tr>
          </Header>
          <Body>
            <tr>
              <td>A</td>
              <td>
                <Input
                  type="radiobox"
                  value="A"
                  item={data}
                  setItem={setData}
                  params="classification"
                />
              </td>
              <td>
                <ContentQuestion>
                  <Icons
                    type="question"
                    color="#0f70b9"
                    size={24}
                    onClick={() => setShowDescription("A")}
                  />
                </ContentQuestion>
              </td>
              <td>
                Este grupo precisa de manual de BPF, plano de sequenciamento de
                produção de acordo com a grade de sensibilidade, procedimento de
                limpeza de linha e teste de homogeneidade de mistura com
                periodicidade anual, sendo que o primeiro deve ser entregue um
                ano após a concessão da autorização. Este grupo de risco está
                dispensados de fiscalização prévia.
              </td>
            </tr>
            <tr>
              <td>B</td>
              <td>
                <Input
                  type="radiobox"
                  value="B"
                  item={data}
                  setItem={setData}
                  params="classification"
                />
              </td>
              <td>
                <ContentQuestion>
                  <Icons
                    type="question"
                    color="#0f70b9"
                    size={24}
                    onClick={() => setShowDescription("B")}
                  />
                </ContentQuestion>
              </td>
              <td>
                Este grupo precisa de manual de BPF, plano de sequenciamento de
                produção de acordo com a grade de sensibilidade, procedimento de
                limpeza de linha e o teste de homogeneidade de mistura deve ser
                apresentado no momento da solicitação da autorização. Este grupo
                de risco está dispensados de fiscalização prévia.
              </td>
            </tr>
            <tr>
              <td>C</td>
              <td>
                <Input
                  type="radiobox"
                  value="C"
                  item={data}
                  setItem={setData}
                  params="classification"
                />
              </td>
              <td>
                <ContentQuestion>
                  <Icons
                    type="question"
                    color="#0f70b9"
                    size={24}
                    onClick={() => setShowDescription("C")}
                  />
                </ContentQuestion>
              </td>
              <td>
                Este grupo precisa de manual de BPF, plano de sequenciamento de
                produção de acordo com a grade de sensibilidade, e no momento da
                solicitação da autorização devem ser apresentados o teste de
                homogeneidade de mistura e procedimento de limpeza de linha
                validado. Este grupo de risco passará obrigatoriamente por
                fiscalização prévia a autorização.
              </td>
            </tr>
          </Body>
        </Table>
      )}
    </Modal>
  );
}
