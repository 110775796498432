import { format } from "date-fns";
import { Bar } from "react-chartjs-2";
import { Block, BlockBody, BlockHeader } from "styles";
import { necropsyConfig } from "utils/globalData";
import { getColor } from "utils/globalFunctions";

export function GraphScore({
  responseReport,
  dateRange,
  input,
  typesOfInjuries,
  query,
}) {
  const getReportScoreGraph = (scores) => {
    const response = {
      datasets: [],
    };
    const keys = [];
    Object.keys(scores).forEach((key) => {
      scores[key].forEach((item) => {
        if (!keys.filter((score) => score === item.key)[0]) {
          keys.push(item.key);
        }
      });
    });
    keys.forEach((key) => {
      const color = getColor();
      response.datasets.push({
        label: "Score " + key,
        type: "bar",
        backgroundColor: Object.keys(scores).map(() => {
          return getColor(color, 0.7);
        }),
        borderColor: Object.keys(scores).map(() => {
          return getColor(color, 0.8);
        }),
        hoverBackgroundColor: Object.keys(scores).map(() => {
          return getColor(color, 0.9);
        }),
        hoverBorderColor: Object.keys(scores).map(() => {
          return getColor(color, 1);
        }),
        borderWidth: 1,
        data: Object.keys(scores).map((item) => {
          let response = 0;
          scores[item].forEach((item) => {
            if (item.key === key) {
              response = item.total;
            }
          });
          return response;
        }),
      });
    });

    return response;
  };
  return responseReport.scores.map((scores, index) => (
    <Block id={`graphScore${index}`} key={index}>
      <BlockHeader>
        Lesões | Score | De {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
        {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
        {responseReport.customer ? ` | ${responseReport.customer}` : ""}
        {` | ${
          typesOfInjuries.find(
            ({ value }) => value === JSON.parse(query).injuryType[index]
          ).label
        }`}
      </BlockHeader>
      <BlockBody>
        <Bar
          data={getReportScoreGraph(scores)}
          options={{
            labels: Object.keys(scores).map(
              (key) =>
                necropsyConfig[JSON.parse(query).injuryType[index]][key].label
            ),

            elements: {
              line: {
                fill: false,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    display: false,
                  },

                  labels: Object.keys(scores).map(
                    (key) =>
                      necropsyConfig[JSON.parse(query).injuryType[index]][key]
                        .label
                  ),
                },
              ],
              yAxes: [
                {
                  type: "linear",
                  display: true,
                  position: "left",

                  labels: {
                    show: true,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            legend: {
              position: "right",
            },
          }}
        />
      </BlockBody>
    </Block>
  ));
}
