import styled, { css } from "styled-components";

const showModalCss = css`
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: ${({ show }) => (show ? 0.5 : 0)};
`;

export const Dialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  transition: opacity 0.15s linear;
  ${({ show }) => (show ? showModalCss : "")}
  transition: opacity 0.15s linear;
  padding-bottom: 80px;
`;

export const Container = styled.div`
  transform: none;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
  @media (min-width: 576px) {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  @media (min-width: 1024px) {
    max-width: 800px;
  }
  @media (min-width: 1399px) {
    max-width: 1140px;
  }
  @media (min-width: 1024px) {
    max-width: 950px;
  }
  @media (min-width: 1400px) {
    max-width: 1299px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;

  background-clip: padding-box;
  border: 1px solid #ebedf2;
  border-radius: 0.3rem;
  outline: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem;
  border-bottom: 1px solid #ebedf2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`;

export const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 35px;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #ebedf2;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;
