import React, { useState, useCallback, useEffect } from "react";
import {
  getColor,
  getScoreFecesColor,
  getScoreFecesText,
} from "utils/globalFunctions";
import Chart from "react-apexcharts";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import isMobile from "is-mobile";
import { api } from "services/api";
import { CreateEdit as CreateEditPreNecropsy } from "../CreateEdit";
import { Button } from "components/Button";
import { CreateEdit as CreateEditHbNecropsy } from "../../PreNecropsy/CreateEdit";

export function List(props) {
  const tableParams = [
    {
      key: "code",
      label: "Código",
      width: 250,
      filter: "text",
      placeholder: "Digite o Código",
      mobile: {
        type: "title",
      },
    },
    {
      key: "customer",
      params: "name",
      label: "Cliente",
      filter: !props.match.params.id && "text",
      placeholder: "Nome ou Código",
      mobile: {
        type: "title",
      },
    },
    {
      key: "visitDate",
      label: "Data da visita",
      type: "date",
      filter: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "integrated",
      label: "Integrado",
      filter: "text",
      mobile: {
        type: "item",
      },
    },
    { key: "core", label: "Núcleo", filter: "text" },
    { key: "lote", label: "Lote", filter: "text" },
    { key: "age", label: "Idade", filter: "text" },
    {
      key: "lineage",
      label: "Linhagem",
      filter: "select",
      options: [{ value: "Cobb" }],
      mobile: {
        type: "small",
      },
    },

    {
      key: "score",
      label: "Score",
      type: "custom",
      mobile: {
        type: "status",
      },
      response: (item) => {
        return (
          <div style={{ width: "120px" }}>
            <Chart
              options={{
                plotOptions: {
                  radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                      margin: 0,
                      size: "70%",
                      background: "#f9f9fc ",
                      image: undefined,
                      imageOffsetX: 0,
                      imageOffsetY: 0,
                      position: "front",
                    },
                    track: {
                      background: getColor(
                        getScoreFecesColor(item.results.total),
                        0.2
                      ),
                      strokeWidth: "67%",
                      margin: 0, // margin is in pixels
                    },

                    dataLabels: {
                      showOn: "always",
                      name: {
                        offsetY: -5,
                        show: true,
                        color: getScoreFecesColor(item.results.total),
                        fontSize: "7px",
                      },
                      value: {
                        formatter: function (val) {
                          return (val / 10 < 0 ? 0 : val / 10).toLocaleString();
                        },
                        color: getScoreFecesColor(item.results.total),
                        fontSize: "10px",
                        show: true,
                        offsetY: -5,
                      },
                    },
                  },
                },
                fill: {
                  colors: [getScoreFecesColor(item.results.total)],
                  type: "gradient",
                  gradient: {
                    shade: "dark",
                    type: "horizontal",

                    gradientToColors: [getScoreFecesColor(item.results.total)],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100],
                  },
                },
                stroke: {
                  lineCap: "round",
                },
                labels: [getScoreFecesText(item.results.total)],
              }}
              series={[item.results.total * 10]}
              type="radialBar"
              width={isMobile() ? "40%" : "100%"}
            />
          </div>
        );
      },
    },
    {
      key: "necropsy",
      label: "Necropsia",
      type: "custom",
      response: (item) => {
        return (
          <Button
            type="button"
            bg="info"
            color="white"
            border="info"
            size="sm"
            onClick={() => {
              setPreNecropsyId(item._id);
              setShowNecropsy(true);
            }}
          >
            <Icons type="necropsy" size="13" color="#fff" />
          </Button>
        );
      },
    },
  ];

  const [showPreNecropsy, setShowPreNecropsy] = useState(false);

  const [showNecropsy, setShowNecropsy] = useState(false);

  const [preNecropsyId, setPreNecropsyId] = useState("");

  const [customerName, setCustomerName] = useState("");

  const [customer, setCustomer] = useState("");

  const editHandler = useCallback((row) => {
    setPreNecropsyId(row._id);
    setShowPreNecropsy(true);
  }, []);

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
      setCustomer(response.data);
    };
    if (props.match.params.id) {
      getCustomerName();
    }
  }, [props.match.params.id]);

  return (
    <>
      {showNecropsy && (
        <CreateEditHbNecropsy
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={props.location}
          id={preNecropsyId}
          customer={customer}
        />
      )}
      {showPreNecropsy && (
        <CreateEditPreNecropsy
          show={showPreNecropsy}
          setShow={setShowPreNecropsy}
          location={props.location}
          id={preNecropsyId}
          customer={customer}
        />
      )}
      <SubHeader
        {...props}
        title={customerName || "Necropsias"}
        route="broilernecropsy"
        breadcrumbs={[
          {
            label: customerName,
            to: "/connect/customer/" + props.match.params.id,
          },
          {
            label: "Matriz Pesada",
          },
          {
            label: "Pré-necropsias",
          },
        ]}
        icon={<Icons type="preNecropsy" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editHandler={editHandler}
        emptyText={"Nenhuma necropsia localizada!"}
        route={`/hbprenecropsy`}
        {...props}
      />
    </>
  );
}
