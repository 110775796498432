import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { PhytaseHidosing } from "./PhytaseHidosing";
import { CarbohydraseBlends } from "./CarbohydraseBlends";
import { EnziPacProProtease } from "./EnziPacProProtease";

export function Enzymes(props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Fitase" />
        <Tab label="Corboidrase/Blends" />
        <Tab label="EnzyPac Pro / Protease" />
      </Tabs>

      {tab === 0 && <PhytaseHidosing {...props} />}
      {tab === 1 && <CarbohydraseBlends {...props} />}
      {tab === 2 && <EnziPacProProtease {...props} />}
    </>
  );
}
