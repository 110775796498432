import { useState, useEffect } from "react";
import { PercentageInput } from "./style";
import { Content } from "components/Input/style";

export function CrossContamination({ data, setData, handlePercentage }) {
  const [rate, setRate] = useState("");

  useEffect(() => {
    setRate(data.crossContamination);
  }, [data.crossContamination]);

  const handleContamination = (e) => {
    const newRate = handlePercentage(e, setRate);
    if (newRate && !isNaN(newRate)) {
      setData((prevState) => {
        const newData = { ...prevState };
        newData.crossContamination = newRate;
        return newData;
      });
    }
  };
  return (
    <Content>
      <label>{`Contaminação cruzada (%)`}</label>
      <PercentageInput
        onChange={(e) => handleContamination(e)}
        value={rate}
        type="number"
      />
    </Content>
  );
}
