import { MenuLeft } from "./MenuLeft";
import { MenuRight } from "./MenuRight";
import { Brand, Container, Content, Logo, LogoLink } from "./style";

export function Header(props) {
  return (
    <Container className="animate__animated animate__fadeInDown">
      <Content>
        <Brand>
          <LogoLink to="/">
            <Logo
              alt="Logo"
              src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-MCassab-hz.png"
            />
          </LogoLink>
        </Brand>
        <MenuLeft {...props}></MenuLeft>
        <MenuRight {...props}></MenuRight>
      </Content>
    </Container>
  );
}
