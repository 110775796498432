import { useState, useCallback } from "react";
import ReactLoading from "react-loading";
import { api } from "services/api";
import { login } from "services/auth";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import {
  Background,
  Button,
  Container,
  Content,
  Form,
  H1,
  H1Mobile,
  InputText,
  LogoConnect,
  LogoConnectContent,
  LogoMcassab,
  PasswordContent,
  WelcomeContainer,
} from "../style";
import { PasswordIcon } from "components/PasswordIcon";
import { Welcome } from "../Welcome";

export function Login(props) {
  const erroMessageInitialState = {
    message: "",
    username: false,
    password: false,
  };

  const [form, setForm] = useState({
    username: "",
    password: "",
    type: "web",
  });

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const submitData = useCallback(
    async (e) => {
      e.preventDefault();
      setErrorMessage({
        password: false,
        username: false,
      });
      if (!form.password || !form.username) {
        return setErrorMessage({
          password: !form.password,
          username: !form.username,
        });
      }
      setLoading(true);
      const response = await api.post("/auth/signin", form).catch((err) => err);
      setLoading(false);
      if (!response.data) {
        return Swal.fire("Login", "Usuário ou Senha Incorreto!", "error");
      }
      if (response.data.resetPasswordToken) {
        return props.history.push(
          `/new-password/${response.data.resetPasswordToken}`
        );
      }
      login(response.data.token);
      return props.history.push("/");
    },
    [form, props]
  );

  return (
    <Container>
      <Content className="animate__animated animate__bounceInLeft">
        <Form onSubmit={submitData}>
          <H1Mobile>Toda a produção na palma da sua mão</H1Mobile>
          <LogoConnect src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_connect_nova.png" />
          <H1>
            <b>Toda a produção</b> na palma da sua mão
          </H1>
          <InputText
            placeholder="E-Mail"
            isInvalid={!!errorMessage.username}
            item={form}
            setItem={setForm}
            params="username"
            type="inputOnly"
          />
          <PasswordContent>
            <InputText
              placeholder="Senha"
              isInvalid={!!errorMessage.password}
              item={form}
              setItem={setForm}
              params="password"
              type="inputOnly"
              inputType={!showPassword && "password"}
            />
            <PasswordIcon
              {...{
                showPassword,
                setShowPassword,
                error: errorMessage.password,
              }}
            />
          </PasswordContent>

          <Button
            className="btn btn-primary btn-lg btn-block"
            type="submit"
            disabled={loading}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              {loading ? (
                <ReactLoading
                  type="spin"
                  color="#72b56e"
                  height={19}
                  width={19}
                />
              ) : (
                "Entrar"
              )}
            </span>
          </Button>
          <Link style={{ color: "#414b97" }} to="/forgot">
            Esqueci minha senha
          </Link>
        </Form>
      </Content>

      <Background className="animate__animated animate__bounceInRight">
        <WelcomeContainer>
          <Welcome />
          <LogoConnectContent>
            <LogoMcassab src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-mcassab-branca.png" />
          </LogoConnectContent>
        </WelcomeContainer>
      </Background>
    </Container>
  );
}
