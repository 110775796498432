import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 60px;
    min-height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 9px -3px rgb(0 0 0 / 20%);
    position: relative;
    z-index: 1;
  }
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const LinkLogo = styled(Link)`
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Logo = styled.img`
  height: 30px;
`;

export const ToolBar = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const ToolBarToggle = styled.button`
  @media (max-width: 1024px) {
    margin: 0 0 0 1.5rem;
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: 0;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    width: 26px;
    height: 26px;
    transition: all 0.4s ease;

    span {
      background: #58595a;
      display: block;
      position: absolute;
      top: 12px;
      height: 2px;
      min-height: 2px;
      width: 100%;
      border-radius: 2px;
      transition: all 0.4s ease;
      ::before,
      ::after {
        background: #58595a;
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        min-height: 2px;
        content: "";
        border-radius: 2px;
        transition: all 0.4s ease;
      }
      ::before {
        top: -8px;
      }
      ::after {
        bottom: -8px;
      }
    }
  }
`;

export const TopBarToggle = styled.button`
  @media (max-width: 1024px) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0 1.25rem;
  }
`;
