import { useCallback, useContext, useState } from "react";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import updateList from "contexts/updateList";
import { Card, CardBody, CardHeader } from "styles";
import { CreateEditMixer } from "./CreateEditMixer";

export function MixerData(props) {
  const tableParams = [
    {
      key: "title",
      label: "Descrição",
      filter: "text",
      placeholder: "Digite a nome do misturador",
      mobile: {
        type: "title",
      },
    },
    {
      key: "mixerType",
      label: "Tipo Misturador",
      filter: "text",
      placeholder: "Digite a nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "date",
      label: "Data Cadastro",
      filter: "date",
      type: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "user",
      params: "name",
      label: "Cadastrado por",
      type: "number",
      filter: "text",
      mobile: {
        type: "item",
      },
    },
  ];

  const { setUpdateList } = useContext(updateList);

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const editMixerHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(true);
  }, []);

  return (
    <>
      {modalShow && (
        <CreateEditMixer
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
          setUpdateList={setUpdateList}
        />
      )}
      <Card>
        <CardHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Dados Misturador</div>
            <Button
              color="white"
              bg="default"
              border="default"
              size="sm"
              onClick={() => setModalShow(true)}
              type="button"
            >
              <Icons type="plus" color="#fff" size={18} />
              Novo Misturador
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            noLoad={true}
            cols={tableParams}
            editHandler={editMixerHandler}
            emptyText={"Nenhum misturador localizado!"}
            route={`/mixer/${props.match.params.id}`}
            {...props}
          />
        </CardBody>
      </Card>
    </>
  );
}
