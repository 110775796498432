import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import { api } from "services/api";
import { Card, CardBody, CardHeader } from "styles";
import { convertDateToString } from "utils/globalFunctions";
import { Config } from "./Config";
import { CreateEdit } from "./CreateEdit";

export function Maintenance(props) {
  const tableParams = [
    {
      key: "technicalManager",
      params: "name",
      label: "Técnico Responsável",
      filter: "text",
      placeholder: "Digite a nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "maintenanceDate",
      label: "Data da Manutenção",
      filter: "date",
      type: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "equipment",
      label: "Equipamento",
      type: "custom",
      filter: "text",
      mobile: {
        type: "item",
      },
      response: ({ equipment }) => {
        return equipment;
      },
    },
  ];

  const [maintenance, setMaintenance] = useState({
    equipment: "",
    codes: [
      {
        code: "",
        description: "",
        nextMaintenance: "",
        alert: true,
      },
    ],
    technicalManager: null,
    maintenanceDate: "",
  });

  const [modalConfigShow, setModalConfigShow] = useState(false);

  const [modalMaintenanceShow, setModalMaintenanceShow] = useState(false);

  const [id, setId] = useState("");

  const [getData, setGetData] = useState(true);

  const [config, setConfig] = useState({
    equipments: [],
    codes: [],
  });

  const editHandler = ({
    _id,
    equipment,
    codes,
    technicalManager,
    maintenanceDate,
  }) => {
    setMaintenance({
      equipment,
      codes: codes.map((item) => ({
        ...item,
        nextMaintenance: convertDateToString(item.nextMaintenance),
      })),
      technicalManager,
      maintenanceDate: convertDateToString(maintenanceDate),
    });
    setId(_id);
    setModalMaintenanceShow(true);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.get(
          "maintenanceconfig/" + props.match.params.id
        );
        if (response.data) {
          setConfig(response.data);
        }
      } catch (e) {
        Swal.fire("buscar Configurações", e.message, "error");
      }
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.match.params.id, getData]);

  return (
    <>
      {modalMaintenanceShow && (
        <CreateEdit
          show={modalMaintenanceShow}
          setShow={setModalMaintenanceShow}
          location={props.location}
          match={props.match}
          config={config}
          setConfig={setConfig}
          id={id}
          setId={setId}
          maintenance={maintenance}
          setMaintenance={setMaintenance}
        />
      )}
      {modalConfigShow && (
        <Config
          show={modalConfigShow}
          setShow={setModalConfigShow}
          location={props.location}
          match={props.match}
          config={config}
          setConfig={setConfig}
          getData={getData}
          setGetData={setGetData}
        />
      )}
      <Card>
        <CardHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Manutenção</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                color="white"
                bg="primary"
                border="primary"
                size="sm"
                onClick={() => setModalConfigShow(true)}
                type="button"
                style={{ marginRight: "10px" }}
              >
                <Icons type="gear" color="#fff" size={18} />
                &nbsp;Configuração
              </Button>
              <Button
                color="white"
                bg="default"
                border="default"
                size="sm"
                onClick={() => setModalMaintenanceShow(true)}
                type="button"
              >
                <Icons type="tools" color="#fff" size={18} />
                Lançar Manutenção
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            noLoad={true}
            cols={tableParams}
            editHandler={editHandler}
            emptyText={"Nenhuma manutenção localizado!"}
            route={`/maintenance/${props.match.params.id}`}
            {...props}
          />
        </CardBody>
      </Card>
    </>
  );
}
