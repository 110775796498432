import { FirstLetter } from "../FirstLetter";
import {
  Container,
  Content,
  Data,
  Text,
  Title,
  UserFirstNameContainer,
} from "./style";

export function CustomerItem({ name, bg, code, state, city, button }) {
  return (
    <Container>
      <Content>
        <Data>
          <UserFirstNameContainer>
            <FirstLetter bg={bg} color="#fff" size="50">
              {name}
            </FirstLetter>
          </UserFirstNameContainer>
          <div>
            <Title>
              <h5>{name}</h5>
            </Title>
            <Text>
              {[code, state, city]
                .filter((item) => !!item)
                .reduce((a, b) => a + " - " + b)}
            </Text>
          </div>
        </Data>
        {button}
      </Content>
    </Container>
  );
}
