import { Input } from "components/Input";
import { Col, Row, Separator } from "styles";
import { H2 } from "../style";

export function ProblemDescription({ evaluation, setEvaluation }) {
  return (
    <>
      <H2>Espécie</H2>

      <Row>
        <Col>
          <Input
            type="checkbox"
            label="Aves"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.species.birds"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Suínos"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.species.pigs"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Bovinos"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.species.cattle"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Outros"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.species.others"
          />
        </Col>
        {evaluation.problemDescription.species.others && (
          <Col size={2}>
            <Input
              type="inputOnly"
              placeholder="Digite as espécies"
              item={evaluation}
              setItem={setEvaluation}
              params="problemDescription.species.othersDescription"
            />
          </Col>
        )}
      </Row>
      <Separator />
      <H2>Animais</H2>
      <Row>
        <Col>
          <Input
            type="checkbox"
            label="Baixo Consumo"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.lowConsumption"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Escoliose"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.scoliosis"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Orelhas feridas"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.soreEars"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Alto consumo"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.highConsumption"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            label="Cerdas arrepiadas"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.bristles"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Canibalismo"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.cannibalism"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Baixo peso"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.lowWeight"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Outros*"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.others"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            label="Morte de animais"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.animalDeath"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Baixo GPD"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.lowGPD"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Diarréia"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.animals.diarrhea"
          />
        </Col>
        <Col></Col>
      </Row>

      {evaluation.problemDescription.animals.others && (
        <Row>
          <Col>
            <Input
              type="inputOnly"
              placeholder="* Descrever"
              item={evaluation}
              setItem={setEvaluation}
              params="problemDescription.animals.othersDescription"
            />
          </Col>
        </Row>
      )}
      <Separator />
      <H2>Produtos</H2>
      <Row>
        <Col>
          <Input
            type="checkbox"
            label="Empedramento"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.products.cobblestone"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Coloração Alterada"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.products.changedColoration"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Sabor inconforme"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.products.nonconformingFlavor"
          />
        </Col>
        <Col>
          <Input
            type="checkbox"
            label="Odor rancificado"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.products.rancidOdor"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            label="Outros"
            item={evaluation}
            setItem={setEvaluation}
            params="problemDescription.products.others"
          />
        </Col>

        {evaluation.problemDescription.products.others && (
          <Col size={7}>
            <Input
              type="inputOnly"
              placeholder="* Descrever"
              item={evaluation}
              setItem={setEvaluation}
              params="problemDescription.products.othersDescription"
            />
          </Col>
        )}
      </Row>
    </>
  );
}
