import styled from "styled-components";

export const Container = styled.div`
  padding: 1.6rem;
  flex: 1 1;
  overflow: hidden;
  background-color: white;
  margin: 0.3rem;
  transition: all 0.3s ease;
  box-shadow: -1px 1px 7px rgb(0 0 0 / 20%);
`;

export const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
`;

export const Data = styled.div`
  flex-direction: row;
  display: flex;
`;

export const UserFirstNameContainer = styled.div`
  margin-right: 1rem;
`;

export const Title = styled.div`
  color: #343a40;
  margin-bottom: 0.25rem;
  h5 {
    font-size: 1.25rem;
  }
`;

export const Text = styled.span`
  color: #74788d;
`;
