import React, { useState, useEffect } from "react";
import { SubHeader } from "components/SubHeader";
import { api } from "services/api";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Input } from "components/Input";
import { Image } from "components/Image";
import ReactLoading from "react-loading";
import { ViewLoader } from "components/ViewLoader";
import { Icons } from "components/Icons";
import {
  Block,
  BlockBody,
  BlockHeader,
  Group,
  Card,
  CardBody,
  CardHeader,
  Col,
} from "styles";
import { Row } from "./style";
import { Body, Header, TableContent } from "components/Table/style";
import { Button } from "components/Button";

export function Necropsy(props) {
  const initialInputId = (label) => ({
    new: {
      score: "",
      description: "",
      img: {
        files: [],
        fileName: "",
      },
      loading: false,
      points: "",
    },
    edit: {
      score: "",
      description: "",
      img: {
        files: [],
        fileName: "",
      },
      _id: "",
      loading: false,
      points: "",
    },
    label,
  });

  const [inputs, setInputs] = useState({
    integument: {
      hbNecropyOralInjury: initialInputId("Lesão Oral"),
      hbNecropyMuscleHemorrhage: initialInputId("Hemorragia Muscular"),
      hbNecropyAbdominalFat: initialInputId("Gordura Abdominal"),
      hbNecropyFemurHeadNecrosis: initialInputId("Necrose da Cab. Femur"),
    },
    respiratorySystem: {
      hbNecropyAerosaculite: initialInputId("Aerossaculite"),
      hbNecropyParanasalSinuses: initialInputId("Seios Paranasais"),
      hbNecropyNasalDischarge: initialInputId("Secreção Nasal"),
      hbNecropyTracheitis: initialInputId("Traqueite"),
    },
    immuneSystem: {
      hbNecropySuffusions: initialInputId("Sufusões"),
      hbNecropyPeyerPlate: initialInputId("Placa de Peyer"),
      hbNecropyHemorrhagicCecalTonsils: initialInputId(
        "tonsilas Cecais Hemorrágicas"
      ),
    },
    digestiveSystem: {
      hbNecropygizzardErosion: initialInputId("Erosão de Moela"),
      hbNecropyPancreasAtrophy: initialInputId("Atrofia de Pâncreas"),
      hbNecropyDuodenitis: initialInputId("Duodenite"),
      hbNecropyLiver: initialInputId("Fígado"),
      hbNecropyIntestinalTone: initialInputId("Tônus Intestinal"),
      hbNecropyIntestinalWallThickness: initialInputId(
        "Espessura da parede intestinal"
      ),
      hbNecropyFluidContent: initialInputId("Conteúdo Fluido"),
      hbNecropyMucusPresence: initialInputId("Presença de Muco"),
      hbNecropyPresenceOfBile: initialInputId("Presença de Bile"),
      hbNecropyFlatWorms: initialInputId("Vermes chatos"),
      hbNecropyNecroticEnteritis: initialInputId("Enterite Necrótica"),
      hbNecropyFoodPass: initialInputId("Passagem de Alimento"),
      hbNecropyCellPeeling: initialInputId("Descamação Celular"),
      hbNecropyIntestinalgas: initialInputId("Gás intestinal"),
      hbNecropyHyperemia: initialInputId("Hiperemia (vermelhidão)"),
      hbNecropyCecalGas: initialInputId("Gás cecal"),
    },
    reproductiveSystem: {
      hbNecropyFollicleFormation: initialInputId("Formação De Foliculos"),
      hbNecropyAtresicFollicle: initialInputId("Foliculo Atrésico"),
    },
  });

  const [configs, setConfigs] = useState([]);

  const [getConfigs, setGetConfigs] = useState(true);

  const [loadRemove, setLoadRemove] = useState("");

  const [loading, setLoading] = useState(true);

  const [tab, setTab] = useState(0);

  const setEdit = (key, { score, description, _id, points }) => {
    const keys = key.split(".");
    const copyInputs = JSON.parse(JSON.stringify(inputs));
    copyInputs[keys[0]][keys[1]].edit._id = _id;
    copyInputs[keys[0]][keys[1]].edit.score = score;
    copyInputs[keys[0]][keys[1]].edit.description = description;
    copyInputs[keys[0]][keys[1]].edit.points = points;
    setInputs(copyInputs);
  };

  const cancelEdit = (key) => {
    const keys = key.split(".");
    const copyInputs = JSON.parse(JSON.stringify(inputs));
    copyInputs[keys[0]][keys[1]].edit = {
      score: "",
      description: "",
      img: {
        files: [],
        fileName: "",
      },
      _id: "",
      points: "",
      loading: false,
    };
    setInputs(copyInputs);
  };

  const removeItem = async (config) => {
    const result = await Swal.fire({
      text: "Deseja remover o item",
      title: "remover Item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Remover item!",
    });
    if (result.value) {
      setLoadRemove(config._id);
      const response = await api
        .delete("necropsyconfig", {
          data: { id: config._id },
        })
        .catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        }));
      setLoadRemove("");
      setGetConfigs(true);
      if (response.err) {
        return Swal.fire("remover Item", "Erro ao remover item", "error");
      }
      await api.delete("fileUpload", {
        data: {
          id: `necropsy-${config.key.replace(/\./g, "-")}-${config.score}.${
            config.extention
          }`,
        },
      });
    }
  };

  const saveItem = async (key, input, edit = false) => {
    const keys = key.split(".");
    let copyInputs = JSON.parse(JSON.stringify(inputs));
    const param = edit ? "edit" : "new";
    const request = edit ? "put" : "post";
    copyInputs[keys[0]][keys[1]][param].loading = true;
    setInputs(copyInputs);
    const { score, description, _id, points } = input[param];
    const { fileName, files } = input[param].img;
    const extention = files[0]
      ? fileName.split(".")[fileName.split(".").length - 1]
      : "";
    const data = {
      key,
      score,
      description,
      points,
    };
    if (extention) {
      data.extention = extention;
    }
    const response = await api[request](
      `necropsyconfig${edit ? "/" + _id : ""}`,
      data
    ).catch((err) => ({
      err: true,
      data: { message: err.response.data.message },
    }));

    if (response.err) {
      copyInputs = JSON.parse(JSON.stringify(inputs));
      copyInputs[keys[0]][keys[1]][param].loading = false;
      setInputs(copyInputs);
      return Swal.fire(
        `${edit ? "Edit" : "Add"} Item`,
        response.data.message,
        "error"
      );
    }

    if (files[0]) {
      const formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append(
        "file",
        files[0],
        `necropsy-${key.replace(/\./g, "-")}-${response.data.id}.${extention}`
      );
      const responseImage = await api
        .post("fileUpload", formData, config)
        .catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        }));

      if (responseImage.err) {
        Swal.fire(
          "Enviar Imagem",
          "Não foi possível salvar a imagem",
          "warning"
        );
      }
    }
    copyInputs = JSON.parse(JSON.stringify(inputs));
    copyInputs[keys[0]][keys[1]] = initialInputId(
      inputs[keys[0]][keys[1]].label
    );
    setInputs(copyInputs);
    setGetConfigs(true);
  };

  const necropsyConfigsLoader = (key) => {
    const params = key.split(".");
    const input = inputs[params[0]][params[1]];
    return (
      <Col>
        <Card>
          <CardHeader>{input.label}</CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th style={{ width: "100px" }}>Score</th>
                  <th>Descrição</th>
                  <th style={{ width: "92px" }}>Pontos</th>
                  <th style={{ width: "150px" }}>Imagem</th>
                  <th></th>
                </tr>
              </Header>
              <Body>
                {configs
                  .filter((config) => config.key === key)
                  .map((config) =>
                    input.edit._id === config._id ? (
                      <tr key={config._id}>
                        <td>
                          <Input
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.score`}
                            type="inputOnly"
                            placeholder="Score"
                          />
                        </td>
                        <td>
                          <Input
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.description`}
                            type="inputOnly"
                            placeholder="Descrição"
                          />
                        </td>
                        <td>
                          <Input
                            inputType="number"
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.points`}
                            type="inputOnly"
                            placeholder="Pontos"
                          />
                        </td>
                        <td>
                          <Input
                            type="inputFile"
                            item={inputs}
                            setItem={setInputs}
                            params={`${key}.edit.img`}
                            accept="image/*"
                            placeholder="imagem"
                          />
                        </td>
                        <td>
                          {input.edit.loading ? (
                            <ReactLoading
                              type="spin"
                              color="#009870"
                              height={30}
                              width={30}
                            />
                          ) : (
                            <Group>
                              <Button
                                type="button"
                                bg="basf"
                                border="basf"
                                color="white"
                                onClick={() => saveItem(key, input, true)}
                              >
                                <Icons type="edit" color="#FFF" size={15} />
                              </Button>
                              <Button
                                type="button"
                                bg="light"
                                border="secondary"
                                color="secondary"
                                onClick={() => cancelEdit(key)}
                              >
                                <Icons type="close" color="#7E8299" size={15} />
                              </Button>
                            </Group>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr key={config._id}>
                        <td>{config.score}</td>
                        <td>{config.description}</td>
                        <td>
                          {config.points ? config.points.toLocaleString() : 0}
                        </td>
                        <td>
                          <Image
                            src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/necropsy-${config.key.replace(
                              /\./g,
                              "-"
                            )}-${config._id}.${config.extention}`}
                          />
                        </td>
                        <td>
                          {loadRemove === config._id ? (
                            <ReactLoading
                              type="spin"
                              color="#009870"
                              height={30}
                              width={30}
                            />
                          ) : (
                            <Group>
                              <Button
                                type="button"
                                bg="basf"
                                border="basf"
                                color="white"
                                onClick={() => setEdit(key, config)}
                              >
                                <Icons type="edit" color="#FFF" size={15} />
                              </Button>
                              <Button
                                type="button"
                                bg="danger"
                                border="danger"
                                color="white"
                                onClick={() => removeItem(config)}
                              >
                                <Icons type="trash" color="#FFF" size={15} />
                              </Button>
                            </Group>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                <tr>
                  <td>
                    <Input
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.score`}
                      type="inputOnly"
                      placeholder="Score"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    <Input
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.description`}
                      type="inputOnly"
                      placeholder="Descrição"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    <Input
                      inpuType="number"
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.points`}
                      type="inputOnly"
                      placeholder="Pontos"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    <Input
                      type="inputFile"
                      item={inputs}
                      setItem={setInputs}
                      params={`${key}.new.img`}
                      accept="image/*"
                      placeholder="Imagem"
                      disabled={input.new.loading}
                    />
                  </td>
                  <td>
                    {input.new.loading ? (
                      <ReactLoading
                        type="spin"
                        color="#009870"
                        height={30}
                        width={30}
                      />
                    ) : (
                      <Button
                        type="button"
                        bg="default"
                        border="default"
                        color="white"
                        onClick={() => saveItem(key, input)}
                      >
                        <Icons type="save" color="#FFF" size={15} />
                      </Button>
                    )}
                  </td>
                </tr>
              </Body>
            </TableContent>
          </CardBody>
        </Card>
      </Col>
    );
  };

  useEffect(() => {
    const loadConfigs = async () => {
      const response = await api.get("necropsyconfig");
      setConfigs(response.data);
      setLoading(false);
    };
    if (getConfigs) {
      setGetConfigs(false);
      loadConfigs();
    }
  }, [getConfigs]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Necropsia"
        breadcrumbs={[
          { label: "Configuração" },
          {
            label: "Matriz Pesada",
          },
          {
            label: "Necropsia",
          },
        ]}
        icon={<Icons type="necropsy" size="24" color="#fff" />}
      />
      {!loading && (
        <Block className="animate__animated animate__fadeInUp">
          <BlockHeader>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => {
                setTab(value);
              }}
            >
              <Tab label="Tegumentar" />
              <Tab label="Sistema Respiratório" />
              <Tab label="Sistema Imunológico" />
              <Tab label="Sistema Digestório" />
              <Tab label="Sistema Reprodutivo" />
            </Tabs>
          </BlockHeader>
          {tab === 0 && (
            <BlockBody>
              <Row>
                {necropsyConfigsLoader("integument.hbNecropyOralInjury")}
                {necropsyConfigsLoader("integument.hbNecropyMuscleHemorrhage")}
              </Row>
              <Row>
                {necropsyConfigsLoader("integument.hbNecropyAbdominalFat")}
                {necropsyConfigsLoader("integument.hbNecropyFemurHeadNecrosis")}
              </Row>
            </BlockBody>
          )}
          {tab === 1 && (
            <BlockBody>
              <Row>
                {necropsyConfigsLoader(
                  "respiratorySystem.hbNecropyAerosaculite"
                )}
                {necropsyConfigsLoader(
                  "respiratorySystem.hbNecropyParanasalSinuses"
                )}
              </Row>
              <Row>
                {necropsyConfigsLoader(
                  "respiratorySystem.hbNecropyNasalDischarge"
                )}
                {necropsyConfigsLoader("respiratorySystem.hbNecropyTracheitis")}
              </Row>
            </BlockBody>
          )}
          {tab === 2 && (
            <BlockBody>
              <Row>
                {necropsyConfigsLoader("immuneSystem.hbNecropySuffusions")}
                {necropsyConfigsLoader("immuneSystem.hbNecropyPeyerPlate")}
              </Row>
              <Row>
                {necropsyConfigsLoader(
                  "immuneSystem.hbNecropyHemorrhagicCecalTonsils"
                )}
              </Row>
            </BlockBody>
          )}
          {tab === 3 && (
            <BlockBody>
              <Row>
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropygizzardErosion"
                )}
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyPancreasAtrophy"
                )}
              </Row>
              <Row>
                {necropsyConfigsLoader("digestiveSystem.hbNecropyDuodenitis")}
                {necropsyConfigsLoader("digestiveSystem.hbNecropyLiver")}
              </Row>
              <Row>
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyIntestinalTone"
                )}
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyIntestinalWallThickness"
                )}
              </Row>
              <Row>
                {necropsyConfigsLoader("digestiveSystem.hbNecropyFluidContent")}
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyMucusPresence"
                )}
              </Row>
              <Row>
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyPresenceOfBile"
                )}
                {necropsyConfigsLoader("digestiveSystem.hbNecropyFlatWorms")}
              </Row>
              <Row>
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyNecroticEnteritis"
                )}
                {necropsyConfigsLoader("digestiveSystem.hbNecropyFoodPass")}
              </Row>
              <Row>
                {necropsyConfigsLoader("digestiveSystem.hbNecropyCellPeeling")}
                {necropsyConfigsLoader(
                  "digestiveSystem.hbNecropyIntestinalgas"
                )}
              </Row>
              <Row>
                {necropsyConfigsLoader("digestiveSystem.hbNecropyHyperemia")}
                {necropsyConfigsLoader("digestiveSystem.hbNecropyCecalGas")}
              </Row>
            </BlockBody>
          )}
          {tab === 4 && (
            <BlockBody>
              <Row>
                {necropsyConfigsLoader(
                  "reproductiveSystem.hbNecropyFollicleFormation"
                )}
                {necropsyConfigsLoader(
                  "reproductiveSystem.hbNecropyAtresicFollicle"
                )}
              </Row>
            </BlockBody>
          )}
        </Block>
      )}
    </>
  );
}
