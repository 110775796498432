import { Input } from "components/Input";
import { Col, Row } from "styles";

export function MgMethod({ data, setData, getAverage }) {
  return (
    <>
      <Row>
        {data.cvBeats.map((item, index) => (
          <Col>
            <Input
              key={`cvBeats${index}`}
              type="input"
              item={data}
              setItem={setData}
              params={`cvBeats.${index}`}
              label={`CV batida ${index + 1}`}
              inputType="number"
              onChange={(e) =>
                getAverage(e.target.value, "cvBeats", "cvAverage", index)
              }
              value={data.cvBeats[index]}
            />
          </Col>
        ))}
        <Col>
          <Input
            type="input"
            item={data}
            setItem={setData}
            params={`cvAverage`}
            label={`Média`}
            inputType="number"
          />
        </Col>
        <Col>
          <Input
            type="input"
            item={data}
            setItem={setData}
            params={`residualCvBeat`}
            label={`CV batida ${data.amountOfBeats} residual`}
            inputType="number"
          />
        </Col>
      </Row>
      <Row>
        {data.recoveryRates.map((item, index) => (
          <Col>
            <Input
              key={`recoveryRates${index}`}
              type="input"
              item={data}
              setItem={setData}
              params={`recoveryRates.${index}`}
              label={`Taxa de recuperação ${index + 1}`}
              inputType="number"
              onChange={(e) =>
                getAverage(
                  e.target.value,
                  "recoveryRates",
                  "recoveryRateAverage",
                  index
                )
              }
              value={data.recoveryRates[index]}
            />
          </Col>
        ))}
        <Col>
          <Input
            type="input"
            item={data}
            setItem={setData}
            params={`recoveryRateAverage`}
            label={`Média`}
            inputType="number"
          />
        </Col>
        <Col>
          <Input
            type="input"
            item={data}
            setItem={setData}
            params={`residualRecoveryRate`}
            label={`Taxa de recuperação ${data.amountOfBeats}`}
            inputType="number"
          />
        </Col>
      </Row>
    </>
  );
}
