import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function PhytaseHidosing(props) {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <Header>
              <tr>
                <th style={{ width: "185px" }}>
                  <span></span>
                </th>
                <th>
                  <span>Qual tipo?(Pó ou líquida)</span>
                </th>
                <th>
                  <span>Qual Produto?</span>
                </th>
                <th>
                  <span>Qual dose?</span>
                </th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Pré</td>
                <td>
                  <Input
                    type="selectOnly"
                    options={[{ value: "Pó" }, { value: "Líquida" }]}
                    params="enzymes.phytaseHidosing.pre.type"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Selecione o Tipo"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.pre.product"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.pre.dose"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Inicial</td>
                <td>
                  <Input
                    type="selectOnly"
                    options={[{ value: "Pó" }, { value: "Líquida" }]}
                    params="enzymes.phytaseHidosing.initial.type"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Selecione o Tipo"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.initial.product"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.initial.dose"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Crescimento 1</td>
                <td>
                  <Input
                    type="selectOnly"
                    options={[{ value: "Pó" }, { value: "Líquida" }]}
                    params="enzymes.phytaseHidosing.growth.type"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Selecione o Tipo"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.growth.product"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.growth.dose"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
              <tr>
                <td>Crescimento 2</td>
                <td>
                  <Input
                    type="selectOnly"
                    options={[{ value: "Pó" }, { value: "Líquida" }]}
                    params="enzymes.phytaseHidosing.growth2.type"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Selecione o Tipo"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.growth2.product"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.growth2.dose"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>

              <tr>
                <td>Final</td>
                <td>
                  <Input
                    type="selectOnly"
                    options={[{ value: "Pó" }, { value: "Líquida" }]}
                    params="enzymes.phytaseHidosing.final.type"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Selecione o Tipo"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.final.product"
                    item={props.broiler}
                    setItem={props.setBroiler}
                    placeholder="Digite o Produto"
                  />
                </td>
                <td>
                  <Input
                    type="inputOnly"
                    params="enzymes.phytaseHidosing.final.dose"
                    item={props.broiler}
                    setItem={props.setBroiler}
                  />
                </td>
              </tr>
            </Body>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Microtech está aprovada? Se não, por qual motivo?</label>
          <Input
            type="checkbox"
            params="enzymes.phytaseHidosing.approved.checked"
            item={props.broiler}
            setItem={props.setBroiler}
            label="Aprovado"
          />
          <Input
            type="input"
            label=" "
            params="enzymes.phytaseHidosing.approved.reason"
            item={props.broiler}
            setItem={props.setBroiler}
            disabled={props.broiler.enzymes.phytaseHidosing.approved.checked}
            placeholder="Digite o motivo"
          />
        </Col>
      </Row>
    </>
  );
}
