export const historicOptions = [
  { label: "Fitase Hidolsing", value: "enzymes.phytaseHidosing" },
  { label: "Corboidrase/Blends", value: "enzymes.carbohydraseBlends" },
  { label: "EnzyPac Pro / Protease", value: "enzymes.enziPacProProtease" },
  { label: "Anticoccidiano", value: "health.anticoccidian" },
  { label: "Medicação Terapeutica", value: "health.therapeuticMedication" },
  { label: "Melhorador de Desempenho", value: "health.performanceEnhancer" },
  { label: "Minerais Orgânicos", value: "minerals.organics" },
  { label: "Ácidos Orgânicos", value: "additions.organicAcids" },
  {
    label: "Absorventes de Micotoxina",
    value: "additions.mycotoxinAdsorbents",
  },
  { label: "Óleos Essenciais", value: "additions.essencialOils" },
  { label: "Prebióticos", value: "additions.prebiotics" },
  { label: "Probióticos", value: "additions.probiotics" },
];

export const typesOfInjuries = [
  { value: "digestive", label: "Digestório" },
  { value: "locomotor", label: "Locomotor" },
  { value: "respiratory", label: "Respiratório" },
  { value: "immunological", label: "Imunológico" },
  { value: "integumentary", label: "Tegumentar" },
];

export const graphConsolidadoOptions = [
  { value: "score", label: "Score" },
  { value: "ca", label: "CA" },
  { value: "birds", label: "No aves" },
  { value: "age", label: "Idade" },
  { value: "averageWeight", label: "PM" },
  { value: "mortality", label: "Mortalidade" },
  { value: "density", label: "Densidade (aves/m2)" },
  { value: "sanitaryVoid", label: "Vazio Sanitário (d)" },
  { value: "gpd", label: "GPD" },
  { value: "iep", label: "IEP" },
  { value: "chickWeight", label: "Peso de pinto" },
  { value: "cac", label: "C.A.C." },
  { value: "cost", label: "R$/Aves" },
];
