import React from "react";
import { AnimatedButton } from "components/AnimatedButton";
import { Icons } from "components/Icons";
import { Col, Row } from "styles";

export function StepButtonsMegg({ tab, customer }) {
  return (
    <Row>
      <Col className="animate__animated animate__fadeInLeft" size={1}>
        <AnimatedButton
          icon={<Icons type="factory" size={34} color="#009870" />}
          title="Fábrica"
          text="Connect"
          active={tab === "3"}
          disabled={true}
          to={`/customer/factory/1/30/-date/{}/${customer._id}`}
        />
      </Col>
      <Col className="animate__animated animate__fadeInLeft">
        <AnimatedButton
          icon={
            <img
              src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/matriz.png"
              alt="matriz"
              style={{ width: "34px" }}
            />
          }
          title="Cria-Recria"
          text="Necrópsia"
          active={tab === "1"}
          to={`/customer/${customer._id}/${1}`}
        />
      </Col>
      <Col className="animate__animated animate__fadeInRight">
        <AnimatedButton
          icon={<Icons type="lineChart" size={34} color="#009870" />}
          title="Produção"
          text={
            <div style={{ textAlign: "center" }}>Pré-Necropsia Necropsia</div>
          }
          active={tab === "0" || !tab}
          to={`/customer/${customer._id}/${0}`}
        />
      </Col>
      <Col className="animate__animated animate__fadeInRight">
        <AnimatedButton
          icon={<Icons type="slaughter" size={34} color="#009870" />}
          title="Qualidade de Ovos"
          text={
            <div style={{ textAlign: "center" }}>
              Impacto Ovo Ovo Eletrônico
            </div>
          }
          active={tab === "2"}
          to={`/customer/${customer._id}/${2}`}
        />
      </Col>
    </Row>
  );
}
