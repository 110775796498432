import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  transition: all 0.5s linear;
`;

export const Img = styled.img`
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;
