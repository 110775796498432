import { useState, useContext } from "react";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import updateList from "contexts/updateList";
import { Card, CardBody, CardHeader } from "styles";
import { CreateEditNutritionalLevels } from "./CreateEditNutritionalLevels";
import { Table } from "components/Table";

export function NutritionalLevels(props) {
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState("");
  const { setUpdateList } = useContext(updateList);

  const tableParams = [
    {
      key: "user",
      params: "name",
      label: "Cadastrado por",
      filter: "text",
      placeholder: "Digite a nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "date",
      label: "Data da Criação",
      filter: "date",
      type: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "content",
      label: "Média do consumo",
      params: "consume.percentageConsume.weightedAverage",
      mobile: {
        type: "small",
      },
    },
  ];

  const editHandler = (row) => {
    setId(row._id);
    setModalShow(true);
  };

  const handleCreate = () => {
    setId("");
    setModalShow(true);
  };

  return (
    <>
      {modalShow && (
        <CreateEditNutritionalLevels
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          match={props.match}
          setUpdateList={setUpdateList}
          id={id}
        />
      )}
      <Card>
        <CardHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Níveis Nutricionais</div>

            <Button
              color="white"
              bg="default"
              border="default"
              size="sm"
              onClick={handleCreate}
              type="button"
            >
              <Icons type="plus" color="#fff" size={18} />
              Novo Lançamento
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            noLoad={true}
            cols={tableParams}
            editHandler={editHandler}
            emptyText={"Nenhum termo localizado!"}
            route={`/nutritionalLevels/${props.match.params.id}`}
            {...props}
          />
        </CardBody>
      </Card>
    </>
  );
}
