import { format } from "date-fns";
import { Bar } from "react-chartjs-2";
import { Block, BlockBody, BlockBodyContent, BlockHeader } from "styles";
import { months, necropsyConfig } from "utils/globalData";
import { convertDateToString, getColor } from "utils/globalFunctions";

export function Graph({
  responseReport,
  dateRange,
  input,
  typesOfInjuries,
  query,
}) {
  const getReportlabels = (perPeriod) =>
    perPeriod.map((item) => {
      return [
        (responseReport.group === "Semanal"
          ? `Semana ${item.week}`
          : months[item.month]) +
          " / " +
          item.year,
        `De ${convertDateToString(
          responseReport.group === "Semanal"
            ? item.startOfWeek
            : item.startOfMonth
        )} até ${convertDateToString(
          responseReport.group === "Semanal" ? item.endOfWeek : item.endOfMonth
        )}`,
        `${item.totalBirds} Aves Necropsiadas`,
        responseReport.createdBy ? responseReport.createdBy : "",
      ];
    });

  const getReportData = (index) => {
    const response = {
      datasets: [
        {
          label: "Score Connect (Digestivo)",
          type: "line",
          data: responseReport.perPeriod[index].map((item) => item.totalScore),
          fill: false,
          borderColor: getColor("#007bff", 0.7),
          backgroundColor: getColor("#007bff", 0.7),
          pointBorderColor: "#007bff",
          pointBackgroundColor: "#007bff",
          pointHoverBackgroundColor: getColor("#007bff", 0.9),
          pointHoverBorderColor: getColor("#007bff", 0.9),
          yAxisID: "y-axis-1",
        },
      ],
    };
    Object.keys(responseReport.initialTotalPerScore[index]).forEach((key) => {
      const { injuryType } = JSON.parse(query);
      const stack = key.split("-")[0];
      const config = necropsyConfig[injuryType[index]][key.split("-")[0]];
      response.datasets.push({
        label: `${config.label}, score:${key.split("-")[1]}`,
        yAxisID: "y-axis-2",
        type: "bar",
        backgroundColor: responseReport.perPeriod[index].map((item) => {
          return getColor(config.color, 1 - +`0.${+key.split("-")[1] * 3}`);
        }),
        borderColor: responseReport.perPeriod[index].map((item) => {
          return getColor(config.color, 0.8);
        }),
        hoverBackgroundColor: responseReport.perPeriod[index].map((item) => {
          return getColor(config.color, 1.2 - +`0.${+key.split("-")[1] * 3}`);
        }),
        hoverBorderColor: responseReport.perPeriod[index].map((item) => {
          return getColor(config.color, 1);
        }),
        stack,
        borderWidth: 1,
        data: responseReport.perPeriod[index].map((item) => {
          return item.totalBirds
            ? ((item[key] / item.totalBirds) * 100).toFixed(0)
            : 0;
        }),
      });
    });

    return response;
  };
  return responseReport.perPeriod.map((perPeriod, index) => (
    <Block id={`graph${index}`} key={index}>
      <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
        % Lesões | Score | De {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
        {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
        {responseReport.customer ? ` | ${responseReport.customer}` : ""}
        {` | ${
          typesOfInjuries.find(
            ({ value }) => value === JSON.parse(query).injuryType[index]
          ).label
        }`}
      </BlockHeader>
      <BlockBody>
        <BlockBodyContent>
          <Bar
            data={getReportData(index)}
            options={{
              labels: getReportlabels(perPeriod),

              elements: {
                line: {
                  fill: false,
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: false,
                    },
                    stacked: true,
                    labels: getReportlabels(perPeriod),
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-1",
                    gridLines: {
                      display: false,
                    },
                    labels: {
                      show: true,
                    },
                    ticks: {
                      beginAtZero: true,
                      max: 10,
                    },
                  },
                  {
                    type: "linear",
                    display: true,
                    position: "right",
                    id: "y-axis-2",
                    gridLines: {
                      display: false,
                    },
                    labels: {
                      show: true,
                    },
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              legend: {
                position: "bottom",
              },
              showAllTooltips: true,
              tooltips: {
                backgroundColor: "rgba(0, 0, 0, 0)",
                bodyFontColor: "#48465b",
                titleFontStyle: "normal",
                displayColors: false,
                callbacks: {
                  label: function (tooltipItem, chart) {
                    const { label, data } =
                      chart.datasets[tooltipItem.datasetIndex];
                    return (
                      (label === "Score Connect (Digestivo)"
                        ? ""
                        : label + ": ") +
                      data[tooltipItem.index].toString().replace(".", ",") +
                      (label !== "Score Connect (Digestivo)" ? "%" : "")
                    );
                  },
                  title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                  },
                },
              },
            }}
          />
        </BlockBodyContent>
      </BlockBody>
    </Block>
  ));
}
