import styled from "styled-components";
import { Button } from "components/Button";
import { BlockBodyContent } from "styles";

export const SliderContent = styled.div``;

export const SliderContentInput = styled.div`
  margin-bottom: 15px;
  display: flex;
  input {
    :first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    :last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: none;
      :active,
      :focus {
        border-left: solid 1px var(--default);
      }
    }
  }
`;

export const ReportButton = styled(Button)`
  display: flex;
  width: 100% !important;
  align-items: center;
  justify-content: Center;
  i {
    margin-right: 10px;
  }
`;
export const ItemScore = styled(BlockBodyContent)`
  height: 100%;
  cursor: pointer;
`;

export const ItemScoreTitle = styled.span`
  color: var(--gray);
  font-weight: 500;
`;

export const ItemScoreProgress = styled.div`
  color: var(--gray);
  font-weight: 500;
  border-radius: 3px;
  background-color: var(--light);
  div {
    min-height: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 12px;
      color: #fff;
    }
  }
`;

export const ItemScoreQtd = styled.div`
  color: var(--gray);
  font-weight: 500;
  margin-bottom: 5px;
`;

export const ItemScoreNumber = styled.div`
  color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HistoricOptionContent = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const HistoricOption = styled.span`
  background-color: var(--default);
  padding: 5px 10px;
  margin: 5px;
  font-size: 10px;
  border-radius: 2px;
  color: #fff !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-left: 10px;
  }
`;

export const BoxIconContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
