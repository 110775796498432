import { Container, Content, Copyright } from "./style";

export function Footer() {
  return (
    <Container>
      <Content>
        <img
          alt="Logo"
          src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-MCassab-hz.svg"
        />
        <Copyright>
          © {new Date().getFullYear()} Desenvolvido por Zenbytes
        </Copyright>
      </Content>
    </Container>
  );
}
