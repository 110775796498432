import { Button } from "components/Button";
import { HistoricItem } from "components/historicItem";
import { Icons } from "components/Icons";
import Score from "react-apexcharts";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "styles";
import { useState } from "react";
import { CreateEdit as HistoricHeavyBroiler } from "./Historic/CreateEdit";
import Matriz from "assets/icons/matriz.png";
import {
  getColor,
  getScoreFecesColor,
  getScoreFecesText,
} from "utils/globalFunctions";
import { ScoreItem } from "components/ScoreItem";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import isMobile from "is-mobile";
import { CreateEdit as CreateEditPreNecropsy } from "./PreNecropsy/CreateEdit";
import { CreateEdit as CreateEditHbNecropsy } from "./Necropsy/CreateEdit";
import { Line } from "react-chartjs-2";

export function HeavyBroiler({
  historicHeavyBroiler,
  customer,
  location,
  lastPreNecropsies,
  randomCore,
}) {
  const [showModalHistoric, setShowModalHistoric] = useState(false);

  const [showPreNecropsy, setShowPreNecropsy] = useState(false);

  const [showNecropsy, setShowNecropsy] = useState(false);

  const [preNecropsyId, setPreNecropsyId] = useState(null);

  const [historicId, setHistoricId] = useState(null);

  return (
    <>
      {showNecropsy && (
        <CreateEditHbNecropsy
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={location}
          id={preNecropsyId}
          customer={customer}
        />
      )}
      {showPreNecropsy && (
        <CreateEditPreNecropsy
          show={showPreNecropsy}
          setShow={setShowPreNecropsy}
          location={location}
          id={preNecropsyId}
          customer={customer}
        />
      )}
      {showModalHistoric && (
        <HistoricHeavyBroiler
          show={showModalHistoric}
          setShow={setShowModalHistoric}
          customer={customer._id}
          id={historicId}
          onHide={() => {
            setShowModalHistoric(false);
            setHistoricId(null);
          }}
          location={location}
        />
      )}
      <Row>
        <Col size={1.3} className="animate__animated animate__fadeInLeft">
          <Block>
            <BlockHeader>
              <span>
                <img
                  src={Matriz}
                  style={{ width: "20px" }}
                  alt="matriz pesada"
                />
                &nbsp;Histórico de Programas
              </span>
              <Button
                color="white"
                bg="default"
                border="default"
                size="sm"
                onClick={() => {
                  setHistoricId(null);
                  setShowModalHistoric(true);
                }}
              >
                <Icons type="substract" size="20" color="#fff" />
                Programa Atual
              </Button>
            </BlockHeader>
            <BlockBody>
              {historicHeavyBroiler.map((item) => (
                <HistoricItem
                  key={item._id}
                  {...item}
                  button={
                    <Button
                      bg={`var(--default)`}
                      border={`var(--default)`}
                      color="white"
                      size="sm"
                      onClick={() => {
                        setHistoricId(item._id);
                        setShowModalHistoric(true);
                      }}
                    >
                      <Icons type="etc" color="#fff" size="15" />
                    </Button>
                  }
                />
              ))}
              <Separator />
              <Button
                color="default"
                bg="light"
                border="light"
                style={{ width: "100%" }}
                to={`/connect/heavybroilerhistoric/1/30/index/{}/${customer._id}`}
              >
                Acessar Histórico Completo&nbsp;
                <Icons type="angleDoubleRight" size={20} color="#009870" />
              </Button>
            </BlockBody>
          </Block>
        </Col>
        <Col size={2} className="animate__animated animate__fadeInRight">
          <Block>
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_verde_escuro_wave_tall.png">
              Resultado Pré-Necropsia, núcleo{" "}
              {!!randomCore[0] ? randomCore[0].core : ""}
              <Button
                color="white"
                bg="default"
                border="default"
                to={`/connect/heavybroilerreport/${customer._id}/{}`}
              >
                <Icons type="plus" size="20" color="#fff" />
                &nbsp; Novo Relatório
              </Button>
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                {!!randomCore[0] && (
                  <Line
                    data={{
                      labels: randomCore.map(({ age }) => `${age} dias`),
                      datasets: [
                        {
                          label: "Score pre necropsia",
                          data: randomCore.map(({ total }) => total.score),
                          fill: false,
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgba(255, 99, 132, 0.2)",
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </BlockBodyContent>
            </BlockBody>
          </Block>
        </Col>
      </Row>
      <Row>
        <Col className="animate__animated animate__fadeInRight">
          <Block>
            <BlockHeader>
              <span>Últimas Pré-necropsias</span>
              <div>
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  size="sm"
                  onClick={() => {
                    setPreNecropsyId("");
                    setShowPreNecropsy(true);
                  }}
                >
                  <Icons type="plus" size="20" color="#fff" />
                  &nbsp; Nova Pré-necropsia
                </Button>
              </div>
            </BlockHeader>
            <BlockBody>
              {lastPreNecropsies.map((item) => (
                <ScoreItem
                  key={item._id}
                  title={item.customer?.name}
                  scoreColor={getScoreFecesColor(item.results.total)}
                  text={["Núcleo:" + item.core, "Lote:" + item.lote]}
                  description={[
                    item.visitDate
                      ? `Visita ${format(new Date(item.visitDate), "dd MMM y", {
                          locale: ptBR,
                        })}`
                      : null,

                    item.age
                      ? `idade:
                      ${item.age}`
                      : null,
                  ]}
                  score={
                    <Score
                      options={{
                        plotOptions: {
                          radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                              margin: 0,
                              size: "70%",
                              background: "#f9f9fc ",
                              image: undefined,
                              imageOffsetX: 0,
                              imageOffsetY: 0,
                              position: "front",
                            },
                            track: {
                              background: getColor(
                                getScoreFecesColor(item.results.total),
                                0.2
                              ),
                              strokeWidth: "67%",
                              margin: 0, // margin is in pixels
                            },

                            dataLabels: {
                              showOn: "always",
                              name: {
                                offsetY: -5,
                                show: true,
                                color: getScoreFecesColor(item.results.total),
                                fontSize: "5px",
                              },
                              value: {
                                formatter: function (val) {
                                  return (val / 10).toLocaleString();
                                },
                                color: getScoreFecesColor(item.results.total),
                                fontSize: "10px",
                                show: true,
                                offsetY: -5,
                              },
                            },
                          },
                        },
                        fill: {
                          colors: [getScoreFecesColor(item.results.total)],
                          type: "gradient",
                          gradient: {
                            shade: "dark",
                            type: "horizontal",

                            gradientToColors: [
                              getScoreFecesColor(item.results.total),
                            ],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 0.8,
                            stops: [0, 100],
                          },
                        },
                        stroke: {
                          lineCap: "round",
                        },
                        labels: [getScoreFecesText(item.results.total)],
                      }}
                      series={[item.results.total * 10]}
                      type="radialBar"
                      width={isMobile() ? "90%" : "110"}
                    />
                  }
                  button={
                    <>
                      <Button
                        style={{ marginRight: "10px" }}
                        type="button"
                        bg="info"
                        color="white"
                        border="info"
                        onClick={() => {
                          setPreNecropsyId(item._id);
                          setShowNecropsy(true);
                        }}
                        size="sm"
                      >
                        Necropsia&nbsp;
                        <Icons type="necropsy" size="15" color="#fff" />
                      </Button>
                      <Button
                        bg={getScoreFecesColor(item.results.total)}
                        border={getScoreFecesColor(item.results.total)}
                        onClick={() => {
                          setPreNecropsyId(item._id);
                          setShowPreNecropsy(true);
                        }}
                        color="white"
                        size="sm"
                      >
                        Pré-Necropsia&nbsp;
                        <Icons type="preNecropsy" color="#fff" size="15" />
                      </Button>
                    </>
                  }
                />
              ))}
              {lastPreNecropsies[0] && (
                <>
                  <Separator />
                  <Button
                    to={`/connect/heavybroilerprenecropsy/1/30/-code/%7B%7D/${customer._id}`}
                    color="default"
                    bg="light"
                    border="light"
                    style={{ width: "100%" }}
                  >
                    Acessar Página de Pré-Necropsias&nbsp;
                    <Icons type="angleDoubleRight" size={20} color="#009870" />
                  </Button>
                </>
              )}
            </BlockBody>
          </Block>
        </Col>
      </Row>
    </>
  );
}
