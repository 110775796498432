import { Input } from "components/Input";
import { Col, Row } from "styles";

export function InitialDescription({ evaluation, setEvaluation }) {
  return (
    <>
      <Row>
        <Col>
          <Input
            label="Responsável Técnico"
            item={evaluation}
            setItem={setEvaluation}
            params="initialDescription.technician"
            type="autocomplete"
            paramsGet={["code", "name"]}
            paramsLabel={["code", "name"]}
            select={"code name"}
            model={"user"}
            placeholder={"Digite o Código ou o nome do Técnico"}
          />
        </Col>
        <Col>
          <Input
            type="inputMask"
            label="Data da visita"
            mask="99/99/9999"
            item={evaluation}
            setItem={setEvaluation}
            params="initialDescription.visitDate"
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="Temperatura Média Últimos 7 Dias"
            item={evaluation}
            setItem={setEvaluation}
            params="initialDescription.temperature"
          />
        </Col>
      </Row>
    </>
  );
}
