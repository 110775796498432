import { Input } from "components/Input";
import { Col, Row } from "styles";

export function Data({ slaughter, setSlaughter, nutritionList }) {
  return (
    <>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="technicalManager"
            type="autocomplete"
            label="Responsável Técnico"
            paramsGet={["code", "name"]}
            paramsLabel={["code", "name"]}
            select="code name"
            model="user"
            placeholder="Digite o nome ou o código do Técnico"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="evaluationDate"
            type="inputMask"
            label="Data Avaliação"
            mask="99/99/9999"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="collectionTime"
            type="inputMask"
            label="Hora da coleta da amostra (hs:min)"
            mask="99:99"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="collectionShift"
            type="input"
            label="Turno da coleta da amostra"
          />
        </Col>

        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="integratedProducer"
            type="input"
            label="Integrado/Produtor"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="batchCode"
            type="input"
            label="Código do lote"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="matrixLot"
            type="input"
            label="Lote de matriz"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="batchAge"
            type="input"
            label="Idade do lote abatido (dias)"
          />
        </Col>

        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="numberOfBirdsLot"
            type="input"
            label="Numero de aves no lote"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="averegeBatchWeight"
            type="input"
            label="Peso médio do lote vivo (kg/ave)"
            placeholder="(Kg)"
            max={4}
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="dailyBreastYieldPercent"
            type="input"
            label="Rendimento de peito do dia (%)"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="batchUniformtyPercent"
            type="input"
            label="Uniformidade do lote (%)"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="lotDensity"
            type="input"
            label="Densidade do lote (aves/m2)"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            type="select"
            params="numberOfBirdsMale"
            label="N° de aves amostradas (Machos)"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({
              value: item,
            }))}
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="numberOfBirdsFemale"
            type="select"
            label="N° de aves amostradas (Fêmeas)"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({
              value: item,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="detectedDiseases"
            type="input"
            label="Doenças detectadas no lote"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="fastingHours"
            type="input"
            label="Horas de jejum (hs)"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="suspensionDate"
            type="input"
            mask="99/99/9999"
            label={
              <span style={{ fontSize: 11 }}>
                Data de suspensão da ração com antibiótico e/ou coccidiostático
              </span>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="lineage"
            type="select"
            placeholder="Linhagem"
            label="Linhagem"
            options={["Cobb", "Ross", "Hubbard", "Misto"].map((item) => ({
              value: item,
            }))}
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="nutrition"
            type="select"
            label="Nutrição"
            options={nutritionList}
            placeholder="Selecione a nutrição"
          />
        </Col>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="gender"
            type="select"
            placeholder="Selecione o sexo"
            label="Sexo"
            options={["Macho", "Femea", "Misto"].map((item) => ({
              value: item,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={slaughter}
            setItem={setSlaughter}
            params="lineSpeed"
            type="input"
            label="Velocidade da linha de abate"
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </>
  );
}
