import { Container, Img } from "./style";

export function NotFound() {
  return (
    <Container>
      <Img
        src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/404.png"
        alt="logo"
      />
    </Container>
  );
}
