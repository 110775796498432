import { useEffect, useMemo, useState } from "react";
import { api } from "services/api";
import { Input } from "components/Input";
import isMobile from "is-mobile";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Table, Title } from "./styled";
import { Body, Header } from "components/Table/style";

export function Customer({
  necropsy,
  setNecropsy,
  loadHistoric,
  setLoadHistoric,
  disableCustomer,
}) {
  const initialFarmData = useMemo(
    () => ({
      _id: "",
      customer: "",
      health: {
        anticoccidian: {
          moleculePre: "",
          moleculeInitial: "",
          moleculeGrowth: "",
          moleculeGrowth2: "",
          moleculeFinal: "",
          dosePre: "",
          doseInitial: "",
          doseGrowth: "",
          doseGrowth2: "",
          doseFinal: "",
        },
        performanceEnhancer: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
      },
      additions: {
        organicAcids: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        mycotoxinAdsorbents: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        essencialOils: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        prebiotics: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        probiotics: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
      },
    }),
    []
  );
  const [farmData, setFarmData] = useState(initialFarmData);

  useEffect(() => {
    const getCustomerHistoric = async () => {
      setLoadHistoric(false);
      const response = await api.get(
        `broilernecropsy/historic/${necropsy.customer._id}`
      );
      if (!response.data) {
        response.data = {
          customer: necropsy.customer._id,
        };
      }

      setFarmData(response.data);
    };
    if (
      (necropsy.customer && necropsy.customer._id !== farmData.customer) ||
      (loadHistoric && necropsy.customer)
    ) {
      getCustomerHistoric();
    } else if (!necropsy.customer && farmData.customer) {
      setFarmData(initialFarmData);
    }
  }, [
    necropsy.customer,
    farmData.customer,
    initialFarmData,
    setLoadHistoric,
    loadHistoric,
  ]);

  return (
    <>
      {!disableCustomer && (
        <Row>
          <Col>
            <Input
              required
              item={necropsy}
              setItem={setNecropsy}
              params="customer"
              type="autocomplete"
              disabled={!!disableCustomer}
              label="Cliente"
              paramsGet={["code", "name"]}
              paramsLabel={["code", "name", "state", "city"]}
              select="code name state city"
              model="customer"
              placeholder="Digite o nome ou o código do cliente e selecione"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Title>
            Programa em Andamento{" "}
            {disableCustomer ? ` - ${necropsy.customer?.name}` : ""}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <span>Saúde</span>
            </CardHeader>
            <CardBody>
              <Table>
                <Header>
                  <tr>
                    <th style={{ width: isMobile() ? "50px" : "130px" }}>
                      Fase
                    </th>
                    {isMobile() ? (
                      <th>Programa</th>
                    ) : (
                      <>
                        <th className="text-primary">Anticoccidianos</th>
                        <th className="text-primary">Dose PPM</th>
                        <th className="text-info">Melhorador de desempenho</th>
                        <th className="text-info">Dose PPM</th>
                      </>
                    )}
                  </tr>
                </Header>
                <Body>
                  <tr>
                    <td>Pré</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Anticoccidianos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.health?.anticoccidian?.moleculePre}:{" "}
                            {farmData?.health?.anticoccidian?.dosePre}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.health?.performanceEnhancer?.pre
                                ?.product
                            }
                            : {farmData?.health?.performanceEnhancer?.pre?.dose}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.moleculePre}
                        </td>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.dosePre}
                        </td>
                        <td className="text-info">
                          {farmData?.health?.performanceEnhancer?.pre?.product}
                        </td>
                        <td className="text-info">
                          {farmData?.health?.performanceEnhancer?.pre?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Inicial</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Anticoccidianos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.health?.anticoccidian?.moleculeInitial}:{" "}
                            {farmData?.health?.anticoccidian?.doseInitial}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.health?.performanceEnhancer?.initial
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.health?.performanceEnhancer?.initial
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.moleculeInitial}
                        </td>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.doseInitial}
                        </td>
                        <td className="text-info">
                          {
                            farmData?.health?.performanceEnhancer?.initial
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {farmData?.health?.performanceEnhancer?.initial?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.1" : "Crescimento 1"}</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Anticoccidianos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.health?.anticoccidian?.moleculeGrowth}:{" "}
                            {farmData?.health?.anticoccidian?.doseGrowth}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.health?.performanceEnhancer?.growth
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.health?.performanceEnhancer?.growth
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.moleculeGrowth}
                        </td>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.doseGrowth}
                        </td>
                        <td className="text-info">
                          {
                            farmData?.health?.performanceEnhancer?.growth
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {farmData?.health?.performanceEnhancer?.growth?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cresc.2" : "Crescimento 2"}</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Anticoccidianos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.health?.anticoccidian?.moleculeGrowth2}:{" "}
                            {farmData?.health?.anticoccidian?.doseGrowth2}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.health?.performanceEnhancer?.growth2
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.health?.performanceEnhancer?.growth2
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.moleculeGrowth2}
                        </td>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.doseGrowth2}
                        </td>
                        <td className="text-info">
                          {
                            farmData?.health?.performanceEnhancer?.growth2
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {farmData?.health?.performanceEnhancer?.growth2?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Final</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Anticoccidianos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.health?.anticoccidian?.moleculeFinal}:{" "}
                            {farmData?.health?.anticoccidian?.doseFinal}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Melhorador de desempenho</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.health?.performanceEnhancer?.final
                                ?.product
                            }
                            :{" "}
                            {farmData?.health?.performanceEnhancer?.final?.dose}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.moleculeFinal}
                        </td>
                        <td className="text-primary">
                          {farmData?.health?.anticoccidian?.doseFinal}
                        </td>
                        <td className="text-info">
                          {
                            farmData?.health?.performanceEnhancer?.final
                              ?.product
                          }
                        </td>
                        <td className="text-info">
                          {farmData?.health?.performanceEnhancer?.final?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                </Body>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Title>Aditivos</Title>
            </CardHeader>
            <CardBody>
              <Table>
                <Header>
                  <tr>
                    <th style={{ width: isMobile() ? "50px" : "130px" }}>
                      Fase
                    </th>
                    {isMobile() ? (
                      <th className="text-primary">Programa</th>
                    ) : (
                      <>
                        <th className="text-primary">Ácidos orgânicos</th>
                        <th className="text-primary">Dose</th>
                        <th className="text-dark">Óleos essenciais</th>
                        <th className="text-dark">Dose</th>
                        <th className="text-danger">Probióticos</th>
                        <th className="text-danger">Dose</th>
                        <th className="text-info">Prebioticos</th>
                        <th className="text-info">Dose</th>
                        <th className="text-warning">
                          Adsorvente de micotoxinas
                        </th>
                        <th className="text-warning">Dose</th>
                      </>
                    )}
                  </tr>
                </Header>
                <Body>
                  <tr>
                    <td>Pré</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.additions?.organicAcids?.pre?.product}:{" "}
                            {farmData?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.essencialOils?.pre?.product}:{" "}
                            {farmData?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.probiotics?.pre?.product}:{" "}
                            {farmData?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.prebiotics?.pre?.product}:{" "}
                            {farmData?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.pre?.product}
                        </td>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.pre?.dose}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.pre?.product}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.pre?.dose}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.pre?.product}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.pre?.dose}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.pre?.product}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.pre?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.pre
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {farmData?.additions?.mycotoxinAdsorbents?.pre?.dose}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Inicial</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.additions?.organicAcids?.pre?.product}:{" "}
                            {farmData?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.essencialOils?.pre?.product}:{" "}
                            {farmData?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.probiotics?.pre?.product}:{" "}
                            {farmData?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.prebiotics?.pre?.product}:{" "}
                            {farmData?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.initial?.product}
                        </td>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.initial?.dose}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.initial?.product}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.initial?.dose}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.initial?.product}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.initial?.dose}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.initial?.product}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.initial?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.initial
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.initial
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.1" : "Crescimento 1"}</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.additions?.organicAcids?.pre?.product}:{" "}
                            {farmData?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.essencialOils?.pre?.product}:{" "}
                            {farmData?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.probiotics?.pre?.product}:{" "}
                            {farmData?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.prebiotics?.pre?.product}:{" "}
                            {farmData?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.growth?.product}
                        </td>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.growth?.dose}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.growth?.product}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.growth?.dose}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.growth?.product}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.growth?.dose}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.growth?.product}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.growth?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.growth
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.growth
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.2" : "Crescimento 2"}</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.additions?.organicAcids?.pre?.product}:{" "}
                            {farmData?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.essencialOils?.pre?.product}:{" "}
                            {farmData?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.probiotics?.pre?.product}:{" "}
                            {farmData?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.prebiotics?.pre?.product}:{" "}
                            {farmData?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.growth2?.product}
                        </td>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.growth2?.dose}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.growth2?.product}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.growth2?.dose}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.growth2?.product}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.growth2?.dose}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.growth2?.product}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.growth2?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.growth2
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.growth2
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Final</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Ácidos orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.additions?.organicAcids?.pre?.product}:{" "}
                            {farmData?.additions?.organicAcids?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>óleos essenciais</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.essencialOils?.pre?.product}:{" "}
                            {farmData?.additions?.essencialOils?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Probióticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.probiotics?.pre?.product}:{" "}
                            {farmData?.additions?.probiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Prebioticos</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.additions?.prebiotics?.pre?.product}:{" "}
                            {farmData?.additions?.prebiotics?.pre?.dose}
                          </span>
                          <span className="text-info mt-1">
                            <strong> Adsorvente de micotoxinas</strong>
                          </span>
                          <span className="text-info">
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.product
                            }
                            :{" "}
                            {
                              farmData?.additions?.mycotoxinAdsorbents?.pre
                                ?.dose
                            }
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.final?.product}
                        </td>
                        <td className="text-primary">
                          {farmData?.additions?.organicAcids?.final?.dose}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.final?.product}
                        </td>
                        <td className="text-dark">
                          {farmData?.additions?.essencialOils?.final?.dose}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.final?.product}
                        </td>
                        <td className="text-danger">
                          {farmData?.additions?.probiotics?.final?.dose}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.final?.product}
                        </td>
                        <td className="text-info">
                          {farmData?.additions?.prebiotics?.final?.dose}
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.final
                              ?.product
                          }
                        </td>
                        <td className="text-warning">
                          {
                            farmData?.additions?.mycotoxinAdsorbents?.final
                              ?.dose
                          }
                        </td>
                      </>
                    )}
                  </tr>
                </Body>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
