import styled from "styled-components";
import { TableContent } from "components/Table/style";
import { InputText, Textarea } from "components/Input/style";

export const MicrotracerTable = styled(TableContent)`
  th,
  td {
    padding: 8px 0px !important;
  }
  td {
    text-align: center;
  }
  width: ${(props) =>
    props.width === 1 ? "50%" : props.width === 2 ? "70%" : "100%"};
`;

export const BeatsHeader = styled.th`
  text-align: center !important;
`;

export const BeatsQuantity = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  height: 100%;
`;

export const PercentageInput = styled(InputText)`
  text-align: center;
  display: inline-block;
  color: ${(props) => props.colorRef || "inherit"};

  :focus {
    color: inherit;
    border-color: ${(props) => props.colorRef || "var(--default)"};
  }
`;

export const ConclusionArea = styled(Textarea)`
  text-align: center;
  display: inline-block;
  padding: 0.5rem 0.5rem;
  min-height: calc(2em + 2rem + 2px);
  height: calc(2em + 4rem + 2px);
  max-height: calc(2em + 8rem + 2px);
`;
