import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const show = css`
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease,
    top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  right: 0;
  left: auto;
`;

export const Container = styled.div`
  right: -395px;
  left: auto;
  -webkit-box-shadow: 0 1px 9px -3px rgb(0 0 0 / 25%);
  box-shadow: 0 1px 9px -3px rgb(0 0 0 / 25%);
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease,
    top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  left: -395px;
  width: 375px;
  background-color: #fff;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
  padding: 2.5rem !important;
  @media (max-width: 1024px) {
    width: 275px;
  }
  ${({ on }) => (on === "true" ? show : "")}
`;

export const Header = styled.div`
  padding-bottom: 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  small {
    color: #b5b5c3;
    font-weight: 400;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  color: #7e8299;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-left: 10px;
`;

export const Profile = styled.div`
  margin-top: 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`;

export const ProfilePhoto = styled.div`
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
  margin-right: 1.25rem;
  svg {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`;

const imgStyle = css`
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
  @media (max-width: 1024px) {
    width: 50px;
    height: 50px;
  }
`;

export const ProfileImg = styled.div`
  background-image: url("https://scontent.faqa2-1.fna.fbcdn.net/v/t31.18172-8/13502823_1202180296479535_8715190793336032896_o.jpg?_nc_cat=102&ccb=1-3&_nc_sid=174925&_nc_ohc=eUkkzOszJZIAX-bTFx0&_nc_ht=scontent.faqa2-1.fna&oh=185ac703e14b4e23fe214b587a4ea2f5&oe=6093A331");
  ${imgStyle}
`;

export const ProfileFirstLetter = styled.div`
  ${imgStyle}
  h1 {
    color: var(--default);
    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }
  }
`;

export const ProfileData = styled.div`
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`;

export const ProfileDataName = styled.div`
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  font-size: 1.25rem;
  color: var(--default);
  font-weight: 500;
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;
export const ProfileDataRole = styled.div`
  color: #b5b5c3;
  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
`;

const profileDataLogoutStyle = css`
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  margin-top: 0.5rem;
  button {
    margin-top: 0.5rem;
    @media (max-width: 1024px) {
      font-size: 0.9rem;
    }
  }
`;

export const ProfileDataLogout = styled.div`
  ${profileDataLogoutStyle}
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ProfileDataLogoutMobile = styled.div`
  ${profileDataLogoutStyle}
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ProfileDataEmail = styled.div`
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: var(--default);
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 0.5rem;
  padding: 0;
  span {
    color: #b5b5c3;
    margin-left: 5px;
    @media (max-width: 1024px) {
      font-size: 0.8rem;
    }
  }
`;

export const ItemsContainer = styled.div`
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
`;

export const ItemContent = styled(Link)`
  padding-left: 0;
  padding-right: 0;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3f4254;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  @media (max-width: 1024px) {
    padding: 0.75rem 0;
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
  margin-right: 0.75rem;
  background-color: #f3f6f9;
  width: 40px;
  height: 40px;
`;

export const ItemContentText = styled.div`
  color: #3f4254;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 1rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
`;

export const ItemTitle = styled.div`
  font-weight: 500;
  color: #3f4254;
`;

export const ItemText = styled.div`
  color: #b5b5c3;
  font-size: 1rem;
  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
`;
