import { useState, Fragment } from "react";
import { Icons } from "components/Icons";
import { Col } from "styles";
import { hbPreNecropsyConfig } from "utils/globalData";
import {
  ItemScore,
  ItemScoreTitle,
  ItemScoreProgress,
  ItemScoreQtd,
  ItemScoreNumber,
} from "../style";

export function BoxInjury({ param, response }) {
  const config = hbPreNecropsyConfig.feces[param];
  const { feces } = response;
  const [showScores, setShowScores] = useState(true);
  return (
    <Col>
      <ItemScore onClick={() => setShowScores(!showScores)}>
        <Icons type="preNecropsy" size={24} color={config.color} />
        <ItemScoreTitle>{config.label}</ItemScoreTitle>
        <ItemScoreProgress>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuenow="100"
            aria-valuemax="100"
            style={{
              width: `${(
                feces.map((item) => item[param]).reduce((a, b) => a + b) /
                feces.length
              ).toFixed(0)}%`,
              backgroundColor: config.color,
            }}
          >
            <span>
              {(
                feces.map((item) => item[param]).reduce((a, b) => a + b) /
                feces.length
              ).toFixed(0)}
              %
            </span>
          </div>
        </ItemScoreProgress>

        <ItemScoreQtd>
          <b>Qtd: </b>
          {feces
            .map(({ totalFecesAnalysis }) => totalFecesAnalysis)
            .reduce((a, b) => a + b)}
        </ItemScoreQtd>

        {showScores &&
          feces
            .sort(function (a, b) {
              return a.lote < b.lote ? -1 : a.lote > b.lote ? 1 : 0;
            })
            .map((item) => (
              <Fragment key={item.lote}>
                <ItemScoreNumber>
                  <div style={{ width: "100%" }}>
                    <b>Lote {item.lote}:</b>{" "}
                    {(item.totalFecesAnalysis * (item[param] / 100)).toFixed(0)}{" "}
                    de {item.totalFecesAnalysis}
                  </div>
                  <div>{item[param].toFixed(0)}%</div>
                </ItemScoreNumber>
                <ItemScoreProgress>
                  <div
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuenow="100"
                    aria-valuemax="100"
                    style={{
                      width: `${item[param].toFixed(0)}%`,
                      backgroundColor: config.color,
                    }}
                  ></div>
                </ItemScoreProgress>
              </Fragment>
            ))}
      </ItemScore>
    </Col>
  );
}
