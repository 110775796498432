import React, { useCallback, useEffect, useMemo } from "react";

import Chart from "react-apexcharts";
import { Body, Header, TableContent } from "components/Table/style";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import {
  getColor,
  getScoreFecesColor,
  getScoreFecesText,
} from "utils/globalFunctions";

export function Result({
  preNecropsy,
  config,
  results,
  setResults,
  getEstimatedBodyWeight,
  getEstimatedTotalEgg,
  getEstimatedTotalEggsWeekly,
  getEstimatedVIAB,
  getEstimatedEggQuality,
}) {
  const paramsConfigFeces = useMemo(
    () => ({
      good: "1",
      humid: "2",
      gas: "3",
      feedPassage: "4",
      limestonePresence: "5",
      mucusPresence: "6",
      intestinalFlaking: "7",
    }),
    []
  );

  const paramsConfigFecesScore = useCallback((score) => {
    if (score > 9) {
      return 0;
    } else if (score > 8) {
      return -0.25;
    } else if (score > 7) {
      return -0.5;
    } else if (score > 6) {
      return -1;
    } else if (score > 5) {
      return -1.5;
    } else if (score > 4) {
      return -2;
    } else if (score > 3) {
      return -2.25;
    } else if (score > 2) {
      return -2.5;
    } else if (score > 1) {
      return -2.75;
    } else {
      return -3;
    }
  }, []);
  const paramsConfigEnvironment = useMemo(
    () => ({
      externalCleaning: "environment.hbExternalCleaning",
      internalCleaning: "environment.hbInternalCleaning",
      ventilationSystem: "environment.hbVentilationSystem",
      luxLighting: "environment.hbLuxLighting",
      footbathPresence: "environment.hbFootbathPresence",
      exclusiveFootwearUse: "environment.hbExclusiveFootwearUse",
    }),
    []
  );

  const paramsConfigSanity = useMemo(
    () => ({
      vivacity: "sanity.hbVivacity",
      bedQuality: "sanity.hbBedQuality",
      prolapse: "sanity.hbProlapse",
      snore: "sanity.hbSnore",
      sneeze: "sanity.hbSneeze",
      coryza: "sanity.hbCoryza",
      swollenHead: "sanity.hbSwollenHead",
    }),
    []
  );

  useEffect(() => {
    const feces =
      preNecropsy.boxes
        .map((box) => {
          const sum =
            box.birds
              .map((bird) => {
                let response = 0;
                Object.keys(bird).forEach((key) => {
                  const score =
                    config.filter(
                      (item) =>
                        item.key === "feces.hbFeces" &&
                        item.score === paramsConfigFeces[key]
                    )[0]?.points || 0;
                  response = response + (bird[key] ? score : 0);
                });
                return response;
              })
              .reduce((a, b) => a + b) /
              box.birds.length +
            10;

          return (
            sum +
            Object.keys(paramsConfigFeces)
              .map((key) => (box[key] > 50 ? -1 : 0))
              .reduce((a, b) => a + b)
          );
        })
        .reduce((a, b) => a + b) / preNecropsy.boxes.length;

    const environment =
      Object.keys(paramsConfigEnvironment)
        .map(
          (key) =>
            config.filter(
              (item) =>
                item.key === paramsConfigEnvironment[key] &&
                preNecropsy.environment[key] === item.score
            )[0]?.points || 0
        )
        .reduce((a, b) => a + b) + 10;

    const sanity =
      Object.keys(paramsConfigSanity)
        .map(
          (key) =>
            config.filter(
              (item) =>
                item.key === paramsConfigSanity[key] &&
                preNecropsy.sanity[key] === item.score
            )[0]?.points || 0
        )
        .reduce((a, b) => a + b) +
      paramsConfigFecesScore(feces) +
      10;

    const production =
      [
        preNecropsy.production.viab / preNecropsy.birds >= getEstimatedVIAB() &&
        preNecropsy.production.viab / preNecropsy.birds <=
          getEstimatedVIAB() * 0.1 + getEstimatedVIAB()
          ? -2
          : preNecropsy.production.viab / preNecropsy.birds >=
            getEstimatedVIAB() * 0.2 + getEstimatedVIAB()
          ? -3
          : 0,
        preNecropsy.production.bodyWeight <= getEstimatedBodyWeight() &&
        preNecropsy.production.bodyWeight >=
          getEstimatedBodyWeight() - getEstimatedBodyWeight() * 0.05
          ? -1
          : preNecropsy.production.bodyWeight <=
            getEstimatedBodyWeight() - getEstimatedBodyWeight() * 0.1
          ? -2.5
          : 0,
        preNecropsy.production.totalEgg <= getEstimatedTotalEgg() &&
        preNecropsy.production.totalEgg >=
          getEstimatedTotalEgg() * 0.05 - getEstimatedTotalEgg()
          ? -1
          : preNecropsy.production.totalEgg <
            getEstimatedTotalEgg() * 0.1 - getEstimatedTotalEgg()
          ? -2
          : 0,
        preNecropsy.production.averageProductionWeek <=
          getEstimatedTotalEggsWeekly() &&
        preNecropsy.production.averageProductionWeek >=
          getEstimatedTotalEggsWeekly() -
            preNecropsy.production.averageProductionWeek * 0.05
          ? -1
          : preNecropsy.production.averageProductionWeek <=
            getEstimatedTotalEggsWeekly() - getEstimatedTotalEggsWeekly() * 0.1
          ? -2.5
          : 0,
      ].reduce((a, b) => a + b) + 10;

    const eggQuality =
      [
        preNecropsy.eggQuality.weight <=
          getEstimatedEggQuality("eggWeight", 1) &&
        preNecropsy.eggQuality.weight >=
          getEstimatedEggQuality("eggWeight", 1) -
            getEstimatedEggQuality("eggWeight", 1) * 0.02
          ? -0.5
          : preNecropsy.eggQuality.weight <=
            getEstimatedEggQuality("eggWeight", 1) -
              getEstimatedEggQuality("eggWeight", 1) * 0.05
          ? -1
          : 0,
        preNecropsy.eggQuality.small >=
          getEstimatedEggQuality("smallEggs", 100) &&
        preNecropsy.eggQuality.small <=
          getEstimatedEggQuality("smallEggs", 100) * 0.5 +
            getEstimatedEggQuality("smallEggs", 100)
          ? -0.5
          : preNecropsy.eggQuality.small >=
            getEstimatedEggQuality("smallEggs", 100) * 0.1 +
              getEstimatedEggQuality("smallEggs", 100)
          ? -1
          : 0,
        preNecropsy.eggQuality.doubleYolk >=
          getEstimatedEggQuality("doubleEggYolk", 100) &&
        preNecropsy.eggQuality.doubleYolk <=
          getEstimatedEggQuality("doubleEggYolk", 100) * 0.5 +
            getEstimatedEggQuality("doubleEggYolk")
          ? -1
          : preNecropsy.eggQuality.doubleYolk >=
            getEstimatedEggQuality("doubleEggYolk", 100) * 0.1 +
              getEstimatedEggQuality("doubleEggYolk", 100)
          ? -2
          : 0,
        preNecropsy.eggQuality.discart >=
          getEstimatedEggQuality("discartEggs", 100) &&
        preNecropsy.eggQuality.discart <=
          getEstimatedEggQuality("discartEggs", 100) * 0.5 +
            getEstimatedEggQuality("discartEggs", 100)
          ? -1
          : preNecropsy.eggQuality.discart >=
            getEstimatedEggQuality("discartEggs", 100) * 0.1 +
              getEstimatedEggQuality("discartEggs", 100)
          ? -2
          : 0,
        preNecropsy.eggQuality.fissura >=
          getEstimatedEggQuality("fissuraEggs", 100) &&
        preNecropsy.eggQuality.fissura <=
          getEstimatedEggQuality("fissuraEggs", 100) * 0.5 +
            getEstimatedEggQuality("fissuraEggs", 100)
          ? -0.25
          : preNecropsy.eggQuality.fissura >=
            getEstimatedEggQuality("fissuraEggs", 100) * 0.1 +
              getEstimatedEggQuality("fissuraEggs", 100)
          ? -0.5
          : 0,
        preNecropsy.eggQuality.cracked >=
          getEstimatedEggQuality("crackedEggs", 100) &&
        preNecropsy.eggQuality.cracked <=
          getEstimatedEggQuality("crackedEggs", 100) * 0.5 +
            getEstimatedEggQuality("crackedEggs", 100)
          ? -0.75
          : preNecropsy.eggQuality.cracked >=
            getEstimatedEggQuality("crackedEggs", 100) * 0.1 +
              getEstimatedEggQuality("crackedEggs", 100)
          ? -1.5
          : 0,
        preNecropsy.eggQuality.litter >=
          getEstimatedEggQuality("litterEggs", 100) &&
        preNecropsy.eggQuality.litter <=
          getEstimatedEggQuality("litterEggs", 100) * 0.5 +
            getEstimatedEggQuality("litterEggs", 100)
          ? -1
          : preNecropsy.eggQuality.litter >=
            getEstimatedEggQuality("litterEggs", 100) * 0.1 +
              getEstimatedEggQuality("litterEggs", 100)
          ? -2
          : 0,
      ].reduce((a, b) => a + b) + 10;

    const total =
      feces * 0.2 + environment * 0.25 + production * 0.25 + sanity * 0.3;

    setResults({
      feces,
      environment,
      production,
      eggQuality,
      sanity,
      total,
    });
  }, [
    preNecropsy,
    setResults,
    config,
    paramsConfigFeces,
    paramsConfigEnvironment,
    paramsConfigSanity,
    paramsConfigFecesScore,
    getEstimatedVIAB,
    getEstimatedTotalEggsWeekly,
    getEstimatedTotalEgg,
    getEstimatedBodyWeight,
    getEstimatedEggQuality,
  ]);
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader>Fezes</CardHeader>
            <CardBody>
              <Chart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "80%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreFecesColor(results.feces),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -10,
                          show: true,
                          color: getScoreFecesColor(results.feces),
                          fontSize: "11px",
                        },
                        value: {
                          offsetY: 0,
                          formatter: function (val) {
                            return (
                              val / 10 < 0 ? 0 : val / 10
                            ).toLocaleString();
                          },
                          color: getScoreFecesColor(results.feces),
                          fontSize: "20px",
                          show: true,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [getScoreFecesColor(results.feces)],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [getScoreFecesColor(results.feces)],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [getScoreFecesText(results.feces)],
                }}
                series={[results.feces * 10]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>Ambiente</CardHeader>
            <CardBody>
              <Chart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "80%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreFecesColor(results.environment),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -10,
                          show: true,
                          color: getScoreFecesColor(results.environment),
                          fontSize: "11px",
                        },
                        value: {
                          offsetY: 0,
                          formatter: function (val) {
                            return (
                              val / 10 < 0 ? 0 : val / 10
                            ).toLocaleString();
                          },
                          color: getScoreFecesColor(results.environment),
                          fontSize: "20px",
                          show: true,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [getScoreFecesColor(results.environment)],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [
                        getScoreFecesColor(results.environment),
                      ],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [getScoreFecesText(results.environment)],
                }}
                series={[results.environment * 10]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>Produção</CardHeader>
            <CardBody>
              <Chart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "80%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreFecesColor(results.production),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -10,
                          show: true,
                          color: getScoreFecesColor(results.production),
                          fontSize: "11px",
                        },
                        value: {
                          offsetY: 0,
                          formatter: function (val) {
                            return (
                              val / 10 < 0 ? 0 : val / 10
                            ).toLocaleString();
                          },
                          color: getScoreFecesColor(results.production),
                          fontSize: "20px",
                          show: true,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [getScoreFecesColor(results.production)],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [
                        getScoreFecesColor(results.production),
                      ],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [getScoreFecesText(results.production)],
                }}
                series={[results.production * 10]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>Qualidade de Ovos</CardHeader>
            <CardBody>
              <Chart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "80%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreFecesColor(results.eggQuality),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -10,
                          show: true,
                          color: getScoreFecesColor(results.eggQuality),
                          fontSize: "11px",
                        },
                        value: {
                          offsetY: 0,
                          formatter: function (val) {
                            return (
                              val / 10 < 0 ? 0 : val / 10
                            ).toLocaleString();
                          },
                          color: getScoreFecesColor(results.eggQuality),
                          fontSize: "20px",
                          show: true,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [getScoreFecesColor(results.eggQuality)],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [
                        getScoreFecesColor(results.eggQuality),
                      ],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [getScoreFecesText(results.eggQuality)],
                }}
                series={[results.eggQuality * 10]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>sanidade</CardHeader>
            <CardBody>
              <Chart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "80%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreFecesColor(results.sanity),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -10,
                          show: true,
                          color: getScoreFecesColor(results.sanity),
                          fontSize: "11px",
                        },
                        value: {
                          offsetY: 0,
                          formatter: function (val) {
                            return (
                              val / 10 < 0 ? 0 : val / 10
                            ).toLocaleString();
                          },
                          color: getScoreFecesColor(results.sanity),
                          fontSize: "20px",
                          show: true,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [getScoreFecesColor(results.sanity)],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [getScoreFecesColor(results.sanity)],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [getScoreFecesText(results.sanity)],
                }}
                series={[results.sanity * 10]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th style={{ width: "300px", textAlign: "center" }}>
                  Resultado Final Pré-Necrópcia
                </th>
                <th>Relatório de Melhorias</th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>
                  <div style={{ width: "300px" }}>
                    <Chart
                      options={{
                        plotOptions: {
                          radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                              margin: 0,
                              size: "80%",
                              background: "#f9f9fc ",
                              image: undefined,
                              imageOffsetX: 0,
                              imageOffsetY: 0,
                              position: "front",
                            },
                            track: {
                              background: getColor(
                                getScoreFecesColor(results.total),
                                0.2
                              ),
                              strokeWidth: "67%",
                              margin: 0, // margin is in pixels
                            },

                            dataLabels: {
                              showOn: "always",
                              name: {
                                offsetY: -10,
                                show: true,
                                color: getScoreFecesColor(results.total),
                                fontSize: "11px",
                              },
                              value: {
                                offsetY: 0,
                                formatter: function (val) {
                                  return (
                                    val / 10 < 0 ? 0 : val / 10
                                  ).toLocaleString();
                                },
                                color: getScoreFecesColor(results.total),
                                fontSize: "20px",
                                show: true,
                              },
                            },
                          },
                        },
                        fill: {
                          colors: [getScoreFecesColor(results.total)],
                          type: "gradient",
                          gradient: {
                            shade: "dark",
                            type: "horizontal",

                            gradientToColors: [
                              getScoreFecesColor(results.total),
                            ],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 0.8,
                            stops: [0, 100],
                          },
                        },
                        stroke: {
                          lineCap: "round",
                        },
                        labels: [getScoreFecesText(results.total)],
                      }}
                      series={[results.total * 10]}
                      type="radialBar"
                      width="100%"
                    />
                  </div>
                </td>
                <td></td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
    </>
  );
}
