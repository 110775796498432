import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Col, Row } from "styles";
import { Table } from "../../style";

export function Others(props) {
  return (
    <>
      <Row>
        <Col>
          <Input
            type="checkbox"
            params="additions.others.emulsifiers"
            item={props.megg}
            setItem={props.setMegg}
            label="Utiliza Emulsificantes"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="checkbox"
            params="additions.others.pigmentation"
            item={props.megg}
            setItem={props.setMegg}
            label={` Utiliza Pigmentante
            ${props.megg.additions.others.pigmentation ? ", Qual" : ""}?`}
            className="kt-checkbox pr-15"
          />
          {props.megg.additions.others.pigmentation && (
            <Input
              type="input"
              label=" "
              params="additions.others.pigmentationType"
              item={props.megg}
              setItem={props.setMegg}
              placeholder={
                props.megg.additions.others.pigmentation
                  ? "Digite o pigmentante"
                  : ""
              }
              disabled={!props.megg.additions.others.pigmentation}
            />
          )}
        </Col>
      </Row>
      {props.megg.additions.others.pigmentation && (
        <Row>
          <Col>
            <Table>
              <Header>
                <tr>
                  <th style={{ width: "185px" }}>
                    <span></span>
                  </th>
                  <th>
                    <span>Qual PPM Amarelo?</span>
                  </th>
                  <th>
                    <span>Qual PPM Vermelho?</span>
                  </th>
                </tr>
              </Header>
              <Body>
                <tr>
                  <td>
                    <span>Pré</span>
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.pre.yellowppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.pre.redppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Inicial</span>
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.initial.yellowppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.initial.redppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Crescimento</span>
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.growth.yellowppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.growth.redppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Desenvolvimento</span>
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.development.yellowppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                  <td>
                    <Input
                      type="inputOnly"
                      params="additions.others.development.redppm"
                      item={props.megg}
                      setItem={props.setMegg}
                    />
                  </td>
                </tr>
              </Body>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
}
