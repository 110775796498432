import { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Forgot } from "./connect/Auth/Forgot";
import { Login } from "./connect/Auth/Login";
import { NewPassword } from "./connect/Auth/NewPassword";
import { App as Application } from "./connect/App";
import Basf from "./contexts/basf";
import { GlobalStyle } from "./styles/global";
import { PrivacityPolicy } from "./connect/PrivacityPolicy";
import { TermsConditions } from "./connect/TermsConditions";

export function App() {
  const [basf, setBasf] = useState(false);

  return (
    <Basf.Provider value={{ basf, setBasf }}>
      {window.location.href.indexOf("test.") > -1 ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "red",
            color: "white",
            padding: 2,
            fontWeight: 600,
          }}
        >
          <span>Atenção! você esta logado na plataforma teste</span>
        </div>
      ) : null}
      <GlobalStyle basf={false} />
      <Router>
        <Switch>
          <Route
            path="/new-password/:token"
            render={(props) => <NewPassword {...props} />}
          />
          <Route
            path="/privacity-policy"
            render={(props) => <PrivacityPolicy />}
          />
          <Route
            path="/terms-conditions"
            render={(props) => <TermsConditions />}
          />
          <Route path="/forgot" render={(props) => <Forgot {...props} />} />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/" render={(props) => <Application {...props} />} />
        </Switch>
      </Router>
    </Basf.Provider>
  );
}
