import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function TherapeuticMedication(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "185px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual tipo?</span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th>
            <span>Qual dose?</span>
          </th>
          <th>
            <span>Via Água?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Pré Postura</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.preLay.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.preLay.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.preLay.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td>
            <Input
              type="checkbox"
              params="health.therapeuticMedication.preLay.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Pré Pico</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.prePeak.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.prePeak.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.prePeak.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.prePeak.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>

        <tr>
          <td>Pico de Postura</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layPeak.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layPeak.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layPeak.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layPeak.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 01</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal01.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal01.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal01.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal01.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 01</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal01.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal01.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal01.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal01.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 02</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal02.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal02.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal02.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal02.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 03</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal03.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal03.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal03.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal03.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 04</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal04.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal04.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal04.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal04.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 05</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal05.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal05.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal05.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal05.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 06</td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal06.type"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Tipo"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal06.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="health.therapeuticMedication.layFinal06.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
          <td className="kt-datatable__cell  text-center">
            <Input
              type="checkbox"
              params="health.therapeuticMedication.layFinal06.water"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
