import { useState } from "react";
import { Input } from "components/Input";
import { Image } from "components/Image";
import { Button } from "components/Button";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Body, Header, TableContent } from "components/Table/style";
import { Icons } from "components/Icons";
import { ImgLink } from "../style";

export function ScoreBirds({
  necropsy,
  setNecropsy,
  config,
  prop,
  params,
  sumBirds,
}) {
  const [info, setInfo] = useState(null);

  const selectHandler = (value, index, param) => {
    const copyNecropsy = JSON.parse(JSON.stringify(necropsy));
    copyNecropsy.birds[index][prop][param] = value;
    sumBirds(copyNecropsy);
  };

  return info ? (
    <Card style={{ height: "100%", marginBottom: "15px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <strong>
          <i className="far fa-question-circle" /> &nbsp;
          {info.label}
        </strong>
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={() => setInfo(null)}
          size="sm"
        >
          Voltar
        </Button>
      </CardHeader>
      <CardBody>
        <TableContent>
          <Header>
            <tr>
              <th style={{ width: "100px" }}>Score</th>
              <th>Descrição</th>
              <th style={{ width: "500px" }}>Imagem</th>
            </tr>
          </Header>
          <Body>
            {info.options.map((item) => (
              <tr key={item._id}>
                <td>
                  <span>
                    <b>{item.score}</b>
                  </span>
                </td>
                <td>{item.description}</td>
                <td>
                  <Image
                    className="img-fluid"
                    src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/necropsy-${item.key.replace(
                      /\./g,
                      "-"
                    )}-${item._id}.${item.extention}`}
                  />
                </td>
              </tr>
            ))}
          </Body>
        </TableContent>
      </CardBody>
    </Card>
  ) : (
    <Row>
      {necropsy.birds.map((bird, index) => (
        <Col key={index}>
          <Card>
            <CardHeader>Ave {index + 1}</CardHeader>
            <CardBody style={{ padding: "10px" }}>
              {params.map((param, indexParam) => (
                <div
                  style={
                    indexParam < params.length - 1
                      ? {
                          borderBottom: "solid 1px #F3F6F9",
                          marginBottom: "10px",
                        }
                      : null
                  }
                  key={param}
                >
                  <Input
                    item={necropsy}
                    setItem={setNecropsy}
                    label={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {config[param].style ? (
                            <small style={config[param].style}>
                              {config[param].label}
                            </small>
                          ) : (
                            config[param].label
                          )}
                        </span>
                        <Icons
                          type="question"
                          color="#009870"
                          size={18}
                          onClick={() => setInfo(config[param])}
                        />
                      </div>
                    }
                    params={`birds.${index}.${prop}.${param}`}
                    type="select"
                    placeholder="Selecione o Score"
                    onChange={(e) =>
                      selectHandler(e.target.value, index, param)
                    }
                    options={config[param].options.map((item) => ({
                      value: item.score,
                    }))}
                  />
                </div>
              ))}
              {bird[prop].images && bird[prop].images[0]
                ? bird[prop].images.map(({ fileName }) => (
                    <ImgLink
                      rel="noreferrer"
                      target="_blank"
                      href={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/${necropsy._id}-${fileName}`}
                    >
                      <Image
                        src={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/${necropsy._id}-${fileName}`}
                      />
                    </ImgLink>
                  ))
                : null}
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
