import { useState, Fragment } from "react";
import { Icons } from "components/Icons";
import { Col } from "styles";
import { hbPreNecropsyConfig } from "utils/globalData";
import {
  ItemScore,
  ItemScoreTitle,
  ItemScoreProgress,
  ItemScoreNumber,
} from "../style";

export function BoxEggQuality({ param, response }) {
  const config = hbPreNecropsyConfig.eggQuality[param];
  const { eggQualityBoxes } = response;
  const [showScores, setShowScores] = useState(true);
  return (
    <Col>
      <ItemScore onClick={() => setShowScores(!showScores)}>
        <Icons type="preNecropsy" size={24} color={config.color} />
        <ItemScoreTitle>{config.label}</ItemScoreTitle>
        {param !== "weight" && (
          <ItemScoreProgress>
            <div
              role="progressbar"
              aria-valuemin="0"
              aria-valuenow="100"
              aria-valuemax="100"
              style={{
                width: `${(
                  eggQualityBoxes
                    .map((item) => item[param])
                    .reduce((a, b) => a + b) / eggQualityBoxes.length
                ).toFixed(0)}%`,
                backgroundColor: config.color,
              }}
            >
              <span>
                {(
                  eggQualityBoxes
                    .map((item) => item[param])
                    .reduce((a, b) => a + b) / eggQualityBoxes.length
                ).toFixed(0)}
                {param === "weight" ? "g" : "%"}
              </span>
            </div>
          </ItemScoreProgress>
        )}

        {showScores &&
          eggQualityBoxes
            .sort(function (a, b) {
              return a.lote < b.lote ? -1 : a.lote > b.lote ? 1 : 0;
            })
            .map((item) => (
              <Fragment key={item.lote}>
                <ItemScoreNumber>
                  <div style={{ width: "100%" }}>
                    <b>Lote {item.lote}</b>
                  </div>
                  <div>
                    {item[param].toFixed(0)}
                    {param === "weight" ? "g" : "%"}
                  </div>
                </ItemScoreNumber>
                {param !== "weight" && (
                  <ItemScoreProgress>
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuenow="100"
                      aria-valuemax="100"
                      style={{
                        width: `${item[param].toFixed(0)}%`,
                        backgroundColor: config.color,
                      }}
                    ></div>
                  </ItemScoreProgress>
                )}
              </Fragment>
            ))}
      </ItemScore>
    </Col>
  );
}
