import { Input } from "components/Input";
import { Body, Header, TableContent } from "components/Table/style";
import { Col, Row } from "styles";

export function Production({
  preNecropsy,
  setPreNecropsy,
  getEstimatedBodyWeight,
  getEstimatedTotalEgg,
  getEstimatedTotalEggsWeekly,
  getEstimatedVIAB,
}) {
  return (
    <Row>
      <Col>
        <TableContent>
          <Header>
            <tr>
              <th></th>
              <th>Estimado</th>
              <th>Real</th>
              <th></th>
            </tr>
          </Header>
          <Body>
            <tr>
              <td>VIAB.% N aves mortas/sem.</td>
              <td>
                {(getEstimatedVIAB() * 100).toFixed(3).replace(".", ",")}%
              </td>
              <td>
                {((preNecropsy.production.viab / preNecropsy.birds) * 100 || 0)
                  .toFixed(3)
                  .replace(".", ",")}
                %
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="production.viab"
                  placeholder="Digite o núm de aves mortas/sem."
                />
              </td>
            </tr>
            <tr>
              <td>Peso Corporal</td>
              <td>{getEstimatedBodyWeight().toFixed(0).replace(".", ",")}</td>
              <td>
                {(preNecropsy.production.bodyWeight
                  ? (+preNecropsy.production.bodyWeight /
                      getEstimatedBodyWeight() -
                      1) *
                    100
                  : 0
                )
                  .toFixed(2)
                  .replace(".", ",")}
                %
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="production.bodyWeight"
                  placeholder="Digite o peso"
                />
              </td>
            </tr>
            <tr>
              <td>Total de ovos (HH)</td>
              <td>{getEstimatedTotalEgg().toFixed(1).replace(".", ",")} </td>
              <td>
                {(preNecropsy.production.totalEgg
                  ? (+preNecropsy.production.totalEgg / getEstimatedTotalEgg() -
                      1) *
                    100
                  : 0
                )
                  .toFixed(2)
                  .replace(".", ",")}
                %
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="production.totalEgg"
                  placeholder="Digite o total de ovos"
                />
              </td>
            </tr>
            <tr>
              <td>Media de produção semanal %</td>
              <td>
                {(getEstimatedTotalEggsWeekly() * 100)
                  .toFixed(0)
                  .replace(".", ",")}
                %{" "}
              </td>
              <td>
                {+preNecropsy.production.averageProductionWeek -
                  getEstimatedTotalEggsWeekly() * 100}
                %
              </td>
              <td>
                <Input
                  type="inputOnly"
                  size="sm"
                  item={preNecropsy}
                  setItem={setPreNecropsy}
                  params="production.averageProductionWeek"
                  placeholder="Digite a média de produção semanal em %"
                />
              </td>
            </tr>
          </Body>
        </TableContent>
      </Col>
    </Row>
  );
}
