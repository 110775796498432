import { useState, useCallback, useMemo, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { api } from "services/api";
import { Block, BlockBody, Card, CardBody, Col, Row, Separator } from "styles";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { UserFirstNameContainer } from "components/CustomerItem/style";
import { FirstLetter } from "components/FirstLetter";

export function Contact({
  customer,
  match,
  setCustomer,
  setGetCustomer,
  loading,
}) {
  const initialContact = useMemo(
    () => ({
      name: "",
      responsibility: "",
      paper: "",
      supportLevel: "",
      reportsTo: "",
      phoneNumber: "",
      celphoneNumber: "",
      email: "",
      activity: "",
    }),
    []
  );

  const [tab, setTab] = useState(0);

  const [contact, setContact] = useState(initialContact);

  const [isContactEdit, setIsContactEdit] = useState(false);

  const [contactIndex, setContactIndex] = useState(0);

  const [config, setConfig] = useState([]);

  const cancelEditContact = useCallback(() => {
    setContact(initialContact);
    setIsContactEdit(false);
  }, [initialContact]);

  const saveCustomer = useCallback(
    async (e) => {
      e.preventDefault();
      const requestCustomer = { ...customer };
      const response = await Swal.fire({
        title: "Editar Cliente",
        text: "Deseja confirmar edição do Cliente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Cliente!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("connect/customers/" + match.params.id, requestCustomer)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Cliente",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetCustomer(true);
      }
    },
    [customer, match, setGetCustomer]
  );

  const saveContact = useCallback(
    async (e) => {
      e.preventDefault();
      const requestCustomer = { ...customer };
      requestCustomer.contact = [...customer.contact];
      if (isContactEdit) {
        requestCustomer.contact[contactIndex] = contact;
      } else {
        requestCustomer.contact.push(contact);
      }
      const response = await Swal.fire({
        title: isContactEdit ? "Editar Contato" : "Add Contato",
        text: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: `Sim, ${
          isContactEdit ? "Editar Contato" : "Add Contato"
        }!`,
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("connect/contacts/" + match.params.id, requestCustomer)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          isContactEdit ? "Editar Contato" : "Add Contato",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetCustomer(true);
        setContact(initialContact);
        setIsContactEdit(false);
      }
    },
    [
      contact,
      contactIndex,
      customer,
      initialContact,
      isContactEdit,
      match,
      setGetCustomer,
    ]
  );

  const editContact = useCallback(
    (index) => {
      setContactIndex(index);
      setIsContactEdit(true);
      setContact({ ...customer.contact[index] });
    },
    [customer]
  );

  useEffect(() => {
    const getConfig = async () => {
      const responseConfig = await api.get("broilerhistoric/config", {
        params: {
          query:
            "premixComposition premixAnimalFeedType premixPhysicalForm premixCommonDescription " +
            "customerHealthScore customerExportCuts enzymesPhytaseProduct enzymesPhytaseType " +
            "enzymesCarbohydraseBlendsProduct enzymesEnziPacProProteaseProduct healthAnticoccidianMolecule " +
            "healthTherapeuticMedicationType healthTherapeuticMedicationProduct healthPerformanceEnhancerProduct " +
            "mineralsOrganicsProduct additionsOrganicAcidsProduct additionsMycotoxinAdsorbentsProduct " +
            "additionsEssencialOilsProduct additionsPrebioticsProduct additionsProbioticsProduct othersPigmentationType",
        },
      });
      setConfig(responseConfig.data);
    };

    getConfig();
  }, []);

  return (
    <Block className="animate__animated animate__fadeInRight">
      <BlockBody>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            setTab(value);
          }}
        >
          <Tab label="Dados" />
          <Tab label="Contatos" />
        </Tabs>
        {loading ? (
          <ReactLoading type="spin" color="#009870" height={30} width={30} />
        ) : (
          <>
            {tab === 0 && (
              <form
                onSubmit={saveCustomer}
                className="animate__animated animate__fadeInUp"
              >
                <Row>
                  <Col size={1}>
                    <Input
                      type="input"
                      label="Código"
                      value={customer.code}
                      disabled
                    />
                  </Col>
                  <Col size={3}>
                    <Input
                      type="input"
                      label="Nome do Cliente"
                      value={customer.name}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="input"
                      label="CNPJ"
                      value={customer.cnpj}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      label="CNPJ"
                      value={customer.city}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      label="Estado"
                      value={customer.state}
                      disabled
                    />
                  </Col>
                </Row>
                <h3 className="form-label">Seguimentação</h3>
                <Separator />
                <Row>
                  <Col>
                    <Input
                      type="select"
                      label="Nutrição"
                      item={customer}
                      setItem={setCustomer}
                      params="nutrition"
                      placeholder="Selecione a nutrição"
                      options={config
                        .filter((item) => item.key === "customerDataNutrition")
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      label="Motivação de compra"
                      item={customer}
                      setItem={setCustomer}
                      params="purchaseMotivation"
                      placeholder="Selecione a motivação de compra"
                      options={config
                        .filter((item) => item.key === "customerDataMotivation")
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      label="Porte"
                      item={customer}
                      setItem={setCustomer}
                      params="port"
                      placeholder="Selecione o porte"
                      options={config
                        .filter((item) => item.key === "customerDataPort")
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="select"
                      label="Canal"
                      item={customer}
                      setItem={setCustomer}
                      params="channel"
                      placeholder="Selecione o Canal"
                      options={config
                        .filter((item) => item.key === "customerDataChannel")
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      label="Classificação do Cliente"
                      item={customer}
                      setItem={setCustomer}
                      params="customerRating"
                      placeholder="Selecione a classificação do cliente"
                      options={config
                        .filter(
                          (item) => item.key === "customerDataClassification"
                        )
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                </Row>
                <Separator />
                <Row>
                  <Col
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Button
                      type="submit"
                      color="white"
                      bg="default"
                      border="default"
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
            {tab === 1 && (
              <form
                onSubmit={saveContact}
                className="animate__animated animate__fadeInUp"
              >
                <Row style={{ flexWrap: "wrap" }}>
                  {customer.contact.map((item, index) => (
                    <Col key={item.name + index} style={{ flexBasis: "30%" }}>
                      <Card>
                        <CardBody style={{ display: "flex" }}>
                          <div>
                            <Row>
                              <Col flexRow>
                                <UserFirstNameContainer>
                                  <FirstLetter
                                    bg="#009870"
                                    color="#fff"
                                    size="50"
                                  >
                                    {item.name}
                                  </FirstLetter>
                                </UserFirstNameContainer>
                                <Row>
                                  <Col
                                    style={{
                                      justifyContent: "center",
                                      marginBottom: "0",
                                    }}
                                  >
                                    <h2>{item.name}</h2> {item.responsibility}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            {item.email ? (
                              <Row>
                                <Col flexRow>
                                  <strong>Email:</strong>{" "}
                                  <span>{item.email}</span>
                                </Col>
                              </Row>
                            ) : null}

                            {item.phoneNumber ? (
                              <Row>
                                <Col flexRow>
                                  <strong>Telefone Fixo:</strong>{" "}
                                  <span>{item.phoneNumber}</span>
                                </Col>
                              </Row>
                            ) : null}

                            {item.celphoneNumber ? (
                              <Row>
                                <Col flexRow>
                                  <strong>Celular:</strong>{" "}
                                  <span>{item.celphoneNumber}</span>
                                </Col>
                              </Row>
                            ) : null}

                            {item.paper ? (
                              <Row>
                                <Col flexRow>
                                  <strong>Papel na Compra:</strong>{" "}
                                  <span>{item.paper}</span>
                                </Col>
                              </Row>
                            ) : null}

                            {item.supportLevel ? (
                              <Row>
                                <Col flexRow>
                                  <strong>Nível de Suporte:</strong>{" "}
                                  <span>{item.supportLevel}</span>
                                </Col>
                              </Row>
                            ) : null}

                            {item.reportsTo ? (
                              <Row>
                                <Col flexRow>
                                  <strong>Reporta para:</strong>{" "}
                                  <span>{item.reportsTo}</span>
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                          <div>
                            <Separator />
                            <Row>
                              <Col style={{ alignItems: "center" }}>
                                <Button
                                  type="button"
                                  color="white"
                                  bg="default"
                                  border="default"
                                  onClick={() => editContact(index)}
                                >
                                  <Icons
                                    type="edit"
                                    size={15}
                                    color="#fff"
                                  ></Icons>
                                  &nbsp;Editar
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {customer.contact[0] ? <Separator /> : null}
                <Row>
                  <Col>
                    <Input
                      type="input"
                      label="Nome"
                      item={contact}
                      setItem={setContact}
                      params="name"
                    />
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      label="Cargo"
                      item={contact}
                      setItem={setContact}
                      params="responsibility"
                    />
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      label="Papel na Compra"
                      item={contact}
                      setItem={setContact}
                      params="paper"
                      placeholder="Selecione o papel na compra"
                      options={config
                        .filter(
                          (item) => item.key === "customerDataContactPaper"
                        )
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      label="Nível de Suporte"
                      item={contact}
                      setItem={setContact}
                      params="supportLevel"
                      placeholder="Selecione o nível de suporte"
                      options={config
                        .filter(
                          (item) => item.key === "customerDataContactSuport"
                        )
                        .map((item) => ({ value: item.description }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="input"
                      label="Reporta para"
                      item={contact}
                      setItem={setContact}
                      params="reportsTo"
                    />
                  </Col>

                  <Col>
                    <Input
                      item={contact}
                      setItem={setContact}
                      params="phoneNumber"
                      type="inputMask"
                      label="Telefone Fixo"
                      mask="(99)9999-9999"
                    />
                  </Col>
                  <Col>
                    <Input
                      item={contact}
                      setItem={setContact}
                      params="celphoneNumber"
                      type="inputMask"
                      label="Telefone Celular"
                      mask="(99)9999-9999"
                    />
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      label="E-mail"
                      item={contact}
                      setItem={setContact}
                      params="email"
                      inputType="email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="input"
                      label="Atividade"
                      item={contact}
                      setItem={setContact}
                      params="activity"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col flexRow style={{ justifyContent: "center" }}>
                    <Button
                      type="submit"
                      bg="default"
                      border="default"
                      color="white"
                    >
                      {isContactEdit ? "Editar Contato" : "Adicionar Contato"}
                    </Button>
                    {isContactEdit !== false && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        type="button"
                        bg="danger"
                        border="danger"
                        color="white"
                        onClick={cancelEditContact}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            )}
          </>
        )}
      </BlockBody>
    </Block>
  );
}
