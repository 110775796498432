import { Icons } from "../Icons";
import { Container, Img, Spin } from "./style";

export function ViewLoader({ isLoading }) {
  return (
    <Container isLoading={isLoading}>
      <Img>
        <Icons type="logoMCassab" color="rgba(65,75,151,0.6)" size={75} />
      </Img>

      <Spin type="spin" color="rgba(65,75,151,0.6)" height={130} width={130} />
    </Container>
  );
}
