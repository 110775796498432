import { useState, useEffect, useCallback } from "react";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import dateFormat from "dateformat";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import Compress from "compress.js";
import { imgPreview } from "utils/globalFunctions";
import { Input } from "components/Input";
import { api } from "services/api";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";
import { Card, CardBody, CardHeader, Col, Group, Row } from "styles";
import { Body, Header, TableContent } from "components/Table/style";

const Export = (props) => {
  const compress = new Compress();
  const [loading, setloading] = useState(false);
  const [input, setInput] = useState({
    img: {
      files: [],
    },
    visitDate: "",
    conclusion: "",
  });

  const [checkGraph, setCheckGraph] = useState({
    feces: true,
    graphEgg: true,
    graphProduction: true,
    graph: true,
    graphNecropsy: true,
    historicTable: true,
    historicTreatment: true,
  });

  const [injury, setInjury] = useState([]);

  const removeItem = useCallback((index) => {
    setInput((prevState) => ({
      img: {
        files: Array.from(prevState.img.files).filter((item, i) => index !== i),
      },
    }));
  }, []);

  const getImages = () => {
    const response = [];
    for (let i = 0; i < input.img.files.length; i++) {
      const file = input.img.files[i];
      response.push(
        <tr key={file.name}>
          <td>
            <img style={{ maxWidth: "100%" }} src={imgPreview(file)} alt="" />
          </td>
          <td>
            <span>{file.name}</span>
            <br></br>
            <Group style={{ width: "100%" }}>
              <Input
                type="inputOnly"
                onChange={(e) => {
                  const newState = [...injury];
                  newState[i] = e.target.value;
                  setInjury(newState);
                }}
                value={injury[i]}
                disabled={loading}
                placeholder="Digite Lesão"
              />
              <Button
                bg="danger"
                border="danger"
                type="button"
                color="white"
                onClick={() => removeItem(i)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </Group>
          </td>
        </tr>
      );
    }
    return response;
  };

  async function resizeImageFn(file) {
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 300, // the max width of the output image, defaults to 1920px
      maxHeight: 300, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFile;
  }

  const generateReport = async () => {
    try {
      setloading(true);
      const formData = new FormData();
      const content = {
        injury: injury.map((item) => item),
        report: props.responseReport,
        visitDate: input.visitDate,
        conclusion: input.conclusion,
        customer: props.customer,
      };
      const blob = new Blob([JSON.stringify(content)], { type: "text/xml" });
      formData.append("data", blob);
      if (checkGraph.feces) {
        const fecesBlob = await domtoimage
          .toPng(document.getElementById("feces"))
          .catch(() => null);

        if (fecesBlob) {
          formData.append(
            "feces",
            new Blob([JSON.stringify(fecesBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.graphEgg) {
        const graphEggBlob = await domtoimage
          .toPng(document.getElementById("graphEgg"))
          .catch(() => null);
        if (graphEggBlob) {
          formData.append(
            "graphEgg",
            new Blob([JSON.stringify(graphEggBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.graphProduction) {
        const graphProductionBlob = await domtoimage
          .toPng(document.getElementById("graphProduction"))
          .catch(() => null);
        if (graphProductionBlob) {
          formData.append(
            "graphProduction",
            new Blob([JSON.stringify(graphProductionBlob)], {
              type: "text/xml",
            })
          );
        }
      }
      if (checkGraph.graph) {
        const graphBlob = await domtoimage
          .toPng(document.getElementById("graph"))
          .catch(() => null);
        if (graphBlob) {
          formData.append(
            "graph",
            new Blob([JSON.stringify(graphBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.graphNecropsy) {
        const graphNecropsyBlob = await domtoimage
          .toPng(document.getElementById("graphNecropsy"))
          .catch(() => null);
        if (graphNecropsyBlob) {
          formData.append(
            "graphNecropsy",
            new Blob([JSON.stringify(graphNecropsyBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.historicTable) {
        const historicTabledBlob = await domtoimage
          .toPng(document.getElementById("historicTable"))
          .catch(() => null);
        if (historicTabledBlob) {
          formData.append(
            "historicTable",
            new Blob([JSON.stringify(historicTabledBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.historicTreatment) {
        const historicTreatmentdBlob = await domtoimage
          .toPng(document.getElementById("historicTreatment"))
          .catch(() => null);
        if (historicTreatmentdBlob) {
          formData.append(
            "historicTreatment",
            new Blob([JSON.stringify(historicTreatmentdBlob)], {
              type: "text/xml",
            })
          );
        }
      }

      for (let i = 0; i < input.img.files.length; i++) {
        const file = await resizeImageFn(input.img.files[i]);
        formData.append("file", file, input.img.files[i].name);
      }
      const config = {
        responseType: "arraybuffer",
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/pdf",
        },
      };
      const response = await api
        .post("/connect/heavybroiler/export/pdf", formData, config)
        .catch(() => ({ error: true }));
      if (response.error) {
        setloading(false);
        return Swal.fire("Exportar Relatório", "Erro ao exportar !", "error");
      }
      const blobPDF = new Blob([response.data], {
        type: "application/pdf",
      });
      saveAs(
        blobPDF,
        `Relatório de visita - ${dateFormat(new Date(), "isoDateTime")}.pdf`
      );
      setloading(false);
    } catch (e) {
      Swal.fire("Exportar Relatório", "Erro ao exportar !", "error");
      setloading(false);
    }
  };

  useEffect(() => {
    if (input.img.files[0]) {
      setInjury(() => {
        const injury = [];
        for (let i = 0; i < input.img.files.length; i++) {
          injury.push("");
        }
        console.log(injury);
        return injury;
      });
    }
  }, [input.img.files]);

  return (
    <Modal
      show={props.show}
      close={props.onHide}
      header={
        <>
          <Icons type="lineChart" color="#009870" size={30} />
          <h2>Exportar Relatório</h2>
        </>
      }
      footer={
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={generateReport}
          disabled={loading}
        >
          {loading ? (
            <div style={{ display: "flex" }}>
              <ReactLoading
                type="spin"
                color="#ffffff"
                height={19}
                width={19}
              />
              &nbsp;
              <span>Aguarde, isso pode levar alguns minutos </span>
            </div>
          ) : (
            <>
              <i className="fas fa-cogs" />
              &nbsp; Gerar PDF
            </>
          )}
        </Button>
      }
    >
      <Row>
        <Col>
          <Input
            label="Datas das Visitas"
            type="input"
            item={input}
            setItem={setInput}
            multiple={true}
            params="visitDate"
            disabled={loading}
            placeholder="Digite as datas das visitas técnicas"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Selecione os relatórios</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Qualidade de Fezes"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="feces"
            disabled={loading}
          />
        </Col>
        <Col>
          <Input
            label="Qualidade de Ovos"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="graphEgg"
            disabled={loading}
          />
        </Col>
        <Col>
          <Input
            label="Produção"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="graphProduction"
            disabled={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Score Pré Necrópsia"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="graph"
            disabled={loading}
          />
        </Col>
        <Col>
          <Input
            label="Programa por Idade"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="historicTable"
            disabled={loading}
          />
        </Col>
        <Col>
          <Input
            label="Tratamento por Idade"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="historicTreatment"
            disabled={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Score Necrópsia"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="graphNecropsy"
            disabled={loading}
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Conclusão"
            type="textarea"
            item={input}
            setItem={setInput}
            multiple={true}
            params="conclusion"
            disabled={loading}
            placeholder="Digite a Conclusão"
            rows={5}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Imagens Necrópsia"
            type="inputFile"
            item={input}
            setItem={setInput}
            multiple={true}
            params="img"
            accept="image/*"
            disabled={loading}
            placeholder="Selecione as fotos tiradas nas visitas"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>Imagens Selecionadas</CardHeader>
            <CardBody>
              <TableContent>
                <Header>
                  <tr>
                    <th style={{ width: "200px" }}>Imagem</th>
                    <th>Lesão</th>
                  </tr>
                </Header>
                <Body>{getImages()}</Body>
              </TableContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default Export;
