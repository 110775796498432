import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function Steps(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th>
            <span></span>
          </th>
          <th>
            <span>Cria</span>
          </th>
          <th>
            <span>Recria</span>
          </th>
          <th>
            <span>Produção</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>
            <span>
              <b>Produção:</b>
            </span>
          </td>
          <td>
            <Input
              type="inputOnly"
              value={props.heavyBroiler.premixSteps.create.feedProductionYear}
              placeholder="Cria"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              value={props.heavyBroiler.premixSteps.recreate.feedProductionYear}
              placeholder="Recria"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              value={
                props.heavyBroiler.premixSteps.production.feedProductionYear
              }
              placeholder="Produção"
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>
              <b>Forma Física:</b>
            </span>
          </td>
          <td>
            <Input
              type="selectOnly"
              params="premixSteps.create.physicalForm"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Cria"
              options={props.config
                .filter((item) => item.key === "premixPhysicalForm")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="premixSteps.recreate.physicalForm"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Recria"
              options={props.config
                .filter((item) => item.key === "premixPhysicalForm")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="premixSteps.production.physicalForm"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Produção"
              options={props.config
                .filter((item) => item.key === "premixPhysicalForm")
                .map((item) => ({ value: item.description }))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>
              <b>Fornecedor:</b>
            </span>
          </td>
          <td>
            <Input
              type="inputOnly"
              params="premixSteps.create.provider"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Cria"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="premixSteps.recreate.provider"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Recria"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="premixSteps.production.provider"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Produção"
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>
              <b>Descrição Comum:</b>
            </span>
          </td>
          <td>
            <Input
              type="selectOnly"
              params="premixSteps.create.commonDescription"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Cria"
              options={props.config
                .filter((item) => item.key === "premixCommonDescription")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="premixSteps.recreate.commonDescription"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Recria"
              options={props.config
                .filter((item) => item.key === "premixCommonDescription")
                .map((item) => ({ value: item.description }))}
            />
          </td>
          <td>
            <Input
              type="selectOnly"
              params="premixSteps.production.commonDescription"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Produção"
              options={props.config
                .filter((item) => item.key === "premixCommonDescription")
                .map((item) => ({ value: item.description }))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>
              <b>Classe PX:</b>
            </span>
          </td>
          <td>
            <Input
              type="inputOnly"
              params="premixSteps.create.pxClass"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Cria"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="premixSteps.recreate.pxClass"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Recria"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="premixSteps.production.pxClass"
              item={props.heavyBroiler}
              setItem={props.setHeavyBroiler}
              placeholder="Produção"
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
