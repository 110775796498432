import React, { useState, useEffect, useContext } from "react";
import { SubHeader } from "components/SubHeader";
import { api } from "services/api";
import Swal from "sweetalert2";
import UserProfileContext from "contexts/profile";
import { states } from "utils/globalData";
import { hasPermission } from "utils/globalFunctions";
import { Input } from "components/Input";
import { ViewLoader } from "components/ViewLoader";
import { Icons } from "components/Icons";
import { Block, BlockBody, BlockHeader, Col, Row, Separator } from "styles";
import { Button } from "components/Button";

export function CreateEdit(props) {
  const reponsableModel = [
    {
      model: "user",
      module: "user",
      placeholder: "Digite o nome do responsável",
      param: "name",
      label: "Responsável",
    },
  ];

  const profileContext = useContext(UserProfileContext);

  const [customer, setCustomer] = useState({
    code: "",
    name: "",
    phoneNumber: "",
    state: "",
    city: "",
    cnpj: "",
    email: "",
    responsibles: [],
    status: true,
    megg: false,
    swine: false,
    _id: "",
  });

  const [indexState, setIndexState] = useState(-1);

  const [getCustomer, setGetCustomer] = useState(true);

  const [loading, setLoading] = useState(true);

  const addReponsable = () => {
    const customerCopy = { ...customer };
    customerCopy.responsibles.push({
      sellChannel: null,
      sellZone: null,
      agent: null,
      user: null,
      costCenter: null,
    });
    setCustomer(customerCopy);
  };

  const removeReponsable = (index) => {
    Swal.fire({
      title: "Deseja remover item?",
      text: "Está ação só terá efeito após salvar o cliente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover item!",
      preConfirm: () => {
        const customerCopy = { ...customer };
        customerCopy.responsibles.splice(index, 1);
        setCustomer(customerCopy);
      },
    });
  };

  const selectStateHandler = (e) => {
    const customerCopy = { ...customer };
    const value = e.target.value;
    customerCopy.state =
      value !== "" ? states.estados[+value].nome.toUpperCase() : "";
    setCustomer(customerCopy);
    setIndexState(value);
  };

  const saveCustomer = async (e) => {
    e.preventDefault();
    const requestCustomer = { ...customer };
    requestCustomer.responsibles = requestCustomer.responsibles.map((item) => ({
      sellChannel: item.sellChannel ? item.sellChannel._id : null,
      agent: item.agent ? item.agent._id : null,
      sellZone: item.sellZone ? item.sellZone._id : null,
      user: item.user ? item.user._id : null,
      costCenter: item.costCenter ? item.costCenter._id : null,
    }));
    if (!props.match.params.id) {
      const response = await Swal.fire({
        title: "Criar Cliente",
        text: "Deseja confirmar criação da Cliente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Cliente!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("customer", requestCustomer).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Cliente",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          props.history.replace(
            "/basicregistration/customer/edit/" + response.value.data.id
          );
          setGetCustomer(true);
        }
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Cliente",
        text: "Deseja confirmar edição do Cliente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Cliente!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("customer/" + props.match.params.id, requestCustomer)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Cliente",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetCustomer(true);
      }
    }
  };

  useEffect(() => {
    const loadCustomer = async () => {
      if (props.match.params.id) {
        setLoading(true);
        const customerCopy = { ...customer };
        const response = await api.get(`customer/${props.match.params.id}`);
        if (!response.data) {
          Swal.fire(
            "Editar Cliente",
            "Não foi possível buscar o Cliente",
            "error"
          );
        }
        Object.keys(response.data).forEach((key) => {
          customerCopy[key] = response.data[key];
        });

        if (customerCopy.state) {
          const index = states.estados
            .map((state) => state.nome.toUpperCase())
            .indexOf(customerCopy.state);
          if (index > -1) {
            setIndexState(index);
          }
        }
        setCustomer(customerCopy);
      }
      setLoading(false);
    };
    if (getCustomer) {
      setGetCustomer(false);
      loadCustomer();
    }
  }, [props.match, getCustomer, customer]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Cliente"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Clientes",
            to: "/basicregistration/customer/1/30/index/{}",
          },
          {
            label: hasPermission(profileContext, "customer", "edit")
              ? "Cliente"
              : props.match.params.id
              ? "Editar Cliente"
              : "Novo Cliente",
          },
        ]}
        icon={<Icons type="customer" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          Preencha os dados do Cliente
          <Input
            type="switch"
            item={customer}
            setItem={setCustomer}
            params="status"
            label="status"
            labelPlacement="start"
          />
        </BlockHeader>
        <BlockBody>
          <form onSubmit={(e) => saveCustomer(e)}>
            <Row>
              <Col>
                <Input
                  type="input"
                  item={customer}
                  setItem={setCustomer}
                  params="code"
                  label="Código"
                  required={true}
                  placeholder="Código"
                />
              </Col>
              <Col size={2}>
                <Input
                  type="input"
                  item={customer}
                  setItem={setCustomer}
                  params="name"
                  label="Nome"
                  placeholder="Nome do Cliente"
                  capitalize={true}
                  required={true}
                />
              </Col>
              <Col size={2}>
                <Input
                  type="input"
                  mask="phoneNumber"
                  item={customer}
                  setItem={setCustomer}
                  params="phoneNumber"
                  label="Telefone"
                  placeholder="Telefone"
                />
              </Col>
              <Col style={{ paddingTop: 34 }}>
                <Input
                  type="checkbox"
                  item={customer}
                  setItem={setCustomer}
                  params="megg"
                  label="MEgg"
                />
              </Col>
              <Col style={{ paddingTop: 34 }}>
                <Input
                  type="checkbox"
                  item={customer}
                  setItem={setCustomer}
                  params="swine"
                  label="Suínos"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="input"
                  inputType="email"
                  item={customer}
                  setItem={setCustomer}
                  params="email"
                  label="E-mail"
                  placeholder="E-mail"
                  required={true}
                />
              </Col>
              <Col>
                <Input
                  type="input"
                  mask="cpfCNPJ"
                  item={customer}
                  setItem={setCustomer}
                  params="cnpj"
                  label="CPF/CNPJ"
                  placeholder="CPF ou CNPJ do Cliente"
                />
              </Col>
              <Col>
                <Input
                  type="select"
                  onChange={(e) => selectStateHandler(e)}
                  value={indexState}
                  label="Estado"
                  placeholder="Selecione o Estado"
                  options={states.estados.map((estado, index) => ({
                    value: index,
                    label: estado.nome.toUpperCase(),
                  }))}
                />
              </Col>
              <Col>
                <Input
                  type="select"
                  params="city"
                  item={customer}
                  setItem={setCustomer}
                  label="Cidade"
                  placeholder="Selecione a Cidade"
                  options={
                    indexState > -1
                      ? states.estados[+indexState].cidades.map((cidade) => ({
                          value: cidade.toUpperCase(),
                        }))
                      : null
                  }
                />
              </Col>
            </Row>
            <Separator />
            {customer.responsibles.map((item, index) => (
              <Row key={index}>
                {reponsableModel.map((model) => (
                  <Col key={model.module} size={3}>
                    <Input
                      item={customer}
                      setItem={setCustomer}
                      params={`responsibles.${index}.${model.module}`}
                      type="autocomplete"
                      label={model.label}
                      paramsGet={["code", model.param]}
                      paramsLabel={["code", model.param]}
                      select={"code " + model.param}
                      model={model.model}
                      placeholder={model.placeholder}
                    />
                  </Col>
                ))}
                <Col size={1} style={{ paddingTop: "33px" }}>
                  <Icons
                    type="trash"
                    color="#F64E60"
                    size={24}
                    onClick={() => removeReponsable(index)}
                  />
                </Col>
              </Row>
            ))}

            <Row>
              <Col>
                <Button
                  type="button"
                  size="sm"
                  onClick={addReponsable}
                  color="white"
                  bg="default"
                  border="default"
                >
                  <Icons type="plus" color="#fff" size={15} /> Add Responsável
                </Button>
              </Col>
            </Row>

            <Separator />
            {hasPermission(profileContext, "customer", "edit") ? (
              <Row>
                <Col style={{ alignItems: "center" }}>
                  <Button
                    type="submit"
                    bg="default"
                    border="default"
                    color="white"
                    disabled={!hasPermission(profileContext, "user", "edit")}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            ) : null}
          </form>
        </BlockBody>
      </Block>
    </>
  );
}
