import { format } from "date-fns";
import {
  Block,
  BlockBody,
  BlockHeader,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "styles";
import { necropsyConfig } from "utils/globalData";
import ScoreChart from "react-apexcharts";
import { getColor, getScoreColor, getScoreText } from "utils/globalFunctions";

export function GpdScore({ dateRange, responseReport }) {
  const createGpdScore = () => {
    try {
      const responseScoresGpd = [0, 0, 0, 0, 0, 0];
      const countScoresGpd = [0, 0, 0, 0, 0, 0];
      const ages = [7, 14, 21, 28, 35, 42];
      responseReport.necropsies.forEach((necropsy, index) => {
        const gpdConfig = [
          {
            w1: necropsy.weight0,
            w2: necropsy.weight1,
            indexAge: 7,
          },
          {
            w1: necropsy.weight1,
            w2: necropsy.weight2,
            indexAge: 14,
          },
          {
            w1: necropsy.weight2,
            w2: necropsy.weight3,
            indexAge: 21,
          },
          {
            w1: necropsy.weight3,
            w2: necropsy.weight4,
            indexAge: 28,
          },
          {
            w1: necropsy.weight4,
            w2: necropsy.weight5,
            indexAge: 35,
          },
          {
            w1: necropsy.weight5,
            w2: necropsy.weight6,
            indexAge: 42,
          },
        ];
        const getScoreGPD = (w1, w2, indexAge, indexLoop) => {
          try {
            const startWeekIndex =
              indexLoop === 0 ? 1 : gpdConfig[indexLoop - 1].indexAge;

            let gpds = 0;
            for (let i = startWeekIndex; i <= indexAge; i++) {
              gpds +=
                necropsyConfig.others[necropsy.lineage][i].weight -
                necropsyConfig.others[necropsy.lineage][i - 1].weight;
            }
            gpds = gpds / 7;

            const response = parseFloat(
              (((w2 - w1) / 7 / gpds) * 10).toFixed(2)
            );

            return response > 10 ? 10 : response < 0 ? 0 : response;
          } catch (e) {
            return 0;
          }
        };
        gpdConfig.forEach(({ w1, w2, indexAge }, index) => {
          if (w2) {
            countScoresGpd[index]++;
            responseScoresGpd[index] += getScoreGPD(w1, w2, indexAge, index);
          }
        });
      });
      const takeAvarage = (score, count) => {
        if (count) {
          const response = parseFloat((score / count).toFixed(2));
          return response > 10 ? 10 : response < 0 ? 0 : response;
        }
        return 0;
      };

      return responseScoresGpd.map((score, index) => (
        <Col key={"age" + ages[index]}>
          <Card>
            <CardHeader>Peso {ages[index]} Dias</CardHeader>
            <CardBody>
              <ScoreChart
                options={{
                  plotOptions: {
                    radialBar: {
                      startAngle: -135,
                      endAngle: 225,
                      hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#f9f9fc ",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                      },
                      track: {
                        background: getColor(
                          getScoreColor(
                            takeAvarage(score, countScoresGpd[index])
                          ),
                          0.2
                        ),
                        strokeWidth: "67%",
                        margin: 0, // margin is in pixels
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: -5,
                          show: true,
                          color: getScoreColor(
                            takeAvarage(score, countScoresGpd[index])
                          ),
                          fontSize:
                            getScoreText(
                              takeAvarage(score, countScoresGpd[index])
                            ) === "Muita Atenção"
                              ? "4px"
                              : "9px",
                        },
                        value: {
                          formatter: function (val) {
                            return (val / 10).toLocaleString();
                          },
                          color: getScoreColor(
                            takeAvarage(score, countScoresGpd[index])
                          ),
                          fontSize: "12px",
                          show: true,
                          offsetY: -5,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: [
                      getScoreColor(takeAvarage(score, countScoresGpd[index])),
                    ],
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "horizontal",

                      gradientToColors: [
                        getScoreColor(
                          takeAvarage(score, countScoresGpd[index])
                        ),
                      ],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [
                    getScoreText(takeAvarage(score, countScoresGpd[index])),
                  ],
                }}
                series={[
                  takeAvarage(score, countScoresGpd[index]) * 10 > 0
                    ? takeAvarage(score, countScoresGpd[index]) * 10
                    : 0,
                ]}
                type="radialBar"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
      ));
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  return (
    <Block id="scoreGPD">
      <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
        Desenvolvimento Semana: {format(dateRange[0].startDate, "dd-MM-yyyy")}{" "}
        Até {format(dateRange[0].endDate, "dd-MM-yyyy")}
        {responseReport.customer ? ` | ${responseReport.customer}` : ""}
      </BlockHeader>
      <BlockBody>
        <Row>{createGpdScore()}</Row>
      </BlockBody>
    </Block>
  );
}
