import { useState, useEffect, useContext, useCallback } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { Customer } from "./Customer";
import { Locomotor } from "./Locomotor";
import { CallusAndChest } from "./CallusAndChest";
import { Dermatosis } from "./Dermatosis";
import { CompleteSlaughter } from "./CompleteSlaughter";
import { FarmData } from "./FarmData";
import { Modal } from "components/Modal";
import UpdateList from "contexts/updateList";
import {
  convertStringToDate,
  convertDateToString,
} from "utils/globalFunctions";
import { api } from "services/api";
import { Icons } from "components/Icons";
import { Button } from "components/Button";

export function CreateEdit(props) {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [checklist, setChecklist] = useState({
    _id: "",
    customer: props.customer || null,
    visitDate: "",
    integrated: "",
    lotNumber: "",
    bedNumber: "",
    lineage: "",
    age: "",
    incubatory: "",
    technicalManager: null,
    averageWeight: "",
    ageOfWeight: "",
    interval: "",
    density: "",
    mortality: "",
    gender: "",
    accommodatingDate: "",
    locomotor: {
      box: "",
      lineage: "",
      accommodationDensity: {
        winter: "",
        summer: "",
      },
      pinteiro: "",
      paperType: "",
      density: "",
      kgM2: "",
      averageWeight: "",
      bedType: "",
      callus: [],
      createdNumberBad: "",
      bedFermentationProcess: "",
      qualityBedWeek: "",
    },
    callusAndChest: {
      accommodationDensity: {
        winter: "",
        summer: "",
      },
      pinteiro: "",
      farmType: "",
      density: "",
      kgM2: "",
      averageWeight: "",
      space: "",
      badHeight: "",
      badType: "",
      callus: [],
      createdNumberBad: "",
      bedFermentationProcess: "",
      qualityBedWeek: "",
      humidity: "",
      cal: "",
    },
    dermatosis: {
      lineage: "",
      farmType: "",
      accommodationDensity: "",
      kgM2: "",
      averageWeight: "",
      space: "",
      FeedersNumber: "",
      nipplesNumber: "",
      warp: {
        male: "",
        female: "",
      },
      streakedBirds: "",
      scaredLot: "",
      kgPerBox: "",
      peopleNumber: "",
      numberHour: "",
      trainedStaff: "",
      runningMachine: "",
    },
    waitTrucks: {
      fans: "",
      nebulization: "",
      waitTime: "",
      convered: "",
      tempAndHumidityThermometer: "",
      monitoringInsideBoxes: "",
      wetTruck: "",
      percMortality: "",
      totalFastingPeriod: "",
    },
    stunning: {
      curveInTheLines: "",
      lowFrequency: "",
      highFrequency: "",
      resistanceInOhms: "",
      mAPerBird: "",
      presentsTonicPhaseOfBirds: "",
    },
    scalding: {
      scaldingTime: "",
      waterTemperature: "",
    },
  });
  const [getChecklist, setGetChecklist] = useState(true);

  const [loadHistoric, setLoadHistoric] = useState(!!props.customer);

  const { setUpdateList } = useContext(UpdateList);

  const saveChecklist = useCallback(
    async (e) => {
      const requestChecklist = { ...checklist };
      if (requestChecklist.visitDate) {
        requestChecklist.visitDate = convertStringToDate(
          requestChecklist.visitDate
        );
      }
      if (requestChecklist.accommodatingDate) {
        requestChecklist.accommodatingDate = convertStringToDate(
          requestChecklist.accommodatingDate
        );
      }
      if (!requestChecklist._id) {
        const response = await Swal.fire({
          title: "Salvar Checklist Abate",
          text: "Deseja confirmar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Salvar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .post("broilerchecklistslaughter", requestChecklist)
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });
        if (response.value) {
          Swal.fire(
            "Salvar Abate",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          if (!response.value.err) {
            requestChecklist._id = response.value.data.id;
            setChecklist(requestChecklist);
          }
          setUpdateList(true);
        }
      } else {
        const response = await Swal.fire({
          title: "Editar Abate",
          text: "Deseja confirmar edição?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Editar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .put(
                "broilerchecklistslaughter/" + requestChecklist._id,
                requestChecklist
              )
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });

        if (response.value) {
          Swal.fire(
            "Editar Abate",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          setUpdateList(true);
        }
      }
    },
    [checklist, setUpdateList]
  );

  useEffect(() => {
    const loadChecklist = async () => {
      if (props.id) {
        setLoading(true);
        const responseChecklist = await api.get(
          `broilerchecklistslaughter/${props.id}`
        );
        if (responseChecklist.data) {
          if (responseChecklist.data.visitDate) {
            responseChecklist.data.visitDate = convertDateToString(
              responseChecklist.data.visitDate
            );
          }
          if (responseChecklist.data.accommodatingDate) {
            responseChecklist.data.accommodatingDate = convertDateToString(
              responseChecklist.data.accommodatingDate
            );
          }
          setChecklist(JSON.parse(JSON.stringify(responseChecklist.data)));
        }
      }
      setLoading(false);
      setLoadHistoric(true);
    };
    if (getChecklist) {
      setGetChecklist(false);
      loadChecklist();
    }
  }, [getChecklist, checklist, props.id]);

  return loading ? (
    <ReactLoading type="spin" color="#009870" height={30} width={30} />
  ) : (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Checklist Abate</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveChecklist}
        >
          Salvar
        </Button>
      }
    >
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Cliente" />
        <Tab label="Dados da Granja" />
        <Tab label="Locomotor" />
        <Tab label="Calo de Pata e Peito" />
        <Tab label="Dermatose" />
        <Tab label="Abate Completo" />
      </Tabs>
      <div
        className="kt-separator kt-separator--solid"
        style={{ marginTop: 0 }}
      />
      {tab === 0 && (
        <Customer
          checklist={checklist}
          setChecklist={setChecklist}
          loadHistoric={loadHistoric}
          disableCustomer={props.disableCustomer}
          setLoadHistoric={setLoadHistoric}
        />
      )}
      {tab === 1 && (
        <FarmData checklist={checklist} setChecklist={setChecklist} />
      )}
      {tab === 2 && (
        <Locomotor checklist={checklist} setChecklist={setChecklist} />
      )}
      {tab === 3 && (
        <CallusAndChest checklist={checklist} setChecklist={setChecklist} />
      )}
      {tab === 4 && (
        <Dermatosis checklist={checklist} setChecklist={setChecklist} />
      )}
      {tab === 5 && (
        <CompleteSlaughter checklist={checklist} setChecklist={setChecklist} />
      )}
    </Modal>
  );
}
