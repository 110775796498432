import { useContext, useState } from "react";
import { createRef } from "react";
import { Icons } from "components/Icons";
import { useOutsideClick } from "services/outsideClick";
import { CreateEdit as CreateEditNecropsy } from "../../../../Views/Customer/Connect/Broiler/Necropsy/CreateEdit";
import { CreateEdit as CreateEditSlaughter } from "../../../../Views/Customer/Connect/Slaughter/Slaughter/CreateEdit";
import ProfileContext from "contexts/profile";

import {
  Container,
  HeaderTitle,
  Header,
  TabTitle,
  TabTitleItem,
  ItemsContainer,
  ItemContentLink,
  ItemContentButton,
  ItemIcon,
  ItemContentText,
  ItemTitle,
  ItemText,
} from "./style";
import { CreateEdit as CreateEditChecklistSlaughter } from "../../../../Views/Customer/Connect/Slaughter/Checklist/CreateEdit";
import { CreateEdit as CreateEditPreNecropsy } from "../../../../Views/Customer/Connect/HeavyBroiler/PreNecropsy/CreateEdit";
import { CreateEdit as CreateEditHbNecropsy } from "../../../../Views/Customer/Connect/HeavyBroiler/Necropsy/CreateEdit";
import { Logo } from "../../style";

export default function DropdownMenuRight({
  showDropdown,
  setShowDropdown,
  tabDropdown,
  setTabDropdown,
  location,
}) {
  const ref = createRef();

  const { roles } = useContext(ProfileContext);

  const [showNecropsy, setShowNecropsy] = useState(false);

  const [showSlaughter, setShowSlaughter] = useState(false);

  const [showPreNecropsy, setShowPreNecropsy] = useState(false);
  const [showHbNecropsy, setShowHbNecropsy] = useState(false);

  const [showChecklistSlaughter, setShowChecklistSlaughter] = useState(false);

  useOutsideClick(ref, () => {
    if (showDropdown === "true" || showDropdown) {
      setShowDropdown("");
    }
  });

  const tabs = [
    { label: "Clientes", show: roles?.indexOf("cliente") < 0 },
    { label: "Frango de Corte", show: true },
    { label: "Matriz Pesada", show: true },
    /* { label: "Matriz Pesada" }, */
  ];

  return (
    <>
      {showPreNecropsy && (
        <CreateEditPreNecropsy
          show={showPreNecropsy}
          setShow={setShowPreNecropsy}
          location={location}
        />
      )}

      {showHbNecropsy && (
        <CreateEditHbNecropsy
          show={showHbNecropsy}
          setShow={setShowHbNecropsy}
          location={location}
        />
      )}

      {showNecropsy && (
        <CreateEditNecropsy
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={location}
        />
      )}

      {showSlaughter && (
        <CreateEditSlaughter
          show={showSlaughter}
          setShow={setShowSlaughter}
          location={location}
        />
      )}

      {showChecklistSlaughter && (
        <CreateEditChecklistSlaughter
          show={showChecklistSlaughter}
          setShow={setShowChecklistSlaughter}
          location={location}
        />
      )}

      <Container show={showDropdown} ref={ref}>
        <Header>
          <HeaderTitle>
            <Logo
              style={{ width: 100 }}
              alt="Logo"
              src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_connect_nova_branca.png"
            />
          </HeaderTitle>
          <TabTitle>
            {tabs.map((item, index) =>
              item.show ? (
                <TabTitleItem
                  key={index}
                  isactive={(tabDropdown === index).toString()}
                  onClick={() => setTabDropdown(index)}
                >
                  <span>{item.label}</span>
                </TabTitleItem>
              ) : null
            )}
          </TabTitle>
        </Header>
        {tabDropdown === 0 && (
          <ItemsContainer>
            <ItemContentLink to="/customers/1/30/index/{}">
              <ItemIcon>
                <Icons type="users" size={24} color="#007bff"></Icons>
              </ItemIcon>
              <ItemContentText>
                <ItemTitle>Clientes</ItemTitle>
                <ItemText>Lista de Clientes</ItemText>
              </ItemContentText>
            </ItemContentLink>
          </ItemsContainer>
        )}
        {tabDropdown === 1 && (
          <ItemsContainer>
            <ItemContentButton
              onClick={() => {
                setShowNecropsy(true);
                document.getElementById("root").click();
              }}
            >
              <ItemIcon>
                <Icons type="necropsy" color="#007bff" size={24} />
              </ItemIcon>
              <ItemContentText>
                <ItemTitle>Necropsia</ItemTitle>
                <ItemText>Nova Ficha</ItemText>
              </ItemContentText>
            </ItemContentButton>
            <ItemContentButton
              onClick={() => {
                setShowSlaughter(true);
                document.getElementById("root").click();
              }}
            >
              <ItemIcon>
                <Icons type="slaughter" color="#6f42c1" size={24} />
              </ItemIcon>
              <ItemContentText>
                <ItemTitle>Abate</ItemTitle>
                <ItemText>Nova Ficha</ItemText>
              </ItemContentText>
            </ItemContentButton>
            <ItemContentButton
              onClick={() => {
                setShowChecklistSlaughter(true);
                document.getElementById("root").click();
              }}
            >
              <ItemIcon>
                <Icons type="checklist" color="#1BC5BD" size={24} />
              </ItemIcon>
              <ItemContentText>
                <ItemTitle>Checklist Abate</ItemTitle>
                <ItemText>Nova Ficha</ItemText>
              </ItemContentText>
            </ItemContentButton>
          </ItemsContainer>
        )}
        {tabDropdown === 2 && (
          <ItemsContainer>
            <ItemContentButton
              onClick={() => {
                setShowPreNecropsy(true);
                document.getElementById("root").click();
              }}
            >
              <ItemIcon>
                <Icons type="preNecropsy" color="#F64E60" size={24} />
              </ItemIcon>
              <ItemContentText>
                <ItemTitle>Pré-Necropsia</ItemTitle>
                <ItemText>Nova Ficha</ItemText>
              </ItemContentText>
            </ItemContentButton>
          </ItemsContainer>
        )}
      </Container>
    </>
  );
}
