export const hasPermission = (userProfile, module, to) =>
  (userProfile.roles && userProfile.roles.indexOf("admin") > -1) ||
  (userProfile.modules && userProfile.modules[to].indexOf(module) > -1);

export const imgPreview = (img) => {
  try {
    return URL.createObjectURL(img);
  } catch (e) {
    return null;
  }
};

export const cpfMask = (value) => {
  return value.length > 14
    ? value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
    : value
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
};

export const phoneNumberMask = (value) => {
  return value.length > 13
    ? value
        .replace(/\D/g, "")
        .replace(/(\d{0})(\d)/, "$1($2")
        .replace(/(\d{2})(\d)/, "$1)$2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1")
    : value
        .replace(/\D/g, "")
        .replace(/(\d{0})(\d)/, "$1($2")
        .replace(/(\d{2})(\d)/, "$1)$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");
};

export const percentage = (value) => {
  try {
    return value
      .replace(/[^0-9.,]*/g, "")
      .replace(/\.{2,}/g, ".")
      .replace(/\.,/g, ",")
      .replace(/\.[0-9]+\./g, ".")
      .replace(/(\d{1})(\d)/, "$1%");
  } catch (e) {
    return null;
  }
};

export const convertStringToDate = (string) => {
  try {
    const array = string.split("/");
    if (!array[2]) return null;
    return new Date(
      parseInt(array[2]),
      parseInt(array[1]) - 1,
      parseInt(array[0])
    );
  } catch (e) {
    return null;
  }
};
export const convertDateToString = (date) => {
  try {
    const dateArray = date.split("-");
    return `${dateArray[2].substr(0, 2)}/${dateArray[1]}/${dateArray[0]}`;
  } catch (e) {
    return null;
  }
};

export const getValue = (item, stringParams) => {
  try {
    if (!stringParams) return item;
    const params = stringParams.split(".");
    if (params.length === 6) {
      return item[params[0]][params[1]][params[2]][params[3]][params[4]][
        params[5]
      ];
    }
    if (params.length === 5) {
      return item[params[0]][params[1]][params[2]][params[3]][params[4]];
    }
    if (params.length === 4) {
      return item[params[0]][params[1]][params[2]][params[3]];
    }
    if (params.length === 3) {
      return item[params[0]][params[1]][params[2]];
    }
    if (params.length === 2) {
      return item[params[0]][params[1]];
    }
    if (params.length === 1) {
      return item[params[0]];
    }
  } catch (e) {
    return null;
  }
};

export const getInitialObject = (model, data) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] && typeof data[key] === "object") {
        Object.keys(data[key]).forEach((key2) => {
          if (data[key][key2] && typeof data[key][key2] === "object") {
            Object.keys(data[key][key2]).forEach((key3) => {
              if (
                data[key][key2][key3] &&
                typeof data[key][key2][key3] === "object"
              ) {
                Object.keys(data[key][key2][key3]).forEach((key4) => {
                  if (
                    data[key][key2][key3][key4] &&
                    typeof data[key][key2][key3][key4] === "object"
                  ) {
                    Object.keys(data[key][key2][key3][key4]).forEach((key5) => {
                      model[key][key2][key3][key4][key5] =
                        data[key][key2][key3][key4][key5];
                    });
                  } else {
                    model[key][key2][key3][key4] = data[key][key2][key3][key4];
                  }
                });
              } else {
                model[key][key2][key3] = data[key][key2][key3];
              }
            });
          } else {
            model[key][key2] = data[key][key2];
          }
        });
      } else {
        model[key] = data[key];
      }
    });
  }
  return model;
};

export const getScoreColor = (score) => {
  if (score >= 8) {
    return "#009877";
  } else if (score >= 6) {
    return "#007bff";
  } else if (score >= 4) {
    return "#d4a005";
  } else {
    return "#dc3545";
  }
};

export const getScoreFecesColor = (score) => {
  if (score >= 8) {
    return "#009877";
  } else if (score >= 6) {
    return "#007bff";
  } else if (score >= 3) {
    return "#ffc107";
  } else {
    return "#dc3545";
  }
};

export const getScoreText = (score) => {
  if (score >= 8) {
    return "Bom";
  } else if (score >= 6) {
    return "Regular";
  } else if (score >= 4) {
    return "Atenção";
  } else {
    return "Muita Atenção";
  }
};

export const getScoreFecesText = (score) => {
  if (score >= 8) {
    return "Bom";
  } else if (score >= 6) {
    return "Regular";
  } else if (score >= 3) {
    return "Atenção";
  } else {
    return "Muita Atenção";
  }
};

export const getScoreSlaughterColor = (score) => {
  if (score < 6) {
    return "#dc3545";
  } else if (score < 8) {
    return "#ffc107";
  } else if (score < 9) {
    return "#007bff";
  } else {
    return "#009877";
  }
};

export const getScoreSlaughterText = (score) => {
  if (score < 6) {
    return "Muita Atenção";
  } else if (score < 8) {
    return "Atenção";
  } else if (score < 9) {
    return "Regular";
  } else {
    return "Bom";
  }
};

export const getColor = (color, opacity = 1) => {
  if (!color) {
    var letters = "0123456789ABCDEF";
    color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
    let c = color.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  const o = Math.round,
    r = Math.random,
    s = 255;
  const colors = {
    dark: `rgba(52, 58, 64, ${opacity})`,
    success: `rgba(29, 201, 184, ${opacity})`,
    danger: `rgba(253, 57, 122, ${opacity})`,
    info: `rgba(85, 120, 235, ${opacity})`,
    warning: `rgba(255, 185, 34, ${opacity})`,
    primary: `rgba(0, 152, 111, ${opacity})`,
    random: `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${opacity})`,
  };

  return colors[color];
};

export const getFirstLetter = (name, index) => {
  if (!name) return "";
  const array = name.split(" ");
  let response = array[0].substr(0, 1).toUpperCase();
  if (array.length > 1) {
    response += array[index || array.length - 1].substr(0, 1).toUpperCase();
  }
  return response;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getTotalScore = (birds) =>
  birds?.map(({ total }) => total).reduce((a, b) => a + b) / birds?.length;

export const compareValues = (key, order) => {
  return function (a, b) {
    const keys = key.split(".");
    let varA = "-";
    let varB = "-";
    if (keys[2]) {
      if (a[keys[0]] && a[keys[0]][keys[1]]) {
        varA =
          typeof a[keys[0]][keys[1]][keys[2]] === "string"
            ? a[keys[0]][keys[1]][keys[2]].toUpperCase()
            : a[keys[0]][keys[1]][keys[2]];
      }
      if (b[keys[0]] && b[keys[0]][keys[1]]) {
        varB =
          typeof b[keys[0]][keys[1]][keys[2]] === "string"
            ? b[keys[0]][keys[1]][keys[2]].toUpperCase()
            : b[keys[0]][keys[1]][keys[2]];
      }
    } else if (keys[1]) {
      if (a[keys[0]]) {
        varA =
          typeof a[keys[0]][keys[1]] === "string"
            ? a[keys[0]][keys[1]].toUpperCase()
            : a[keys[0]][keys[1]];
      }
      if (b[keys[0]]) {
        varB =
          typeof b[keys[0]][keys[1]] === "string"
            ? b[keys[0]][keys[1]].toUpperCase()
            : b[keys[0]][keys[1]];
      }
    } else {
      if (a[keys[0]]) {
        varA =
          typeof a[keys[0]] === "string"
            ? a[keys[0]].toUpperCase()
            : a[keys[0]];
      }
      if (b[keys[0]]) {
        varB =
          typeof b[keys[0]] === "string"
            ? b[keys[0]].toUpperCase()
            : b[keys[0]];
      }
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};


export const handlePercentage = (e, setValue) => {
  const { value } = e.target;
  let formattedValue = value;
  if (value[0] === ".") {
    formattedValue = "0" + value;
  }
  const hasDecimalSeparator =
    formattedValue.includes(".") || formattedValue.includes(",");
  if (!hasDecimalSeparator) {
    const lowerThousand = /^\d{0,3}$/.test(formattedValue);
    if (!lowerThousand) {
      if (formattedValue.length > 4) {
        return;
      }
      formattedValue =
        formattedValue.slice(0, -1) + "." + formattedValue.slice(-1);
      setValue(formattedValue);
      return formattedValue;
    } else {
      setValue(formattedValue);
      return formattedValue;
    }
  } else {
    const [integerPart, decimalPart] = formattedValue.split(/[.,]/);
    const isValid =
      /^\d{0,3}$/.test(integerPart) && /^\d{0,2}$/.test(decimalPart);

    if (!isValid) return;
    setValue(formattedValue);
    return formattedValue;
  }
};
