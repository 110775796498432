import styled from "styled-components";

export const Container = styled.div`
  padding-top: 20px;
`;
export const ImgLink = styled.a`
  margin-bottom: 10px;
`;
export const TitleGPD = styled.h1`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
`;
