import styled from "styled-components";
import { Input } from "components/Input";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: url("https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_connect.jpg")
    no-repeat 0;
  background-size: cover;
  background-position: right;
  position: relative;
`;

export const Background = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  background-position: top;
`;

export const Form = styled.form`
  padding: 0 50px;
  text-align: center;
  @media (min-width: 768px) {
    width: 440px;
  }
`;

export const ContentImg = styled.div`
  margin-bottom: 3rem !important;
  justify-content: center !important;
  display: flex !important;
`;

export const Connect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const H1 = styled.h1`
  font-size: 1.2rem;
  padding-bottom: 5rem;
  color: #414b97;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const H1Mobile = styled.h1`
  font-size: 1.2rem;
  padding-bottom: 2rem;
  color: #414b97;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const LogoMcassab = styled.img`
  width: 250px;
`;

export const LogoConnectContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const LogoConnect = styled.img`
  width: 100%;
  margin-bottom: 50px;
`;

export const WelcomeContainer = styled.div`
  padding: 2rem;
  max-width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const WelcomeContent = styled.div`
  padding: 2rem;
  border: var(--default) solid 2px;
  border-radius: 2rem;
  color: var(--default);
`;

export const WelcomeTitle = styled.h1`
  font-size: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: var(--white) solid 2px;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

export const WelcomeSubTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`;

export const WelcomeText = styled.span`
  font-size: 1.1rem;
`;

export const InputText = styled(Input)`
  margin-bottom: 15px;
  height: calc(1.5em + 2.3rem + 2px);
  padding: 1.15rem 1.65rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 1rem;
  display: block;
  width: 100%;
  font-weight: 400;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${({ isInvalid }) =>
    isInvalid
      ? `
    border: 1px solid #fd397a !important;  
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd397a'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd397a' stroke='none'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;    
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem) !important;  
    padding-right:  calc(1.5em + 1.3rem);
    background-position: right calc(0.375em + 0.325rem) center !important;
`
      : null}
`;

export const PasswordContent = styled.div`
  position: relative;
`;

export const Button = styled.button`
  width: 100%;
  margin-bottom: 23px;
  background-color: var(--default) !important;
  border-color: var(--default) !important;
  color: var(--green);
  align-items: center;
  background: transparent;
  outline: none !important;
  display: block;
  padding: 1.15rem 1.65rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
