import { Body, Header, TableContent } from "components/Table/style";
import {
  Block,
  BlockBody,
  BlockHeader,
  Card,
  CardBody,
  CardHeader,
} from "styles";

export function CurrentHistoric({ responseReport, farmData }) {
  return (
    <Block id="historic">
      <BlockHeader>
        Programa em Andamento
        {responseReport.customer ? ` | ${responseReport.customer}` : ""}
      </BlockHeader>
      <BlockBody>
        <Card>
          <CardHeader>
            <strong>Saúde</strong>
          </CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th className="text-right" style={{ width: "130px" }}>
                    Fase
                  </th>
                  <th className="text-primary">Anticoccidianos</th>
                  <th className="text-primary">Dose PPM</th>
                  <th className="text-info">Melhorador de desempenho</th>
                  <th className="text-info">Dose PPM</th>
                </tr>
              </Header>
              <Body>
                <tr>
                  <td className="text-right">Pré</td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.moleculePre}
                  </td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.dosePre}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.pre.product}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.pre.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Inicial</td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.moleculeInitial}
                  </td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.doseInitial}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.initial.product}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.initial.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Crescimento 1</td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.moleculeGrowth}
                  </td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.doseGrowth}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.growth.product}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.growth.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Crescimento 2</td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.moleculeGrowth2}
                  </td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.doseGrowth2}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.growth2.product}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.growth2.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Final</td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.moleculeFinal}
                  </td>
                  <td className="text-primary">
                    {farmData.health.anticoccidian.doseFinal}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.final.product}
                  </td>
                  <td className="text-info">
                    {farmData.health.performanceEnhancer.final.dose}
                  </td>
                </tr>
              </Body>
            </TableContent>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong>Aditivos</strong>
          </CardHeader>
          <CardBody>
            <TableContent>
              <Header>
                <tr>
                  <th className="text-right" style={{ width: "130px" }}>
                    Fase
                  </th>
                  <th className="text-primary">Ácidos orgânicos</th>
                  <th className="text-primary">Dose</th>
                  <th className="text-dark">óleos essenciais</th>
                  <th className="text-dark">Dose</th>
                  <th className="text-danger">Probióticos</th>
                  <th className="text-danger">Dose</th>
                  <th className="text-info">Prebioticos</th>
                  <th className="text-info">Dose</th>
                  <th className="text-warning">Adsorvente de micotoxinas</th>
                  <th className="text-warning">Dose</th>
                </tr>
              </Header>
              <Body>
                <tr>
                  <td className="text-right">Pré</td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.pre.product}
                  </td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.pre.dose}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.pre.product}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.pre.dose}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.pre.product}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.pre.dose}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.pre.product}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.pre.dose}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.pre.product}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.pre.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Inicial</td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.initial.product}
                  </td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.initial.dose}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.initial.product}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.initial.dose}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.initial.product}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.initial.dose}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.initial.product}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.initial.dose}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.initial.product}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.initial.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Crescimento 1</td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.growth.product}
                  </td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.growth.dose}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.growth.product}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.growth.dose}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.growth.product}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.growth.dose}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.growth.product}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.growth.dose}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.growth.product}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.growth.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Crescimento 2</td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.growth2.product}
                  </td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.growth2.dose}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.growth2.product}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.growth2.dose}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.growth2.product}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.growth2.dose}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.growth2.product}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.growth2.dose}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.growth2.product}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.growth2.dose}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">Final</td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.final.product}
                  </td>
                  <td className="text-primary">
                    {farmData.additions.organicAcids.final.dose}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.final.product}
                  </td>
                  <td className="text-dark">
                    {farmData.additions.essencialOils.final.dose}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.final.product}
                  </td>
                  <td className="text-danger">
                    {farmData.additions.probiotics.final.dose}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.final.product}
                  </td>
                  <td className="text-info">
                    {farmData.additions.prebiotics.final.dose}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.final.product}
                  </td>
                  <td className="text-warning">
                    {farmData.additions.mycotoxinAdsorbents.final.dose}
                  </td>
                </tr>
              </Body>
            </TableContent>
          </CardBody>
        </Card>
      </BlockBody>
    </Block>
  );
}
