import { Input } from "components/Input";
import { Col, Row } from "styles";
import { H2 } from "../style";

export function Installations({ evaluation, setEvaluation }) {
  return (
    <>
      <H2>Armazenamento</H2>
      <Row>
        <Col>
          <Row>
            <Col size={2}>A lotação está correta?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.stocking"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.stocking"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>Os cochos estão bem espaçados?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.troughsAreWellSpaced"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.troughsAreWellSpaced"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>A área de cocho está correta?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.troughArea"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.troughArea"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>O acesso ao cocho está adequado?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.accessToTrough"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.accessToTrough"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>As baias estão limpas?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.stallsClean"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.stallsClean"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>A água está disponível?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.toiletVoid"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.toiletVoid"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>A água é clorada corretamente?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.availableWater"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.availableWater"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>Os cochos estão bem espaçados?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.waterIsChlorinated"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.waterIsChlorinated"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col size={2}>A temperatura da água está adequada?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.waterTemperature"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.waterTemperature"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col size={2}>A temperatura da sala está adequada?</Col>
            <Col flexRow={true}>
              <Input
                type="radiobox"
                label="SIM"
                value={true}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.roomTemperature"
              />
              <Input
                type="radiobox"
                label="NÃO"
                value={false}
                item={evaluation}
                setItem={setEvaluation}
                params="installations.roomTemperature"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="textarea"
            label="*Justificar os itens assinalados como NÃO:"
            item={evaluation}
            rows="5"
            setItem={setEvaluation}
            params="installations.JustifyTheItemsMarkedAsNo"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="textarea"
            label="*Descrever a estrutura dos cochos/instalações:"
            item={evaluation}
            rows="5"
            setItem={setEvaluation}
            params="installations.describeTheStructureOfTheTroughs"
          />
        </Col>
      </Row>
    </>
  );
}
/* 
    installations: {
      stocking: true,
      troughsAreWellSpaced: true,
      troughArea: true,
      accessToTrough: true,
      stallsClean: true,
      toiletVoid: true,
      availableWater: true,
      waterIsChlorinated: true,
      waterTemperature: true,
      roomTemperature: true,
      JustifyTheItemsMarkedAsNo: "",
      describeTheStructureOfTheTroughs: "",
    },
*/
