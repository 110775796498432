import React, { useState, useCallback, useEffect } from "react";
import { getScoreColor, getScoreText, getColor } from "utils/globalFunctions";
import Chart from "react-apexcharts";
import { CreateEdit } from "../CreateEdit";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import isMobile from "is-mobile";
import { api } from "services/api";
import { Image } from "components/Image";

export function List(props) {
  const tableParams = [
    {
      key: "code",
      label: "Código",
      width: 250,
      filter: "text",
      placeholder: "Digite o Código",
      mobile: {
        type: "title",
      },
    },
    {
      key: "customer",
      params: "name",
      label: "Cliente",
      filter: !props.match.params.id && "text",
      placeholder: "Nome ou Código",
      mobile: {
        type: "title",
      },
    },
    {
      key: "visitDate",
      label: "Data da visita",
      type: "date",
      filter: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "integrated",
      label: "Integrado",
      filter: "text",
      mobile: {
        type: "item",
      },
    },
    { key: "age", label: "Idade", filter: "text" },
    {
      key: "weight6",
      label: "Peso médio",
      filter: "text",
      type: "custom",
      response: ({ weight6 }) => (weight6 ? weight6 + " Kg" : ""),
    },
    {
      key: "lineage",
      label: "Linhagem",
      filter: "select",
      options: [
        { value: "Cobb" },
        { value: "Ross" },
        { value: "Hubbard" },
        { value: "Misto" },
      ],
      mobile: {
        type: "small",
      },
    },
    {
      key: "score",
      label: "Score",
      type: "custom",
      mobile: {
        type: "status",
      },
      response: (item) => {
        return (
          <div style={{ width: "120px" }}>
            <Chart
              options={{
                plotOptions: {
                  radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                      margin: 0,
                      size: "70%",
                      background: "#f9f9fc ",
                      image: undefined,
                      imageOffsetX: 0,
                      imageOffsetY: 0,
                      position: "front",
                    },
                    track: {
                      background: getColor(
                        getScoreColor(getTotalScore(item.birds)),
                        0.2
                      ),
                      strokeWidth: "67%",
                      margin: 0, // margin is in pixels
                    },

                    dataLabels: {
                      showOn: "always",
                      name: {
                        offsetY: -5,
                        show: true,
                        color: getScoreColor(getTotalScore(item.birds)),
                        fontSize: "7px",
                      },
                      value: {
                        formatter: function (val) {
                          return (val / 10 < 0 ? 0 : val / 10).toLocaleString();
                        },
                        color: getScoreColor(getTotalScore(item.birds)),
                        fontSize: "10px",
                        show: true,
                        offsetY: -5,
                      },
                    },
                  },
                },
                fill: {
                  colors: [getScoreColor(getTotalScore(item.birds))],
                  type: "gradient",
                  gradient: {
                    shade: "dark",
                    type: "horizontal",

                    gradientToColors: [
                      getScoreColor(getTotalScore(item.birds)),
                    ],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100],
                  },
                },
                stroke: {
                  lineCap: "round",
                },
                labels: [getScoreText(getTotalScore(item.birds))],
              }}
              series={[getTotalScore(item.birds) * 10]}
              type="radialBar"
              width={isMobile() ? "40%" : "100%"}
            />
          </div>
        );
      },
    },
  ];

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const [customerName, setCustomerName] = useState("");

  const [customer, setCustomer] = useState({});

  const getTotalScore = useCallback((birds) => {
    return (
      birds.map(({ total }) => total).reduce((a, b) => a + b) / birds.length
    );
  }, []);

  const editHandler = useCallback((row) => {
    setId(row._id);
    setModalShow(true);
  }, []);

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
      setCustomer(response.data);
    };
    if (props.match.params.id) {
      getCustomerName();
    }
  }, [props.match.params.id]);

  return (
    <>
      {modalShow && (
        <CreateEdit
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          customer={customer}
          id={id}
          match={props.match}
        />
      )}
      <SubHeader
        {...props}
        title={customerName || "Necropsias"}
        route="meggcreaterecreatenecropsy"
        breadcrumbs={[
          {
            label: customerName,
            to: "/customer/" + props.match.params.id,
          },
          {
            label: "Megg",
          },
          {
            label: "Necropsias",
          },
        ]}
        icon={<Icons type="necropsy" size="24" color="#fff" />}
        otherButtons={[
          <Image
            src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_megg_small.png"
            style={{ height: 45 }}
          />,
        ]}
      />
      <Table
        cols={tableParams}
        editHandler={editHandler}
        emptyText={"Nenhuma necropsia localizada!"}
        route={`/meggcreaterecreatenecropsy`}
        {...props}
      />
    </>
  );
}
