import {
  Container,
  LinkLogo,
  Logo,
  ToolBar,
  ToolBarToggle,
  TopBarToggle,
} from "./style";

export function HeaderMobile({ setShowSideMenu, setShowTopBar }) {
  return (
    <Container>
      <LinkLogo to="/home">
        <Logo
          alt="Logo"
          src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-MCassab-hz.svg"
        />
      </LinkLogo>
      <ToolBar>
        <ToolBarToggle
          onClick={() =>
            setShowSideMenu((prevState) => (prevState === "true" ? "" : "true"))
          }
        >
          <span />
        </ToolBarToggle>
        <TopBarToggle
          onClick={() =>
            setShowTopBar((prevState) => (prevState === "true" ? "" : "true"))
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <circle fill="#58595a" cx="5" cy="12" r="2" />
              <circle fill="#58595a" cx="12" cy="12" r="2" />
              <circle fill="#58595a" cx="19" cy="12" r="2" />
            </g>
          </svg>
        </TopBarToggle>
      </ToolBar>
    </Container>
  );
}
