import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { api } from "services/api";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import { ScoreBirds } from "./ScoreBirds";
import { ScoreTotal } from "./ScoreTotal";
import { Customer } from "./Customer";
import { FarmData } from "./FarmData";
import UpdateList from "contexts/updateList";
import {
  convertDateToString,
  convertStringToDate,
} from "utils/globalFunctions";
import { Container } from "./style";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";

export function CreateEdit(props) {
  const initialBird = useMemo(
    () => ({
      integumentaryMegg: {
        legPigmentationMegg: "",
        callusMegg: "",
        cornealInjuryMegg: "",
        oralInjuryMegg: "",
        muscleBleedingMegg: "",
        yolkRetentionMegg: "",
        hydropericardiumMegg: "",
      },
      immunologicalMegg: {
        thymusAtrophyMegg: "",
        bursometryMegg: "",
        fabriciusBagInjuryMegg: "",
        intestinalSuffusionsMegg: "",
        hemorrhagicTonsilsMegg: "",
        activePeyerPlateMegg: "",
      },
      respiratoryMegg: {
        tracheitisMegg: "",
        swollenHeadMegg: "",
        aerosaculiteMegg: "",
      },
      locomotorMegg: {
        tibialDyschondroplasiaMegg: "",
        femurHeadNecrosisMegg: "",
      },
      digestiveMegg: {
        bedIntakeMegg: "",
        gizzardErosionMegg: "",
        pancreasAtrophyMegg: "",
        duodenitisMegg: "",
        liverMegg: "",
        intestinalToneMegg: "",
        WallThicknessMegg: "",
        eimeriaAcervulinaMegg: "",
        maximumEimeriaMegg: "",
        eimeriaTenellaMegg: "",
        fluidContentMegg: "",
        mucusPresenceMegg: "",
        bilePresenceMegg: "",
        flatwormsMegg: "",
        turkishTowelMegg: "",
        necroticEnteritisMegg: "",
        foodPassMegg: "",
        cellPellingMegg: "",
        intestinalGasMegg: "",
        hyperemiaMegg: "",
        cecalGasMegg: "",
      },
      total: 10,
    }),
    []
  );

  const initialConfig = useMemo(
    () => ({
      integumentaryMegg: {
        legPigmentationMegg: {
          label: "Pigmentação das Patas",
          style: "",
          options: [],
        },
        callusMegg: {
          label: "Calo de Patas",
          style: "",
          options: [],
        },
        dermatosisMegg: {
          label: "Dermatose",
          style: "",
          options: [],
        },
        cornealInjuryMegg: {
          label: "Lesão de córnea por amônia",
          style: { fontSize: "84%" },
          options: [],
        },
        oralInjuryMegg: {
          label: "Lesão oral",
          style: "",
          options: [],
        },
        muscleBleedingMegg: {
          label: "Hemorragia muscular",
          style: "",
          options: [],
        },
        yolkRetentionMegg: {
          label: "Retenção de gema",
          style: "",
          options: [],
        },
        hydropericardiumMegg: {
          label: "Hidropericárdio",
          style: "",
          options: [],
        },
      },
      immunologicalMegg: {
        thymusAtrophyMegg: {
          label: "Atrofia de timo",
          style: "",
          options: [],
        },
        bursometryMegg: {
          label: "Bursometria",
          style: "",
          options: [],
        },
        fabriciusBagInjuryMegg: {
          label: "Lesão de Bolsa de Fabrícius",
          style: { fontSize: "84%" },
          options: [],
        },
        intestinalSuffusionsMegg: {
          label: "Sufusões Intestinais",
          style: "",
          options: [],
        },
        hemorrhagicTonsilsMegg: {
          label: "Tonsilas Hemorragicas",
          style: "",
          options: [],
        },
        activePeyerPlateMegg: {
          label: "Placa de Peyer Ativa",
          style: "",
          options: [],
        },
      },
      respiratoryMegg: {
        tracheitisMegg: {
          label: "Traqueíte",
          style: "",
          options: [],
        },
        swollenHeadMegg: {
          label: "Cabeça Inchada",
          style: "",
          options: [],
        },
        aerosaculiteMegg: {
          label: "Aerossaculite",
          style: "",
          options: [],
        },
      },
      locomotorMegg: {
        tibialDyschondroplasiaMegg: {
          label: "Discondroplasia Tibial",
          style: "",
          options: [],
        },
        femurHeadNecrosisMegg: {
          label: "Necrose Cabeça Fêmur",
          style: { fontSize: "90%" },
          options: [],
        },
      },
      digestiveMegg: {
        bedIntakeMegg: {
          label: "Ingestão de Cama",
          style: "",
          options: [],
        },
        gizzardErosionMegg: {
          label: "Erosão de Moela",
          style: "",
          options: [],
        },
        pancreasAtrophyMegg: {
          label: "Atrofia de Pâncreas",
          style: "",
          options: [],
        },
        duodenitisMegg: {
          label: "Duodenite",
          style: "",
          options: [],
        },
        liverMegg: {
          label: "Fígado",
          style: "",
          options: [],
        },
        intestinalToneMegg: {
          label: "Tônus Intestinal",
          style: "",
          options: [],
        },
        WallThicknessMegg: {
          label: "Espessura da Parede",
          style: "",
          options: [],
        },
        eimeriaAcervulinaMegg: {
          label: "Eimeria de Acervulina",
          style: "",
          options: [],
        },
        maximumEimeriaMegg: {
          label: "Eimeria Máxima",
          style: "",
          options: [],
        },
        eimeriaTenellaMegg: {
          label: "Eimeria Tenella",
          style: "",
          options: [],
        },
        fluidContentMegg: {
          label: "Conteúdo Fluido",
          style: "",
          options: [],
        },
        mucusPresenceMegg: {
          label: "Presença de Muco",
          style: "",
          options: [],
        },
        bilePresenceMegg: {
          label: "Presença de Bile",
          style: "",
          options: [],
        },
        flatwormsMegg: {
          label: "Vermes Chatos",
          style: "",
          options: [],
        },
        turkishTowelMegg: {
          label: "TGI Abalonado",
          style: "",
          options: [],
        },
        necroticEnteritisMegg: {
          label: "Enterite Necrótica",
          style: "",
          options: [],
        },
        foodPassMegg: {
          label: "Passagem de Alimento",
          style: "",
          options: [],
        },
        cellPellingMegg: {
          label: "Descamação Celular",
          style: "",
          options: [],
        },
        intestinalGasMegg: {
          label: "Gás Intestinal",
          style: "",
          options: [],
        },
        hyperemiaMegg: {
          label: "Hiperemia (Vermelhidão)",
          style: { fontSize: "90%" },
          options: [],
        },
        cecalGasMegg: {
          label: "Gás Cecal",
          style: "",
          options: [],
        },
      },
    }),
    []
  );

  const requiredParams = useMemo(
    () => ({
      customer: "Selecione o Cliente",
      visitDate: "Digite a Data da Visita",
      technicalManager: "Digite o Responsável Técnico",
      lineage: "Selecione a Linhagem",
      age: "Digite a Idade do Lote",
      gender: "Selecione o Sexo",
      accommodatingDate: "Digite a Data do Alojamento",
    }),
    []
  );

  const { setUpdateList } = useContext(UpdateList);

  const [necropsy, setNecropsy] = useState({
    _id: "",
    customer: props.customer || null,
    visitDate: "",
    integrated: "",
    nutrition: "",
    lotNumber: "",
    bedNumber: "",
    lineage: "",
    age: "",
    incubatory: "",
    technicalManager: null,
    averageWeight: "",
    ageOfWeight: "",
    weight1: "",
    weight2: "",
    weight3: "",
    weight4: "",
    weight5: "",
    weight6: "",
    interval: "",
    density: "",
    mortality: "",
    gender: "",
    accommodatingDate: "",
    gumboro: "",
    bronquite: "",
    birds: [
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
    ],
  });

  const [loadHistoric, setLoadHistoric] = useState(false);

  const [loading, setLoading] = useState(true);

  const [birds, setBirds] = useState(5);

  const [getNecropsy, setGetNecropsy] = useState(true);

  const [config, setConfig] = useState(initialConfig);

  const [tab, setTab] = useState(0);

  const getProps = useCallback(
    (prop) => ({ prop, params: Object.keys(config[prop]) }),
    [config]
  );

  const sumBirds = useCallback(
    (copyNecropsy) => {
      copyNecropsy.birds.forEach((bird) => {
        let sum = 0;
        Object.keys(bird).forEach((prop) => {
          if (prop !== "total") {
            Object.keys(bird[prop]).forEach((param) => {
              if (prop !== "_id" && bird[prop][param]) {
                const option = config[prop][param].options.find(
                  (item) => item.score === bird[prop][param]
                );
                if (option && option.points) {
                  sum += +option.points;
                }
              }
            });
          }
        });
        bird.total = 10 + sum;
      });
      setNecropsy(JSON.parse(JSON.stringify(copyNecropsy)));
    },
    [config]
  );

  const changeBirds = useCallback(
    async (e) => {
      const value = +e.target.value;
      const necropsyCopy = JSON.parse(JSON.stringify(necropsy));
      if (necropsy.birds.length < value) {
        for (let i = necropsy.birds.length; i < value; i++) {
          necropsyCopy.birds.push(JSON.parse(JSON.stringify(initialBird)));
        }
      } else {
        const lostBirds = [];
        for (let i = value; i < necropsy.birds.length; i++) {
          lostBirds.push(i + 1);
        }
        const response = await Swal.fire({
          title: "Diminuir número de Aves",
          text: `Os dados da${lostBirds[1] ? "s" : ""} ave${
            lostBirds[1] ? "s" : ""
          } ${lostBirds.reduce(
            (text, value, index) =>
              `${text}${index === lostBirds.length - 1 ? " e" : ","} ${value}`
          )} serão perdidos, deseja continuar`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          preConfirm: () => true,
        });
        if (response.dismiss) return;
        for (let i = necropsy.birds.length; i > value; i--) {
          necropsyCopy.birds.splice(i - 1, 1);
        }
      }
      setBirds(value);
      setNecropsy(necropsyCopy);
    },
    [initialBird, necropsy]
  );

  const saveNecropsy = useCallback(
    async (e) => {
      const requestNecropsy = JSON.parse(JSON.stringify(necropsy));
      for (let param in requiredParams) {
        if (!requestNecropsy[param]) {
          return Swal.fire(
            requiredParams[param],
            "Preencha os campos Obrigatórios",
            "error"
          );
        }
      }
      requestNecropsy.birds = requestNecropsy.birds.map((item) => ({
        ...item,
        total: item.total < 0 ? 0 : item.total,
      }));

      if (requestNecropsy.visitDate) {
        requestNecropsy.visitDate = convertStringToDate(
          requestNecropsy.visitDate
        );
      }
      if (requestNecropsy.accommodatingDate) {
        requestNecropsy.accommodatingDate = convertStringToDate(
          requestNecropsy.accommodatingDate
        );
      }
      if (!requestNecropsy._id) {
        const response = await Swal.fire({
          title: "Salvar Necrópsia",
          text: "Deseja confirmar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Salvar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .post("meggcreaterecreatenecropsy", requestNecropsy)
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });
        if (response.value) {
          Swal.fire(
            "Salvar Necrópsia",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          if (!response.value.err) {
            requestNecropsy._id = response.value.data.id;
            setNecropsy(requestNecropsy);
          }
          setUpdateList(true);
        }
      } else {
        const response = await Swal.fire({
          title: "Editar Necrópsia",
          text: "Deseja confirmar edição?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Editar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .put(
                "meggcreaterecreatenecropsy/" +
                  requestNecropsy._id +
                  "/" +
                  props.customer._id,
                requestNecropsy
              )
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });

        if (response.value) {
          Swal.fire(
            "Editar Necrópsia",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          setUpdateList(true);
        }
      }
    },
    [necropsy, setUpdateList, requiredParams, props.customer]
  );

  useEffect(() => {
    const loadNecropsy = async () => {
      setLoading(true);
      const response = await api.get("meggcreaterecreatenecropsy/config");
      if (response.data) {
        const copyConfig = JSON.parse(JSON.stringify(config));
        response.data.forEach((item) => {
          const keys = item.key.split(".");
          if (copyConfig[keys[0]] && copyConfig[keys[0]][keys[1]]) {
            copyConfig[keys[0]][keys[1]].options.push({ ...item });
          }
        });
        setConfig(copyConfig);
      }
      if (props.id) {
        const responseNecropsy = await api.get(
          `meggcreaterecreatenecropsy/${props.id}/${props.match.params.id}`
        );
        if (responseNecropsy.data) {
          if (responseNecropsy.data.visitDate) {
            responseNecropsy.data.visitDate = convertDateToString(
              responseNecropsy.data.visitDate
            );
          }
          setNecropsy(JSON.parse(JSON.stringify(responseNecropsy.data)));
          setBirds(responseNecropsy.data.birds.length);
          setLoadHistoric(true);
        }
      }
      setLoading(false);
    };
    if (getNecropsy) {
      setGetNecropsy(false);
      loadNecropsy();
    }
  }, [getNecropsy, necropsy, props, config]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="necropsy" color="#009870" size={30} />
          <h2>Necropsia</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveNecropsy}
        >
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          {" "}
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Cliente" />
            <Tab label="Dados da Granja" />
            <Tab label="Tegumentar" />
            <Tab label="Imunológico" />
            <Tab label="Respiratório" />
            <Tab label="Locomotor" />
            <Tab label="Digestório" />
            <Tab label="Score Total" />
          </Tabs>
          <Container>
            {tab === 0 && (
              <Customer
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                loadHistoric={loadHistoric}
                disableCustomer={props.disableCustomer}
                setLoadHistoric={setLoadHistoric}
              />
            )}
            {tab === 1 && (
              <FarmData
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                birds={birds}
                changeBirds={changeBirds}
              />
            )}
            {tab === 2 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.integumentaryMegg}
                {...getProps("integumentaryMegg")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 3 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.immunologicalMegg}
                {...getProps("immunologicalMegg")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 4 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.respiratoryMegg}
                {...getProps("respiratoryMegg")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 5 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.locomotorMegg}
                {...getProps("locomotorMegg")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 6 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.digestiveMegg}
                {...getProps("digestiveMegg")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 7 && (
              <ScoreTotal necropsy={necropsy} setNecropsy={setNecropsy} />
            )}
          </Container>
        </>
      )}
    </Modal>
  );
}
