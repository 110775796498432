import { Input } from "components/Input";
import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { Body, Header, TableContent } from "components/Table/style";
import React, { useEffect, useState } from "react";
import { api } from "services/api";
import { BlockBody, Card, CardBody, CardHeader, Col, Row } from "styles";
import Swal from "sweetalert2";
import Loading from "react-loading";

export function Lists() {
  const [config, setConfig] = useState({
    nutrition: [],
  });

  const [newItem, setNewItem] = useState({
    nutrition: {
      label: "",
      value: "",
    },
  });

  const [loadingItems, setLoadingItems] = useState(true);

  const [loadingSave, setLoadingSave] = useState({
    nutrition: false,
  });

  const [updateList, setUpdateList] = useState(true);

  const removeItem = (index, key, isDeleted) => {
    setConfig((prevState) => ({
      ...prevState,
      [key]: prevState[key].map((item, i) =>
        i === index ? { ...item, isDeleted } : item
      ),
    }));
  };

  const addItem = (key) => {
    setConfig((prevState) => ({
      ...prevState,
      [key]: [...prevState[key], { ...newItem[key], key }],
    }));
    setNewItem((prevState) => ({
      ...prevState,
      [key]: { label: "", value: "" },
    }));
  };

  const saveData = async (key) => {
    const result = await Swal.fire({
      text: "Deseja Salvar as configurações da lista",
      title: "Salvar Lista!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Remover item!",
    });
    if (result.value) {
      setLoadingSave((prevState) => ({ ...prevState, nutrition: true }));
      const response = await api
        .post("necropsyconfiglists", { list: config[key] })
        .catch((e) => ({ error: true, message: e.message }));
      if (response.error) {
        Swal.fire({
          text: response.message,
          title: "Erro ao Salvar!",
          icon: "error",
        });
      } else {
        Swal.fire({
          text: "Lista salva com sucesso!",
          title: "Salvar Lista!",
          icon: "success",
        });
      }
      setLoadingSave(false);
      setUpdateList(true);
    }
  };

  useEffect(() => {
    const getLists = async () => {
      const response = await api.get("necropsyconfiglists/nutrition");
      setConfig((prevState) => ({ ...prevState, nutrition: response.data }));
      setLoadingItems(false);
    };
    if (updateList) {
      setUpdateList(false);
      getLists();
    }
  }, [updateList]);

  return (
    <BlockBody>
      <Row>
        <Col>
          <Card>
            <CardHeader>Nutrição</CardHeader>
            <CardBody>
              <TableContent>
                <Header>
                  <tr>
                    <th>Valor</th>
                    <th style={{ width: "50px" }}></th>
                  </tr>
                </Header>
                <Body>
                  {loadingItems ? (
                    <div style={{ marginTop: "10px" }}>
                      <Loading
                        color="#3699FF"
                        height={24}
                        width={24}
                        type="spin"
                      />
                    </div>
                  ) : (
                    <>
                      {config.nutrition.map(
                        ({ value, _id, isDeleted }, index) => (
                          <tr
                            key={_id || index}
                            style={
                              isDeleted
                                ? { textDecoration: "line-through" }
                                : null
                            }
                          >
                            <td>
                              {isDeleted ? (
                                value
                              ) : (
                                <Input
                                  type="inputOnly"
                                  setItem={setConfig}
                                  item={config}
                                  params={`nutrition.${index}.value`}
                                  value={value}
                                  placeholder="Nutrição"
                                />
                              )}
                            </td>

                            <td>
                              <Button
                                type="button"
                                bg={isDeleted ? "warning" : "danger"}
                                border={isDeleted ? "warning" : "danger"}
                                color="white"
                                onClick={() =>
                                  removeItem(index, "nutrition", !isDeleted)
                                }
                              >
                                <Icons
                                  type={isDeleted ? "edit" : "trash"}
                                  color="#FFF"
                                  size={15}
                                />
                              </Button>
                            </td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td>
                          <Input
                            type="inputOnly"
                            setItem={setNewItem}
                            item={newItem}
                            params={`nutrition.value`}
                            placeholder="Nutrição"
                            onKeyPress={(e) =>
                              e.key === "Enter" ? addItem("nutrition") : null
                            }
                          />
                        </td>

                        <td>
                          <Button
                            type="button"
                            bg="basf"
                            border="basf"
                            color="white"
                            onClick={() => addItem("nutrition")}
                          >
                            <Icons type="plus" color="#FFF" size={15} />
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <Button
                            disabled={loadingSave.nutrition}
                            fullSize="true"
                            type="button"
                            bg="default"
                            border="default"
                            color="white"
                            onClick={() => saveData("nutrition")}
                          >
                            Salvar Alterações &nbsp;
                            {loadingSave.nutrition ? (
                              <Loading
                                color="#FFF"
                                height={15}
                                width={15}
                                type="spin"
                              />
                            ) : (
                              <Icons type="save" color="#FFF" size={15} />
                            )}
                          </Button>
                        </td>
                      </tr>
                    </>
                  )}
                </Body>
              </TableContent>
            </CardBody>
          </Card>
        </Col>
        <Col></Col>
      </Row>
    </BlockBody>
  );
}
