import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px 0;
  padding: 30px 0;
  height: 120px;
`;

export const Main = styled.div`
  flex-wrap: wrap;
  padding: 0.25rem 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #fff;
  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  span,
  a {
    padding: 0 0.35rem 0 0;
    font-size: 1rem;
    font-weight: 500;
    color: hsla(0, 0%, 100%, 0.7);
    transition: all 0.3s;
  }
  svg {
    width: 1.3rem;
    height: 1.3rem;
    path {
      fill: hsla(0, 0%, 100%, 0.7);
    }
  }
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.35rem;
  ::after {
    background: hsla(0, 0%, 100%, 0.6);
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    content: " ";
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  button,
  a {
    margin-left: 10px;
  }
`;
