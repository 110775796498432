import { Input } from "components/Input";

export function Row({ index, path, weightedAverage, levels, setLevels, showCheckbox = true, toggleSelect, selected }) {
  const getAverageValue = (rowValuesObject) => {
    const valuesList = Object.values(rowValuesObject).slice(0, -1);
    if (valuesList.length === 0) return 0;
    const sum = valuesList.reduce((acc, curr) => acc + Number(curr), 0);
    return sum / valuesList.length;
  };
  const handleChange = (e, path) => {
    setLevels((prev) => {
      const newState = { ...prev };
      newState[path.row][path.column] = e.target.value;
      if (path.column !== "weightedAverage") {
        getAverageValue(newState[path.row]);
        newState[path.row]["weightedAverage"] = getAverageValue(
          newState[path.row]
        );
      }
      return newState;
    });
  };
  return (
    <tr>
       {showCheckbox ? (
        <td>
          <Input
            type="checkbox"
            checked={selected}
            onChange={toggleSelect}
          />
        </td>
      ) : (
        <td /> 
      )}
      <td>
        <span>
          <b>{path.title}</b>
        </span>
      </td>
     
      <td>
        <Input
          type="input"
          inputType="number"
          placeholder="Pré-inicial"
          value={levels[path.row] ? levels[path.row]["preInitial"] : ''}
          onChange={(e) =>
            handleChange(e, {
              row: path.row,
              column: "preInitial",
            })
          }
        />
      </td>
      <td>
        <Input
          type="input"
          inputType="number"
          placeholder="Inicial"
          value={levels[path.row] ? levels[path.row]["preInitial"] : ''}
          onChange={(e) =>
            handleChange(e, {
              row: path.row,
              column: "initial",
            })
          }
        />
      </td>
      <td>
        <Input
          type="input"
          inputType="number"
          placeholder="Crescimento"
          value={levels[path.row] ? levels[path.row]["preInitial"] : ''}
          onChange={(e) =>
            handleChange(e, {
              row: path.row,
              column: "growth",
            })
          }
        />
      </td>
      <td>
        <Input
          type="input"
          inputType="number"
          placeholder="Engorda"
          value={levels[path.row] ? levels[path.row]["preInitial"] : ''}
          onChange={(e) =>
            handleChange(e, {
              row: path.row,
              column: "fatening",
            })
          }
        />
      </td>
      <td>
        <Input
          type="input"
          inputType="number"
          placeholder="Abate"
          value={levels[path.row] ? levels[path.row]["preInitial"] : ''}
          onChange={(e) =>
            handleChange(e, {
              row: path.row,
              column: "slaughter",
            })
          }
        />
      </td>
      {weightedAverage && (
        <td>
          <Input
            type="input"
            inputType="number"
            placeholder="Média Ponderada"
            value={levels[path.row]["weightedAverage"]}
            onChange={(e) =>
              handleChange(e, {
                row: path.row,
                column: "weightedAverage",
              })
            }
          />
        </td>
      )}
    </tr>
  );
}
