import { Tab, Tabs } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { FactoryAndProductInStockEvaluation } from "./FactoryAndProductInStockEvaluation";
import { InitialDescription } from "./InitialDescription";
import { Installations } from "./Installations";
import { ProblemDescription } from "./ProblemDescription";
import { Products } from "./Products";
import { RecommendedCollectionAndAnalysis } from "./RecommendedCollectionAndAnalysis";
import ReactLoading from "react-loading";
import {
  convertDateToString,
  convertStringToDate,
} from "utils/globalFunctions";

export function Evaluation(props) {
  const [evaluation, setEvaluation] = useState({
    initialDescription: { technician: null, visitDate: "", temperature: "" },
    problemDescription: {
      species: {
        birds: false,
        pigs: false,
        cattle: false,
        others: false,
        othersDescription: "",
      },
      animals: {
        lowConsumption: false,
        scoliosis: false,
        soreEars: false,
        highConsumption: false,
        bristles: false,
        cannibalism: false,
        lowWeight: false,
        others: false,
        animalDeath: false,
        lowGPD: false,
        diarrhea: false,
        othersDescription: "",
      },
      products: {
        cobblestone: false,
        changedColoration: false,
        nonconformingFlavor: false,
        rancidOdor: false,
        others: false,
        othersDescription: "",
      },
    },
    factoryAndProductInStockEvaluation: {
      storage: {
        fefo: true,
        storedOnPallets: true,
        awayFromTheWalls: true,
        storedAwayFromSunlight: true,
        storedFreeOfHumidity: true,
        packagingIsIntact: true,
        sacksWithOthersProducts: true,
        dailyHouseTemperatureMeasurement: true,
        CVOfLastMeasurement: "",
        JustifyTheItemsMarkedAsNo: "",
      },
      silosMixerAndProductionLine: {
        equipmentsAreClean: true,
        accumulationOfFeedInTheMixerOrInTheLine: true,
        silosAreWellSealed: true,
        mixerIsGrounded: true,
        weighingScaleIsCalibrated: true,
        productionSequenceIsCorrect: true,
        mixerCleaningBetweenBeats: true,
        MixerCVHasBeenChecked: true,
        drugAtTheFactory: true,
        lowInclusionIngredientsArePreMixed: true,
        CVOfLastMeasurement: "",
        JustifyTheItemsMarkedAsNo: "",
      },
    },
    installations: {
      stocking: true,
      troughsAreWellSpaced: true,
      troughArea: true,
      accessToTrough: true,
      stallsClean: true,
      toiletVoid: true,
      availableWater: true,
      waterIsChlorinated: true,
      waterTemperature: true,
      roomTemperature: true,
      JustifyTheItemsMarkedAsNo: "",
      describeTheStructureOfTheTroughs: "",
    },
    products: [],
    recommendedCollectionAndAnalysis: {
      cobblestone: {
        humidity: false,
      },
      nonconformingOdor: {
        acidity: false,
        peroxide: false,
        rancid: false,
      },
      cannibalism: {
        PB: false,
        Ca: false,
        P: false,
        Na: false,
      },
    },
    factory: props.match.params.id,
  });

  const [tab, setTab] = useState(0);

  const [loading, setLoading] = useState(true);

  const [getEvaluation, setGetEvaluation] = useState(true);

  const saveEvaluation = useCallback(async () => {
    const query = JSON.parse(JSON.stringify(evaluation));
    if (query.initialDescription.visitDate) {
      query.initialDescription.visitDate = convertStringToDate(
        query.initialDescription.visitDate
      );
    }
    if (!props.id) {
      const response = await Swal.fire({
        title: "Criar Avaliação",
        text: "Deseja confirmar criação da Avaliação",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`factoryevaluation/${props.match.params.id}/`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Avaliação",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setEvaluation((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState._id = response.value.data.id;
          return newState;
        });
        props.setUpdateList(true);
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Avaliação",
        text: "Deseja confirmar edição da Avaliação",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(
              `factoryevaluation/${props.match.params.id}/${props.id}`,
              query
            )
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Avaliação",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        props.setUpdateList(true);
      }
    }
  }, [evaluation, props]);

  useEffect(() => {
    const loadEvaluation = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `factoryevaluation/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire(
            "Editar Avaliação",
            "Não foi possível buscar a Avaliação",
            "error"
          );
        }
        if (response.data.initialDescription.visitDate) {
          response.data.initialDescription.visitDate = convertDateToString(
            response.data.initialDescription.visitDate
          );
        }
        setEvaluation(response.data);
      }
      setLoading(false);
    };
    if (getEvaluation) {
      setGetEvaluation(false);
      loadEvaluation();
    }
  }, [props.id, props.match.params.id, getEvaluation]);

  return loading ? (
    <ReactLoading type="spin" color="#009870" height={30} width={30} />
  ) : (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Checklist de Campo</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveEvaluation}
        >
          Salvar
        </Button>
      }
    >
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Descrições Iniciais" />
        <Tab label="Descrição do Problema" />
        <Tab
          label="Avaliação de Fábrica e Produtos em Estoque"
          style={{ whiteSpace: "nowrap", maxWidth: "none" }}
        />
        <Tab label="Instalações" />
        <Tab label="Produtos" />
        <Tab label="Coletas e Análises Recomendadas" />
      </Tabs>
      {tab === 0 && (
        <InitialDescription
          evaluation={evaluation}
          setEvaluation={setEvaluation}
        />
      )}
      {tab === 1 && (
        <ProblemDescription
          evaluation={evaluation}
          setEvaluation={setEvaluation}
        />
      )}
      {tab === 2 && (
        <FactoryAndProductInStockEvaluation
          evaluation={evaluation}
          setEvaluation={setEvaluation}
        />
      )}
      {tab === 3 && (
        <Installations evaluation={evaluation} setEvaluation={setEvaluation} />
      )}
      {tab === 4 && (
        <Products evaluation={evaluation} setEvaluation={setEvaluation} />
      )}
      {tab === 5 && (
        <RecommendedCollectionAndAnalysis
          evaluation={evaluation}
          setEvaluation={setEvaluation}
        />
      )}
    </Modal>
  );
}
