import { Input } from "components/Input";
import { Col, Row } from "styles";

export function Dermatosis({ checklist, setChecklist }) {
  return (
    <>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="dermatosis.lineage"
            type="select"
            label="Linhagem"
            placeholder="Selecione uma Opção"
            options={[
              { value: "Cobb" },
              { value: "Ross" },
              { value: "Hubbard" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.farmType"
            label="Tipo de granja "
            placeholder="Selecione uma Opção"
            options={[
              { value: "Convencional" },
              { value: "Climatizada" },
              { value: "Dark House" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.density"
            label="Densidade"
            placeholder="Selecione uma Opção"
            options={[
              { value: "18" },
              { value: "16" },
              { value: "15" },
              { value: "14" },
              { value: "12" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.kgM2"
            label="Kg /m2"
            placeholder="Selecione uma Opção"
            options={[
              { value: "50" },
              { value: "48" },
              { value: "45" },
              { value: "39" },
              { value: "35" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="input"
            params="dermatosis.averageWeight"
            label="Peso médio de saida"
            placeholder="Digite o peso médio"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.FeedersNumber"
            label="Número de comedouro por ave"
            placeholder="Selecione uma Opção"
            options={[
              { value: "1(50)" },
              { value: "1(45)" },
              { value: "1(40)" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.nipplesNumber"
            label="Número de nipple por ave"
            placeholder="Selecione uma Opção"
            options={[
              { value: "1(10)" },
              { value: "1(08)" },
              { value: "1(06)" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.space"
            label="Espaço"
            placeholder="Selecione uma Opção"
            options={[
              { value: "21(100%)" },
              { value: "18(100%)" },
              { value: "14(100%)" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.warp.male"
            label="Empenamendo (Macho)"
            placeholder="Selecione uma Opção"
            options={[{ value: "21" }, { value: "28" }, { value: "35" }]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.warp.female"
            label="Empenamendo (Fêmea)"
            placeholder="Selecione uma Opção"
            options={[{ value: "21" }, { value: "28" }, { value: "35" }]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.streakedBirds"
            label="Aves riscadas (Planilha campo)"
            placeholder="Selecione uma Opção"
            options={[
              { value: "2%" },
              { value: "5%" },
              { value: "10%" },
              { value: "20%" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.scaredLot"
            label="Lote assustado/ idade  0-Não 1-Sim"
            placeholder="Selecione uma Opção"
            options={[
              { value: "14" },
              { value: "21" },
              { value: "28" },
              { value: "35" },
              { value: "42" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="dermatosis.kgPerBox"
            label="Kg por caixa apanha"
            placeholder="Selecione uma Opção"
            options={[
              { value: "30" },
              { value: "25" },
              { value: "23" },
              { value: "21" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="input"
            params="dermatosis.peopleNumber"
            label="Numero de pessoas /10000"
            placeholder="Digite o número de pessoas"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="callusAndChest.numberHour"
            label="Horas por 5000"
            placeholder="Selecione uma Opção"
            options={[
              { value: "02:00" },
              { value: "01:30" },
              { value: "01:00" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="callusAndChest.trainedStaff"
            label="Equipe treinada"
            placeholder="Selecione uma Opção"
            options={[{ value: "Sim" }, { value: "Não" }]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="callusAndChest.runningMachine"
            label="Esteira"
            placeholder="Selecione uma Opção"
            options={[{ value: "Sim" }, { value: "Não" }]}
          />
        </Col>
      </Row>
    </>
  );
}
