import { Input } from "components/Input";
import { Body, Header, TableContent } from "components/Table/style";
import { Col, Row } from "styles";

export function Locomotor({ checklist, setChecklist }) {
  return (
    <>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="locomotor.box"
            type="select"
            label="Caixas de transporte dos pintinho c/ papel enrugado"
            placeholder="Selecione uma Opção"
            options={[{ value: "Sim" }, { value: "Não" }]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            params="locomotor.lineage"
            type="input"
            label="Linhagem"
            placeholder="Selecione uma Opção"
            options={[
              { value: "Cobb" },
              { value: "Ross" },
              { value: "Hubbard" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.accommodationDensity.summer"
            label="Densidade no alojamento (Verão)"
            placeholder="Selecione uma Opção"
            options={[
              { value: "50" },
              { value: "45" },
              { value: "40" },
              { value: "35" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.accommodationDensity.winter"
            label="Densidade no alojamento (Inverno)"
            placeholder="Selecione uma Opção"
            options={[
              { value: "60" },
              { value: "55" },
              { value: "50" },
              { value: "45" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.pinteiro"
            label="Pinteiro forrato c/papel"
            placeholder="Selecione uma Opção"
            options={[{ value: "Sim" }, { value: "Não" }]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="input"
            params="locomotor.paperType"
            label="Tipo de Papel"
            placeholder="Digite o tipo de papel"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.density"
            label="Densidade"
            placeholder="Selecione uma Opção"
            options={[
              { value: "18" },
              { value: "16" },
              { value: "15" },
              { value: "14" },
              { value: "12" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.kgM2"
            label="Kg /m2"
            placeholder="Selecione uma Opção"
            options={[
              { value: "50" },
              { value: "48" },
              { value: "45" },
              { value: "39" },
              { value: "35" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="input"
            params="locomotor.averageWeight"
            label="Peso médio de saida"
            placeholder="Digite o peso médio"
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.bedType"
            label="Tipo de Cama"
            placeholder="Selecione uma Opção"
            options={[
              { value: "C. amendoim" },
              { value: "C. arroz moida" },
              { value: "Casca de arroz" },
              { value: "Pó de elcalipto" },
              { value: "Pó de pinnus" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th colspan="5">Aves c/ calo (Planilha campo)</th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Score</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
              </tr>
              <tr>
                <td>%</td>
                <td>
                  <Input
                    item={checklist}
                    setItem={setChecklist}
                    type="inputOnly"
                    params="locomotor.callus.0"
                  />
                </td>
                <td>
                  <Input
                    item={checklist}
                    setItem={setChecklist}
                    type="inputOnly"
                    params="locomotor.callus.1"
                  />
                </td>
                <td>
                  <Input
                    item={checklist}
                    setItem={setChecklist}
                    type="inputOnly"
                    params="locomotor.callus.2"
                  />
                </td>
                <td>
                  <Input
                    item={checklist}
                    setItem={setChecklist}
                    type="inputOnly"
                    params="locomotor.callus.3"
                  />
                </td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.createdNumberBad"
            label="Número de criadas cama"
            placeholder="Selecione uma Opção"
            options={[
              { value: "2" },
              { value: "3" },
              { value: "4" },
              { value: "5" },
              { value: "6" },
            ]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.bedFermentationProcess"
            label="Processo de fermentação cama"
            placeholder="Selecione uma Opção"
            options={[{ value: "Sim" }, { value: "Não" }]}
          />
        </Col>
        <Col>
          <Input
            item={checklist}
            setItem={setChecklist}
            type="select"
            params="locomotor.qualityBedWeek"
            label="Qualidade da cama semanal"
            placeholder="Selecione uma Opção"
            options={[
              { value: "7" },
              { value: "14" },
              { value: "21" },
              { value: "28" },
              { value: "35" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
