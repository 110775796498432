import { useState, useCallback } from "react";
import ReactLoading from "react-loading";
import { api } from "services/api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  Background,
  Button,
  Container,
  Content,
  Form,
  InputText,
  LogoConnect,
  LogoConnectContent,
  LogoMcassab,
  WelcomeContainer,
} from "../style";
import { Welcome } from "../Welcome";

export function Forgot(props) {
  const erroMessageInitialState = {
    message: "",
    username: false,
  };

  const [form, setForm] = useState({
    username: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const submitData = useCallback(
    async (e) => {
      e.preventDefault();
      if (!form.username) {
        return setErrorMessage({
          username: !form.username,
        });
      }
      setLoading(true);
      const response = await api.post("/auth/forgot", form).catch((err) => err);
      setLoading(false);
      if (!response.data) {
        return Swal.fire("Esqueci minha senha", response.message, "error");
      }
      Swal.fire(
        "Esqueci minha senha",
        "Email de recuparação de senha enviado com sucesso!",
        "success"
      );
      setForm({
        username: "",
      });
      return props.history.push("/login");
    },
    [form, props]
  );

  return (
    <Container>
      <Content className="animate__animated animate__bounceInLeft">
        <Form onSubmit={submitData}>
          <LogoConnect src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_connect_nova.png" />
          <label style={{ color: "#414b97" }}>Recuperar Senha</label>
          <InputText
            placeholder="E-Mail"
            isInvalid={!!errorMessage.username}
            item={form}
            setItem={setForm}
            params="username"
            type="inputOnly"
          />
          <Button
            className="btn btn-primary btn-lg btn-block"
            type="submit"
            disabled={loading}
          >
            <span>
              {loading ? (
                <ReactLoading
                  style={{
                    fill: "#72b56e",
                    height: "20px",
                    width: "20px",
                    display: "inline-table",
                  }}
                  type="spin"
                  color="#72b56e"
                  height={19}
                  width={19}
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  style={{ marginRight: "10px" }}
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                      fill="#72b56e"
                      opacity="0.3"
                    />
                    <path
                      d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                      fill="#72b56e"
                    />
                  </g>
                </svg>
              )}
              Enviar
            </span>
          </Button>
          <Link style={{ color: "#414b97" }} to="/Login">
            Login
          </Link>
        </Form>
      </Content>

      <Background className="animate__animated animate__bounceInRight">
        <WelcomeContainer>
          <Welcome />
          <LogoConnectContent>
            <LogoMcassab src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-mcassab-branca.png" />
          </LogoConnectContent>
        </WelcomeContainer>
      </Background>
    </Container>
  );
}
