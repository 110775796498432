import { addDays } from "date-fns";
import Swal from "sweetalert2";
import { Button } from "components/Button";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Col, Group, Row, Separator } from "styles";
import {
  convertDateToString,
  convertStringToDate,
} from "utils/globalFunctions";
import { Title } from "../../../../Connect/Broiler/Necropsy/CreateEdit/Customer/styled";

export function CreateEdit(props) {
  const { config, id, setId, maintenance, setMaintenance } = props;

  const getToday = () => {
    setMaintenance((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.maintenanceDate = convertDateToString(new Date().toISOString());
      return newState;
    });
  };

  const codeHandler = (index, value) => {
    setMaintenance((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.codes[index].code = value;
      if (value) {
        const { days } = config.equipments
          .filter(({ number }) => number === newState.equipment)[0]
          .frequency.filter(({ codes }) => codes.indexOf(value) > -1)[0];
        newState.codes[index].nextMaintenance = convertDateToString(
          addDays(
            convertStringToDate(newState.maintenanceDate),
            days
          ).toISOString()
        );
      }

      return newState;
    });
  };

  const saveMaintenance = async () => {
    const data = JSON.parse(JSON.stringify(maintenance));
    data.maintenanceDate = convertStringToDate(data.maintenanceDate);
    data.codes = data.codes.map((item) => ({
      ...item,
      alert: item.alert !== "false",
      nextMaintenance: convertStringToDate(item.nextMaintenance),
    }));
    if (!id) {
      const response = await Swal.fire({
        title: "Lançar Manutenção",
        text: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Lançar Manutenção!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`maintenance/${props.match.params.id}`, data)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Lançar Manutenção",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setId(response.value.data.id);
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Manutenção",
        text: "Deseja confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(`maintenance/${props.match.params.id}/${id}`, data)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Cliente",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
      }
    }
  };
  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="tools" color="#009870" size={30} />
          <h2 style={{ marginLeft: "10px" }}>Lançar Manutenção</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveMaintenance}
        >
          Salvar
        </Button>
      }
    >
      <Row>
        <Col>
          <Input
            required={true}
            item={maintenance}
            setItem={setMaintenance}
            params="technicalManager"
            type="autocomplete"
            label="Responsável Técnico"
            paramsGet={["code", "name"]}
            paramsLabel={["code", "name"]}
            query={`%7B"customer":"${props.match.params.customer}"%7D`}
            select="code name"
            model="user"
            placeholder="Digite o nome ou o código do Técnico"
          />
        </Col>
        <Col>
          <Input
            required={true}
            item={maintenance}
            setItem={setMaintenance}
            params="equipment"
            type="select"
            label="Equipamento"
            placeholder="Selecione o Equipamento"
            options={config.equipments.map(({ number, equipment }) => ({
              value: number,
              label: `${number}-${equipment}`,
            }))}
          />
        </Col>
        <Col>
          <label>
            Data da Manutenção <span style={{ color: "red" }}>*</span>
          </label>
          <Group>
            <Input
              required={true}
              item={maintenance}
              setItem={setMaintenance}
              params="maintenanceDate"
              label="Data da Manutenção"
              type="inputMaskOnly"
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
            />
            <Button
              color="white"
              bg="#e2e5ec"
              border="#e2e5ec"
              onClick={getToday}
            >
              <Icons type="calendar" color="#495057" size={18} />
            </Button>
          </Group>
        </Col>
      </Row>

      {maintenance.equipment &&
      maintenance.maintenanceDate &&
      maintenance.technicalManager ? (
        <>
          <Separator />
          <Title>Tipos de Manutenção</Title>

          {maintenance.codes.map((item, index) => (
            <>
              <Row>
                <Col>
                  <Input
                    item={maintenance}
                    onChange={(e) => codeHandler(index, e.target.value)}
                    params={`codes.${index}.code`}
                    type="select"
                    label="Tipo de Manutenção"
                    placeholder="Selecione o tipo de manutençao"
                    options={config.codes
                      .filter(({ number }) => {
                        const response = [];
                        if (
                          !config.equipments.filter(
                            ({ number }) => number === maintenance.equipment
                          )[0]
                        ) {
                          return false;
                        }
                        config.equipments
                          .filter(
                            ({ number }) => number === maintenance.equipment
                          )[0]
                          .frequency.forEach(({ codes }) => {
                            codes.forEach((code) => {
                              if (response.indexOf(code) === -1) {
                                response.push(code);
                              }
                            });
                          });
                        return response.indexOf(number) > -1;
                      })
                      .map(({ number, description }) => ({
                        value: number,
                        label: `${number}-${description}`,
                      }))}
                  />
                </Col>
                <Col>
                  <Input
                    item={maintenance}
                    setItem={setMaintenance}
                    params={`codes.${index}.nextMaintenance`}
                    label="Próxima Manutençao"
                    type="inputMask"
                    mask="99/99/9999"
                    placeholder="dd/mm/aaaa"
                  />
                </Col>
                <Col>
                  <Input
                    item={maintenance}
                    setItem={setMaintenance}
                    params={`codes.${index}.alert`}
                    label="Enviar Notificação"
                    type="select"
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    rows={4}
                    item={maintenance}
                    setItem={setMaintenance}
                    params={`codes.${index}.description`}
                    label="Descrição da Manutenção"
                    type="textarea"
                    placeholder="Observações sobre a manutençao"
                  />
                </Col>
              </Row>
              <Button
                color="white"
                bg="danger"
                border="danger"
                onClick={() =>
                  setMaintenance((prevState) => {
                    const newState = JSON.parse(JSON.stringify(prevState));
                    newState.codes.splice(index, 1);
                    return newState;
                  })
                }
              >
                <Icons type="trash" color="#fff" size={18} />
              </Button>
              <Separator />
            </>
          ))}
          <Button
            color="white"
            bg="default"
            border="default"
            onClick={() =>
              setMaintenance({
                ...maintenance,
                codes: [
                  ...maintenance.codes,
                  {
                    code: "",
                    description: "",
                    nextMaintenance: "",
                    alert: true,
                  },
                ],
              })
            }
          >
            <Icons type="plus" color="#fff" size={18} />
          </Button>
        </>
      ) : null}
    </Modal>
  );
}
