import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import Export from "./Export";
import { hbPreNecropsyConfig, hbNecropsyConfig } from "utils/globalData";
import { api } from "services/api";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { BoxInjury } from "./BoxInjury";
import { BoxEggQuality } from "./BoxEggQuality";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "styles";
import {
  HistoricOption,
  HistoricOptionContent,
  ReportButton,
  SliderContent,
  SliderContentInput,
} from "./style";
import { getColor } from "utils/globalFunctions";
import { Bar } from "react-chartjs-2";
import { BoxProduction } from "./BoxProduction";
import HistoricTable from "./HistoricTable";
import HistoricTreatment from "./HistoricTreatment";
import { DateRange } from "react-date-range";
import { ptBR } from "react-date-range/dist/locale";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { Slider } from "@material-ui/core";

export function Report(props) {
  const heavyBroiler = [
    { label: "Fitase Hidolsing", value: "enzymes.phytaseHidosing" },
    { label: "Corboidrase/Blends", value: "enzymes.carbohydraseBlends" },
    { label: "EnzyPac Pro / Protease", value: "enzymes.enziPacProProtease" },
    { label: "Medicação Terapeutica", value: "health.therapeuticMedication" },
    { label: "Melhorador de Desempenho", value: "health.performanceEnhancer" },
    { label: "Minerais Orgânicos", value: "minerals.organics" },
    { label: "Ácidos Orgânicos", value: "additions.organicAcids" },
    {
      label: "Absorventes de Micotoxina",
      value: "additions.mycotoxinAdsorbents",
    },
    { label: "Óleos Essenciais", value: "additions.essencialOils" },
    { label: "Prebióticos", value: "additions.prebiotics" },
    { label: "Probióticos", value: "additions.probiotics" },
  ];

  const [input, setInput] = useState({
    customer: props.match.params.id,
    core: "",
    lotes: [],
    heavyBroilerList: [],
    age: [23, 66],
    injuryType: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [responseReport, setResponseReport] = useState({});

  const [heavyBroilerInput, setHeavyBroilerInput] = useState("");

  const [generateReport, setGenerateReport] = useState(true);

  const [loading, setLoading] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [customer, setCustomer] = useState({});

  const [cores, setCores] = useState([]);

  const [lotes, setLotes] = useState([]);

  const exportPDF = () => {
    setModalShow(true);
  };

  const createBoxInjuries = () => {
    let columns = [];
    const rows = [];
    const keys = Object.keys(hbPreNecropsyConfig.feces);
    keys
      .filter((key) => hbPreNecropsyConfig.feces[key].report)
      .forEach((key, index) => {
        columns.push(
          <React.Fragment key={key}>
            <BoxInjury param={key} response={responseReport} />
          </React.Fragment>
        );
        if (columns.length === 3 || index === keys.length - 1) {
          for (let i = columns.length; i < 3; i++) {
            columns.push(<Col key={`${key}${i}`}></Col>);
          }
          rows.push(<Row key={`row${key}`}>{columns}</Row>);

          columns = [];
        }
      });

    return rows;
  };

  const heavyBroilerInputHandler = (value) => {
    setInput((prevState) => {
      const nextState = JSON.parse(JSON.stringify(prevState));
      nextState.heavyBroilerList.push(value);
      return nextState;
    });
  };

  const getReportlabels = () =>
    responseReport.ages.map((age) => {
      return [`Semana ${age}`];
    });

  const getReportData = () => {
    const response = {
      datasets: [
        ...Object.keys(responseReport.lotes).map((lote) => {
          const color = getColor();
          return {
            label: `Score Lote ${lote}`,
            type: "line",
            data: responseReport.lotes[lote].map((item) =>
              item ? item.total.toFixed(1) : null
            ),
            fill: false,
            borderColor: getColor(color, 0.7),
            backgroundColor: getColor(color, 0.7),
            pointBorderColor: color,
            pointBackgroundColor: color,
            pointHoverBackgroundColor: getColor(color, 0.9),
            pointHoverBorderColor: getColor(color, 0.9),
            yAxisID: "y-axis-1",
          };
        }),
        ...Object.keys(responseReport.scores).map((key) => {
          return {
            label: hbPreNecropsyConfig.labels[key],
            yAxisID: "y-axis-2",
            type: "bar",
            borderWidth: 1,
            data: responseReport.scores[key].map((item) =>
              item ? item.toFixed(1) : null
            ),
            backgroundColor: getColor(hbPreNecropsyConfig.colors[key], 0.7),
            borderColor: getColor(hbPreNecropsyConfig.colors[key], 0.7),
            hoverBackgroundColor: getColor(
              hbPreNecropsyConfig.colors[key],
              0.7
            ),
            hoverBorderColor: getColor(hbPreNecropsyConfig.colors[key], 0.7),
          };
        }),
      ],
    };
    return response;
  };

  const getReportNecropsyData = () => {
    const { injuryType } = JSON.parse(props.match.params.query);
    const response = {
      datasets: [
        ...Object.keys(responseReport.necropsy[0].lotes).map((lote) => {
          const color = getColor();
          return {
            label: `Score Lote ${lote}`,
            type: "line",
            data: responseReport.necropsy.map((item) =>
              item.lotes[lote] ? item.lotes[lote].toFixed(1) : null
            ),
            fill: false,
            borderColor: getColor(color, 0.7),
            backgroundColor: getColor(color, 0.7),
            pointBorderColor: color,
            pointBackgroundColor: color,
            pointHoverBackgroundColor: getColor(color, 0.9),
            pointHoverBorderColor: getColor(color, 0.9),
            yAxisID: "y-axis-1",
          };
        }),
        ...Object.keys(hbNecropsyConfig[injuryType]).map((key) => {
          return {
            label: hbNecropsyConfig[injuryType][key].label,
            yAxisID: "y-axis-2",
            type: "bar",
            borderWidth: 1,
            data: responseReport.necropsy.map((item) =>
              item[key] ? item[key].toFixed(1) : null
            ),
            backgroundColor: getColor(
              hbNecropsyConfig[injuryType][key].color,
              0.7
            ),
            borderColor: getColor(hbNecropsyConfig[injuryType][key].color, 0.7),
            hoverBackgroundColor: getColor(
              hbNecropsyConfig[injuryType][key].color,
              0.7
            ),
            hoverBorderColor: getColor(
              hbNecropsyConfig[injuryType][key].color,
              0.7
            ),
          };
        }),
      ],
    };
    return response;
  };

  const getEggQualityReportData = () => {
    const response = {
      datasets: [
        {
          label: `Peso do Ovo g`,
          type: "line",
          data: responseReport.eggQuality.weight.map((item) =>
            item ? item.toFixed(1) : null
          ),
          fill: false,
          borderColor: getColor(
            hbPreNecropsyConfig.eggQuality.weight.color,
            0.7
          ),
          backgroundColor: getColor(
            hbPreNecropsyConfig.eggQuality.weight.color,
            0.7
          ),
          pointBorderColor: hbPreNecropsyConfig.eggQuality.weight.color,
          pointBackgroundColor: hbPreNecropsyConfig.eggQuality.weight.color,
          pointHoverBackgroundColor: getColor(
            hbPreNecropsyConfig.eggQuality.weight.color,
            0.9
          ),
          pointHoverBorderColor: getColor(
            hbPreNecropsyConfig.eggQuality.weight.color,
            0.9
          ),
          yAxisID: "y-axis-1",
        },
        ...Object.keys(responseReport.eggQuality)
          .filter((key) => key !== "weight")
          .map((key) => {
            return {
              label: hbPreNecropsyConfig.eggQuality[key].label,
              yAxisID: "y-axis-2",
              type: "bar",
              borderWidth: 1,
              data: responseReport.eggQuality[key].map((item) =>
                item ? item.toFixed(1) : null
              ),
              backgroundColor: getColor(
                hbPreNecropsyConfig.eggQuality[key].color,
                0.7
              ),
              borderColor: getColor(
                hbPreNecropsyConfig.eggQuality[key].color,
                0.7
              ),
              hoverBackgroundColor: getColor(
                hbPreNecropsyConfig.eggQuality[key].color,
                0.7
              ),
              hoverBorderColor: getColor(
                hbPreNecropsyConfig.eggQuality[key].color,
                0.7
              ),
            };
          }),
      ],
    };
    return response;
  };

  const getProductionReportData = () => {
    const response = {
      datasets: [
        {
          label: hbPreNecropsyConfig.production.bodyWeight.label,
          type: "line",
          data: responseReport.production.bodyWeight.map((item) =>
            item ? item.toFixed(1) : null
          ),
          fill: false,
          borderColor: getColor(
            hbPreNecropsyConfig.production.bodyWeight.color,
            0.7
          ),
          backgroundColor: getColor(
            hbPreNecropsyConfig.production.bodyWeight.color,
            0.7
          ),
          pointBorderColor: hbPreNecropsyConfig.production.bodyWeight.color,
          pointBackgroundColor: hbPreNecropsyConfig.production.bodyWeight.color,
          pointHoverBackgroundColor: getColor(
            hbPreNecropsyConfig.production.bodyWeight.color,
            0.9
          ),
          pointHoverBorderColor: getColor(
            hbPreNecropsyConfig.production.bodyWeight.color,
            0.9
          ),
          yAxisID: "y-axis-1",
        },
        ...Object.keys(responseReport.production)
          .filter((key) => key !== "bodyWeight")
          .map((key) => {
            return {
              label: hbPreNecropsyConfig.production[key].label,
              yAxisID: "y-axis-2",
              type: "bar",
              borderWidth: 1,
              data: responseReport.production[key].map((item) =>
                item ? item.toFixed(1) : null
              ),
              backgroundColor: getColor(
                hbPreNecropsyConfig.production[key].color,
                0.7
              ),
              borderColor: getColor(
                hbPreNecropsyConfig.production[key].color,
                0.7
              ),
              hoverBackgroundColor: getColor(
                hbPreNecropsyConfig.production[key].color,
                0.7
              ),
              hoverBorderColor: getColor(
                hbPreNecropsyConfig.production[key].color,
                0.7
              ),
            };
          }),
      ],
    };
    return response;
  };

  const createBoxEggQuality = () => {
    let columns = [];
    const rows = [];
    const keys = Object.keys(hbPreNecropsyConfig.eggQuality);
    keys.forEach((key, index) => {
      columns.push(
        <React.Fragment key={key}>
          <BoxEggQuality param={key} response={responseReport} />
        </React.Fragment>
      );
      if (columns.length === 2 || index === keys.length - 1) {
        for (let i = columns.length; i < 2; i++) {
          columns.push(<Col key={`${key}${i}`}></Col>);
        }
        rows.push(<Row key={`row${key}`}>{columns}</Row>);

        columns = [];
      }
    });

    return rows;
  };

  const createBoxProduction = () => {
    let columns = [];
    const rows = [];
    const keys = Object.keys(hbPreNecropsyConfig.production);
    keys.forEach((key, index) => {
      columns.push(
        <React.Fragment key={key}>
          <BoxProduction param={key} response={responseReport} />
        </React.Fragment>
      );
      if (columns.length === 2 || index === keys.length - 1) {
        for (let i = columns.length; i < 2; i++) {
          columns.push(<Col key={`${key}${i}`}></Col>);
        }
        rows.push(<Row key={`row${key}`}>{columns}</Row>);

        columns = [];
      }
    });

    return rows;
  };

  const handleDate = async (item) => {
    setDateRange([item.selection]);
    const getCores = async () => {
      const response = await api.get(
        `/connect/heavybroiler/cores/${
          props.match.params.id
        }?date=${JSON.stringify(item.selection)}`
      );
      setCores(response.data.cores);
      setLotes(response.data.lotes);
    };
    getCores();
  };

  const handleCore = (e) => {
    setInput((preState) => {
      const newState = JSON.parse(JSON.stringify(preState));
      newState.core = e.target.value;
      newState.lotes = [];
      return newState;
    });
  };

  const reportHandler = () => {
    const query = { ...input };
    query.visitDate = dateRange[0];
    setLoading(true);
    props.history.replace(
      `/connect/heavybroilerreport/${props.match.params.id}/${JSON.stringify(
        query
      )}`
    );
    setGenerateReport(true);
  };

  useEffect(() => {
    const loadReport = async () => {
      const params = JSON.parse(props.match.params.query);
      setInput({
        customer: props.match.params.id,
        core: params.core,
        lotes: params.lotes || [],
        heavyBroilerList: params.heavyBroilerList,
        injuryType: params.injuryType,
        age: params.age,
      });
      setDateRange([
        {
          startDate: startOfDay(new Date(params.visitDate.startDate)),
          endDate: endOfDay(new Date(params.visitDate.endDate)),
          key: "selection",
        },
      ]);
      const response = await api
        .post("/connect/heavybroiler/report", {
          ...props.match.params,
        })
        .catch((e) => {
          return e;
        });
      if (!response.data) {
        setLoading(false);
        return Swal.fire("Relatório", response.message, "error");
      }

      setResponseReport(JSON.parse(JSON.stringify(response.data)));

      setLoading(false);
    };

    if (generateReport && props.match.params.query !== "{}") {
      setGenerateReport(false);
      setLoading(true);
      loadReport();
    }
  }, [generateReport, props.match.params]);

  useEffect(() => {
    const getCustomerName = async () => {
      const responseCustomer = await api.get(
        `connect/customer/${props.match.params.id}`
      );
      setCustomer(responseCustomer.data);
      setLoading(false);
    };
    if (!customer._id) {
      getCustomerName();
    }
  }, [props.match.params.id, customer._id]);

  useEffect(() => {
    if (props.match.params.query === "{}") {
      setInput({
        customer: props.match.params.id,
        core: "",
        lotes: [],
        heavyBroilerList: [],
        injuryType: [],
        age: [23, 66],
      });
      setDateRange([
        {
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
          key: "selection",
        },
      ]);
      setResponseReport({});
    }
  }, [props.match.params]);

  return (
    <>
      {modalShow ? (
        <Export
          show={modalShow}
          onHide={() => setModalShow(false)}
          location={props.location}
          responseReport={responseReport}
          customer={customer.name}
        />
      ) : null}
      <SubHeader
        {...props}
        title={customer.name}
        breadcrumbs={[
          {
            label: customer.name,
            to: `/connect/customer/${props.match.params.id}/1`,
          },

          {
            label: "Matriz Pesada",
          },
          {
            label: "Relatório",
          },
        ]}
        icon={<Icons type="lineChart" size="24" color="#fff" />}
        toolbar={
          responseReport.ages && !loading && props.match.params.query !== "{}"
            ? ["exportReport"]
            : null
        }
        exportReport={exportPDF}
      />

      <Row>
        <Col size={1} className="animate__animated animate__fadeInLeft">
          <Block>
            <BlockHeader>Filtros</BlockHeader>
            <BlockBody>
              <div className="form-group">
                <label className="form-label">Período de Análise</label>
                <DateRange
                  className="date-block"
                  editableDateInputs={true}
                  onChange={(item) => handleDate(item)}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  locale={ptBR}
                  endDatePlaceholder="Até"
                  startDatePlaceholder="De"
                  dateDisplayFormat="dd/MM/yyyy"
                  color="#009870"
                  weekdayDisplayFormat="eeeee"
                  rangeColors={["#009870"]}
                  disabled={loading}
                />
              </div>
              <Input
                item={input}
                setItem={setInput}
                params="core"
                type="select"
                placeholder="Todas"
                label="Núcleo"
                onChange={(e) => handleCore(e)}
                options={cores.map((item) => ({
                  value: item,
                }))}
              />
              <Input
                item={input}
                setItem={setInput}
                params="lotes"
                type="selectItems"
                label="Lote"
                placeholder="Selecione os lotes"
                items={input.lotes}
                options={
                  input.core
                    ? lotes
                        .filter(({ core }) => core === input.core)
                        .map(({ lote }) => ({
                          value: lote,
                        }))
                    : []
                }
              />
              <SliderContent className="form-group">
                <label className="form-label">Idade do lote(Semanas)</label>
                <Slider
                  value={input.age}
                  onChange={(e, newValue) =>
                    setInput((prevState) => {
                      const newState = JSON.parse(JSON.stringify(prevState));
                      newState.age = newValue;
                      return newState;
                    })
                  }
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={23}
                  max={66}
                />
                <SliderContentInput>
                  <Input
                    type="inputOnly"
                    className="form-control "
                    placeholder="De"
                    item={input}
                    params="age.0"
                    setItem={setInput}
                  />
                  <Input
                    type="inputOnly"
                    className="form-control "
                    placeholder="Até"
                    item={input}
                    params="age.1"
                    setItem={setInput}
                  />
                </SliderContentInput>
              </SliderContent>
              <Input
                item={heavyBroilerInput}
                onChange={(e) => heavyBroilerInputHandler(e.target.value)}
                type="select"
                setItem={setHeavyBroilerInput}
                placeholder="Selecione as opções"
                label="Histórico de programa"
                options={heavyBroiler
                  .filter(
                    ({ value }) => input.heavyBroilerList.indexOf(value) === -1
                  )
                  .map((item) => item)}
              />
              <HistoricOptionContent>
                {input.heavyBroilerList.map((item, index) => (
                  <HistoricOption key={item}>
                    {
                      heavyBroiler.filter(({ value }) => value === item)[0]
                        .label
                    }
                    <Icons
                      type="close"
                      size={10}
                      color="#fff"
                      onClick={() =>
                        setInput((prevState) => {
                          const nextState = JSON.parse(
                            JSON.stringify(prevState)
                          );
                          nextState.heavyBroilerList.splice(index, 1);
                          return nextState;
                        })
                      }
                    ></Icons>
                  </HistoricOption>
                ))}
              </HistoricOptionContent>
              <Input
                type="select"
                label="Tipo de Lesão(Necropsia)"
                item={input}
                setItem={setInput}
                params="injuryType"
                placeholder="Selecione a Lesão"
                options={[
                  { value: "digestiveSystem", label: "Sistema Digestório" },
                  { value: "reproductiveSystem", label: "Sistema Reprodutivo" },
                  { value: "respiratorySystem", label: "Sistema Respiratório" },
                  { value: "immuneSystem", label: "Sistema Imunológico" },
                  { value: "integument", label: "Tegumentar" },
                ]}
                disabled={loading}
              />
              <Separator></Separator>
              <ReportButton
                color="white"
                bg="default"
                border="default"
                disabled={loading || !input.core || !input.lotes[0]}
                onClick={reportHandler}
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#ffffff"
                    height={19}
                    width={19}
                  />
                ) : (
                  <>
                    <i className="fas fa-cogs" /> Gerar Relatório
                  </>
                )}
              </ReportButton>
            </BlockBody>
          </Block>
        </Col>
        <Col size={3}>
          {responseReport.feces &&
          !loading &&
          props.match.params.query !== "{}" ? (
            <Block id="feces">
              <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
                <h3 className="kt-portlet__head-title">
                  Qualidade de Excretas
                  {responseReport.customer
                    ? ` | ${responseReport.customer}`
                    : ""}
                </h3>
              </BlockHeader>
              <BlockBody> {createBoxInjuries()}</BlockBody>
            </Block>
          ) : null}
        </Col>
      </Row>
      {responseReport.ages && !loading && props.match.params.query !== "{}" ? (
        <Row>
          <Col>
            <Block id="graphEgg">
              <BlockHeader>Qualidade de Ovos</BlockHeader>
              <BlockBody>
                <Bar
                  data={getEggQualityReportData()}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "top",
                    },
                    showAllTooltips: true,
                    tooltips: {
                      callbacks: {
                        footer: function (tooltipItem, data) {
                          /* const key = Object.keys(responseReport.eggQuality)
                            .filter((key) => key !== "weight")
                            .map((key) => key)[tooltipItem[0].datasetIndex - 1]; */
                          return "";
                        },
                      },
                    },
                  }}
                />
                <Separator />
                {createBoxEggQuality()}
              </BlockBody>
            </Block>
          </Col>
          <Col>
            <Block id="graphProduction">
              <BlockHeader>Produção</BlockHeader>
              <BlockBody>
                <Bar
                  data={getProductionReportData()}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "top",
                    },
                    showAllTooltips: true,
                  }}
                />
                <Separator />
                {createBoxProduction()}
              </BlockBody>
            </Block>
          </Col>
        </Row>
      ) : null}
      {responseReport.ages && !loading && props.match.params.query !== "{}" ? (
        <Block id="graph">
          <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
            Score Pré Necrópsia
          </BlockHeader>
          <BlockBody>
            <BlockBodyContent>
              <Bar
                data={getReportData()}
                options={{
                  labels: getReportlabels(),

                  elements: {
                    line: {
                      fill: false,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        gridLines: {
                          display: false,
                        },

                        labels: getReportlabels(),
                      },
                    ],
                    yAxes: [
                      {
                        type: "linear",
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        gridLines: {
                          display: false,
                        },
                        labels: {
                          show: true,
                        },
                        ticks: {
                          beginAtZero: true,
                          max: 10,
                        },
                      },
                      {
                        type: "linear",
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        gridLines: {
                          display: false,
                        },
                        labels: {
                          show: true,
                        },
                        ticks: {
                          beginAtZero: true,
                          max: 10,
                        },
                      },
                    ],
                  },
                  legend: {
                    position: "right",
                  },
                  showAllTooltips: true,
                }}
              />
            </BlockBodyContent>
          </BlockBody>
        </Block>
      ) : null}
      {responseReport.historic &&
      props.match.params.query !== "{}" &&
      JSON.parse(props.match.params.query).heavyBroilerList[0] ? (
        <HistoricTable
          historic={responseReport.historic}
          heavyBroiler={heavyBroiler}
          heavyBroilerList={
            JSON.parse(props.match.params.query).heavyBroilerList
          }
        />
      ) : null}
      {responseReport.ages && props.match.params.query !== "{}" ? (
        <HistoricTreatment
          lotesPerTreatment={responseReport.lotesPerTreatment}
          ages={responseReport.ages}
        />
      ) : null}
      {responseReport.ages &&
      responseReport.necropsy[0] &&
      !loading &&
      props.match.params.query !== "{}" ? (
        <Block id="graphNecropsy">
          <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/demo4-bg-footer.jpg">
            Score Necrópsia
          </BlockHeader>
          <BlockBody>
            <BlockBodyContent>
              <Bar
                data={getReportNecropsyData()}
                options={{
                  labels: getReportlabels(),

                  elements: {
                    line: {
                      fill: false,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        gridLines: {
                          display: false,
                        },

                        labels: getReportlabels(),
                      },
                    ],
                    yAxes: [
                      {
                        type: "linear",
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        gridLines: {
                          display: false,
                        },
                        labels: {
                          show: true,
                        },
                        ticks: {
                          beginAtZero: true,
                          max: 10,
                        },
                      },
                      {
                        type: "linear",
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        gridLines: {
                          display: false,
                        },
                        labels: {
                          show: true,
                        },
                        ticks: {
                          beginAtZero: true,
                          max: 10,
                        },
                      },
                    ],
                  },
                  legend: {
                    position: "right",
                  },
                  showAllTooltips: true,
                }}
              />
            </BlockBodyContent>
          </BlockBody>
        </Block>
      ) : null}
    </>
  );
}
