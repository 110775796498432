import { necropsyConfig } from "utils/globalData";
import { BoxScore } from "./BoxScore";
import { Fragment } from "react";
import { Block, BlockBody, BlockHeader, Col, Row } from "styles";
import { format } from "date-fns";

export function BoxScores({ query, responseReport, dateRange }) {
  const createBoxScores = () => {
    const indexInjury = JSON.parse(query).injuryType.indexOf("digestive");
    const injuryType = JSON.parse(query).injuryType[indexInjury];
    const keys = Object.keys(necropsyConfig[injuryType]);
    let columns = [
      <BoxScore
        params={`${injuryType}.totalScore`}
        response={responseReport}
        index={indexInjury}
      />,
    ];
    const rows = [];
    keys
      .filter((key) => necropsyConfig[injuryType][key].report)
      .forEach((key, index) => {
        columns.push(
          <Fragment key={key}>
            <BoxScore
              params={`${injuryType}.${key}`}
              response={responseReport}
              index={indexInjury}
            />
          </Fragment>
        );
        if (
          columns.length === 4 ||
          index ===
            keys.filter((key) => necropsyConfig[injuryType][key].report)
              .length -
              1
        ) {
          for (let i = columns.length; i < 4; i++) {
            columns.push(<Col key={`${key}${i}`}></Col>);
          }
          rows.push(<Row key={`row${key}`}>{columns}</Row>);
          columns = [];
        }
      });

    return rows;
  };
  return (
    <Block id="score">
      <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
        Score por Lesão: {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
        {format(dateRange[0].endDate, "dd-MM-yyyy")}
        {responseReport.customer ? ` | ${responseReport.customer}` : ""}
      </BlockHeader>
      <BlockBody>{createBoxScores()}</BlockBody>
    </Block>
  );
}
