import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Table } from "../Table";
import ReactLoading from "react-loading";

export function PriorInspection(props) {
  const [data, setData] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
    11: "",
    12: "",
    13: "",
    14: "",
    15: "",
    16: "",
    17: "",
    18: "",
    20: "",
    21: "",
    22: "",
    23: "",
    description: "",
  });

  const [loading, setLoading] = useState(true);

  const [getData, setGetData] = useState(true);

  const saveData = useCallback(async () => {
    const query = {
      data,
      termType: props.show,
      factory: props.match.params.id,
    };

    const response = await Swal.fire({
      title: "Criar Termo",
      text: "Deseja confirmar criação da Termo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Criar Avaliação!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .post(`inspectiontermbpf/${props.match.params.id}`, query)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Criar Termo",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setData((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setUpdateList(true);
    }

    /*  if (!props.id) {
      const response = await Swal.fire({
        title: "Criar Termo",
        text: "Deseja confirmar criação da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Avaliação!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post(`inspectiontermbpf/${props.match.params.id}`, query)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setData((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState._id = response.value.data.id;
          return newState;
        });
        props.setUpdateList(true);
      }
    } else {
      query._id = props.id;
      const response = await Swal.fire({
        title: "Editar Termo",
        text: "Deseja confirmar edição da Termo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Termo!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put(
              `inspectiontermbpf/${props.match.params.id}/${props.id}`,
              query
            )
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Termo",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        props.setUpdateList(true);
      }
    } */
  }, [data, props]);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `inspectiontermbpf/${props.match.params.id}/${props.id}`
        );
        if (!response.data) {
          Swal.fire("Editar Termo", "Não foi possível buscar a Termo", "error");
        }
        setData(response.data.data);
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Anexo II - Modelo de Termo de Fiscalização Prévia ao Registro</h2>
        </>
      }
      footer={
        <Button color="white" bg="default" border="default" onClick={saveData}>
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Table
            module={data}
            setModule={setData}
            title="ÁREA EXTERNA"
            body={[
              [
                "",
                "N",
                "1.",
                "Ausência de focos de contaminação para atividade pretendida.",
                "1",
              ],
              [
                "",
                "N",
                "2.",
                "O perímetro do pátio fabril é cercado de modo a evitar a entrada de animais ou pessoas estranhas e os acessos são controlados",
                "2",
              ],
              [
                "",
                "N",
                "3.",
                "Pátio industrial resistente ao trânsito sobre rodas, com escoamento adequado, que permita sua limpeza e evite a formação de poeira.",
                "3",
              ],
              [
                "",
                "N",
                "4.",
                "Existe área para a estocagem de resíduos, afastada da área de produção, em bom estado de conservação e limpeza, dotada de cobertura e de dispositivos que a mantenha livre de pragas.",
                "4",
              ],
            ]}
          />
          <Table
            module={data}
            setModule={setData}
            title="ÁREA INTERNA"
            body={[
              [
                "",
                "N",
                "5.",
                "Plantas baixas e de fluxos apresentadas junto à solicitação são condizentes com a estrutura avaliada.",
                "5",
              ],
              [
                "",
                "N",
                "6.",
                "Pisos de material que permite fácil e apropriada limpeza e em bom estado de conservação.",
                "6",
              ],
              [
                "",
                "N",
                "7.",
                "Drenos ou ralos ou grelhas com sistema de fechamento ou sifonados colocados em locais estratégicos de forma a facilitar o escoamento, quando couber.",
                "7",
              ],
              [
                "",
                "N",
                "8.",
                "Tetos de material que permite fácil limpeza e em bom estado de conservação.",
                "8",
              ],
              [
                "",
                "N",
                "9.",
                "Paredes de material que permite fácil e apropriada limpeza e em bom estado de conservação.",
                "9",
              ],
              [
                "",
                "N",
                "10.",
                "Portas ajustadas aos batentes e aos pisos, de material que permite fácil e apropriada limpeza, em bom estado de conservação. Com dispositivos que impeçam a entrada de pragas e pessoas não autorizadas.",
                "10",
              ],
              [
                "",
                "N",
                "11.",
                "Janelas e outras aberturas de material que permite fácil e apropriada limpeza e em bom estado de conservação, ajustadas aos batentes. As que comunicam com o exterior devem ser providas de proteção adequada contra pragas. As proteções devem ser de fácil limpeza.",
                "11",
              ],
              [
                "",
                "N",
                "12.",
                "Luminárias adequadas e em bom estado de limpeza e conservação, com proteção contra o estilhaçamento, quando couber.",
                "12",
              ],
              [
                "",
                "O",
                "13.",
                "Instalações adequadas à quantidade e tipos de produtos.",
                "13",
              ],
              [
                "",
                "O",
                "14.",
                "Os locais destinados à recepção de ingredientes e matérias-primas e expedição de produtos acabados são cobertos e protegidos contra pragas ou possuem sistemas fechados que impeçam a contaminação.",
                "14",
              ],
              [
                "",
                "N",
                "15.",
                "Lavatórios adequados nas áreas de produção, quando necessário.",
                "15",
              ],
              [
                "",
                "N",
                "16.",
                "Existência de lavatórios de utensílios em local adequado, em boas condições de limpeza e conservação.",
                "16",
              ],
              [
                "",
                "N",
                "17.",
                "No interior do estabelecimento há recipientes, de fácil limpeza e transporte, devidamente identificados e limpos para o descarte de resíduos.",
                "17",
              ],
              [
                "",
                "N",
                "18.",
                "Instalações sanitárias e vestiários para os funcionários atendem aos requisitos de Boas Práticas de Fabricação.",
                "18",
              ],
              [
                "",
                "N",
                "19.",
                "Utensílios: de material não contaminante, resistentes à corrosão, de tamanho e forma que permitam fácil limpeza, em bom estado de conservação e apropriados ao tipo de operação realizada.",
                "19",
              ],
              [
                "",
                "O",
                "20.",
                "Existem áreas adequadas para armazenamento de acordo com a natureza das diferentes matérias-primas de modo a manter suas características originais ou evitar que se tornem fontes de contaminação.",
                "20",
              ],
              [
                "",
                "O",
                "20.",
                "Equipamentos da linha de produção são compatíveis e em número adequado à atividade, e em boas condições de higiene e conservação.",
                "20",
              ],
              [
                "",
                "O",
                "22.",
                "Equipamentos destinados à conservação dos produtos, possuem medidores de temperatura calibrados e localizados em local apropriado.",
                "22",
              ],
              [
                "",
                "O",
                "23.",
                "Equipamentos destinados ao processamento térmico, com medidores de parâmetros (tais como temperatura, pressão ou umidade) calibrados e localizados em local apropriado.",
                "23",
              ],
            ]}
          />
          <Input
            type="textarea"
            item={data}
            setItem={setData}
            params="description"
            label="DESCREVER AS NÃO CONFORMIDADES:"
            rows={20}
          />
        </>
      )}
    </Modal>
  );
}
