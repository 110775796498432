import { useEffect, useMemo, useState } from "react";
import isMobile from "is-mobile";
import { api } from "services/api";
import { Input } from "components/Input";
import { Card, CardBody, CardHeader, Col, Row } from "styles";
import { Body, Header, Title } from "components/Table/style";
import { Table } from "./styled";

export function Customer({
  slaughter,
  setSlaughter,
  loadHistoric,
  setLoadHistoric,
  disableCustomer,
}) {
  const initialFarmData = useMemo(
    () => ({
      _id: "",
      customer: "",
      minerals: {
        organics: {
          pre: {
            product: "",
            reason: "",
          },
          initial: {
            product: "",
            reason: "",
          },
          growth: {
            product: "",
            reason: "",
          },
          growth2: {
            product: "",
            reason: "",
          },
          final: {
            product: "",
            reason: "",
          },
        },
        zincOxide: {
          pre: {
            checked: "",
            ppm: "",
          },
          initial: {
            checked: "",
            ppm: "",
          },
          growth: {
            checked: "",
            ppm: "",
          },
          growth2: {
            checked: "",
            ppm: "",
          },
          final: {
            checked: "",
            ppm: "",
          },
        },
        copperSulfate: {
          pre: {
            checked: "",
            ppm: "",
          },
          initial: {
            checked: "",
            ppm: "",
          },
          growth: {
            checked: "",
            ppm: "",
          },
          growth2: {
            checked: "",
            ppm: "",
          },
          final: {
            checked: "",
            ppm: "",
          },
        },
      },
    }),
    []
  );
  const [farmData, setFarmData] = useState(initialFarmData);

  useEffect(() => {
    const getCustomerHistoric = async () => {
      const response = await api.get(
        `broilerslaughter/historic/${slaughter.customer._id}`
      );
      if (!response.data) {
        response.data = {
          customer: slaughter.customer._id,
        };
      }
      setFarmData(response.data);
    };
    if (
      (slaughter.customer && slaughter.customer._id !== farmData.customer) ||
      (loadHistoric && slaughter.customer)
    ) {
      setLoadHistoric(false);
      getCustomerHistoric();
    } else if (!slaughter.customer && farmData.customer) {
      setFarmData(initialFarmData);
    }
  }, [
    slaughter.customer,
    farmData.customer,
    initialFarmData,
    setLoadHistoric,
    loadHistoric,
  ]);

  return (
    <>
      {!disableCustomer && (
        <Row>
          <Col>
            <Input
              item={slaughter}
              setItem={setSlaughter}
              params="customer"
              type="autocomplete"
              disabled={disableCustomer}
              label="Cliente"
              paramsGet={["code", "name"]}
              paramsLabel={["code", "name", "state", "city"]}
              select="code name state city"
              model="customer"
              placeholder="Digite o nome ou o código do cliente e selecione"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Title>
            Programa em Andamento{" "}
            {disableCustomer ? ` - ${slaughter.customer.name}` : ""}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <span>Minerais</span>
            </CardHeader>
            <CardBody>
              <Table>
                <Header>
                  <tr>
                    <th style={{ width: isMobile() ? "50px" : "130px" }}>
                      Fase
                    </th>
                    {isMobile() ? (
                      <th className="text-primary">Programa</th>
                    ) : (
                      <>
                        <th className="text-primary">Minerais Orgânicos</th>

                        <th className="text-dark">Oxido de Zinco - PPM</th>

                        <th className="text-info">Sulfato de Cobre - PPM</th>
                      </>
                    )}
                  </tr>
                </Header>
                <Body>
                  <tr>
                    <td>Pré</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Minerais Orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.minerals?.organics?.pre?.product}
                          </span>
                          <span className="text-dark mt-1">
                            <strong>Oxido de Zinco</strong>
                          </span>
                          <span className="text-dark">
                            {farmData?.minerals?.zincOxide?.pre?.ppm}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Sulfato de Cobre</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.minerals?.copperSulfate?.pre?.ppm}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.minerals?.organics?.pre?.product}
                        </td>

                        <td className="text-dark">
                          {farmData?.minerals?.zincOxide?.pre?.ppm}
                        </td>

                        <td className="text-info">
                          {farmData?.minerals?.copperSulfate?.pre?.ppm}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Inicial</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Minerais Orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.minerals?.organics?.initial?.product}
                          </span>
                          <span className="text-dark mt-1">
                            <strong>Oxido de Zinco</strong>
                          </span>
                          <span className="text-dark">
                            {farmData?.minerals?.zincOxide?.initial?.ppm}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Sulfato de Cobre</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.minerals?.copperSulfate?.initial?.ppm}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.minerals?.organics?.initial?.product}
                        </td>

                        <td className="text-dark">
                          {farmData?.minerals?.zincOxide?.initial?.ppm}
                        </td>

                        <td className="text-info">
                          {farmData?.minerals?.copperSulfate?.initial?.ppm}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cres.1" : "Crescimento 1"}</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Minerais Orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.minerals?.organics?.growth?.product}
                          </span>
                          <span className="text-dark mt-1">
                            <strong>Oxido de Zinco</strong>
                          </span>
                          <span className="text-dark">
                            {farmData?.minerals?.zincOxide?.growth?.ppm}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Sulfato de Cobre</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.minerals?.copperSulfate?.growth?.ppm}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.minerals?.organics?.growth?.product}
                        </td>

                        <td className="text-dark">
                          {farmData?.minerals?.zincOxide?.growth?.ppm}
                        </td>

                        <td className="text-info">
                          {farmData?.minerals?.copperSulfate?.growth?.ppm}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{isMobile() ? "Cresc.2" : "Crescimento 2"}</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Minerais Orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.minerals?.organics?.growth2?.reason}
                          </span>
                          <span className="text-dark mt-1">
                            <strong>Oxido de Zinco</strong>
                          </span>
                          <span className="text-dark">
                            {farmData?.minerals?.zincOxide?.growth2?.ppm}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Sulfato de Cobre</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.minerals?.copperSulfate?.growth2?.ppm}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.minerals?.organics?.growth2?.product}
                        </td>

                        <td className="text-dark">
                          {farmData?.minerals?.zincOxide?.growth2?.ppm}
                        </td>

                        <td className="text-info">
                          {farmData?.minerals?.copperSulfate?.growth2?.ppm}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>Final</td>
                    {isMobile() ? (
                      <td>
                        <div>
                          <span className="text-primary">
                            <strong>Minerais Orgânicos</strong>
                          </span>
                          <span className="text-primary">
                            {farmData?.minerals?.organics?.final?.product}
                          </span>
                          <span className="text-dark mt-1">
                            <strong>Oxido de Zinco</strong>
                          </span>
                          <span className="text-dark">
                            {farmData?.minerals?.zincOxide?.final?.ppm}
                          </span>
                          <span className="text-info mt-1">
                            <strong>Sulfato de Cobre</strong>
                          </span>
                          <span className="text-info">
                            {farmData?.minerals?.copperSulfate?.final?.ppm}
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="text-primary">
                          {farmData?.minerals?.organics?.final?.product}
                        </td>

                        <td className="text-dark">
                          {farmData?.minerals?.zincOxide?.final?.ppm}
                        </td>

                        <td className="text-info">
                          {farmData?.minerals?.copperSulfate?.final?.ppm}
                        </td>
                      </>
                    )}
                  </tr>
                </Body>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
