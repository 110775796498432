import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { api } from "services/api";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import { ScoreBirds } from "./ScoreBirds";
import { ScoreTotal } from "./ScoreTotal";
import { Customer } from "./Customer";
import { FarmData } from "./FarmData";
import UpdateList from "contexts/updateList";
import {
  convertDateToString,
  convertStringToDate,
} from "utils/globalFunctions";
import { Container } from "./style";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";

export function CreateEdit(props) {
  const initialBird = useMemo(
    () => ({
      integumentary: {
        legPigmentation: "",
        callus: "",
        cornealInjury: "",
        oralInjury: "",
        muscleBleeding: "",
        yolkRetention: "",
        hydropericardium: "",
      },
      immunological: {
        thymusAtrophy: "",
        bursometry: "",
        fabriciusBagInjury: "",
        intestinalSuffusions: "",
        hemorrhagicTonsils: "",
        activePeyerPlate: "",
      },
      respiratory: {
        tracheitis: "",
        swollenHead: "",
        aerosaculite: "",
      },
      locomotor: {
        tibialDyschondroplasia: "",
        femurHeadNecrosis: "",
      },
      digestive: {
        bedIntake: "",
        gizzardErosion: "",
        pancreasAtrophy: "",
        duodenitis: "",
        liver: "",
        intestinalTone: "",
        WallThickness: "",
        eimeriaAcervulina: "",
        maximumEimeria: "",
        eimeriaTenella: "",
        fluidContent: "",
        mucusPresence: "",
        bilePresence: "",
        flatworms: "",
        turkishTowel: "",
        necroticEnteritis: "",
        foodPass: "",
        cellPelling: "",
        intestinalGas: "",
        hyperemia: "",
        cecalGas: "",
      },
      total: 10,
    }),
    []
  );

  const initialConfig = useMemo(
    () => ({
      integumentary: {
        legPigmentation: {
          label: "Pigmentação das Patas",
          style: "",
          options: [],
        },
        callus: {
          label: "Calo de Patas",
          style: "",
          options: [],
        },
        dermatosis: {
          label: "Dermatose",
          style: "",
          options: [],
        },
        cornealInjury: {
          label: "Lesão de córnea por amônia",
          style: { fontSize: "84%" },
          options: [],
        },
        oralInjury: {
          label: "Lesão oral",
          style: "",
          options: [],
        },
        muscleBleeding: {
          label: "Hemorragia muscular",
          style: "",
          options: [],
        },
        yolkRetention: {
          label: "Retenção de gema",
          style: "",
          options: [],
        },
        hydropericardium: {
          label: "Hidropericárdio",
          style: "",
          options: [],
        },
      },
      immunological: {
        thymusAtrophy: {
          label: "Atrofia de timo",
          style: "",
          options: [],
        },
        bursometry: {
          label: "Bursometria",
          style: "",
          options: [],
        },
        fabriciusBagInjury: {
          label: "Lesão de Bolsa de Fabrícius",
          style: { fontSize: "84%" },
          options: [],
        },
        intestinalSuffusions: {
          label: "Sufusões Intestinais",
          style: "",
          options: [],
        },
        hemorrhagicTonsils: {
          label: "Tonsilas Hemorragicas",
          style: "",
          options: [],
        },
        activePeyerPlate: {
          label: "Placa de Peyer Ativa",
          style: "",
          options: [],
        },
      },
      respiratory: {
        tracheitis: {
          label: "Traqueíte",
          style: "",
          options: [],
        },
        swollenHead: {
          label: "Cabeça Inchada",
          style: "",
          options: [],
        },
        aerosaculite: {
          label: "Aerossaculite",
          style: "",
          options: [],
        },
      },
      locomotor: {
        tibialDyschondroplasia: {
          label: "Discondroplasia Tibial",
          style: "",
          options: [],
        },
        femurHeadNecrosis: {
          label: "Necrose Cabeça Fêmur",
          style: { fontSize: "90%" },
          options: [],
        },
      },
      digestive: {
        bedIntake: {
          label: "Ingestão de Cama",
          style: "",
          options: [],
        },
        gizzardErosion: {
          label: "Erosão de Moela",
          style: "",
          options: [],
        },
        pancreasAtrophy: {
          label: "Atrofia de Pâncreas",
          style: "",
          options: [],
        },
        duodenitis: {
          label: "Duodenite",
          style: "",
          options: [],
        },
        liver: {
          label: "Fígado",
          style: "",
          options: [],
        },
        intestinalTone: {
          label: "Tônus Intestinal",
          style: "",
          options: [],
        },
        WallThickness: {
          label: "Espessura da Parede",
          style: "",
          options: [],
        },
        eimeriaAcervulina: {
          label: "Eimeria de Acervulina",
          style: "",
          options: [],
        },
        maximumEimeria: {
          label: "Eimeria Máxima",
          style: "",
          options: [],
        },
        eimeriaTenella: {
          label: "Eimeria Tenella",
          style: "",
          options: [],
        },
        fluidContent: {
          label: "Conteúdo Fluido",
          style: "",
          options: [],
        },
        mucusPresence: {
          label: "Presença de Muco",
          style: "",
          options: [],
        },
        bilePresence: {
          label: "Presença de Bile",
          style: "",
          options: [],
        },
        flatworms: {
          label: "Vermes Chatos",
          style: "",
          options: [],
        },
        turkishTowel: {
          label: "TGI Abalonado",
          style: "",
          options: [],
        },
        necroticEnteritis: {
          label: "Enterite Necrótica",
          style: "",
          options: [],
        },
        foodPass: {
          label: "Passagem de Alimento",
          style: "",
          options: [],
        },
        cellPelling: {
          label: "Descamação Celular",
          style: "",
          options: [],
        },
        intestinalGas: {
          label: "Gás Intestinal",
          style: "",
          options: [],
        },
        hyperemia: {
          label: "Hiperemia (Vermelhidão)",
          style: { fontSize: "90%" },
          options: [],
        },
        cecalGas: {
          label: "Gás Cecal",
          style: "",
          options: [],
        },
      },
    }),
    []
  );

  const requiredParams = useMemo(
    () => ({
      customer: "Selecione o Cliente",
      visitDate: "Digite a Data da Visita",
      technicalManager: "Digite o Responsável Técnico",
      lineage: "Selecione a Linhagem",
      age: "Digite a Idade do Lote",
      gender: "Selecione o Sexo",
      accommodatingDate: "Digite a Data do Alojamento",
    }),
    []
  );

  const { setUpdateList } = useContext(UpdateList);

  const [necropsy, setNecropsy] = useState({
    _id: "",
    customer: props.customer || null,
    visitDate: "",
    integrated: "",
    nutrition: "",
    lotNumber: "",
    bedNumber: "",
    lineage: "",
    age: "",
    incubatory: "",
    technicalManager: null,
    averageWeight: "",
    ageOfWeight: "",
    weight0: "",
    weight1: "",
    weight2: "",
    weight3: "",
    weight4: "",
    weight5: "",
    weight6: "",
    interval: "",
    density: "",
    mortality: "",
    gender: "",
    accommodatingDate: "",
    gumboro: "",
    bronquite: "",
    birds: [
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
      JSON.parse(JSON.stringify(initialBird)),
    ],
  });

  const [loadHistoric, setLoadHistoric] = useState(false);

  const [loading, setLoading] = useState(true);

  const [birds, setBirds] = useState(5);

  const [getNecropsy, setGetNecropsy] = useState(true);

  const [config, setConfig] = useState(initialConfig);

  const [tab, setTab] = useState(0);

  const [nutritionList, setNutritionList] = useState([]);

  const getProps = useCallback(
    (prop) => ({ prop, params: Object.keys(config[prop]) }),
    [config]
  );

  const sumBirds = useCallback(
    (copyNecropsy) => {
      copyNecropsy.birds.forEach((bird) => {
        let sum = 0;
        Object.keys(bird).forEach((prop) => {
          if (prop !== "total") {
            Object.keys(bird[prop]).forEach((param) => {
              if (prop !== "_id" && bird[prop][param]) {
                const option = config[prop][param].options.find(
                  (item) => item.score === bird[prop][param]
                );
                if (option && option.points) {
                  sum += +option.points;
                }
              }
            });
          }
        });
        bird.total = 10 + sum;
      });
      setNecropsy(JSON.parse(JSON.stringify(copyNecropsy)));
    },
    [config]
  );

  const changeBirds = useCallback(
    async (e) => {
      const value = +e.target.value;
      const necropsyCopy = JSON.parse(JSON.stringify(necropsy));
      if (necropsy.birds.length < value) {
        for (let i = necropsy.birds.length; i < value; i++) {
          necropsyCopy.birds.push(JSON.parse(JSON.stringify(initialBird)));
        }
      } else {
        const lostBirds = [];
        for (let i = value; i < necropsy.birds.length; i++) {
          lostBirds.push(i + 1);
        }
        const response = await Swal.fire({
          title: "Diminuir número de Aves",
          text: `Os dados da${lostBirds[1] ? "s" : ""} ave${
            lostBirds[1] ? "s" : ""
          } ${lostBirds.reduce(
            (text, value, index) =>
              `${text}${index === lostBirds.length - 1 ? " e" : ","} ${value}`
          )} serão perdidos, deseja continuar`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          preConfirm: () => true,
        });
        if (response.dismiss) return;
        for (let i = necropsy.birds.length; i > value; i--) {
          necropsyCopy.birds.splice(i - 1, 1);
        }
      }
      setBirds(value);
      setNecropsy(necropsyCopy);
    },
    [initialBird, necropsy]
  );

  const saveNecropsy = useCallback(
    async (e) => {
      const requestNecropsy = JSON.parse(JSON.stringify(necropsy));
      for (let param in requiredParams) {
        if (!requestNecropsy[param]) {
          return Swal.fire(
            requiredParams[param],
            "Preencha os campos Obrigatórios",
            "error"
          );
        }
      }
      requestNecropsy.birds = requestNecropsy.birds.map((item) => ({
        ...item,
        total: item.total < 0 ? 0 : item.total,
      }));

      if (requestNecropsy.visitDate) {
        requestNecropsy.visitDate = convertStringToDate(
          requestNecropsy.visitDate
        );
      }
      if (requestNecropsy.accommodatingDate) {
        requestNecropsy.accommodatingDate = convertStringToDate(
          requestNecropsy.accommodatingDate
        );
      }
      if (!requestNecropsy._id) {
        const response = await Swal.fire({
          title: "Salvar Necrópsia",
          text: "Deseja confirmar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Salvar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api.post("broilernecropsy", requestNecropsy).catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
        });
        if (response.value) {
          Swal.fire(
            "Salvar Necrópsia",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          if (!response.value.err) {
            requestNecropsy._id = response.value.data.id;
            setNecropsy(requestNecropsy);
          }
          setUpdateList(true);
        }
      } else {
        const response = await Swal.fire({
          title: "Editar Necrópsia",
          text: "Deseja confirmar edição?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Editar!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .put(
                "broilernecropsy/" +
                  requestNecropsy._id +
                  "/" +
                  props.customer._id,
                requestNecropsy
              )
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });

        if (response.value) {
          Swal.fire(
            "Editar Necrópsia",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          setUpdateList(true);
        }
      }
    },
    [necropsy, setUpdateList, requiredParams, props.customer]
  );

  useEffect(() => {
    const loadNecropsy = async () => {
      setLoading(true);
      const response = await api.get("broilernecropsy/config");
      if (response.data) {
        const copyConfig = JSON.parse(JSON.stringify(config));
        response.data
          .filter(({ key }) => !!key)
          .forEach((item) => {
            const keys = item.key.split(".");
            if (copyConfig[keys[0]] && copyConfig[keys[0]][keys[1]]) {
              copyConfig[keys[0]][keys[1]].options.push({ ...item });
            }
          });
        setConfig(copyConfig);
      }
      const responseNutritionList = await api.get("necropsyconfiglists");
      setNutritionList(
        responseNutritionList.data.filter(({ key }) => key === "nutrition")
      );
      if (props.id) {
        const responseNecropsy = await api.get(
          `broilernecropsy/${props.id}/${
            props.match.params.id || props.customer
          }`
        );
        if (responseNecropsy.data) {
          if (responseNecropsy.data.visitDate) {
            responseNecropsy.data.visitDate = convertDateToString(
              responseNecropsy.data.visitDate
            );
          }
          setNecropsy(JSON.parse(JSON.stringify(responseNecropsy.data)));
          setBirds(responseNecropsy.data.birds.length);
          setLoadHistoric(true);
        }
      }
      setLoading(false);
    };
    if (getNecropsy) {
      setGetNecropsy(false);
      loadNecropsy();
    }
  }, [getNecropsy, necropsy, props, config]);

  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="necropsy" color="#009870" size={30} />
          <h2>Necropsia</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveNecropsy}
        >
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          {" "}
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Cliente" />
            <Tab label="Dados da Granja" />
            <Tab label="Tegumentar" />
            <Tab label="Imunológico" />
            <Tab label="Respiratório" />
            <Tab label="Locomotor" />
            <Tab label="Digestório" />
            <Tab label="Score Total" />
          </Tabs>
          <Container>
            {tab === 0 && (
              <Customer
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                loadHistoric={loadHistoric}
                disableCustomer={props.disableCustomer}
                setLoadHistoric={setLoadHistoric}
              />
            )}
            {tab === 1 && (
              <FarmData
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                birds={birds}
                changeBirds={changeBirds}
                nutritionList={nutritionList}
              />
            )}
            {tab === 2 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.integumentary}
                {...getProps("integumentary")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 3 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.immunological}
                {...getProps("immunological")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 4 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.respiratory}
                {...getProps("respiratory")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 5 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.locomotor}
                {...getProps("locomotor")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 6 && (
              <ScoreBirds
                necropsy={necropsy}
                setNecropsy={setNecropsy}
                config={config.digestive}
                {...getProps("digestive")}
                sumBirds={sumBirds}
              />
            )}
            {tab === 7 && (
              <ScoreTotal necropsy={necropsy} setNecropsy={setNecropsy} />
            )}
          </Container>
        </>
      )}
    </Modal>
  );
}
