import { Icons } from "components/Icons";
import { SubHeader } from "components/SubHeader";
import { Table } from "components/Table";

export function List(props) {
  const tableParams = [
    {
      key: "code",
      label: "Código",
      width: 250,
      filter: "text",
      placeholder: "Digite o Código",
      left: true,
      mobile: {
        type: "title",
      },
    },
    {
      key: "name",
      label: "Nome",
      filter: "text",
      placeholder: "Digite o Nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "email",
      label: "E-mail",
      filter: "text",
      placeholder: "Digite o E-mail",
      mobile: {
        type: "item",
      },
    },
    {
      key: "cnpj",
      label: "CNPJ",
      filter: "text",
      placeholder: "Digite o CNPJ",
      mask: "99.999.999/9999-99",
      mobile: {
        type: "small",
        label: true,
      },
    },
    {
      key: "city",
      label: "Cidade",
      filter: "text",
      placeholder: "Digite a Cidade",
      mobile: {
        type: "item",
      },
    },
    {
      key: "state",
      label: "Estado",
      filter: "text",
      placeholder: "Digite o Estado",
      mobile: {
        type: "item",
      },
    },

    {
      key: "status",
      type: "status",
      label: "Status",
      width: 150,
      filter: "status",
      mobile: {
        type: "status",
      },
    },
  ];

  return (
    <>
      <SubHeader
        {...props}
        title="Clientes"
        toolbar={["exportPDF"]}
        route="customer"
        newLabel="Novo Cliente"
        newLink="/basicregistration/customer/create"
        otherButtons={[]}
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Clientes",
          },
        ]}
        icon={<Icons type="customer" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editLink={"/basicregistration/customer/edit/"}
        emptyText={"Nenhum cliente localizado!"}
        route={"customer"}
        {...props}
      />
    </>
  );
}
