import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function Prebiotics(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th style={{ width: "185px" }}>
            <span></span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th>
            <span>Qual Finalidade?</span>
          </th>
          <th>
            <span>Qual Dose?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Pré</td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.pre.product"
              item={props.broiler}
              setItem={props.setBroiler}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.pre.reason"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.pre.dose"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Inicial</td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.initial.product"
              item={props.broiler}
              setItem={props.setBroiler}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.initial.reason"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.initial.dose"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Crescimento 1</td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.growth.product"
              item={props.broiler}
              setItem={props.setBroiler}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.growth.reason"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.growth.dose"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
        <tr>
          <td>Crescimento 2</td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.growth2.product"
              item={props.broiler}
              setItem={props.setBroiler}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.growth2.reason"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.growth2.dose"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>

        <tr>
          <td>Final</td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.final.product"
              item={props.broiler}
              setItem={props.setBroiler}
              placeholder="Digite o Produto"
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.final.reason"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
          <td>
            <Input
              type="inputOnly"
              params="additions.prebiotics.final.dose"
              item={props.broiler}
              setItem={props.setBroiler}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
