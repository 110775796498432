import React, { useState, useEffect } from "react";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Table } from "components/Table";
import { api } from "services/api";
import { Button } from "components/Button";

export function List(props) {
  const tableParams = [
    {
      key: "customer",
      params: "name",
      label: "Cliente",
      mobile: {
        type: "title",
      },
    },
    {
      key: "createdBy",
      params: "name",
      label: "Criado por",
      filter: "text",
      placeholder: "Nome",
      mobile: {
        type: "item",
      },
    },
    {
      key: "date",
      label: "Data",
      type: "date",
      filter: "date",
      mobile: {
        type: "small",
      },
    },
    {
      key: "reportId",
      label: "PDF",
      type: "custom",
      mobile: {
        type: "small",
      },
      response: ({ customer, reportId }) => (
        <Button
          bg="danger"
          color="white"
          border="danger"
          size="sm"
          href={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/report-slaughter-${customer._id}-${reportId}.pdf`}
          target="_blank"
        >
          <Icons type="download" size="14" color="#fff" />
        </Button>
      ),
    },
  ];

  const [customerName, setCustomerName] = useState("");

  const editHandler = (item) => {
    props.history.push(item.url + "/" + item._id);
  };

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
    };
    if (props.match.params.id) {
      getCustomerName();
    }
  }, [props.match.params.id]);

  return (
    <>
      <SubHeader
        {...props}
        title={customerName}
        route="reportbroilerslaughter"
        breadcrumbs={[
          {
            label: customerName,
            to: "/connect/customer/" + props.match.params.id,
          },
          {
            label: "Frango de Corte",
          },
          {
            label: "Abate",
          },
          {
            label: "Relatórios",
          },
        ]}
        icon={<Icons type="lineChart" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editHandler={editHandler}
        emptyText={"Nenhuma relatório localizada!"}
        route={`/connect/reportbroilerslaughter`}
        {...props}
      />
    </>
  );
}
