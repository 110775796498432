import styled from "styled-components";

export const Container = styled.div`
  background-image: url("https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_connect.jpg");
  flex: none;
  max-width: 100%;
  padding: 2rem 0;
  margin: 0;
  background-size: cover;
  background-position: bottom;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  @media (min-width: 1025px) {
    padding: 0 30px;
  }
  @media (min-width: 1439px) {
    width: 1380px;
    margin: 0 auto;
  }
  img {
    height: 25px;
    padding-bottom: 3px;
  }
`;
export const Copyright = styled.div`
  margin: 0;
  padding: 0 1.25rem 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--default);
  a {
    transition: color 0.3s ease;
  }
`;
