import styled from "styled-components";

export const Container = styled.div`
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 5px;
  margin-bottom: 0.25rem;
  padding: 1.7rem;
  background-color: #fff;
`;

export const Content = styled.div`
  border-left: 5px solid ${({ scoreColor }) => scoreColor};
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
`;

export const Data = styled.div`
  margin-left: 0.5rem;
  flex-direction: column;
  display: flex;
  span {
    color: var(--gray);
    margin-bottom: 0.25rem;
  }
  small {
    color: var(--gray);
  }
`;

export const Title = styled.div`
  color: var(--gray-dark);
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`;

export const Text = styled.span`
  color: var(--gray);
  margin-bottom: 0.25rem;
  @media (max-width: 1200px) {
    font-size: 0.9rem;
  }
`;

export const Description = styled.small`
  color: var(--gray);
  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }
`;

export const ScoreAndButton = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    a,
    button {
      display: none;
    }
  }
`;
