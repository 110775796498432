import { Input } from "components/Input";
import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
} from "styles";

export function GraphConsolidado({
  dateRange,
  responseReport,
  filter,
  setFilter,
  setRunConsolidated,
  selection,
  setSelection,
  selections,
  reportConsolidated,
}) {
  const filters = [
    { value: "all", label: "Consolidado" },
    { value: "lineage", label: "Linhagem" },
    { value: "integrated", label: "Integrado" },
    { value: "nutrition", label: "Nutrição" },
    { value: "incubatory", label: "Incubatório" },
    { value: "technicalManager", label: "Técnico" },
    { value: "shedType", label: "Tipo Galpão" },
  ];

  const filterHandler = (e) => {
    setFilter(e.target.value);
    setRunConsolidated(true);
  };

  const selectionHandler = (key, value) => {
    const copySelection = { ...selection };
    copySelection[key] = value;
    setSelection(copySelection);
    setRunConsolidated(true);
  };

  return (
    <Block id="graphConsolidado">
      <BlockHeader>
        Resultado Consolidado: {format(dateRange[0].startDate, "dd-MM-yyyy")}{" "}
        Até {format(dateRange[0].endDate, "dd-MM-yyyy")}
        {responseReport.customer ? ` | ${responseReport.customer}` : ""}
      </BlockHeader>
      <BlockBody>
        <BlockBodyContent>
          <Row>
            <Col>
              <Input
                item={filter}
                setItem={setFilter}
                onChange={filterHandler}
                options={filters.map((item) => item)}
                label="Filtro"
                type="select"
              />
            </Col>
            <Col>
              <Input
                onChange={(e) => selectionHandler("x", e.target.value)}
                options={selections.map((item) => item)}
                name="x"
                value={selection.x}
                label="Resultado 1"
                type="select"
              />
            </Col>
            <Col>
              <Input
                onChange={(e) => selectionHandler("y", e.target.value)}
                options={selections.map((item) => item)}
                value={selection.y}
                label="Resultado 2"
                type="select"
              />
            </Col>
          </Row>
        </BlockBodyContent>
        <BlockBodyContent id="consolidated">
          <Line
            data={{ datasets: reportConsolidated.datasets }}
            options={{
              labels: reportConsolidated.labels,
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: false,
                    },

                    labels: reportConsolidated.labels,
                  },
                ],
                yAxes: [
                  {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-1",
                    gridLines: {
                      display: false,
                    },
                    labels: {
                      show: true,
                    },
                    ticks: {
                      beginAtZero: false,
                    },
                  },
                  {
                    type: "linear",
                    display: true,
                    position: "right",
                    id: "y-axis-2",
                    gridLines: {
                      display: false,
                    },
                    labels: {
                      show: true,
                    },
                    ticks: {
                      beginAtZero: false,
                    },
                  },
                ],
              },
              legend: {
                position: "right",
              },
            }}
          />
        </BlockBodyContent>
      </BlockBody>
    </Block>
  );
}
