import { Input } from "components/Input";

export const Row = ({ macroFormula, setMacroFormula, ingredient, selected, toggleSelect }) => {
  const phases = [
    { name: "preInitial", placeholder: "Pré-inicial" },
    { name: "initial", placeholder: "Inicial" },
    { name: "growth", placeholder: "Crescimento" },
    { name: "fatening", placeholder: "Engorda" },
    { name: "slaughter", placeholder: "Abate" },
  ];
  const handleChange = (e, path) => {
    setMacroFormula((prev) => {
      const newState = { ...prev };
      newState[path.row][path.column] = e.target.value;
      return newState;
    });
  };
  return (
    <tr>
       <td>
        <Input
          type="checkbox"
          checked={selected}
          onChange={toggleSelect}
        />
      </td>
      <td>
        <span>
          <b>{ingredient.name}</b>
        </span>
      </td>
      {phases.map((phase, index) => (
        <td key={index}>
          <Input
            type="input"
            inputType="number"
            placeholder={phase.placeholder}
            value={macroFormula[ingredient.row][phase.name] || ""}
            onChange={(e) =>
              handleChange(e, {
                row: ingredient.row,
                column: phase.name,
              })
            }
          />
        </td>
      ))}
    </tr>
  );
};
