import styled from "styled-components";

export const SvgMap = styled.svg`
  path {
    fill: #7e8299;
  }
  text {
    fill: #ffffff;
    font-size: 1rem;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }
  a:hover path {
    filter: brightness(0.9);
  }
  a:hover text {
    fill: #ffffff !important;
  }
  .circle {
    filter: brightness(0.6);
  }
  a:hover .circle {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;
