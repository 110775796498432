import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "components/Button/style";
import { Icons } from "components/Icons";
import { Modal } from "components/Modal";
import { api } from "services/api";
import { Tab, Tabs } from "@material-ui/core";
import ReactLoading from "react-loading";
import { ConsumeTab } from "./ConsumeTab";
import { LevelsTab } from "./LevelsTab";
import { MacroFormulaTab } from "./MacroFormulaTab";

export function CreateEditNutritionalLevels(props) {
  const columns = {
    preInitial: 0,
    initial: 0,
    growth: 0,
    fatening: 0,
    slaughter: 0,
  };
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [addedIngredients, setAddedIngredients] = useState([]);
  const [consume, setConsume] = useState({
    percentageConsume: { ...columns, weightedAverage: 0 },
    feedPerBird: { ...columns, weightedAverage: 0 },
  });
  const [dataId, setDataId] = useState({});
  const [levels, setLevels] = useState({
    energy: { ...columns },
    PB: { ...columns },
    CA: { ...columns },
    phosphor: { ...columns },
    lisinaDig: { ...columns },
    metCisDig: { ...columns },
    treoDig: { ...columns },
    valinaDig: { ...columns },
    isoleoDig: { ...columns },
    averagePricePerPhase: { ...columns, weightedAverage: 0 },
    paths: [
      { title: "Energia", row: "energy" },
      { title: "P.B.", row: "PB" },
      { title: "C.A.", row: "CA" },
      { title: "Fósforo", row: "phosphor" },
      { title: "Lisina Dig", row: "lisinaDig" },
      { title: "Met + Cis Dig", row: "metCisDig" },
      { title: "Treo Dig", row: "treoDig" },
      { title: "Valina Dig", row: "valinaDig" },
      { title: "Isoleo Dig", row: "isoleoDig" },
    ],
  });
  const [macroFormula, setMacroFormula] = useState({
    ingredients: [],
  });

  useEffect(() => {
    const getIngredients = async () => {
      try {
        const items = await api.get(`ingredients`);
        setIngredients(
          items.data.docs.map((doc) => ({ id: doc._id, value: doc.ingredient }))
        );
      } catch (error) {
        console.log(error.message);
      }
    };

    getIngredients();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        const response = await api.get(
          `nutritionalLevels/${props.match.params.id}/${props.id}`
        );

        if (!response.data) {
          Swal.fire(
            "Editar níveis nutricionais",
            "Não foi possível buscar os níveis nutricionais",
            "error"
          );
        } else {
          setConsume(response.data.content.consume);
          setLevels(response.data.content.levels);
          setMacroFormula(response.data.content.macroFormula);
        }
      }
      setLoading(false);
    };
    if (getData) {
      setGetData(false);
      loadData();
    }
  }, [props.id, props.match.params.id, getData]);

  const saveNutritionalLevels = async () => {
    const query = {
      content: {
        consume: { ...consume },
        levels: { ...levels },
        macroFormula: { ...macroFormula },
      },
      factory: props.match.params.id,
      dataId: { ...dataId },
    };

    const createData = async () => {
      const response = await api
        .post(`nutritionalLevels/${props.match.params.id}`, query)
        .catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        }));

      return response;
    };

    const updateData = async () => {
      const response = await api
        .put(`nutritionalLevels/${props.match.params.id}/${props.id}`, query)
        .catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        }));

      return response;
    };
    const response = await Swal.fire({
      title: "Salvar Níveis Nutricionais",
      text: "Deseja confirmar salvar Níveis Nutricionais",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Salvar Níveis Nutricionais!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const response = props.id ? await updateData() : createData();
        return response;
      },
    });
    if (response.value) {
      Swal.fire(
        "Criar Níveis Nutricionais",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      setDataId((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState._id = response.value.data.id;
        return newState;
      });
      props.setShow(false);
      props.setUpdateList(true);
    }
  };
  return (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#009870" size={30} />
          <h2>Níveis Nutricionais</h2>
        </>
      }
      footer={
        <Button
          color="white"
          bg="default"
          border="default"
          onClick={saveNutritionalLevels}
        >
          Salvar
        </Button>
      }
    >
      {loading ? (
        <ReactLoading type="spin" color="#009870" height={30} width={30} />
      ) : (
        <>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Consumo" />
            <Tab label="Níveis" />
            <Tab label="Níveis da Macro Fórmula" />
          </Tabs>
          {tab === 0 && (
            <ConsumeTab consume={consume} setConsume={setConsume} />
          )}
          {tab === 1 && (
            <LevelsTab
              levels={levels}
              setLevels={setLevels}
              columns={columns}
            />
          )}
          {tab === 2 && (
            <MacroFormulaTab
              macroFormula={macroFormula}
              setMacroFormula={setMacroFormula}
              ingredients={ingredients}
              addedIngredients={addedIngredients}
              setAddedIngredients={setAddedIngredients}
              columns={columns}
            />
          )}
        </>
      )}
    </Modal>
  );
}
