import styled from "styled-components";

export const ModuleContent = styled.div`
  border: 1px solid #ebedf2;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
`;

export const ModuleItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border-top: ${({ isChildren }) =>
    isChildren === "true" ? "1px dashed #ebedf2" : "1px solid #ebedf2"};
  :first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
`;

export const ModuleChildren = styled.div`
  padding-top: 5px;
`;
