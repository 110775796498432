import React from "react";
import isMobile from "is-mobile";
import { Input } from "components/Input";
import { Table } from "../../style";
import { Body, Header } from "components/Table/style";

const Steps = (props) => {
  return (
    <Table>
      <Header>
        <tr>
          <th>
            <span></span>
          </th>
          {isMobile() ? (
            <th>
              <span>Fase</span>
            </th>
          ) : (
            <>
              <th>
                <span>Pré</span>
              </th>
              <th>
                <span>Inicial</span>
              </th>
              <th>
                <span>Crescimento 1</span>
              </th>
              <th>
                <span>Crescimento 2</span>
              </th>
              <th>
                <span>Final</span>
              </th>
            </>
          )}
        </tr>
      </Header>
      <Body>
        <tr>
          <td>
            <span>
              <b>Produção:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                inputType="number"
                value={props.broiler.premixSteps.pre.feedProductionYear}
                label="Pré"
              />
              <Input
                type="input"
                inputType="number"
                value={props.broiler.premixSteps.initial.feedProductionYear}
                label="Inicial"
              />
              <Input
                type="input"
                inputType="number"
                value={props.broiler.premixSteps.growth.feedProductionYear}
                label="Crescimento 1"
              />
              <Input
                type="input"
                inputType="number"
                value={props.broiler.premixSteps.growth2.feedProductionYear}
                label="Crescimento 2"
              />
              <Input
                type="inputOnly"
                inputType="number"
                value={props.broiler.premixSteps.final.feedProductionYear}
                label="Final"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={props.broiler.premixSteps.pre.feedProductionYear}
                  placeholder="Pré"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={props.broiler.premixSteps.initial.feedProductionYear}
                  placeholder="Inicial"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={props.broiler.premixSteps.growth.feedProductionYear}
                  placeholder="Crescimento 1"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={props.broiler.premixSteps.growth2.feedProductionYear}
                  placeholder="Crescimento 2"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  inputType="number"
                  value={props.broiler.premixSteps.final.feedProductionYear}
                  placeholder="Final"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Forma Física:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.pre.physicalForm"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Pré"
                label="Pré"
              />

              <Input
                type="input"
                params="premixSteps.initial.physicalForm"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Inicial"
                label="Inicial"
              />

              <Input
                type="input"
                params="premixSteps.growth.physicalForm"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Crescimento 1"
                label="Crescimento 1"
              />

              <Input
                type="input"
                params="premixSteps.growth2.physicalForm"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Crescimento 2"
                label="Crescimento 2"
              />

              <Input
                type="input"
                params="premixSteps.final.physicalForm"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Final"
                label="Final"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.pre.physicalForm"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Pré"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.initial.physicalForm"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Inicial"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth.physicalForm"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 1"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth2.physicalForm"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 2"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.final.physicalForm"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Final"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Fornecedor:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.pre.provider"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Pré"
              />

              <Input
                type="input"
                params="premixSteps.initial.provider"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Inicial"
              />

              <Input
                type="input"
                params="premixSteps.growth.provider"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Crescimento 1"
              />

              <Input
                type="input"
                params="premixSteps.growth2.provider"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Crescimento 2"
              />

              <Input
                type="input"
                params="premixSteps.final.provider"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Final"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.pre.provider"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Pré"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.initial.provider"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Inicial"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth.provider"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 1"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth2.provider"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 2"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.final.provider"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Final"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Descrição Comum:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.pre.commonDescription"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Pré"
                label="Pré"
              />

              <Input
                type="input"
                params="premixSteps.initial.commonDescription"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Inicial"
                label="Inicial"
              />

              <Input
                type="input"
                params="premixSteps.growth.commonDescription"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Crescimento 1"
                label="Crescimento 1"
              />

              <Input
                type="input"
                params="premixSteps.growth2.commonDescription"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Crescimento 2"
                label="Crescimento 2"
              />

              <Input
                type="input"
                params="premixSteps.final.commonDescription"
                item={props.broiler}
                setItem={props.setBroiler}
                placeholder="Final"
                label="Final"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.pre.commonDescription"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Pré"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.initial.commonDescription"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Inicial"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth.commonDescription"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 1"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth2.commonDescription"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 2"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.final.commonDescription"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Final"
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>
            <span>
              <b>Classe PX:</b>
            </span>
          </td>
          {isMobile() ? (
            <td>
              <Input
                type="input"
                params="premixSteps.pre.pxClass"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Pré"
              />

              <Input
                type="input"
                params="premixSteps.initial.pxClass"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Inicial"
              />

              <Input
                type="input"
                params="premixSteps.growth.pxClass"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Crescimento 1"
              />

              <Input
                type="input"
                params="premixSteps.growth2.pxClass"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Crescimento 2"
              />

              <Input
                type="input"
                params="premixSteps.final.pxClass"
                item={props.broiler}
                setItem={props.setBroiler}
                label="Final"
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.pre.pxClass"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Pré"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.initial.pxClass"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Inicial"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth.pxClass"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 1"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.growth2.pxClass"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Crescimento 2"
                />
              </td>
              <td>
                <Input
                  type="inputOnly"
                  params="premixSteps.final.pxClass"
                  item={props.broiler}
                  setItem={props.setBroiler}
                  placeholder="Final"
                />
              </td>
            </>
          )}
        </tr>
      </Body>
    </Table>
  );
};

export default Steps;
