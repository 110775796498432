import React from "react";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { Table } from "../../style";

export function PerformanceEnhancer(props) {
  return (
    <Table>
      <Header>
        <tr>
          <th>
            <span></span>
          </th>
          <th>
            <span>Qual Produto?</span>
          </th>
          <th>
            <span>Qual PPM?</span>
          </th>
        </tr>
      </Header>
      <Body>
        <tr>
          <td>Pré Postura</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.preLay.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.preLay.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Pré Pico</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.prePeak.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.prePeak.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Pico de Postura</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layPeak.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layPeak.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 01</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal01.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal01.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 02</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal02.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal02.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 03</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal03.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal03.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 04</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal04.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal04.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 05</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal05.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal05.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
        <tr>
          <td>Postura 06</td>
          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal06.product"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
              placeholder="Digite o Produto"
            />
          </td>

          <td>
            <Input
              type="inputOnly"
              params="health.performanceEnhancer.layFinal06.dose"
              item={props.meggProduction}
              setItem={props.setMeggProduction}
            />
          </td>
        </tr>
      </Body>
    </Table>
  );
}
